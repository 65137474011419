import api from 'api';

export const getAll = () => api.get('/systemConfig');
export const get = name => api.get(`/systemConfig/key/${name}`);

export const set = (name, data) => api.post(`/systemConfig/key/${name}`, { ...data });
export const update = (name, data) => api.patch(`/systemConfig/key/${name}`, { ...data });

export const remove = name => api.delete(`/systemConfig/key/${name}`);

export const getBlockHelp = () => api.get('/systemConfig/blockHelp');

export const patchUpdateBlockHelp = data => api.patch('/systemConfig/blockHelp', { ...data });

export const updateTempWorkSpaceIntroductionHtml = data => api.patch('/systemConfig/tempWorkSpaceIntroduction', { ...data });

export const getTempWorkSpaceIntroductionHtml = (type) => {
  if (type) {
    return api.get(`/systemConfig/tempWorkSpaceIntroduction/${type}`);
  } else {
    return api.get('/systemConfig/tempWorkSpaceIntroduction');
  }
};
