import {
  pipe,
  path,
  map,
  objOf,
  converge,
  merge,
  type,
  equals,
  both,
  has,
  all,
  call,
} from 'ramda';

const log = require('debug')('ui:expressValidatorErrorParse.js');

/**
 * Extract errors from response.data and set message from statusText.
 *
 * @returns {Function} - An function that accept fromPromise
 * object argument and return {{errors: {Array}, message: {string}}.
 */
export function execute() {
  log('execute');
  const mapField = it => ({ field: it.param, message: it.msg });
  const mapErrors = pipe(
    path(['response', 'data']),
    map(mapField),
  );

  const getStatusText = path(['response', 'statusText']);
  const getMessage = pipe(getStatusText, objOf('message'));
  const getErrors = pipe(mapErrors, objOf('errors'));
  return converge(merge, [getMessage, getErrors]);
}

/**
 * Check if request should be resolve by this parser.
 *  - response.data should be an Array
 *  - all items of array should have msg and param property
 *
 * @returns {boolean} - If true {@link execute) will be execute.
 */
export function matchExecution() {
  log('matchExecution');
  const isArray = pipe(type, equals('Array'));
  const hasMsgAndParam = both(has('msg'), has('param'));
  const allPass = all(hasMsgAndParam);

  return pipe(
    path(['response', 'data']),
    both(isArray, allPass),
  );
}

export default [call(matchExecution), call(execute)];
