import Ajv from 'ajv';

const ajv = new Ajv();

// {"identifier":8,"sensor":[15,1,1,1]}

export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/sensor_feedback',
    },
    payload: {
      type: 'object',
      properties: {
        identifier: { type: 'number' },
        sensor: { type: 'array' },
      },
      required: ['identifier', 'sensor'],
    },
  },
  required: ['topic', 'payload'],
  additionalProperties: true,
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
