import classRoomStore from 'store/classRoomStore';
import mqttStore from 'store/mqttStore';
import userStore from 'store/userStore';

const log = require('debug')('ui:mqttProcessor:request');

const robotListRequest = (payload, originalString, classroomCode, reqId, cmd) => {
  mqttStore.publish(`to/classroom/${classroomCode}/response/${reqId}/${cmd}`, JSON.stringify(Object
    .entries(classRoomStore.classRoomRobots.toJSON())
    // eslint-disable-next-line max-len
    .reduce((acc, [macAddress, data]) => Object.assign(acc, { [macAddress]: data.identifier }), {})));
};

const robotListUpdate = (payload, originalString, classroomCode, reqId, cmd) => {
  // if (userStore.isAdmin || userStore.isTeacher) {
  classRoomStore.arrangeRobots(payload)
    .then(() => {
      if (userStore.isAdmin || userStore.isTeacher) {
        mqttStore.publish(`to/classroom/${classroomCode}/response/${reqId}/${cmd}`, JSON.stringify({
          code: 0,
          msg: 'OK',
        }));
      }
    });
  // }
};

export const executor = (packet) => {
  const { topic, payload } = packet;
  log(topic, payload);
  // classRoomStore.updateCameraStatus(payload.ws_server, payload.app_status);
  const regExp = /^to\/classroom\/(.*)\/request\/([^/]*)\/(.*)$/;
  const topicArgument = regExp.exec(topic);
  if (!topicArgument) {
    console.warn('It is not a classroom request mqtt topic pattern');
  } else {
    // eslint-disable-next-line no-unused-vars
    const [originalString, classroomCode, reqId, cmd] = topicArgument;
    switch (cmd) {
      case 'request_robot': {
        robotListRequest(payload, originalString, classroomCode, reqId, cmd);
        break;
      }
      case 'update_robot': {
        robotListUpdate(payload, originalString, classroomCode, reqId, cmd);
        break;
      }
      default: {
        console.warn(`Did not match any request cmd processor with \`${cmd}\``);
        break;
      }
    }
  }
};

export default executor;
