/* eslint-disable func-names */
/* eslint-disable prefer-destructuring */
import { getDropdownValue } from 'utils/blocklyUtils';
import * as blockChecker from './blockChecker';

export default blockChecker;

export const BLOCK_CHECKER__CHECK_ROBOT_OFFLINE = 'BLOCK_CHECKER__CHECK_ROBOT_OFFLINE';
export const BLOCK_CHECKER__CHECK_ROBOT_EXISTION = 'BLOCK_CHECKER__CHECK_ROBOT_EXISTION';

export const blockCheckerDecorator = function (...tags) {
  return function (target, name, _descriptor) {
    let originalFunc;
    function decoratorFunc(...args) {
      const block = args[0];
      blockChecker.cleanBlockWarning(block);
      const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
      if (Array.isArray(tags[0])) {
        tags = tags[0];
      }
      if (tags.indexOf(BLOCK_CHECKER__CHECK_ROBOT_EXISTION) > -1) {
        blockChecker.checkRobotOrObjectExistion(block, ROBOT);
      }
      if (tags.indexOf(BLOCK_CHECKER__CHECK_ROBOT_OFFLINE) > -1) {
        blockChecker.checkRobotOffine(block, ROBOT);
      }
      // if (tags.indexOf('checkSensorOnRobot') > -1) {
      //   blockChecker.checkSensorOnRobot(block, ROBOT, sensor.id);
      // }
      return originalFunc.apply(this, args);
    }
    if (arguments.length === 1) {
      originalFunc = target;
      return decoratorFunc;
    }
    const descriptor = _descriptor;
    originalFunc = descriptor.value;
    if (typeof originalFunc === 'function') {
      descriptor.value = decoratorFunc;
    }
    return descriptor;
  };
};
