/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable quote-props */
/* eslint-disable no-continue */
import _ from 'lodash';
import sysend from 'sysend';
import classroomStore from 'store/classRoomStore';
import {
  createNewSpreadsheet,
  appendData,
} from 'api/googlesheets';
import { statehookify } from './hookFactory';

const log = require('debug')('store: googlesheetsHook.js');

const mapSheetFieldsDict = {
  Sensors: {
    'Time': 'Time',
    'QRCodeId': 'Robot ID',
    'Gripper': 'Gripper',
    'Bright LED': 'Bright LED',
    'RGB-LED': 'RGB-LED',
    'buzzer': 'buzzer',
    'IR transmitter': 'IR transmitter',
    'Relay': 'Relay',
    'LED': 'LED',
    'Ultrasonic Distance Sensor': 'Ultrasonic Distance Sensor',
    'Waterproof Temperature Sensor': 'Waterproof Temperature Sensor',
    'humidity sensor': 'humidity sensor',
    'IR receiver': 'IR receiver',
    'push button': 'push button',
    'magnetic sensor': 'magnetic sensor',
    'vibration sensor': 'vibration sensor',
    'Tilt Switch': 'Tilt Switch',
    'knock sensor': 'knock sensor',
    'Touch Sensor': 'Touch Sensor',
    'sound sensor': 'sound sensor',
    'PIR Motion Sensor': 'PIR Motion Sensor',
    'limit switch': 'limit switch',
    'Switch': 'Switch',
    'IR Reflective Sensor': 'IR Reflective Sensor',
    'Rotary Angel Sensor': 'Rotary Angel Sensor',
    'Moisture Sensor': 'Moisture Sensor',
    'Light Sensor': 'Light Sensor',
    'steam sensor': 'steam sensor',
    'Water Sensor': 'Water Sensor',
    'Air Quality Sensor': 'Air Quality Sensor',
    'Sound Sensor': 'Sound Sensor',
    'Linear Potentiometer': 'Linear Potentiometer',
    'UV Sensor': 'UV Sensor',
    'Pulse Sensor': 'Pulse Sensor',
    'PH Sensor': 'PH Sensor',
    'MEMS Microphone': 'MEMS Microphone',
    '3-Axis Digital Compass': '3-Axis Digital Compass',
    'MPU6050 Accelerometer & Gyro': 'MPU6050 Accelerometer & Gyro',
    'Color Sensor': 'Color Sensor',
    'Laser Ranging Sensor': 'Laser Ranging Sensor',
    '4-Digit Display': '4-Digit Display',
    'LED Matrix': 'LED Matrix',
    'I2C EEPROM': 'I2C EEPROM',
    'Weight Sensor': 'Weight Sensor',
    'BME280 Atmospheric Sensor': 'BME280 Atmospheric Sensor',
    'BMP180 Barometer': 'BMP180 Barometer',
  },
  Battery: {
    'Time': 'Time',
    'QRCodeId': 'Robot ID',
    'Battery': 'Battery',
  },
  Positions: {
    'Time': 'Time',
    'QRCodeId': 'Robot ID',
    'X': 'X',
    'Y': 'Y',
    'Angle': 'Angle',
    'Distance': 'Distance',
    'Total Distance': 'Total Distance',
  },
};

function mapSheetFields(sheetTitle, fieldName) {
  if (mapSheetFieldsDict[sheetTitle]) {
    return mapSheetFieldsDict[sheetTitle][fieldName];
  }
  return null;
}


function mapSheetTitle_UI2Googlesheets(sheetTitle) {
  if (/.*Sensors/.test(sheetTitle)) {
    return 'Sensors';
  } else if (/.*Battery/.test(sheetTitle)) {
    return 'Battery';
  } else if (/.*Positions/.test(sheetTitle)) {
    return 'Positions';
  }
  return null;
}

class GooglesheetsHook {
  constructor() {
    statehookify('googlesheetsHook', this, {
      current_oauth_code: null,
      sync_timestamps: {},
      current_spreadsheet_id: null,
      current_spreadsheet_url: null,
      is_sync_on: true,
      is_creating_spreadsheet: false,
    });
    this._init();
  }
  allUnwatcher = []
  _init() {
    // start subscribe google oauth code
    this.allUnwatcher.push(this.subscribeGoogleOAuthCode((event) => {
      this.setHookStatePath('current_oauth_code', event.oauth_code);
    }));
    this._startCheck();
  }
  _startCheck() {
    setTimeout(async () => {
      const isSyncOn = this.getHookStatePath('is_sync_on');
      log('_startCheck isSyncOn: ', isSyncOn);
      if (!isSyncOn) {
        // ![reset sync_timestamps] set the last sync time to the current time to ignore all the new incoming data
        let allSyncTime = this.getHookStatePath('sync_timestamps') || {};
        allSyncTime = Object.keys(allSyncTime).reduce((acc, curr) => {
          return Object.assign(acc, {
            [curr]: Date.now(),
          });
        }, {});
        this.setHookStatePath('sync_timestamps', allSyncTime);
      } else {
        const currentOAuthCode = this.getHookStatePath('current_oauth_code');
        if (currentOAuthCode) {
          const sheetData = classroomStore.generateGoogleSheetData();
          let httpResp = null;
          let httpError = null;
          try {
            httpResp = await this.detectAndUploadNewData(sheetData);
            httpError = null;
          } catch (error) {
            httpError = error;
            httpResp = null;
          }
          this.statehookEmit('data_tick', httpError, httpResp);
        } else {
          console.log('no current_oauth_code');
        }
      }
      this._startCheck();
    }, 3 * 1e3);
  }
  _parseFieldForData(sheetTitle, row) {
    return Object.entries(row).reduce((acc, [key, value]) => {
      const newKey = mapSheetFields(sheetTitle, key);
      if (newKey) {
        if (newKey === 'Time') {
          const dt = new Date(value);
          acc.Date = dt.toLocaleDateString();
          acc.Time = dt.toLocaleTimeString();
        } else {
          acc[newKey] = value;
        }
      }
      return acc;
    }, {});
  }
  _parseDataForSheet(sheetTitle, rows) {
    const lastTimePath = ['sync_timestamps', sheetTitle];
    const lastTime = this.getHookStatePath(lastTimePath) || 0;
    const returnValue = [];
    let current = rows.length;
    let lastTimeInRows;
    while (current-- > 0) {
      const idx = current;
      const rowTime = _.get(rows[idx], 'Time');
      if (rowTime > lastTime) {
        returnValue.unshift(this._parseFieldForData(sheetTitle, rows[idx]));
        lastTimeInRows = lastTimeInRows || rowTime;
      } else {
        break;
      }
    }
    const newLastTime = lastTimeInRows || lastTime;
    this.setHookStatePath(lastTimePath, newLastTime);
    return { [sheetTitle]: returnValue };
  }
  createNewSpreadsheet() {
    const { classRoomCode } = classroomStore;
    const currentOAuthCode = this.getHookStatePath('current_oauth_code');
    return createNewSpreadsheet(classRoomCode, currentOAuthCode)
      .then((httpResp) => {
        this.setHookStatePath('current_spreadsheet_id', httpResp.data.newSpreadsheetData.spreadsheetId);
        return httpResp;
      });
  }
  // allData={
  //   position: [
  //     {time:xxxx, x:xxxx}
  //     {time:xxxx, x:xxxx}
  //   ],
  //   sensors: [
  //     {time: xxxx, abc: xxxx }
  //   ],
  //   Battery: [
  //     {time: xxxx, Battery: xxxx }
  //   ]
  // }
  detectAndUploadNewData(allData) {
    const currentOAuthCode = this.getHookStatePath('current_oauth_code');
    const currentSpreadsheetId = this.getHookStatePath('current_spreadsheet_id');
    log('detectAndUploadNewData currentOAuthCode, currentSpreadsheetId:', currentOAuthCode, currentSpreadsheetId);
    if (currentOAuthCode && currentSpreadsheetId) {
      const newData = Object.entries(allData).reduce((acc, [sheetTitle, dataRows]) => {
        return Object.assign(acc, this._parseDataForSheet(mapSheetTitle_UI2Googlesheets(sheetTitle), dataRows));
      }, {});
      const newDataArray = Object.entries(newData).reduce((acc, [sheetTitle, dataRows]) => {
        if (dataRows && dataRows.length > 0) {
          acc.push({
            sheetTitle,
            rows: dataRows,
          });
        }
        return acc;
      }, []);
      const { classRoomCode } = classroomStore;
      if (newDataArray && newDataArray.length > 0) {
        return appendData(classRoomCode, currentOAuthCode, currentSpreadsheetId, newDataArray);
      }
    }
    return null;
  }
  subscribeGoogleOAuthCode(cb) {
    cb = cb || (() => {});
    sysend.on('googlesheets/oauth_code', cb);
    return () => {
      sysend.off('googlesheets/oauth_code', cb);
    };
  }
  publishGoogleOAuthCode(oauthCode) {
    return sysend.broadcast('googlesheets/oauth_code', {
      oauth_code: oauthCode,
    });
  }
  clear() {
    while (this.allUnwatcher.length) this.allUnwatcher.pop()();
    this.setHookState({});
  }
}

function createGooglesheetsHook() {
  return new GooglesheetsHook();
}

export { createGooglesheetsHook };

export default createGooglesheetsHook();
