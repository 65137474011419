import {
  getAllClassroomStateStore,
  patchAllClassroomStateStore,
  getAllUserStateStore,
} from 'api/account';
import { debounce } from 'debounce-decorator';
import { statehookify } from './hookFactory';


class AccountStateStoreHook {
  constructor() {
    statehookify('accountStateStoreHook', this, {});
  }
  // individual
  loadUserStore() {
    // return getAllUserStateStore()
    return getAllUserStateStore()
      .then((res) => {
        this.setHookStatePath('userStore', res.data);
        return res;
      });
  }

  getCurrentClassroom() {
    return this.getHookStatePath('current_classroom_code');
  }

  // classroom
  loadClassroomStore(classroomCode) {
    return getAllClassroomStateStore(classroomCode)
      .then((res) => {
        this.setHookStatePath(`classroomStore.${classroomCode}`, res.data.data);
        return res;
      });
  }
  getClassroomStore(classroomCode, key) {
    const allState = this.getHookStatePath(`classroomStore.${classroomCode}`);
    if (key !== undefined) return allState[key];
    return allState;
  }
  // classroom info
  patchCurrentClassroomInfo(classroomCode, workType, refId) {
    return patchAllClassroomStateStore(classroomCode, {
      current_workspace_type: workType,
      current_workspace_ref_id: refId,
    })
      .then(() => {
        return this.loadClassroomStore(classroomCode);
      });
  }
  patchCurrentClassroomInfoWithDebounce = debounce(this.patchCurrentClassroomInfo, 1 * 1e3)

  // workspace info
  patchCurrentWorkspaceInfo(classroomCode, xml) {
    return patchAllClassroomStateStore(classroomCode, {
      current_classroom_workspace_xml: xml,
    })
      .then(() => {
        return this.loadClassroomStore(classroomCode);
      });
  }
  patchCurrentWorkspaceInfoWithDebounce = debounce(this.patchCurrentWorkspaceInfo, 1 * 1e3)
}

export default new AccountStateStoreHook();
