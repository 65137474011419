/* eslint-disable class-methods-use-this */
import { autobind } from 'core-decorators';
import { action, observable, computed } from 'mobx';
import { statehookify } from './statehook/hookFactory';

const log = require('debug')('ui:uiStore');

const KEY_ROBOT_SENSOR_MODAL_DATA = 'robotSensor/modalData';
const KEY_ELFINDER_MODAL = 'elfinder';

export class UiStore {
  @observable isViewsVisible = false;
  @observable activatedSideMenu = undefined;
  // @observable highlightBlockIds = observable.map({}); // just a set, use its keys
  @observable isProjectsModalActive = false;
  @observable isMediaExplorerActive = false;
  @observable isSwitchClassroomActive = false;
  @observable isPathFindingEditorActive = false;
  // @observable viewsWidth = 570;
  @observable viewsWidth = 500;
  @observable workspaceMarginLeftWhenViewOn = -70;
  @observable statusBarHeight = 130;
  @observable isStatusBarVisible = false;
  @observable activatedBlocklyToolboxMode = 'beginner/';
  @observable isSearchResultVisible = false;
  @observable isKaiMascotVisible = false;
  @observable isGuidePageVisible = false;
  @observable language = 'en';
  @observable isBlocklyReadOnly = false;
  leaveConfirmFunction = null;

  constructor() {
    statehookify('uiStore', this, {});
  }

  get isTestingDomain() {
    return /^app-testing\..*/.test(window.location.hostname);
  }
  get isAssistDomain() {
    return /^assist\..*/.test(window.location.hostname);
  }
  get isAppDomain() {
    return /^app\..*/.test(window.location.hostname);
  }

  @computed get isClassroomChatVisible() {
    return `${this.activatedSideMenu}`.startsWith('classroomChat/');
  }

  @computed get isStudentConfigurationVisible() {
    return `${this.activatedSideMenu}`.startsWith('student_configuration/');
  }

  @computed get isRobotConfigurationVisible() {
    return `${this.activatedSideMenu}`.startsWith('robot_configuration/');
  }

  @computed get isMapVisible() {
    return `${this.activatedSideMenu}`.startsWith('map/');
  }

  @computed get isCodeVisible() {
    return `${this.activatedSideMenu}`.startsWith('code/');
  }

  @computed get isHelpVisible() {
    return `${this.activatedSideMenu}`.startsWith('help/');
  }

  @computed get isTeacherHelpVisible() {
    return `${this.activatedSideMenu}`.startsWith('help/teacherHelp/');
  }

  @computed get isStudentHelpVisible() {
    return `${this.activatedSideMenu}`.startsWith('help/studentHelp/');
  }

  @computed get isBlockHelpVisible() {
    return `${this.activatedSideMenu}`.startsWith('help/blockHelp/');
  }

  @computed get isConsoleVisible() {
    return `${this.activatedSideMenu}`.startsWith('console/');
  }

  @computed get isMap3DVisible() {
    return `${this.activatedSideMenu}`.startsWith('map3D/');
  }

  @computed get currentVisibleView() {
    return this.activatedSideMenu;
  }

  @computed get isBeginnerModeToolbox() {
    return this.activatedBlocklyToolboxMode.startsWith('beginner/');
  }

  @computed get isAdvancedModeToolbox() {
    return this.activatedBlocklyToolboxMode.startsWith('advanced/');
  }

  get highlightBlockIds() {
    return this.getHookStatePath('highlightBlockIds');
  }

  @action.bound setBegginerModelToolboxActive(value) {
    this.activatedBlocklyToolboxMode = value ? 'beginner/' : 'advanced/';
  }

  @action.bound setAdvancedModelToolboxActive(value) {
    this.activatedBlocklyToolboxMode = value ? 'advanced/' : 'beginner/';
  }

  @action.bound setViewsWidth(value) {
    this.viewsWidth = value;
  }

  @action.bound setViewsVisible(value) {
    // this.isViewsVisible = !!value;
    this.showViewsWithAnimation(!!value);
  }

  @action.bound changeToViewByName(toTheViewName, extra) {
    const oldViewName = this.getHookStatePath('activatedSideMenu');
    const newViewName = `${toTheViewName}`;
    log(oldViewName, newViewName, 'newViewName');
    return Promise.resolve()
      .then(() => {
        if (oldViewName !== newViewName) {
          return this.callViewLeavingConfirmFunction(oldViewName);
        }
        return true;
      })
      .then((res) => {
        if (res === false) {
          return false;
        }
        this.activatedSideMenu = newViewName;
        this.setHookStatePath('activatedSideMenu', newViewName);
        if (extra) {
          this.setViewLeavingConfirmFunction(newViewName, extra.leavingConfirmFunction);
        }
        return true;
      });
  }
  @action.bound setViewLeavingConfirmFunction(viewKey, fn) {
    if ('function' === typeof fn) {
      this.setHookStatePath(['sideMenuLeavingConfirmFunction', `${viewKey}`], fn);
    }
  }
  @action.bound getViewLeavingConfirmFunction(viewKey) {
    return this.getHookStatePath(['sideMenuLeavingConfirmFunction', `${viewKey}`]);
  }
  @action.bound callViewLeavingConfirmFunction(viewKey, ...args) {
    const func = this.getViewLeavingConfirmFunction(viewKey);
    if ('function' === typeof func) {
      return func(...args);
    }
    return Promise.resolve(true);
  }
  @action.bound callCurrentViewLeavingConfigrmFunction(...args) {
    const currentViewName = this.getHookStatePath('activatedSideMenu');
    return this.callViewLeavingConfirmFunction(currentViewName, ...args);
  }
  @action.bound setStudentConfigurationVisible(value, extra) {
    return this.changeToViewByName(value ? 'student_configuration/' : undefined, extra);
  }

  @action.bound setRobotConfigurationVisible(value, extra) {
    return this.changeToViewByName(value ? 'robot_configuration/' : undefined, extra);
  }

  @action.bound setMapVisible(value, extra) {
    return this.changeToViewByName(value ? 'map/' : undefined, extra);
  }

  @action.bound setCodeVisible(value, extra) {
    return this.changeToViewByName(value ? 'code/' : undefined, extra);
  }

  @action.bound setHelpVisible(value, extra) {
    return this.changeToViewByName(value ? 'help/' : undefined, extra);
  }

  @action.bound setBlockHelpVisible(value, extra) {
    return this.changeToViewByName(value ? 'help/blockHelp/' : undefined, extra);
  }

  @action.bound setTeacherHelpVisible(value, extra) {
    return this.changeToViewByName(value ? 'help/teacherHelp/' : undefined, extra);
  }

  @action.bound setStudentHelpVisible(value, extra) {
    return this.changeToViewByName(value ? 'help/studentHelp/' : undefined, extra);
  }

  @action.bound setConsoleVisible(value, extra) {
    return this.changeToViewByName(value ? 'console/' : undefined, extra);
  }

  @action.bound setMap3DVisible(value, extra) {
    return this.changeToViewByName(value ? 'map3D/' : undefined, extra);
  }

  @action.bound setClassroomChatVisible(value, extra) {
    return this.changeToViewByName(value ? 'classroomChat/' : undefined, extra);
  }

  @action.bound setNoVisible(extra) {
    return this.changeToViewByName(undefined, extra);
  }

  // view statehook

  @autobind
  getStudentConfigurationVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('student_configuration/');
  }

  @autobind
  getRobotConfigurationVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('robot_configuration/');
  }

  @autobind
  getMapVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('map/');
  }

  @autobind
  getCodeVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('code/');
  }

  @autobind
  getHelpVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('help/');
  }

  @autobind
  getTeacherHelpVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('help/teacherHelp/');
  }

  @autobind
  getStudentHelpVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('help/studentHelp/');
  }

  @autobind
  getBlockHelpVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('help/blockHelp/');
  }

  @autobind
  getConsoleVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('console/');
  }

  @autobind
  getMap3DVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('map3D/');
  }

  @autobind
  getClassroomChatVisibleState() {
    return `${this.getCurrentVisibleView()}`.startsWith('classroomChat/');
  }

  @autobind
  getCurrentVisibleView() {
    return this.getHookStatePath('activatedSideMenu');
  }

  @autobind
  watchCurrentVisibleView(cb) {
    return this.watchHookStatePath('activatedSideMenu', cb);
  }

  @autobind
  setConfirmFunctionWhenSwitcView(fn) {
    return this.setHookStatePath('confirmFunctionWhenSwitcView', fn);
  }

  // view Statehook END

  @action.bound setProjectsModalVisibility(value) {
    this.isProjectsModalActive = value;
  }

  @action.bound setMediaExplorerActive(value) {
    this.isMediaExplorerActive = value;
  }

  @action.bound setHighlightBlock(blockId) {
    // this.highlightBlockIds.set(blockId, blockId);
    this.updateHookStatePath('highlightBlockIds', (oldValue) => {
      return Object.assign({}, oldValue || {}, {
        [blockId]: blockId,
      });
    });
  }

  @action.bound clearHighlightBlock(blockId) {
    this.updateHookStatePath('highlightBlockIds', (oldValue) => {
      const newValue = Object.assign({}, oldValue || {});
      delete newValue[blockId];
      return newValue;
    });
  }
  @action.bound clearHighlightBlocks() {
    // this.highlightBlockIds.clear();
    this.setHookStatePath('highlightBlockIds', {});
  }

  @action.bound setSwitchClassroomActive(value) {
    this.isSwitchClassroomActive = value;
  }

  @action.bound setPathFindingEditorActive(value) {
    this.isPathFindingEditorActive = value;
  }

  @action.bound setStatusBarHeight(value) {
    this.statusBarHeight = value;
  }

  @action.bound setStatusBarVisible(value) {
    this.isStatusBarVisible = !!value;
  }

  @action.bound setKaiMascotVisible(value) {
    this.isKaiMascotVisible = !!value;
  }

  @action.bound setGuidePageVisible(value) {
    this.isGuidePageVisible = !!value;
  }

  setViewsVisibleProgress(progress) {
    setTimeout(() => {
      if (progress > 1) progress = 1;
      if (progress < 0) progress = 0;
      this.isViewsVisible = progress > 0;
      this.setHookStatePath('ViewVisibleProgress', progress);
    }, 0);
  }
  getViewsVisibleProgress() {
    return this.getHookStatePath('ViewVisibleProgress') || 0;
  }
  watchViewsVisibleProgress(cb) {
    return this.watchHookStatePath('ViewVisibleProgress', (eventType, path, value) => {
      cb(value);
    });
  }

  watchPath(path, cb) {
    return this.watchHookStatePath(path, cb);
  }

  watch(...rest) {
    return this.watchHookState(...rest);
  }

  setLeaveConfirm(confirmFunction) {
    this.leaveConfirmFunction = confirmFunction;
  }

  removeLeaveConfirm() {
    this.leaveConfirmFunction = null;
  }

  leaveConfirm() {
    if (typeof this.leaveConfirmFunction === 'function') return this.leaveConfirmFunction();
    return true;
  }

  currentShowAnimationHD = 0
  showViewsWithAnimation(isShow, cb) {
    cb = cb || (() => {});
    clearInterval(this.currentShowAnimationHD);
    const duration = 0.3;
    const frameRatePerMinute = 30;
    const speedMS = 1e3 / frameRatePerMinute;
    const totalFrameNumber = duration * frameRatePerMinute;
    const startTime = Date.now();
    // const endTime = startTime + (duration * 1e3);
    let progressCurrent = this.getViewsVisibleProgress();
    log('progressCurrent', progressCurrent);
    if (isShow) {
      // this.setViewsVisibleProgress(progressCurrent);
      this.currentShowAnimationHD = setInterval(() => {
        log('progressCurrent: +', progressCurrent);
        progressCurrent = Number(Number(progressCurrent + (1 / totalFrameNumber)).toFixed(4));
        this.setViewsVisibleProgress(progressCurrent);
        // if (Date.now() >= endTime) {
        if (progressCurrent >= 1) {
          progressCurrent = 1;
          this.setViewsVisibleProgress(progressCurrent);
          this.setViewsVisibleProgress(progressCurrent);
          clearInterval(this.currentShowAnimationHD);
          log('progressCurrent: + time gap', startTime - Date.now());
        }
        cb({
          progress: progressCurrent,
        });
      }, speedMS);
    } else {
      // this.setViewsVisibleProgress(progressCurrent);
      this.currentShowAnimationHD = setInterval(() => {
        log('progressCurrent: -', progressCurrent);
        progressCurrent = Number(Number(progressCurrent - (1 / totalFrameNumber)).toFixed(4));
        this.setViewsVisibleProgress(progressCurrent);
        // if (Date.now() >= endTime) {
        if (progressCurrent <= 0) {
          progressCurrent = 0;
          this.setViewsVisibleProgress(progressCurrent);
          this.setViewsVisibleProgress(progressCurrent);
          clearInterval(this.currentShowAnimationHD);
          log('progressCurrent: - time gap', startTime - Date.now());
        }
        cb({
          progress: progressCurrent,
        });
      }, speedMS);
    }
  }

  // sandbox inner window
  setWebGLInnerWindowSize(width, height) {
    return this.setHookStatePath('webGLInnerWindow.size', { width, height });
  }
  get webGLInnerWindowSize() {
    return this.getHookStatePath('webGLInnerWindow.size') || {};
  }
  watchWebGLInnerWindowSize(cb) {
    return this.watchHookStatePath('webGLInnerWindow.size', cb);
  }

  set webGLInnerWindowVisibleState(state) {
    return this.setHookStatePath('webGLInnerWindow.visible', !!state);
  }
  get webGLInnerWindowVisibleState() {
    return this.getHookStatePath('webGLInnerWindow.visible');
  }
  watchWebGLInnerWindowVisibleState(cb) {
    return this.watchHookStatePath('webGLInnerWindow.visible', (eventType, path, value) => {
      cb(!!value);
    });
  }
  // sandbox inner window END


  // simplifier inner window
  setWebGLSimplifierInnerWindowSize(width, height) {
    return this.setHookStatePath('webgl_simplifier_inner_window.size', { width, height });
  }
  get webGLSimplifierInnerWindowSize() {
    return this.getHookStatePath('webgl_simplifier_inner_window.size') || {};
  }
  watchWebGLSimplifierInnerWindowSize(cb) {
    return this.watchHookStatePath('webgl_simplifier_inner_window.size', cb);
  }

  set webGLSimplifierInnerWindowVisibleState(state) {
    return this.setHookStatePath('webgl_simplifier_inner_window.visible', !!state);
  }
  get webGLSimplifierInnerWindowVisibleState() {
    return this.getHookStatePath('webgl_simplifier_inner_window.visible');
  }
  watchWebGLSimplifierInnerWindowVisibleState(cb) {
    return this.watchHookStatePath('webgl_simplifier_inner_window.visible', (eventType, path, value) => {
      cb(!!value);
    });
  }
  // simplifier inner window END

  // robotSensorModalVisibleState
  set robotSensorModalVisibleState(state) {
    return this.setHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.visible`, state);
  }
  get robotSensorModalVisibleState() {
    return !!this.getHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.visible`);
  }
  watchRobotSensorModalVisibleState(cb) {
    return this.watchHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.visible`, (eventType, path, value) => {
      cb(!!value);
    });
  }
  // robotSensorModalData
  set robotSensorModalData(data) {
    return this.setHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.data`, data);
  }
  get robotSensorModalData() {
    return this.getHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.data`);
  }
  watchRobotSensorModalData(cb) {
    return this.watchHookStatePath(`${KEY_ROBOT_SENSOR_MODAL_DATA}.data`, (eventType, path, value) => {
      cb(value);
    });
  }


  // elfinder
  set elfinderVisibleState(state) {
    return this.setHookStatePath(`${KEY_ELFINDER_MODAL}.visible`, state);
  }
  get elfinderVisibleState() {
    return this.getHookStatePath(`${KEY_ELFINDER_MODAL}.visible`);
  }
  watchELFinderVisibleState(cb) {
    return this.watchHookStatePath(`${KEY_ELFINDER_MODAL}.visible`, cb);
  }

  set currentCodeWorkspceEditorType(newValue) {
    return this.setHookStatePath('currentCodeWorkspceEditor.type', newValue);
  }
  get currentCodeWorkspceEditorType() {
    return this.getHookStatePath('currentCodeWorkspceEditor.type');
  }
  watchCurrentCodeWorkspceEditorType(cb) {
    return this.watchHookStatePath('currentCodeWorkspceEditor.type', cb);
  }
  changeCurrentEditorToCodeBlockly() {
    this.currentCodeWorkspceEditorType = 'CODE_BLOCKLY';
  }
  changeCurrentEditorToNormalBlockly() {
    this.currentCodeWorkspceEditorType = 'NORMAL_BLOCKLY';
  }
  // codeBlocklyWorkspace
  get codeBlocklyWorkspaceVisibleState() {
    return this.currentCodeWorkspceEditorType === 'CODE_BLOCKLY';
  }
  get normalBlocklyWorkspaceVisibleState() {
    return this.currentCodeWorkspceEditorType === 'NORMAL_BLOCKLY';
  }
}

const uiStore = new UiStore();
export default uiStore;
