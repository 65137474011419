import { statehookify } from './hookFactory';

class ClassroomAssistanceHook {
  constructor() {
    statehookify('classroomAssistanceHook', this, {});
  }
  setAssistanceCode(code) {
    this.setHookStatePath('assistanceCode', code);
  }
  getAssistanceCode() {
    this.getHookStatePath('assistanceCode');
  }
}

export default new ClassroomAssistanceHook();
