import axios from 'axios';
import userStore from 'store/userStore';
import webStorageStore from 'store/webStorageStore';
import { getNS } from '_i18n_';
// import history from '../../history';
import _ from 'lodash';

const _t = getNS('gotoLoginPageWhenNotAuthorized.js');

export default async function (errResp) {
  if (!errResp) return Promise.reject(errResp);

  if (errResp.response) {
    const res = errResp.response;
    if (res.status === 401) {
      const token = webStorageStore.getItem('jwt');
      if (token && !errResp.config.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        errResp.config.headers.Authorization = token;
        return Promise.resolve(axios(errResp.config));
      }
      await userStore.logout();
      await kaiAlert.fire({
        title: _t('Oops! You have been disconnected, because someone else logged into your account.'),
        html: _.get(res.data, 'error') || res.data || res.statusText,
        type: 'error',
      });
      // history.push('/');
      window.location = '/';
      // return false;
      // return Promise.reject(errResp);
    }
  }

  return Promise.reject(errResp);
  // return Promise.reject(resp);
  // return Promise.resolve(errResp);
}
