/* eslint-disable import/prefer-default-export */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable prefer-template */
/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable object-shorthand */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-restricted-globals */
/* eslint-disable prefer-spread */
/* eslint-disable no-plusplus */
/* eslint-disable camelcase */

import StateCore from 'statecore';

var protoHasOwn = Object.prototype.hasOwnProperty;
function _hasOwn(obj, key) {
  if (obj === undefined || obj === null) return false;
  return protoHasOwn.call(obj, key);
}
var ptotoToString = Object.prototype.toString;
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/isArray#Polyfill
var ARRAY_TYPE_STRINGIFY = Object.prototype.toString.call([]);
function _isArray(variable) {
  return ptotoToString.call(variable) === ARRAY_TYPE_STRINGIFY;
}

function __genUUID() {
  return Date.now() + '__' + Math.random();
}

function _isNone(value) {
  if (value === undefined || null === value) return true;
  if (isNaN(value) && 'number' === typeof value) return true;
  return false;
}

function _isPromise(obj) {
  return !!obj && (typeof obj === 'object' || typeof obj === 'function') && typeof obj.then === 'function';
}

function _objectAssign(target) {
  if (_isNone(target)) throw new TypeError('Cannot convert undefined or null to object');
  var isTargetArray = _isArray(target);
  var to = Object(target);
  var _nextSource;
  if (isTargetArray) {
    for (var iArray = 1; iArray < arguments.length; iArray += 1) {
      _nextSource = arguments[iArray];
      if (_isArray(_nextSource)) {
        to.push.apply(to, _nextSource);
      } else {
        to.push.call(to, _nextSource);
      }
    }
  } else {
    for (var iObject = 1; iObject < arguments.length; iObject += 1) {
      _nextSource = Object(arguments[iObject]);
      if (_nextSource) {
        for (var nextKey in _nextSource) {
          if (_hasOwn(_nextSource, nextKey)) {
            to[nextKey] = _nextSource[nextKey];
          }
        }
      }
    }
  }
  return to;
}

function _objectKeys(obj) {
  var keys = [];
  for (var key in obj) {
    keys.push(key);
  }
  return keys;
}

function _objectKeysForEach(obj, cb) {
  var keys = _objectKeys(obj);
  for (var idx in keys) {
    var oKey = keys[idx];
    if (_hasOwn(obj, oKey)) {
      if (cb(oKey, obj) === false) {
        return;
      }
    }
  }
}

function _bind(func, bindThis) {
  if (func.bind) return func.bind(bindThis);
  return function () {
    return func.apply(bindThis, arguments);
  };
}

function _functionsExtendTo(funcs, bindThis) {
  if ('function' === typeof funcs) funcs = [funcs];
  var container = null;
  if (_isArray(funcs)) {
    throw new Error('The first argument must be a dictionary: {}');
  } else {
    container = {};
  }
  bindThis = bindThis || funcs;
  _objectKeysForEach(funcs, (key) => {
    if (_hasOwn(funcs, key)) {
      if (Object.getOwnPropertyDescriptor) {
        var desc = Object.getOwnPropertyDescriptor(funcs, key);
        if (desc.get || desc.set) {
          if (desc.get) {
            desc.get = _bind(desc.get, bindThis);
          }
          if (desc.set) {
            desc.set = _bind(desc.set, bindThis);
          }
          desc = _objectAssign({}, desc);
          Object.defineProperty(bindThis, key, desc);
          container[key] = desc;
          return;
        }
      }
      if ('function' === typeof funcs[key]) {
        bindThis[key] = _bind(funcs[key], bindThis);
        container[key] = bindThis[key];
      }
    }
  });
  return container;
}

// function _reduce(obj, cb, initObj) {
//   for (var key in obj) {
//     if (_hasOwn(obj, key)) {
//       cb(initObj, key, obj[key]);
//     }
//   }
// }

function _parsePath(path) {
  if (_isArray(path)) return path;
  if (!path) return [];
  path += '';
  if (path[0] === '[') {
    var pathStrLen = path.length;
    if (path[pathStrLen - 1] === ']') {
      if (path[1] === '"' && path[pathStrLen - 2] === '"') {
        try {
          return JSON.parse(path);
        } catch (error) {
          // oops, no json
        }
      }
      return [path.substring(1, pathStrLen - 1)];
    }
  }
  return path.split('.');
}

function _pathStatus(arg, path) {
  arg = arg || {};
  for (var idx = 0; idx < path.length; idx += 1) {
    var key = path[idx];
    if (_hasOwn(arg, key)) {
      arg = arg[key];
    } else {
      return { exists: false };
    }
  }
  return { exists: true, value: arg };
}
function _pathGetter(arg, path) {
  return _pathStatus(arg, path).value;
}
function _pathSetter(argVar, path, value, onlyWhenParentExists) {
  var rootVar = argVar || {};
  var currentVar = rootVar;
  var parentVar = currentVar;
  var key;
  for (var idx = 0; idx < path.length; idx += 1) {
    key = path[idx];
    if (!_hasOwn(currentVar, key)) {
      if (onlyWhenParentExists) {
        throw new Error('Parent node ' + key + ' does not exist');
      } else {
        currentVar[key] = {};
      }
    }
    parentVar = currentVar;
    currentVar = currentVar[key];
  }
  parentVar[key] = value;
  return rootVar;
}

function _pathDelete(argVar, path) {
  if (!argVar) return argVar;
  var rootVar = argVar || {};
  var currentVar = rootVar;
  var parentVar = currentVar;
  var key;
  for (var idx = 0; idx < path.length; idx += 1) {
    key = path[idx];
    if (!_hasOwn(currentVar, key)) {
      return rootVar;
    }
    parentVar = currentVar;
    currentVar = currentVar[key];
  }
  delete parentVar[key];
  return rootVar;
}

function _isSameArray(pattern, input) {
  if (pattern.length !== input.length) return false;
  for (var idx = 0; idx < pattern.length; idx += 1) {
    var patternCurr = pattern[idx];
    var inputCurr = input[idx];
    if (patternCurr instanceof RegExp) {
      if (!patternCurr.test(inputCurr)) {
        return false;
      }
    } else if (patternCurr + '' !== inputCurr + '') {
      return false;
    }
  }
  return true;
}

function _isBeforeOrSameArray(pattern, input) {
  if (pattern.length < input.length) return false;
  for (var idx = 0; idx < input.length; idx += 1) {
    var patternCurr = pattern[idx];
    var inputCurr = input[idx];
    if (patternCurr instanceof RegExp) {
      if (!patternCurr.test(inputCurr)) {
        return false;
      }
    } else if (patternCurr + '' !== inputCurr + '') {
      return false;
    }
  }
  return true;
}

function _isBeforeArray(pattern, input) {
  if (pattern.length <= input.length) return false;
  for (var idx = 0; idx < input.length; idx += 1) {
    var patternCurr = pattern[idx];
    var inputCurr = input[idx];
    if (patternCurr instanceof RegExp) {
      if (!patternCurr.test(inputCurr)) {
        return false;
      }
    } else if (patternCurr + '' !== inputCurr + '') {
      return false;
    }
  }
  return true;
}


function _isAfterOrSameArray(pattern, input) {
  if (pattern.length > input.length) return false;
  for (var idx = 0; idx < pattern.length; idx += 1) {
    var patternCurr = pattern[idx];
    var inputCurr = input[idx];
    if (patternCurr instanceof RegExp) {
      if (!patternCurr.test(inputCurr)) {
        return false;
      }
    } else if (patternCurr + '' !== inputCurr + '') {
      return false;
    }
  }
  return true;
}

function _isAfterArray(pattern, input) {
  if (pattern.length >= input.length) return false;
  for (var idx = 0; idx < pattern.length; idx += 1) {
    var patternCurr = pattern[idx];
    var inputCurr = input[idx];
    if (patternCurr instanceof RegExp) {
      if (!patternCurr.test(inputCurr)) {
        return false;
      }
    } else if (patternCurr + '' !== inputCurr + '') {
      return false;
    }
  }
  return true;
}


var allStatehookInstances = {};
var hookNameCounter = 1;
export const createStatehook = function (opt, argInitPath) {
  opt = opt || {};
  if ('string' === typeof opt) opt = { hookName: opt };
  var hookName = opt.hookName;
  if (hookName) {
    if (allStatehookInstances[hookName]) {
      return allStatehookInstances[hookName];
    }
  } else {
    hookName = ('unnamed_hook__' + (++hookNameCounter));
  }
  var __destroyFunc = null;
  var __hookStateUpdateEventName = __genUUID();
  var __stateCoreInstance = StateCore.createStateCore({});
  var __initPath = {};
  var __unwatcherDefaultNS = 'default';
  var __unwatchers = {};
  var __statehookThis = {};
  _objectAssign(__statehookThis, {
    hookName: hookName,
    statehookCloneFunctions: function () {
      return _objectAssign({}, __statehookThis);
    },
    statehookNotifyAllObservers: __stateCoreInstance.notifyAllObservers,
    statehookAddObserver: __stateCoreInstance.addObserver,
    statehookDiscard: function () {
      if ('function' === typeof __destroyFunc) {
        __destroyFunc();
      }
      __statehookThis.statehookUnwatchAll();
      _objectKeysForEach(__initPath, (key) => {
        delete __initPath[key];
      });
      delete allStatehookInstances[hookName];
      __stateCoreInstance.discard();
    },
    statehookIsDiscarded: __stateCoreInstance.isDiscarded,
    statehookGetState: __stateCoreInstance.getState,
    statehookSetState: __stateCoreInstance.setState,

    statehookWatch: __stateCoreInstance.addObserver,

    statehookAddUnwatcher: function (cb, ns) {
      ns = ns || __unwatcherDefaultNS;
      __unwatchers[ns] = __unwatchers[ns] || [];
      __unwatchers[ns].push(cb);
      return cb;
    },

    getHookState: __stateCoreInstance.getState,
    statehookEmit: __stateCoreInstance.notifyAllObservers,
    statehookOn: function (eventName, func) {
      if (!_isArray(eventName)) {
        eventName = eventName.split('/');
      }
      return __stateCoreInstance.addObserver((_eventName, ...args) => {
        if (!_isArray(_eventName)) {
          _eventName = _eventName.split('/');
        }
        if (_isSameArray(eventName, _eventName)) {
          func(_eventName, ...args);
        }
      });
    },

    setHookState: function (value) {
      var newState = __stateCoreInstance.setState(value);
      __stateCoreInstance.notifyAllObservers(__hookStateUpdateEventName, null, value, 'set', 'setHookState');
      return newState;
    },

    getHookStatePath: function (path) {
      if (arguments.length === 0) {
        return __stateCoreInstance.getState();
      }
      path = _parsePath(path);
      return _pathGetter(__stateCoreInstance.getState(), path);
    },

    setHookStatePath: function (path, value) {
      if (arguments.length < 2) {
        // return __stateCoreInstance.setState(path);
        __statehookThis.setHookState(path);
      } else {
        path = _parsePath(path);
        var state = __stateCoreInstance.getState();
        __stateCoreInstance.setState(_objectAssign({}, _pathSetter(state, path, value)));
        __stateCoreInstance.notifyAllObservers(__hookStateUpdateEventName, path, value, 'set', 'setHookStatePath');
      }
    },

    setHookStateManyPaths: function (values, basePath) {
      basePath = _parsePath(basePath);
      if (_isArray(values)) {
        // [{key1: value1, key2: value2}]
        _objectKeysForEach(values, (idx) => {
          __statehookThis.setHookStateManyPaths(values[idx], basePath);
        });
      } else {
        // {key1: value1, key2: value2}
        _objectKeysForEach(values, (key) => {
          var subPath = _parsePath(key);
          __statehookThis.setHookStatePath([].concat(basePath, subPath), values[key]);
        });
      }
    },

    resetHookState: function () {
      // var currentState = __statehookThis.getHookState();
      // _objectKeysForEach(currentState, (path) => {
      //   if (!_hasOwn(__initPath, path)) {
      //     __statehookThis.deleteHookStatePath(path);
      //   }
      // });
      // __statehookThis.setHookStateManyPaths(_objectAssign({}, __initPath));
      __statehookThis.replaceHookState(_objectAssign({}, __initPath));
    },
    resetHookStatePath: function (path) {
      path = _parsePath(path);
      const pathInfo = _pathStatus(__initPath, path);
      if (pathInfo.exists) {
        return __statehookThis.setHookStatePath(path, pathInfo.value);
      }
      return pathInfo.value;
    },
    replaceHookState: function (newState) {
      var currentState = __statehookThis.getHookState();
      _objectKeysForEach(currentState, (path) => {
        if (!_hasOwn(newState, path)) {
          __statehookThis.deleteHookStatePath(path);
        }
      });
      __statehookThis.setHookStateManyPaths(_objectAssign({}, newState));
    },
    hasHookStatePath: function (path) {
      path = _parsePath(path);
      return !!_pathStatus(__stateCoreInstance.getState(), path).exists;
    },

    updateHookStatePath: function (path, cb) {
      path = _parsePath(path);
      return __statehookThis.setHookStatePath(path, cb(__statehookThis.getHookStatePath(path)));
    },

    deleteHookStatePath: function (path) {
      path = _parsePath(path);
      var state = __stateCoreInstance.getState();
      _pathDelete(state, path);
      __stateCoreInstance.notifyAllObservers(__hookStateUpdateEventName, path, undefined, 'delete', 'deleteHookStatePath');
      return __stateCoreInstance.getState();
    },

    watchHookState: function (cb) {
      return __stateCoreInstance.addObserver((eventType, ...rest) => {
        if (eventType === __hookStateUpdateEventName) {
          cb(eventType, ...rest);
        }
      });
    },

    watchHookStatePath: function (watchPath, cb) {
      watchPath = _parsePath(watchPath);
      return __statehookThis.watchHookState((eventType, path, ...rest) => {
        path = _parsePath(path);
        if (_isSameArray(watchPath, path)) {
          cb(eventType, path, ...rest);
        }
      });
    },

    watchHookStatePathAfter: function (matchPath, cb) {
      matchPath = _parsePath(matchPath);
      return __statehookThis.watchHookState((eventType, path, value) => {
        path = _parsePath(path);
        if (_isAfterArray(matchPath, path)) {
          cb(eventType, path, value);
        }
      });
    },
    watchHookStatePathAfterOrSame: function (matchPath, cb) {
      matchPath = _parsePath(matchPath);
      return __statehookThis.watchHookState((eventType, path, value) => {
        path = _parsePath(path);
        if (_isAfterOrSameArray(matchPath, path)) {
          cb(eventType, path, value);
        }
      });
    },
    watchHookStatePathBefore: function (matchPath, cb) {
      matchPath = _parsePath(matchPath);
      return __statehookThis.watchHookState((eventType, path, value) => {
        path = _parsePath(path);
        if (_isBeforeArray(matchPath, path)) {
          cb(eventType, path, value);
        }
      });
    },
    watchHookStatePathBeforeOrSame: function (matchPath, cb) {
      matchPath = _parsePath(matchPath);
      return __statehookThis.watchHookState((eventType, path, value) => {
        path = _parsePath(path);
        if (_isBeforeOrSameArray(matchPath, path)) {
          cb(eventType, path, value);
        }
      });
    },
    statehookUnwatchAll: function (namespace) {
      if (namespace) {
        __statehookThis.statehookUnwatchWithNS(namespace);
      } else {
        _objectKeysForEach(__unwatchers, (ns) => {
          __statehookThis.statehookUnwatchWithNS(ns);
        });
      }
    },
    statehookUnwatchWithNS: function (ns) {
      ns = ns || __unwatcherDefaultNS;
      var unwatchersNS = __unwatchers[ns] || [];
      while (unwatchersNS.length) {
        var currentUnwatcher = unwatchersNS.pop();
        if ('function' === typeof currentUnwatcher) {
          currentUnwatcher();
        }
      }
    },
  });
  // init
  if ('function' === typeof argInitPath) {
    __destroyFunc = argInitPath(__statehookThis.statehookCloneFunctions());
  } else {
    __statehookThis.setHookStateManyPaths(_objectAssign({}, argInitPath));
  }
  var returnInstance = __statehookThis.statehookCloneFunctions();
  if (opt.unmanageable !== true) {
    allStatehookInstances[hookName] = returnInstance;
  }
  return returnInstance;
};

export const haveStatehook = function (hookName) {
  return _hasOwn(allStatehookInstances, hookName);
};

export const getStatehook = function (hookName) {
  if (!hookName) return _objectAssign({}, allStatehookInstances);
  if (allStatehookInstances[hookName]) {
    return allStatehookInstances[hookName].statehookCloneFunctions();
  }
  return null;
};

export const extendStatehook = function (hookName, base) {
  var statehook = allStatehookInstances[hookName];
  if (statehook) {
    _functionsExtendTo(statehook.statehookCloneFunctions(), base);
    allStatehookInstances[hookName] = base;
  }
  return allStatehookInstances[hookName];
};

if ('undefined' !== typeof window) {
  window.getStatehook = getStatehook;
}

export const discardStatehook = function (hookName) {
  const instance = getStatehook(hookName);
  if (instance) {
    instance.statehookDiscard();
  }
  delete allStatehookInstances[hookName];
};

export const statehookify = function (opt, base, argInitPath) {
  opt = opt || {};
  if ('string' === typeof opt) {
    opt = {
      hookName: opt,
    };
  }
  base = base || {};
  if (base.hookName && base.hookName === opt.hookName) {
    return base;
  }
  if (opt.bindThisToBase) {
    _functionsExtendTo(base, base);
  }
  var destroyFunc = { current: null };
  _functionsExtendTo(createStatehook(opt, () => {
    return () => {
      if ('function' === typeof destroyFunc.current) {
        destroyFunc.current();
      }
      delete destroyFunc.current;
    };
  }), base);
  argInitPath = (arguments.length > 1) ? argInitPath : {};
  if ('function' === typeof argInitPath) {
    destroyFunc.current = argInitPath.call(base, base);
  } else {
    base.setHookStateManyPaths(argInitPath);
  }
  return base;
};

export const reactStatehookify = function (opt, component, argInitPath) {
  var statehookInstance = statehookify(opt, component, argInitPath);
  function forceUpdate() {
    component.forceUpdate();
  }
  statehookInstance.statehookAddUnwatcher(component.watchHookState(forceUpdate));

  if ('function' === typeof component.componentWillUnmount && 'function' !== typeof component._extOriginal_componentWillUnmount) {
    component._extOriginal_componentWillUnmount = component.componentWillUnmount;
    component.componentWillUnmount = (function () {
      var returnVar = component._extOriginal_componentWillUnmount.apply(this, arguments);
      statehookInstance.statehookDiscard();
      return returnVar;
    }).bind(component);
  }
  return statehookInstance;
};

export const useStatehook = function (initArgs, initFunc, reactLibrary) {
  var { useState, useEffect } = reactLibrary;
  var [statehookFlag, setStatehookFlag] = useState(null);
  if (!statehookFlag) {
    statehookFlag = {
      uuid: __genUUID(),
    };
    setStatehookFlag(statehookFlag);
  }
  var [, setForceUpdateValue] = useState(0);
  var isInited = haveStatehook(statehookFlag.uuid);
  var statehook = createStatehook(statehookFlag.uuid, {});
  if (!isInited) {
    var forceUpdate = function () { setForceUpdateValue(__genUUID()); };
    if ('function' === typeof initFunc) initFunc = { useStatehookInit: initFunc };
    _objectAssign(initFunc, { forceUpdate: forceUpdate });
    if ('function' !== typeof initFunc.useStatehookReceiveArgs) {
      initFunc.useStatehookReceiveArgs = function (inputArgs) { this.useStatehookArgs = inputArgs; };
    }
    // bind to oneself
    _functionsExtendTo(initFunc, initFunc);
    statehook = extendStatehook(
      statehookFlag.uuid,
      initFunc,
    );
    if ('function' === typeof statehook.useStatehookInit) {
      initFunc = (...rest) => { return statehook.useStatehookInit(...rest); };
    } else {
      initFunc = () => {};
    }
    statehookFlag.destroyFunction = initFunc(initArgs, forceUpdate, statehook);
    if (_isPromise(statehookFlag.destroyFunction)) {
      statehookFlag.destroyFunction.then((unFunction) => {
        statehookFlag.destroyFunction = unFunction;
      });
    }
    statehookFlag.unwatchForceUpdate = statehook.watchHookState(forceUpdate);
    setStatehookFlag(statehookFlag);
  }
  // inputArgs
  if ('function' === typeof statehook.useStatehookReceiveArgs) statehook.useStatehookReceiveArgs(initArgs);
  ((_statehookFlag) => {
    // debugger;
    useEffect(() => {
      return () => {
        if ('function' === typeof _statehookFlag.destroyFunction) {
          _statehookFlag.destroyFunction(statehook);
        }
        _statehookFlag.unwatchForceUpdate();
      };
    }, []);
  })(statehookFlag);
  statehook.statehookEmit('useStatehookBeforeRender', initArgs);
  if ('function' === typeof statehook.useStatehookBeforeRender) {
    statehook.useStatehookBeforeRender(initArgs);
  }
  return statehook;
};

export const useUUID = function (React) {
  var { useState } = React;
  var [uuid, setUUID] = useState(0);
  if (uuid === 0) {
    setUUID(__genUUID());
  }
  return uuid;
};
