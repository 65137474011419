// import classRoomStore from 'store/classRoomStore';
// import mqttStore from 'store/mqttStore';
// import userStore from 'store/userStore';

const log = require('debug')('ui:mqttProcessor:response');

export const executor = (packet) => {
  const { topic, payload } = packet;
  log(topic, payload);
  // classRoomStore.updateCameraStatus(payload.ws_server, payload.app_status);
  const regExp = /^to\/classroom\/(.*)\/response\/([^/]*)\/(.*)$/;
  const topicArgument = regExp.exec(topic);
  if (!topicArgument) {
    console.warn('Do not a classroom response mqtt topic pattern');
  } else {
    // eslint-disable-next-line no-unused-vars
    const [originalString, classroomCode, reqId, cmd] = topicArgument;
    switch (cmd) {
      case 'update_robot': {
        // do not need following code anymore,
        // because I modified function: classRoomStore.arrangeRobots
        // classRoomStore.fetchAndSelectClassRoom(classroomCode);
        break;
      }
      default: {
        console.warn(`Did not match any response cmd processor with \`${cmd}\``);
        break;
      }
    }
  }
};

export default executor;
