/* eslint-disable no-param-reassign */
import { observable, observe } from 'mobx';
import { autobind } from 'core-decorators';
import _ from 'lodash';
import { uploadToUserRange, getAllMediaByUser, remove as removeMedia } from 'api/media';
import { getNS } from '_i18n_';

const _t = getNS('store/userMediaStore.js');

const FILE_SIZE_LIMITED = 1 * 1024 * 1024;

export class UserMediaStore {
  @observable library = observable.map({});
  @observable draggingOut = observable.map({});
  @observable draggingIn = observable.map({});
  @observable selected = observable.map({});

  @observable uploading = observable.map({}); // temporary-mediaId: file object
  @observable uploadSuccessful = observable.map({});
  @observable uploadFailed = observable.map({});

  @observable deleting = observable.map({});
  @observable deleteSuccessful = observable.map({});
  @observable deleteFailed = observable.map({});
  userId = null;

  unobserveUploadSuccessful = null;
  unobserveUploadFailed = null;

  unobserveDeleteSuccessful = null;
  unobserveDeleteFailed = null;
  constructor() {
    this.unobserveUploadSuccessful = observe(this.uploadSuccessful, this.observeUploadSuccessful);
    this.unobserveDeleteSuccessful = observe(this.deleteSuccessful, this.observeDeleteSuccessful);
  }
  @autobind
  observeUploadSuccessful() {
    this.uploadSuccessful.entries().forEach(([temporaryMediaId, m]) => {
      const savedMediaId = m._id;
      this.library.set(savedMediaId, this.uploadSuccessful.get(temporaryMediaId));
      this.uploadSuccessful.delete(temporaryMediaId);
    });
  }

  @autobind
  observeDeleteSuccessful() {
    // eslint-disable-next-line no-unused-vars
    this.deleteSuccessful.entries().forEach(([mediaId, m]) => {
      this.deleteSuccessful.delete(m._id);
      this.library.delete(m._id);
    });
  }

  @autobind
  unobserveAll() {
    if (this.unobserveUploadSuccessful) this.unobserveUploadSuccessful();
    if (this.unobserveUploadFailed) this.unobserveUploadFailed();

    if (this.unobserveDeleteSuccessful) this.unobserveDeleteSuccessful();
    if (this.unobserveDeleteFailed) this.unobserveDeleteFailed();
  }

  @autobind
  clearAllFailed() {
    this.uploadFailed.entries().forEach(([temporaryMediaId]) => {
      this.uploading.delete(temporaryMediaId);
    });
    this.uploadFailed.clear();

    this.deleteFailed.entries().forEach(([temporaryMediaId]) => {
      this.deleting.delete(temporaryMediaId);
    });
    this.deleteFailed.clear();
  }
  @autobind
  addDeleteFile(_mediaIds) {
    let mediaIds = null;
    if (_.isArrayLikeObject(_mediaIds)) {
      mediaIds = _mediaIds;
    } else {
      mediaIds = [_mediaIds];
    }
    const willDeleted = {};
    mediaIds.forEach((mediaId) => {
      const m = this.library.get(mediaId);
      willDeleted[mediaId] = m;
      this.remove(m._id)
        .then((httpRes) => {
          const jsonStruct = httpRes.data;
          if (jsonStruct.error) {
            if (this.deleting.has(m._id)) this.deleteFailed.set(m._id, jsonStruct.message);
            this.deleting.delete(m._id);
            return;
          }
          if (this.deleting.has(m._id)) this.deleteSuccessful.set(m._id, this.deleting.get(m._id));
          this.deleting.delete(m._id);
          this.selected.delete(m._id);
        })
        .catch((e) => {
          if (this.deleting.has(m._id)) this.deleteFailed.set(m._id, e.message);
          this.deleting.delete(m._id);
        });
    });

    this.deleting.merge(willDeleted);
  }
  @autobind
  addUploadFile(dict) {
    Object.entries(dict).forEach(([temporaryMediaId, vDict]) => {
      this.uploading.set(temporaryMediaId, {});
      const temporaryMedia = this.uploading.get(temporaryMediaId);
      const { file } = vDict;
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          // willUploaded[mediaId] = { file, dataURL: reader.result };
          // this.uploading.set(temporaryMediaId]: { file, dataURL: reader.result } });
          temporaryMedia.file = file;
          temporaryMedia.dataURL = reader.result;
          if (file.size > FILE_SIZE_LIMITED) {
            // this.uploading.delete(temporaryMediaId);
            this.uploadFailed.set(temporaryMediaId, _t('Files need to be smaller that 1MB'));
            return;
          }
          this.uploadFile(temporaryMediaId, file)
            .then((httpResRaw) => {
              const jsonResStruct = httpResRaw.data;
              this.uploading.delete(temporaryMediaId);
              this.uploadSuccessful.set(temporaryMediaId, jsonResStruct.data);
            })
            .catch((e) => {
              // this.uploading.delete(temporaryMediaId);
              this.uploadFailed.set(temporaryMediaId, e.message);
            });
        };
      };
      reader.readAsDataURL(file);
    });
  }
  @autobind
  addSelected(value) {
    this.selected.merge(value);
  }
  @autobind
  removeSelected(mediaId) {
    this.selected.delete(mediaId);
  }
  @autobind
  switchUserById(userId) {
    if (this.userId !== userId) {
      this.library.clear();
      this.selected.clear();
      this.draggingOut.clear();
      this.draggingIn.clear();
      this.deleteFailed.clear();
      this.deleteSuccessful.clear();
      this.uploadFailed.clear();
      this.uploadSuccessful.clear();
      if (userId) {
        this.loadLibrary();
      }
    }
    this.userId = userId;
  }

  @autobind
  loadLibrary() {
    return getAllMediaByUser()
      .then((httpRes) => {
        this.library.clear();
        httpRes.data.data.forEach((m) => {
          this.library.set(m._id, m);
        });
      });
  }

  @autobind
  // eslint-disable-next-line class-methods-use-this
  remove(mediaId) {
    return removeMedia(mediaId);
  }

  @autobind
  // eslint-disable-next-line class-methods-use-this
  uploadFile(mediaId, file) {
    const { type } = file;
    const upFile = new Blob([file], { type });
    const formData = new FormData();
    formData.append('mediaFile', upFile, file.name);
    return uploadToUserRange(formData, {});
  }
}

export default new UserMediaStore();
