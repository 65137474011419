import { action, observable } from 'mobx';
import { debounce } from 'debounce-decorator';
import {
  getAll,
  // create,
  // update,
  // getMapDefaultPathfinding,
  setMapDefaultPathfinding,
  getAllMapDefautPathfinding,
} from 'api/pathFinding';
import PathfindingModel from 'store/models/PathfindingModel';

// const log = require('debug')('ui:pathfindingStore');

export class PathfindingStore {
  @observable pathfindings = observable.map({});
  @observable pathfindingSelected = observable.map({});

  @observable pathfindingCurrentlyUsed = observable.map({});
  @observable allDefaultPathfinding = observable.map({});
  _lastTimeFetchedPathfindingCurrentlyUsed = 0;
  _theTimeOfCachePathfindingCurrentlyUsed = 5 * 1e3;

  constructor() {
    this.pathfindingSelected = PathfindingModel.create({});
    // this.wrapPathfindingFile({
    //   name: '',
    //   places: {},
    //   mapId: 0,
    //   matrix: [],
    //   __isUnsaved: false,
    // });
  }

  @action loadPathfindings() {
    return getAll()
      .then((res) => {
        if (res.data) {
          this.pathfindings = observable.map(res.data.reduce((acc, curr) => {
            acc[curr._id] = PathfindingModel.create(curr);
            return acc;
          }, {}));
        }
        return res;
      });
  }

  @action
  addPlace(name, value) {
    this.pathfindingSelected.addPlace(name, value);
  }

  @action setPathfindingSelected(_id) {
    if (!_id || !this.pathfindings.get(_id)) {
      this.pathfindingSelected = PathfindingModel.create({});
    } else {
      this.pathfindingSelected = PathfindingModel.create(this.pathfindings.get(_id).getSnapshot());
    }
    return this.pathfindingSelected;
  }

  @action save() {
    this.pathfindingSelected.save();
  }

  @action async fetchAllDefaultPathfinding() {
    return getAllMapDefautPathfinding()
      .then((res) => {
        this.allDefaultPathfinding = observable.map(Object
          .entries(res.data)
          .reduce((acc, [mapId, _pf]) => {
            acc[mapId] = PathfindingModel.create(_pf);
            return acc;
          }, {}));
        return res;
      });
  }

  // eslint-disable-next-line
  @action applyPathfindingToCurrentWorkspaceByMapId = action(
    'applyPathfindingToCurrentWorkspaceByMapId',
    debounce(async function _aptcb(mapId) {
      return this.fetchAllDefaultPathfinding()
        .then((res) => {
          const defaultPF = this.allDefaultPathfinding.get(mapId);
          this.pathfindingCurrentlyUsed = defaultPF;
          return res;
        });
    }, 1 * 1e3),
  )

  // eslint-disable-next-line
  @action async setDefaultPathfindingForMapId(mapId, pathfindingId) {
    await setMapDefaultPathfinding(mapId, pathfindingId);
    // this.fetchDefaultPathfindingToCurrentlyUsedByMapId();
    return this.fetchAllDefaultPathfinding();
  }

  @action insertNewPathfinding(pathfindingData) {
    this.pathfindings.set(pathfindingData._id, PathfindingModel.create(pathfindingData));
    return this.pathfindings.get(pathfindingData._id);
  }
}

export default new PathfindingStore();
