/* eslint-disable max-len */
import debug from 'debug';
import classRoomStore from 'store/classRoomStore';
import classRoomRobotsStore from 'store/classRoomRobotsStore';
import userStore from 'store/userStore';
// import { parking } from '../commands';

const log = debug('ui:mqtt');

async function saveRegister(mac, identifier, classRoomCode) {
  const data = {
    _id: mac,
    identifier,
    classRoom: classRoomCode,
    name: 'unused', // remove name once API deprecates it.
  };
  await classRoomRobotsStore.addRobot(classRoomCode, data);
  classRoomStore.addRobot(data);
}

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
export const executor = (packet) => {
  if (userStore.isStudent) return;

  const { payload } = packet;

  const {
    mac, identifier,
  } = payload;

  const { classRoomCode } = classRoomStore;
  try {
    saveRegister(mac, identifier, classRoomCode);
    // send parking command
    // parking(classRoomCode, identifier);
  } finally {
    log('Remove %s from registering list', mac);
    classRoomStore.registeringRobots.delete(mac);
  }
};

export default executor;
