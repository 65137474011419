/* eslint-disable import/prefer-default-export */
import { MqttStore } from 'store/mqttStore';
import { byId, bindSerialNumber } from 'api/classRoom';
import { statehookify } from './hookFactory';

class ClassroomHook {
  static currentClassroomHook = null
  static instances = []
  static async create(classroomCode) {
    const instance = new ClassroomHook();
    await instance.init(classroomCode);
    return instance;
  }
  static async switchClassroomHook(classroomCode) {
    if (ClassroomHook.currentClassroomHook) {
      const currentClassroomCode = ClassroomHook.currentClassroomHook.classRoomCode;
      ClassroomHook.currentClassroomHook.discard();
      delete ClassroomHook.instances[currentClassroomCode];
    }
    if (!ClassroomHook.instances[classroomCode]) {
      ClassroomHook.instances[classroomCode] = await ClassroomHook.create(classroomCode);
    }
    ClassroomHook.currentClassroomHook = ClassroomHook.instances[classroomCode];
    return ClassroomHook.currentClassroomHook;
  }
  async init(classRoomCode) {
    statehookify(`classroomHook_${classRoomCode}`, this, {});
    const initResult = await this.initProperties({
      classRoomCode,
    });
    this.initMQTT();
    return initResult;
  }
  async discard() {
    if (this.mqttObject) {
      this.mqttObject.discard();
    }
  }
  initProperties(defaultValue) {
    defaultValue = defaultValue || {};
    this.setHookStateManyPaths(Object.assign({
      classroomData: {},
      classroomDataFeatchState: 'pending',
      blocklyCode: {},
      classRoomCode: '',
      classRoomSSID: '',
      classRoomName: '',
      classRoomCreatedBy: '',
      projectStore: undefined,
      classRoomStudents: [],
      classRoomRobots: {},
      classRoomObjects: {},
      workspace: undefined,
      highlightedRobot: undefined,
      highlightedObjectId: undefined,

      workSpaceIsRunningBox: false,
      workSpaceRunningStatusBox: 'STOPPED',

      jsCodeParseErrors: {},
      jsCodeCheckerState: undefined,
      // jsCodeCheckerStateEnum =
      //  jsEnum('unknown', 'checking', 'cleaning', 'succeeded', 'failed', 'flashing'),
      jsCodeCheckerStateEnum: {
        unknown: 'unknown',
        checking: 'checking',
        cleaning: 'cleaning',
        succeeded: 'succeeded',
        failed: 'failed',
        flashing: 'flashing',
      },
      blockErrorsFlashingState: undefined,
      // blockErrorsFlashingStateEnum: jsEnum('flashing', 'highlight', 'normal'),
      blockErrorsFlashingStateEnum: {
        flashing: 'flashing',
        highlight: 'highlight',
        normal: 'normal',
      },
      runtimeVariables: {
        mapMark: {},
      },

      sandboxWorker: null,

      // store the state of registerRobotSensors function
      // registerRobotSensorsStateEnums =
      //   jsEnum('pending', 'sent', 'received', 'verificationfailed', 'completed', 'timeout'),
      registerRobotSensorsStateEnums: {
        pending: 'pending',
        sent: 'sent',
        received: 'received',
        verificationfailed: 'verificationfailed',
        completed: 'completed',
        timeout: 'timeout',
      },
      registerRobotSensorsState: {},

      searchKeywords: '',
      searchResult: [],

      selectedBlockInfo: {
        blockId: null,
        blockType: null,
        blocklyElement: null,
        clickTimes: 0,
      },

      blockErrors: {},

      jsCodeOfSetupBlock: '',
      jsCodeOfLoopBlock: '',
      jsCodeOfEventsBlock: '',

      searchDatabase: {},
      offTopicList: [],
      qrcodeDataURL: null,
    }, defaultValue));
    return this.loadClassroomData();
  }
  initMQTT() {
    this.mqttObject = new MqttStore();
    const topicRegister = `to/classroom/${this.classRoomCode}/camera_register`;
    this.statehookAddUnwatcher(this.mqttObject.onTopic(topicRegister, async (topic, msg) => {
      if (!topicRegister.includes(topic)) return;
      const payload = JSON.parse(msg.toString());
      if (payload.serial) {
        this.bindSerialNumber(payload.serial)
          .then(() => {
            this.loadClassroomData();
          });
      }
    }));
  }
  async fetchClassroomData() {
    return byId(this.classRoomCode);
  }
  async loadClassroomData() {
    this.setHookStatePath('classroomDataFetchState', 'fetching');
    const httpResp = await this.fetchClassroomData();
    this.setHookStatePath('classroomDataFetchState', 'done');
    this.setHookStatePath('classroomData', httpResp.data);
    return httpResp;
  }
  get classRoomCode() {
    return this.getHookStatePath('classRoomCode');
  }
  get classroomData() {
    return this.getHookStatePath('classroomData');
  }

  bindSerialNumber(serialNumber) {
    return bindSerialNumber(this.classRoomCode, serialNumber);
  }
  emitUI3dModelDropdownListOpen(data) {
    return this.statehookEmit('3dmodel/dropdown_list/open', data);
  }
  emitUI3dModelDropdownListSelectedItem(data) {
    return this.statehookEmit('3dmodel/dropdown_list/selected', data);
  }
  emitUI3dModelDropdownListClosed(data) {
    return this.statehookEmit('3dmodel/dropdown_list/closed', data);
  }
  onUI3dModelDropdownListOpen(cb) {
    return this.statehookOn('3dmodel/dropdown_list/open', cb);
  }
  onUI3dModelDropdownListSelectedItem(cb) {
    return this.statehookOn('3dmodel/dropdown_list/selected', cb);
  }
  onUI3dModelDropdownListClosed(cb) {
    return this.statehookOn('3dmodel/dropdown_list/closed', cb);
  }
}

export default ClassroomHook;
