/**
 * This module listen for command status update
 * and **update BLOCKLY** status allowing execute the next command.
 *
 * @see JSON {@link Mqtt.CommandStatus.schema schema} definition.
 * @module commandStatus
 * @namespace Mqtt.CommandStatus
 */

import { validator } from './schema';
import { executor } from './executor';

export default [validator, executor];
