import classRoomStore from 'store/classRoomStore';

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
export const executor = (packet) => {
  const { payload } = packet;

  const {
    id, status, battery,
  } = payload;
  const batteryVoltage = (battery * 6.6) / 1024;
  let batteryPercent = 0;
  if (batteryVoltage > 3.85) batteryPercent = 100;
  else if (batteryVoltage > 3.85) batteryPercent = 100;
  else if (batteryVoltage > 3.75) batteryPercent = 80;
  else if (batteryVoltage > 3.60) batteryPercent = 50;
  else if (batteryVoltage > 3.55) batteryPercent = 30;
  else if (batteryVoltage > 3.45) batteryPercent = 20;
  else if (batteryVoltage > 3.40) batteryPercent = 10;
  classRoomStore.updateRobotStatus(id, status, batteryPercent);
};

export default executor;
