/* eslint-disable no-param-reassign */

import { types, flow } from 'mobx-state-tree';
// import Raven from 'raven-js';
import { removeNamedWork, loadNamedWork, saveNamedWork } from 'api/classRoom';
import { popupError, dehydrateError } from 'utils/error';
import Work from 'models/Work';

const WorkStore = types
  .model('WorkStore', {
    works: types.optional(types.array(Work), []),
    worksState: types.optional(types.enumeration('State', ['pending', 'done', 'error']), 'pending'),
  }).actions((self) => {
    const fetchWorks = flow(function* fetchWorks() {
      try {
        const result = yield loadNamedWork(self.classRoomCode);
        self.works = result.data ? result.data.map(p => Work.create(p)) : [];
        self.worksState = 'done';
      } catch (error) {
        // Raven.captureException(error);
        console.reportError(error);
        const errorD = dehydrateError(error);
        self.worksState = 'error';
        popupError(errorD, error.message);
      }
    });

    const removeWork = flow(function* removeWork(work) {
      const resp = yield removeNamedWork(work._id);
      self.works = self.works.filter(p => p._id !== work._id);
      return resp;
    });

    const addWork = flow(function* addWork({
      ruleId,
      xml,
      name,
      mapId,
      projectRef,
      workRef,
    }) {
      const result = yield saveNamedWork(
        ruleId,
        self.classRoomCode,
        xml,
        name,
        mapId,
        projectRef,
        workRef,
      );
      const workCreated = Work.create(result.data);
      self.works.push(workCreated);
      return workCreated;
    });

    return {
      fetchWorks, removeWork, addWork,
    };
  });

export default WorkStore;
