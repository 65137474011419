import notificationStatehook from 'store/notificationStatehook';

import {
  getAll3DModelsFromClassroom,
  delete3DModelFromClassroom,
  modifyModelData,
  accept3DModel,
} from 'api/threeDModel';
import { statehookify } from './statehook/hookFactory';
// const log = require('debug')('ui:statehook:threeDModelStatehook.js');

export default statehookify('threeDModelStatehook', {
  getCurrentPageNumber(classRoomCode) {
    return this.getHookStatePath([classRoomCode, 'currentPageNumber']) | 0;
  },
  setCurrentPageNumber(classRoomCode, pageNumber) {
    const res = this.setHookStatePath([classRoomCode, 'currentPageNumber'], pageNumber);
    // this.statehookEmit([classRoomCode, 'currentPageNumber'], pageNumber);
    return res;
  },
  watchCurrentPageNumber(classroomCode, cb) {
    return this.watchHookStatePath([classroomCode, 'currentPageNumber'], cb);
  },

  getTotalPage(classRoomCode) {
    return this.getHookStatePath([classRoomCode, 'totalPage']) | 0;
  },
  setTotalPage(classRoomCode, pageNumber) {
    const res = this.setHookStatePath([classRoomCode, 'totalPage'], pageNumber);
    // this.statehookEmit([classRoomCode, 'totalPage'], pageNumber);
    return res;
  },
  watchTotalPage(classroomCode, cb) {
    return this.watchHookStatePath([classroomCode, 'totalPage'], cb);
  },

  getCountWaitingToBeAccepted(classRoomCode) {
    return this.getHookStatePath([classRoomCode, 'countWaitingToBeAccepted']) | 0;
  },
  setCountWaitingToBeAccepted(classRoomCode, count) {
    const res = this.setHookStatePath([classRoomCode, 'countWaitingToBeAccepted'], count);
    // this.statehookEmit([classRoomCode, 'countWaitingToBeAccepted'], count);
    return res;
  },
  load3DModel(classRoomCode, opt) {
    opt = opt || {};
    const isSilent = opt.silent || false;
    let { pageNumber } = opt;
    if (pageNumber === 'current') {
      pageNumber = this.getCurrentPageNumber(classRoomCode);
    }
    if (!isSilent) this.set3DModelFetchingStatus(classRoomCode, 'fetching');
    return this.fetch3DModel(classRoomCode, pageNumber)
      .then((res) => {
        res.data.data = res.data.data || [];
        this.setCurrentPageNumber(classRoomCode, res.data.pageNumber);
        this.setTotalPage(classRoomCode, res.data.totalPage);
        this.setCountWaitingToBeAccepted(classRoomCode, res.data.countWaitingToBeAccepted);
        this.setHookStatePath([classRoomCode, '3DModelsLastFetchTime'], Date.now());
        this.set3DModelsToClassroom(classRoomCode, res.data.data);
        if (!isSilent) this.set3DModelFetchingStatus(classRoomCode, 'successful');
        return res;
      })
      .catch((err) => {
        if (!isSilent) this.set3DModelFetchingStatus(classRoomCode, 'failed');
        throw err;
      });
  },
  fetch3DModel(classroomCode, pageNumber) {
    return getAll3DModelsFromClassroom(classroomCode, { pageNumber });
  },
  isFetching(classRoomCode) {
    return this.get3DModelFetchingStatus(classRoomCode) === 'fetching';
  },
  isSuccessful(classRoomCode) {
    return this.get3DModelFetchingStatus(classRoomCode) === 'successful';
  },
  isFailed(classRoomCode) {
    return this.get3DModelFetchingStatus(classRoomCode) === 'failed';
  },
  set3DModelFetchingStatus(classRoomCode, status) {
    this.setHookStatePath([classRoomCode, '3DModelsFetchingStatus'], status);
    // this.statehookEmit([classRoomCode, '3DModelsFetchingStatus'], status);
  },
  get3DModelFetchingStatus(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModelsFetchingStatus']);
  },
  watch3DModelFetchingStatus(classRoomCode, cb) {
    return this.watchHookStatePath([classRoomCode, '3DModelsFetchingStatus'], cb);
  },

  set3DModelsToClassroom(classRoomCode, data) {
    this.setHookStatePath([classRoomCode, '3DModels'], data);
    // this.statehookEmit([classRoomCode, '3DModels'], data);
  },

  get3DModelsFromClassroom(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModels']) || [];
  },
  watch3DModelsInClassroom(classRoomCode, cb) {
    return this.watchHookStatePath([classRoomCode, '3DModels'], cb);
  },
  delete3DModelsInClassroom(classRoomCode, modelId) {
    return delete3DModelFromClassroom(classRoomCode, modelId)
      .then(() => {
        this.sendModelEventNotificationToMqtt(classRoomCode, 'delete', { modelId });
        // mqttStore.publish(`to/classroom/${classRoomCode}/3d/model/delete`, JSON.stringify({
        //   modelId,
        //   sender: userStore.currentUser._id,
        // }));
        return this.load3DModel(classRoomCode, {
          pageNumber: 'current',
        });
      });
  },
  patch3DModelData(classRoomCode, modelId, changedData, opt) {
    opt = opt || {};
    return modifyModelData(classRoomCode, modelId, changedData)
      .then(() => {
        this.sendModelEventNotificationToMqtt(classRoomCode, 'modify', { modelId, ...changedData });
        return this.load3DModel(classRoomCode, opt);
      });
  },

  accept3DModel(classRoomCode, data, opt) {
    data = data || {};
    return accept3DModel(classRoomCode, data)
      .then(() => {
        this.sendModelEventNotificationToMqtt(classRoomCode, 'accept', data);
        return this.load3DModel(classRoomCode, opt);
      });
  },

  // model modify
  mqttSubscribeModelEvent(classroomCode) {
    return notificationStatehook.mqttSubscribe(`notify/classroom/${classroomCode}/3d_model_event/+`);
  },
  mqttUnsubscribeModelEvent(classroomCode) {
    // if (this.mqttSubscribeModelEventUnwatcher) this.mqttSubscribeModelEventUnwatcher();
    return notificationStatehook.mqttUnsubscribe(`notify/classroom/${classroomCode}/3d_model_event/+`);
  },
  sendModelEventNotificationToMqtt(classroomCode, eventType, info) {
    if (!eventType) throw new Error('Need to specify args[2]: eventType');
    return notificationStatehook.notify(`notify/classroom/${classroomCode}/3d_model_event/${eventType}`, info, {
      eventType,
    });
  },
  watchMQTTModelEvent(classroomCode, cb) {
    // return notificationStatehook.watchTopic(`notify/classroom/${classroomCode}/3d_model_event/+`, (eventType, path, value) => {
    //   cb(value);
    // });
    return notificationStatehook.onNotified(`notify/classroom/${classroomCode}/3d_model_event/+`, (data) => {
      cb(data.notification);
    });
  },

  // gif
  sendGifEventNotificationToMqtt(classroomCode, eventType, info) {
    if (!eventType) throw new Error('Need to specify args[2]: eventType');
    return notificationStatehook.notify(`classroom/${classroomCode}/gif_event/${eventType}`, info, {
      eventType,
    });
  },
}, {});
