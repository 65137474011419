import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import { recoveryPassword } from 'api/teacher';
import userStore from 'store/userStore';
import { getNS } from '_i18n_';

const _t = getNS('store/teacherRecoveryStore.js');

require('promise.prototype.finally').shim();

const dehydrateError = (error) => {
  const genericMessage = _.get(
    error,
    'response.data.message',
    _.get(
      error, 'response.errmsg',
      _.get(
        error, 'response.statusText',
        _t('Please, check the errors bellow.'),
      ),
    ),
  );
  return {
    genericMessage,
    errors: error.errors,
  };
};

class TeacherRecoveryStore {
  @observable isRecoveringPassword = false;
  @observable recoverPasswordError = undefined;

  @action recoverPassword = async (history, email) => {
    runInAction('starting loading state for recovery password action', () => {
      this.isRecoveringPassword = true;
      this.setRecoverPasswordError(undefined);
    });

    try {
      await recoveryPassword(email);
      userStore.setInfoMessage(_t('Please, check your e-mail.'));
      history.push('/teacher/login');
    } catch (e) {
      this.setRecoverPasswordError(e);
    } finally {
      runInAction('stop loading state for recovery password action', () => {
        this.isRecoveringPassword = false;
      });
    }
  }

  // region Setters

  @action setRecoverPasswordError = action('set recover password error', (err) => {
    if (err) {
      this.recoverPasswordError = dehydrateError(err);
    } else {
      this.recoverPasswordError = err;
    }
  });

  // endregion
}

const teacherRecoveryStore = new TeacherRecoveryStore();

export default teacherRecoveryStore;
export { TeacherRecoveryStore };
