import { action, observable, computed } from 'mobx';
import { popupError, dehydrateError } from 'utils/error';
import {
  getAll,
  set,
  remove,
  update,
  getBlockHelp,
  patchUpdateBlockHelp,
  updateTempWorkSpaceIntroductionHtml,
  getTempWorkSpaceIntroductionHtml,
} from 'api/systemConfig';

import {
  globalStatehook,
  GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL,
  GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_TEACHER,
  GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_STUDENT,
} from 'store/statehook/globalStatehook';

export class SystemConfigStore {
  @observable _configs = observable({});
  @observable _blockHelp = observable({});

  constructor() {
    this.init();
  }

  @action init() {
    getAll()
      .then((res) => {
        Object.keys(this._configs || {}).forEach((_key) => {
          delete this._configs[_key];
        });
        Object.assign(this._configs, (res.data || []).reduce((acc, curr) => {
          acc[curr.name] = curr;
          return acc;
        }, {}));
      })
      .catch((error) => {
        console.reportError(error, 'systemConfigStore.init()');
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
    this.fetchBlockHelp();

    this.refreshTempWorkSpaceIntroductionHtmlAll();
  }

  @action setConfig(name, value) {
    return set(name, value)
      .then((res) => {
        if (res.data) {
          this._configs.set(name, res.data);
        }
        return res;
      })
      .catch((error) => {
        console.reportError(error);
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
  }

  @action updateConfig(name, value) {
    return update(name, value)
      .then((res) => {
        if (res.data) {
          this._configs.set(name, res.data);
        }
        return res;
      })
      .catch((error) => {
        console.reportError(error);
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
  }

  @computed get configs() {
    return this._configs;
  }

  @computed get blockHelp() {
    return this._blockHelp;
  }

  @action getConfigItem(name) {
    return this._configs.get(name);
  }

  @action deleteConfig(name) {
    return remove(name)
      .then((res) => {
        this._configs.delete(name);
        return res;
      })
      .catch((error) => {
        console.reportError(error);
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
  }

  @action fetchBlockHelp() {
    return getBlockHelp()
      .then((res) => {
        this._blockHelp = res.data;
        return res;
      })
      .catch((error) => {
        console.reportError(error);
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
  }

  @action patchUpdateBlockHelp(data) {
    return patchUpdateBlockHelp(data)
      .then((res) => {
        this._blockHelp = res.data;
        return res;
      })
      .catch((error) => {
        console.reportError(error);
        const errorD = dehydrateError(error);
        popupError(errorD, 'Oops!');
      });
  }

  refreshTempWorkSpaceIntroductionHtmlAll() {
    return getTempWorkSpaceIntroductionHtml()
      .then((resp) => {
        if (resp && resp.data) {
          globalStatehook.setHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL, resp.data.value);
        }
        return resp;
      });
  }

  adminUpdateTempWorkSpaceIntroductionOnStudent(html) {
    return updateTempWorkSpaceIntroductionHtml({ student: html })
      .then((resp) => {
        if (resp && resp.data) {
          globalStatehook.setHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL, resp.data.value);
        }
        return resp;
      });
  }

  adminUpdateTempWorkSpaceIntroductionOnTeacher(html) {
    return updateTempWorkSpaceIntroductionHtml({ teacher: html })
      .then((resp) => {
        if (resp && resp.data) {
          globalStatehook.setHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL, resp.data.value);
        }
        return resp;
      });
  }

  getTempWorkSpaceIntroductionHtmlAll() {
    return globalStatehook.getHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL);
  }

  getTempWorkSpaceIntroductionHtmlTeacher() {
    return globalStatehook.getHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_TEACHER);
  }

  getTempWorkSpaceIntroductionHtmlStudent() {
    return globalStatehook.getHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_STUDENT);
  }

  watchTempWorkSpaceIntroductionHtmlAll(cb) {
    return globalStatehook.watchHookStatePath(GLOBAL_STATEHOOK_KEY_TEMP_WORKSPACE_HELP_ALL, cb);
  }
}

export default new SystemConfigStore();
