// @flow

/* eslint-disable max-len */
import _ from 'lodash';
import { getNS } from '_i18n_';

const _t = getNS('sensor_specs.js');

const COLOR_GRIPPER = '#D5A615';

// type Sensor = {
//   id: number,
//   name: string, // human readable name
//   description: string,
//   varName: string,
//   eventName: string,
//   eventVarName: string,
//   slot: string,
//   category: string, // output / input
//   mode: string, // null, continuous, event
//   // this type is used to convert for blockly
//   type: string, // raw data type always number or array[number]
//   converter: (number) => any, // convert function
//   formatValueForDisplay: (any) => any,
//   dataKeys: [string], // only if type is array
//   range: [number], // if array, this is the range of its dataKeys
//   unit: string,
// }

function toBoolean(num) {
  return num === 1;
}

function toFloat(num) {
  return num;
}

function toInt(num) {
  return parseInt(num, 10);
}

export const sensorSpecs = {
  S2: {
    id: 'S2',
    name: _t('Gripper'),
    description: _t('Gripper'),
    varName: 'gripper',
    slot: 4,
    category: 'output',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    unit: '',
    blocklyColor: COLOR_GRIPPER,
    formatValueForDisplay: value => value ? _t('Open') : _t('Closed'),
  },
  S3: {
    id: 'S3',
    name: _t('Bright LED'),
    description: _t('Bright LED'),
    varName: 'Bright_LED',
    slot: 1,
    category: 'output',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
  },
  S4: {
    id: 'S4',
    name: _t('RGB-LED'),
    description: _t('RGB-LED'),
    varName: 'rgb_led_strip',
    slot: 1,
    category: 'output',
    mode: 'continuous',
    type: 'int',
    dataKeys: ['LED1_R', 'LED1_G', 'LED1_B', 'LED2_R', 'LED2_G', 'LED2_B', 'LED3_R', 'LED3_G', 'LED3_B', 'LED4_R', 'LED4_G', 'LED4_B'],
    converter: num => num, // can convert to RGB hex here
    formatValueForDisplay: (value) => {
      if (_.isArrayLikeObject(value)) {
        const colours = [null, 'BLACK', 'WHITE', 'RED', 'GREEN', 'BLUE', 'YELLOW', 'PURPLE', 'CYAN', 'PINK'];
        return value.reduce((acc, curr) => { if (colours[curr]) acc.push({ display: `${colours[curr]}`, value: curr }); return acc; }, []);
      }
      return value;
    },
    range: [0, 100],
    unit: '',
  },
  S5: {
    id: 'S5',
    name: _t('buzzer'),
    description: _t('buzzer'),
    varName: 'buzzer',
    slot: 1,
    category: 'output',
    mode: null,
    unit: '',
  },
  S6: {
    id: 'S6',
    name: _t('IR transmitter'),
    description: _t('IR transmitter'),
    varName: 'ir_transmitter',
    slot: 1,
    category: 'output',
    mode: null,
    unit: '',
  },
  S7: {
    id: 'S7',
    name: _t('Relay'),
    description: _t('Relay'),
    varName: 'relay',
    slot: 1,
    category: 'output',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    unit: '',
    formatValueForDisplay: value => value ? _t('On') : _t('Off'),
  },
  S8: {
    id: 'S8',
    name: _t('LED'),
    description: _t('LED'),
    varName: 'LED',
    slot: 1,
    category: 'output',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    dataKeys: ['On'],
    unit: '',
    formatValueForDisplay: value => value ? _t('On') : _t('Off'),
  },
  S13: {
    id: 'S13',
    name: _t('Ultrasonic Distance Sensor'),
    description: _t('Ultrasonic Distance Sensor'),
    varName: 'ultrasonic_distance_sensor',
    slot: 5,
    category: 'input',
    mode: 'continuous',
    type: 'float',
    converter: toFloat,
    range: [1, 4000],
    unit: 'mm',
  },
  S14: {
    id: 'S14',
    name: _t('Waterproof Temperature Sensor'),
    description: _t('Waterproof Temperature Sensor'),
    varName: 'Waterproof_Temperature_Sensor',
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'float',
    converter: toFloat,
    range: [-20, 100],
    unit: '°C',
  },
  S15: {
    id: 'S15',
    name: _t('humidity sensor'),
    description: _t('Temp & Humidity Sensor'),
    varName: 'humidity_sensor',
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '',
    dataKeys: [_t('Temperature'), _t('Humidity')],
    fontSize: '15px',
  },
  S16: {
    id: 'S16',
    name: _t('IR receiver'),
    description: _t('IR receiver'),
    varName: 'ir_receiver',
    // eventName: 'IR received',
    // eventVarName: 'ir_received',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 17],
    unit: '',
    event: {
      eventName: 'IR received',
      eventVarName: 'ir_received',
    },
  },
  S22: {
    id: 'S22',
    name: _t('push button'),
    description: _t('Push Button'),
    varName: 'push_button',
    // eventName: 'button pushed',
    // eventVarName: 'button_pushed',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    event: {
      eventName: 'Button Triggered',
      eventVarName: 'button_pushed',
    },
  },
  S23: {
    id: 'S23',
    name: _t('magnetic sensor'),
    description: _t('Magnetic Sensor'),
    varName: 'magnetic_sensor',
    // eventName: 'magnetic field detected',
    // eventVarName: 'magnetic_field_detected',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    event: {
      eventName: 'Magnetic Sensor Triggered',
      eventVarName: 'magnetic_field_detected',
    },
  },
  S24: {
    id: 'S24',
    name: _t('vibration sensor'),
    description: _t('vibration sensor'),
    varName: 'vibration_sensor',
    // eventName: 'vibration detected',
    // eventVarName: 'vibration_detected',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    event: {
      eventName: 'Vibration Sensor Triggered',
      eventVarName: 'vibration_detected',
    },
  },
  S25: {
    id: 'S25',
    name: _t('Tilt Switch'),
    description: _t('Tilt Switch'),
    varName: 'tilt_switch',
    event: {
      eventName: 'Tilt Sensor Triggered',
      eventVarName: 'tilt_detected',
    },
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 1],
    unit: '',
  },
  S26: {
    id: 'S26',
    name: _t('knock sensor'),
    description: _t('knock sensor'),
    varName: 'knock_sensor',
    // eventName: 'knock detected',
    // eventVarName: 'knock_detected',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    event: {
      eventName: 'Knock Sensor Triggered',
      eventVarName: 'knock_detected',
    },
  },
  S27: {
    id: 'S27',
    name: _t('Touch Sensor'),
    description: _t('Touch Sensor'),
    varName: 'Touch_Sensor',
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    unit: '',
    formatValueForDisplay: value => value ? _t('Touching') : _t('Idle'),
  },
  S28: {
    id: 'S28',
    name: _t('sound sensor'),
    description: _t('sound sensor'),
    varName: 'sound_sensor',
    // eventName: 'sound detected',
    // eventVarName: 'sound_detected',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    event: {
      eventName: 'Sound Sensor Triggered',
      eventVarName: 'sound_detected',
    },
  },
  S29: {
    id: 'S29',
    name: _t('PIR Motion Sensor'),
    description: _t('PIR Motion Sensor'),
    varName: 'human_body_ir_sensor',
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    unit: '',
    formatValueForDisplay: value => value ? _t('Triggered') : _t('Idle'),
    event: {
      eventName: 'Motion Sensor Triggered',
      eventVarName: 'human_body_ir_sensor',
    },
  },
  S30: {
    id: 'S30',
    name: _t('limit switch'),
    description: _t('limit switch'),
    varName: 'limit_switch',
    eventName: 'limit switched',
    eventVarName: 'limit_switched',
    slot: 1,
    category: 'input',
    mode: 'event',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
  },
  S32: {
    id: 'S32',
    name: _t('Switch'),
    description: _t('Switch'),
    varName: 'Switch',
    event: {
      eventName: 'Switched Triggered',
      eventVarName: 'Switched',
    },
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [1, 1],
    unit: '',
    formatValueForDisplay: value => value ? _t('On') : _t('Off'),
  },
  S33: {
    id: 'S33',
    name: _t('IR Reflective Sensor'),
    description: _t('IR Reflective Sensor'),
    varName: 'ir_reflective_sensor',
    slot: 1,
    category: 'input',
    mode: 'continuous',
    type: 'boolean',
    converter: toBoolean,
    range: [0, 1],
    unit: '',
    fontSize: '20px',
    formatValueForDisplay: value => value ? _t('Reflection detected') : _t('No reflection'),
    event: {
      eventName: 'Reflective Sensor Triggered',
      eventVarName: 'ir_reflective_sensor',
    },
  },
  S37: {
    id: 'S37',
    name: _t('Rotary Angel Sensor'),
    description: _t('Rotary Angel Sensor'),
    varName: 'Rotary_Angel_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 330],
    unit: _t('degree'),
    // formatValueForDisplay: value => `${value}`,
    dataKeys: [_t('Angle')],
  },
  S38: {
    id: 'S38',
    name: _t('Moisture Sensor'),
    description: _t('Moisture Sensor'),
    varName: 'Moisture_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Moisture ratio')],
  },
  S39: {
    id: 'S39',
    name: _t('Light Sensor'),
    description: _t('Light Sensor'),
    varName: 'Light_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Brightness')],
  },
  S40: {
    id: 'S40',
    name: _t('steam sensor'),
    description: _t('steam sensor'),
    varName: 'steam_sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
  },
  S41: {
    id: 'S41',
    name: _t('Water Sensor'),
    description: _t('Water Sensor'),
    varName: 'Water_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Water index')],
  },
  S42: {
    id: 'S42',
    name: _t('Air Quality Sensor'),
    description: _t('Air Quality Sensor'),
    varName: 'Air_Quality_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Air pollution index')],
  },
  S43: {
    id: 'S43',
    name: _t('Sound Sensor'),
    description: _t('Sound Sensor'),
    varName: 'Sound_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Sound level')],
  },
  S44: {
    id: 'S44',
    name: _t('Linear Potentiometer'),
    description: _t('Linear Potentiometer'),
    varName: 'Linear_Potentiometer',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Slide position index')],
  },
  S45: {
    id: 'S45',
    name: _t('UV Sensor'),
    description: _t('UV Sensor'),
    varName: 'UV_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('UV strength')],
  },
  S47: {
    id: 'S47',
    name: _t('Pulse Sensor'),
    description: _t('Pulse Sensor'),
    varName: 'Pulse_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Pulse level')],
  },
  S48: {
    id: 'S48',
    name: _t('PH Sensor'),
    description: _t('PH Sensor'),
    varName: 'PH_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('PH index')],
  },
  S49: {
    id: 'S49',
    name: _t('MEMS Microphone'),
    description: _t('MEMS Microphone'),
    varName: 'MEMS_Microphone',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 100],
    unit: '%',
    dataKeys: [_t('Sound level')],
  },
  S52: {
    id: 'S52',
    name: _t('3-Axis Digital Compass'),
    description: _t('3-Axis Digital Compass'),
    varName: '3Axis_Digital_Compass',
    slot: 2,
    category: 'input',
    mode: 'continuous',
    type: 'float',
    converter: toFloat,
    range: [-100, 100],
    unit: '',
    dataKeys: ['X', 'Y', 'Z'],
    fontSize: '20px',
    formatValueForDisplay: value => value ? [`X: ${value[0]} ${_t('Gauss')}`, `Y: ${value[1]} ${_t('Gauss')}`, `Z: ${value[2]} ${_t('Gauss')}`] : '-',
  },
  S53: {
    id: 'S53',
    name: _t('MPU6050 Accelerometer & Gyro'),
    description: _t('MPU6050 Accelerometer & Gyro'),
    varName: 'MPU6050_Accelerometer_and_Gyro',
    slot: 2,
    category: 'input',
    mode: 'continuous',
    type: 'float',
    converter: toFloat,
    range: [-100, 100],
    unit: '',
    dataKeys: [`X ${_t('acceleration')}`, `Y ${_t('acceleration')}`, `Z ${_t('acceleration')}`, `X ${_t('gravity')}`, `Y ${_t('gravity')}`, `Z ${_t('gravity')}`],
    fontSize: '10px',
    formatValueForDisplay: value => value ? [
      `X: ${_t('acceleration')} ${value[0]} m/s2`,
      `Y: ${_t('acceleration')} ${value[1]} m/s2`,
      `Z: ${_t('acceleration')} ${value[2]} m/s2`,
      `X: ${_t('gravity')} ${value[3]} m/s2`,
      `Y: ${_t('gravity')} ${value[4]} m/s2`,
      `Z: ${_t('gravity')} ${value[5]} m/s2`,
    ] : '-',
  },
  S55: {
    id: 'S55',
    name: _t('Color Sensor'),
    description: _t('Color Sensor'),
    varName: 'Color_Sensor',
    slot: 2,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    dataKeys: [`${_t('Color')} R`, `${_t('Color')} G`, `${_t('Color')} B`],
    converter: toInt,
    range: [0, 255],
    unit: '',
    fontSize: '20px',
    formatValueForDisplay: value => value ? [`R: ${value[0]}`, `G: ${value[1]}`, `B: ${value[2]}`] : '-',
  },
  S56: {
    id: 'S56',
    name: _t('Laser Ranging Sensor'),
    description: _t('Laser Ranging Sensor'),
    varName: 'Laser_Ranging_Sensor',
    event: {
      eventName: 'Laser Ranging Sensor Triggered',
      eventVarName: 'Laser_Ranging_Sensor_detected',
    },
    slot: 2,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 2000],
    unit: 'mm',
    dataKeys: [_t('Distance')],
  },
  S57: { // tube_display
    id: 'S57',
    name: _t('4-Digit Display'),
    description: _t('4-Digit Display'),
    varName: 'The_4_Digit_Display',
    slot: 2,
    category: 'output',
    mode: 'continuous',
    dataKeys: [`${_t('Number')} 1`, `${_t('Number')} 2`, `${_t('Number')} 3`, `${_t('Number')} 4`],
    type: 'int',
    converter: num => num,
    range: [0, 255],
    unit: '',
    formatValueForDisplay: (value) => {
      if (!value || value.length < 4) return '--:--';
      return `${value[0]}${value[1]}:${value[2]}${value[3]}`;
    },
  },
  S58: {
    id: 'S58',
    name: _t('LED Matrix'),
    description: _t('LED Matrix'),
    varName: 'led_matrix_display',
    slot: 2,
    category: 'output',
    mode: 'continuous',
    type: 'int',
    converter: num => num,
    range: [0, 255],
    unit: '',
    fontSize: '18px',
    dataKeys: ['1', '2', '3', '4'],
    formatValueForDisplay: value => value ?
      [`1: ${value[0]}`, `2: ${value[1]}`, `3: ${value[2]}`, `4: ${value[3]}`] :
      ['1: -', '2: -', '3: -', '4: -'],
  },
  S59: {
    id: 'S59',
    name: _t('I2C EEPROM'),
    description: _t('I2C EEPROM'),
    varName: 'I2C_EEPROM',
    slot: 2,
    category: 'output',
    mode: 'continuous',
    type: 'string',
    converter: num => num,
    unit: '',
    fontSize: '18px',
    dataKeys: [_t('Text')],
  },
  S60: {
    id: 'S60',
    name: _t('Weight Sensor'),
    description: _t('Weight Sensor'),
    varName: 'Weight_Sensor',
    slot: 3,
    category: 'input',
    mode: 'continuous',
    type: 'int',
    converter: toInt,
    range: [0, 1000],
    unit: 'gram',
    dataKeys: [_t('Gram')],
  },
  S61: {
    id: 'S61',
    name: _t('BME280 Atmospheric Sensor'),
    description: _t('BME280 Atmospheric Sensor'),
    varName: 'BME280_Atmospheric_Sensor',
    slot: 3,
    category: 'output',
    mode: 'continuous',
    type: 'float',
    converter: num => num,
    range: [0, 255],
    unit: '',
    fontSize: '18px',
    dataKeys: [_t('Barometric pressure'), _t('Humidity'), _t('Temperature')],
    formatValueForDisplay: value => value ?
      [`${_t('Barometric')}: ${value[0]} hPa`, `${_t('Humidity')}: ${value[1]} %`, `${_t('Temperature')}: ${value[2]} °C`] :
      [`${_t('Barometric')}: - hPa`, `${_t('Humidity')}: - %`, `${_t('Temperature')}: - °C`],
  },
  S62: {
    id: 'S62',
    name: _t('BMP180 Barometer'),
    description: _t('BMP180 Barometer'),
    varName: 'BMP180_Barometer',
    slot: 3,
    category: 'output',
    mode: 'continuous',
    type: 'float',
    converter: num => num,
    range: [300, 1100],
    unit: 'hPa',
    // fontSize: '18px',
    // dataKeys: ['Barometric pressure', 'Humidity', 'Temperature'],
    formatValueForDisplay: value => value ? `${value}` : '- ',
  },
};
// class SensorSpec {
//   name: string
//   type: string
//   description: string
//   id: number
//   unit: string
//   slot: number
//   available: boolean = false
//   dataKeys: string | string[]
//
//   constructor(id, type, name, description, unit, slot, available, dataKeys) {
//     this.id = id;
//     this.type = type; // in - out - interrupt
//     this.name = name;
//     this.description = description;
//     this.unit = unit;
//     this.slot = slot;
//     this.available = available;
//     this.dataKeys = dataKeys; // title of sensing data
//   }
// }

// export const DISTANCE_SENSOR_ID = 'S13';
// export const TEMPERATURE_SENSOR_ID = 'S14';
// export const HUMIDITY_SENSOR_ID = 'S15';
//
// export const sensorSpecs = {
//   // slot 1
//   S3: new SensorSpec('S3', '110', 'Piranha LED', 'Piranha LED', '%', 1, true, 'brightness'),
//   S4: new SensorSpec('S4', '110', 'RGB Color bar', 'RGB Color bar', '', 1, true, ['index 1 color', 'index 2 color', 'index 3 color', 'index 4 color']),
//   S5: new SensorSpec('S5', '100', 'Buzzer', 'Buzzer', '', 1, true),
//   S6: new SensorSpec('S6', '100', 'IR transmitter', 'IR transmitter', '', 1, true),
//   S7: new SensorSpec('S7', '110', 'Relay', 'Relay', '', 1, true, ''),
//   S14: new SensorSpec('S14', '010', 'Temperature sensor', 'Temperature', '°C', 1, true, 'temperature'),
//   S15: new SensorSpec('S15', '010', 'Temperature and humidity', 'Humidity', '%', 1, true, ['temperature', 'humidity']),
//   S16: new SensorSpec('S16', '001', 'IR receiver', 'IR receiver', '', 1, true, ''),
//   S22: new SensorSpec('S22', '001', 'Push button', 'IR receiver', '', 1, true, ''),
//   S23: new SensorSpec('S23', '011', 'Magnetic sensor', 'Magnetic sensor', '', 1, true, 'magnet detected'),
//   S24: new SensorSpec('S24', '011', 'Vibration sensor', 'Vibration sensor', '', 1, true, 'vibration detected'),
//   S25: new SensorSpec('S25', '011', 'Tilt Sensor', 'Tilt Sensor', '', 1, true, 'tilt detected'),
//   S26: new SensorSpec('S26', '011', 'Knock sensor', 'Knock sensor', '', 1, true, 'knock detected'),
//   S27: new SensorSpec('S27', '011', 'Touch sensor', 'Touch sensor', '', 1, true, 'touch detected'),
//   S28: new SensorSpec('S28', '011', 'Sound sensor', 'Sound sensor', '', 1, true, 'sound detected'),
//   S29: new SensorSpec('S29', '011', 'Human Body Infrared Sensor', 'Human Body Infrared Sensor', '', 1, true, 'body infrared detected'),
//   S30: new SensorSpec('S30', '011', 'Limit switch', 'Limit switch', '', 1, true, 'limit detected'),
//   S31: new SensorSpec('S31', '011', 'Opto-coupler', 'Opto-coupler', '', 1, true, 'optocoupler signal'),
//   // slot 2
//   S52: new SensorSpec('S52', '010', 'Compass', 'Compass', '', 2, true, ''),
//   S53: new SensorSpec('S53', '010', 'Accelerometer', 'Acceleration', '', 2, true, 'acceleration'),
//   S54: new SensorSpec('S54', '010', 'Gyroscope', 'Gyroscope', '', 2, true, 'gyroscope'),
//   S55: new SensorSpec('S55', '010', 'Color sensor', 'Color sensor', '', 2, true, 'color'),
//   // slot 3
//   S37: new SensorSpec('S37', '010', 'Rotation sensor', 'Rotation', '%', 3, true, 'rotation'),
//   S38: new SensorSpec('S38', '010', 'Moisture sensor', 'Moisture', '%', 3, true, 'moisture'),
//   S39: new SensorSpec('S39', '010', 'Photo resistor', 'Photo', '', 3, true, 'photo resistant'),
//   S40: new SensorSpec('S40', '010', 'Stream sensor', 'Stream', '', 3, true, 'stream'),
//   S41: new SensorSpec('S41', '010', 'Water level sensor', 'Water level', '', 3, true, 'water level'),
//   S42: new SensorSpec('S42', '010', 'Smog sensor', 'Smog', '', 3, true, 'smog'),
//   S43: new SensorSpec('S43', '010', 'Alcohol sensor', 'Alcohol', '', 3, true, 'alcohol'),
//   S44: new SensorSpec('S44', '010', 'Light sensor', 'Light', '%', 3, true, 'light'),
//   S45: new SensorSpec('S45', '010', 'UV rays sensors', 'UV rays', '', 3, true, 'UV ray'),
//   S46: new SensorSpec('S46', '010', 'IR obstacle sensor', 'IR obstacle', '', 3, true, 'obstacle'),
//   // slot 4
//   S2: new SensorSpec('S2', '110', 'Servo', 'Servo', '', 4, true, 'gripper opened'),
//   // slot 5
//   S13: new SensorSpec('S13', '010', 'Distance sensor', 'Range to object', 'cm', 5, true, 'distance'),
// };

const MAX_SHOW_SENSOR_DATA = 50;

// eslint-disable-next-line import/prefer-default-export
export function getHistoryData(robot, sensorId) {
  const arr = [];
  if (robot && robot.sensorsData) {
    robot.sensorsData.forEach((s) => {
      // eslint-disable-next-line no-prototype-builtins
      if (s && s.data.hasOwnProperty(sensorId)) {
        let keys = sensorSpecs[sensorId].dataKeys;
        if (!keys) keys = [sensorSpecs[sensorId].name];
        let values = s.data[sensorId];
        if (typeof values === 'string' || !_.isArrayLike(values)) {
          values = [values];
        } else {
          values = values.slice();
        }
        arr.push({ time: s.time, ..._.zipObject(keys, values) });
      }
    });
  }
  return arr.slice(-MAX_SHOW_SENSOR_DATA);
}

