/* eslint-disable no-param-reassign */
import mqtt from 'mqtt';
import { nanoid } from 'nanoid';

const log = require('debug')('ui:MqttStore');

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
// . * + ? ^ $ { } ( ) | / [ ] \
const regExpForEscape = /[.*+?^${}()|/[\]\\]/g;
function _escapeRegExp(regExp) {
  // $& means the whole matched string;
  if (regExp instanceof RegExp) return regExp.source.replace(regExpForEscape, '\\$&');
  return (`${regExp}`).replace(regExpForEscape, '\\$&');
}

class MqttStore {
  constructor() {
    this._init();
    log('constructor');
  }

  topicHandles = {};
  onMessageHandles = {};
  handleCounter = 0;
  connUUID = '';

  mqttClient = null;

  _init() {
    const mqttOptions = {
      username: process.env.MQTT_USER,
      password: process.env.MQTT_PASSWORD,
    };
    this.mqttClient = mqtt.connect(process.env.MQTT_HOST, mqttOptions);
    this.mqttClient.on('message', this._onMessage.bind(this));
    this.mqttClient.on('connect', () => {
      this.connUUID = nanoid();
      Object.values(this.topicHandles).forEach((handle) => {
        this.subscribe(...[handle.topic, handle.options]);
      });
    });
  }

  _onMessage(topic, msg) {
    log('mqtt.onMessage', topic, msg.toString());
    Object.values(this.onMessageHandles).forEach((cb) => {
      cb(topic, msg);
    });
    Object.keys(this.topicHandles).forEach((handleTopic) => {
      const handleObject = this.topicHandles[handleTopic];
      let isHit = false;
      if (!!handleObject.regExp && (new RegExp(handleObject.regExp)).test(topic)) {
        isHit = true;
      } else if (handleObject.topic === topic) {
        isHit = true;
      }
      if (isHit) {
        Object.values(handleObject.handles).forEach((cb) => {
          cb(topic, msg);
        });
      }
    });
  }
  _rebuildSubscription() {
    this.topicHandles.keys().forEach((topic) => {
      this.mqttClient.subscribe(topic);
    });
  }

  get clientId() {
    if (!this.mqttClient || !this.mqttClient.options) return undefined;
    return this.mqttClient.options.clientId;
  }

  reset() {
    Object.keys(this.topicHandles).forEach((topic) => {
      this.mqttClient.unsubscribe(topic);
      delete this.topicHandles[topic];
    });
    log('reset', Object.keys(this.topicHandles));
  }

  discard() {
    this.reset();
    this.mqttClient.end();
  }

  subscribe(...args) {
    return this.mqttClient.subscribe(...args);
  }

  publish(...args) {
    log('publish: args:', args);
    return this.mqttClient.publish(...args);
  }

  onMessage(cb) {
    this.handleCounter += 1;
    const handleId = this.handleCounter;
    this.onMessageHandles[handleId] = cb;
    return () => {
      delete this.onMessageHandles[handleId];
    };
  }

  onTopic(topic, _opt, _cb) {
    log('onTopic', topic);
    this.handleCounter += 1;
    const handleId = this.handleCounter;
    // (new RegExp("^/[^/]+/$")).test('/abc/')
    // (new RegExp("^/.*/$")).test('/abc/')
    let cb = _cb;
    let opt = _opt;
    if (typeof _opt === 'function') {
      cb = _opt;
      opt = null;
    }
    cb = cb || (() => {});
    let topicHandle = this.topicHandles[topic];
    if (!topicHandle) {
      let topicRegExp = null;
      if (topic.indexOf('+') > -1 || topic.indexOf('#') > -1) {
        // topicRegExp = `^${topic.replace(/\+/g, '[^/]+').replace(/#/g, '.*')}$`;
        topicRegExp = _escapeRegExp(topic);
        topicRegExp = `^${topicRegExp.replace(/\\\+/g, '[^/]+').replace(/#/g, '.*')}$`;
      }
      topicHandle = {
        regExp: topicRegExp,
        topic,
        options: opt,
        handles: {},
      };
      this.topicHandles[topic] = topicHandle;
      if (this.mqttClient.connected) {
        this.mqttClient.subscribe(...[topic, opt]);
      }
    }

    topicHandle.handles[handleId] = cb;
    return () => {
      log('offTopic', topic, handleId);
      delete topicHandle.handles[handleId];
      if (Object.keys(topicHandle.handles).length === 0) {
        this.mqttClient.unsubscribe(topic);
        delete this.topicHandles[topic];
      }
    };
  }
}

export default new MqttStore();
export { MqttStore };
