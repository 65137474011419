export const COLORS = [
  '#000000',
  '#ffffff',
  '#ff0000',
  '#00ff00',
  '#0000ff',
  '#ffff00',
  '#ff00ff',
  '#00ffff',
  '#ff003f',
];

export const IR_COMMANDS = [
  ['1', '16724175'],
  ['2', '16718055'],
  ['3', '16743045'],
  ['4', '16716015'],
  ['5', '16726215'],
  ['6', '16734885'],
  ['7', '16728765'],
  ['8', '16730805'],
  ['9', '16732845'],
  ['0', '16738455'],
  ['C', '16756815'],
  ['+', '16712445'],
  ['-', '16750695'],
  ['⇦', '16769055'],
  ['⇨', '16748655'],
  ['TEST', '16720605'],
  ['MENU', '16769565'],
  ['Esc', '16761405'],
  ['Power', '16753245'],
];

export const GESTURES = [
  ['⇦', '3'],
  ['⇨', '4'],
  ['⇧', '5'],
  ['⇩', '6'],
];

export const RTTTL_SONGS = [
  ['Indiana', '1'],
  ['Simpsons', '2'],
  ['Picaxe', '3'],
  ['Nokia', '4'],
  ['Alert', '5'],
  ['Landline', '6'],
  ['Siren', '7'],
  ['Finish', '8'],
  ['Dump', '9'],
  ['Ring High', '10'],
];

export const MATRIX_8_8_LIST = [
  ['⇧', '1'],
  ['⇩', '2'],
  ['⇦', '3'],
  ['⇨', '4'],
  ['✚', '5'],
  ['❎', '6'],
  ['✅', '7'],
  ['💙', '8'],
  ['🙈', '9'],
];
