/* eslint-disable no-param-reassign */
import { action, runInAction, observable, computed } from 'mobx';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import {
  addStudent,
  associateStudentToRobotForClassRoom,
  removeStudentFromClassRoom,
  fetchClassRoomStudents,
} from 'api/classRoom';
import Papa from 'papaparse';

class ClassRoomStudentsStore {
  @observable all = observable([]);
  @observable isImportingFromCSV = false;
  @observable importFromCSVError = undefined;
  @observable addResult = undefined;
  @observable activeLayout = 'list';

  constructor() {
    this.tick();
  }

  async tick() {
    if (parseInt(Date.now() / 1e3, 10) % 1 === 0) this.checkStudentOnline();
    setTimeout(this.tick.bind(this), 1 * 1e3);
  }

  checkStudentOnline() {
    this.all.forEach((student) => {
      student.status = student.status || {};
      student.status.online =
        (student.lastPingAt && Date.now() - student.lastPingAt.getTime() < (4 * 1e3));
    });
  }

  @computed get allChecked() {
    const totalChecked = this.selecteds.length;
    if (totalChecked === this.all.length) {
      return true;
    }

    if (totalChecked === 0) {
      return false;
    }

    return undefined;
  }

  set allChecked(value) {
    this.all.forEach(student => this.setChecked(student, value));
  }

  @computed get selecteds() {
    return this.all.filter(it => it.checked);
  }

  @computed get allocatedRobots() {
    return this.all.reduce((acc, stu) => {
      if (stu.robotId && stu.robotId !== 'none') {
        acc[stu.robotId] = stu;
      }
      return acc;
    }, {});
  }

  @action remove(classRoomCode, students) {
    students.forEach((student) => {
      Promise.resolve()
        .then(() => {
          if (!student.studentClassRoomId) return true;
          return removeStudentFromClassRoom(classRoomCode, student.studentClassRoomId);
        })
        .then(() => runInAction('add imported students', () => this.all.remove(student)));
    });
  }

  @action add(student = {
    _id: nanoid(),
    name: '',
    checked: false,
    status: {
      online: false,
    },
    isNew: true,
  }) {
    this.all.push(observable(student));
  }

  @action setAllChecked(val) {
    this.allChecked = val;
  }

  @action setAllStudents(students) {
    const newAllStudent = students.map((s) => {
      const robotId = s.robots.length > 0 ? s.robots[0]._id : undefined;
      return {
        ...s.student,
        studentClassRoomId: s._id,
        robotId,
        classRoomCode: s.classRoom,
        checked: false,
        lastPingAt: undefined,
        status: {
          online: false,
        },
      };
    });
    this.all.replace(newAllStudent);
  }
  @action setChecked = action('set check student state', (student, val) => {
    let studentId;
    if (typeof student !== 'string') {
      studentId = _.get(student, '_id') || student._id;
      student.checked = val;
    }
    const thisStudent = this.all.find(s => `${s._id}` === `${studentId}`);
    if (thisStudent) {
      thisStudent.checked = val;
    } else {
      console.warn(`setChecked: No student ${studentId}`);
    }
  });
  @action setName = action('set name student state', (student, val) => {
    let studentId;
    if (typeof student !== 'string') {
      studentId = _.get(student, '_id') || student._id;
      student.name = val;
    }
    const thisStudent = this.all.find(s => `${s._id}` === `${studentId}`);
    if (thisStudent) {
      thisStudent.name = val;
    } else {
      console.warn(`setName: No student ${studentId}`);
    }
  });
  @action setRobot = action('set robot to the student state', (student, val) => {
    let studentId;
    if (typeof student !== 'string') {
      studentId = _.get(student, '_id') || student._id;
      student.robotId = val;
    }
    const thisStudent = this.all.find(s => `${s._id}` === `${studentId}`);
    if (thisStudent) {
      thisStudent.robotId = val;
    } else {
      console.warn(`setRobot: No student ${studentId}`);
    }
  });

  @action setActiveLayout = action('set active layout', (val) => {
    this.activeLayout = val;
  });

  @action importFromCSV = action('import CSV file/text', (csv) => {
    try {
      let formattedImport = csv;
      if (_.isString(csv)) {
        const firstLine = csv.split('\n')[0];
        const regTab = /(.+)\t(.+)$/gmi;
        if (firstLine.match(regTab)) {
          formattedImport = csv.replace(regTab, '$1,$2');
        }
      }

      this.isImportingFromCSV = true;
      const self = this;
      Papa.parse(formattedImport, {
        header: false,
        complete(results) {
          const mapNameProps = _.curry(_.zipObject)(['name']);
          let studentsToImport = _.map(results.data, mapNameProps);
          studentsToImport = studentsToImport.map((s) => {
            const nameSplited = s.name.split(' ');
            return {
              name: nameSplited[0] + (nameSplited.length > 1 ? nameSplited[1][0] : ''),
            };
          });

          if (_.isArray(results.errors) && results.errors.length > 0) {
            const significantsErrors = _.reject(results.errors, { code: 'UndetectableDelimiter' });
            if (significantsErrors.length > 0) {
              self.importFromCSVError = significantsErrors;
            }
          }

          runInAction('add imported students', () => {
            _.each(studentsToImport, (it) => {
              self.add(_.assignIn({ checked: false, _id: nanoid(), isNew: true }, it));
            });
          });
        },
        error(error) {
          this.importFromCSVError = error;
        },
        skipEmptyLines: true,
      });
    } catch (error) {
      this.importFromCSVError = error;
    } finally {
      this.isImportingFromCSV = false;
    }
  });

  @action save(classRoomId) {
    const students = _.map(this.all, student => ({
      _id: student._id,
      username: student.name,
      name: student.name,
      robotId: student.robotId,
      isNew: student.isNew,
    }));

    this.all.clear();

    const newStudents = students.filter(s => s.isNew && s.name && s.name !== '');
    const updateStudents = students.filter(s => s._id && !s.isNew);

    return Promise.all([
      (newStudents.length > 0 ? addStudent(classRoomId, newStudents) : []),
      ...updateStudents
        .map(s => associateStudentToRobotForClassRoom(classRoomId, s._id, s.name, s.robotId)),
    ]);
  }

  @action clearStudents() {
    this.all.clear();
  }

  @action async fetchAndSetAllStudentsByClassRoomCode(classRoomCode) {
    const students = await fetchClassRoomStudents(classRoomCode);
    this.setAllStudents(students.data);
  }

  @action setLastPingAtByStudentName(studentId, date) {
    const student = this.all.find(s => `${s._id}` === studentId);
    if (student) {
      student.lastPingAt = date;
    }
  }
}

export default new ClassRoomStudentsStore();
export { ClassRoomStudentsStore };
