//  import mqtt from 'mqtt'
//  const host = window.location.hostname
//  const client = mqtt.connect(`ws://${host}:443`, {username: 'alice', password: 'secret'})
//  let mqReady = fals
//  client.on('connect', () => {
//    mqReady = true
//  })

export function getJsInterface (mqttClient) {
  return function (interpreter, scope) {
    var wpAlert = function (text) {
      text = text ? text.toString() : ''
      return interpreter.createPrimitive(window.alert(text))
    }
    interpreter.setProperty(scope, 'alert',
      interpreter.createNativeFunction(wpAlert)
    )

    const wpMqPublish = function (topic, msg) {
      msg = JSON.parse(msg.valueOf()) || {}
      topic = topic.valueOf()
      return mqttClient.publish(topic, JSON.stringify(msg))
    }
    interpreter.setProperty(scope, 'mqPublish',
      interpreter.createNativeFunction(wpMqPublish)
    )
  }
}
