/* eslint-disable no-cond-assign */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable operator-assignment */
/* eslint-disable max-len */

import Blockly from 'blockly';
import { nanoid } from 'nanoid';
import { checkIfDropdownTextValueObject } from 'utils/blocklyUtils';
import classRoomStore from 'store/classRoomStore';
import uiStore from 'store/uiStore';
import blocklySearchStatehook from 'store/statehook/blocklySearchStatehook';
import { getNS } from '_i18n_';
import monkeyify from 'monkeyify';
import optionsFor from './options';
import './blocks/js_code';
import definitions from './blocks/definition';
// const { codeBlocklyStatehook } = require('store/statehook/codeBlocklyStatehook');
const { codeBlocklyPythonStatehook } = require('store/statehook/codeBlocklyPythonStatehook');

const _t = getNS('workspaces/basic/index.js');

const log = require('debug')('ui:workspace/basic/index.js');

// https://github.com/google/blockly/blob/f8fc748055515e30eb6ae63106b85313824c837f/core/trashcan.js#L428
function bindMonkeyPatch__preventRunProgramFromBingDeleted__Blockly_Trashcan_prototype_onDelete_() {
  Blockly.Trashcan.prototype.onDelete_ = monkeyify(Blockly.Trashcan.prototype.onDelete_, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const event = args[0];
      if (event.type === Blockly.Events.BLOCK_DELETE) {
        if (event.oldXml) {
          if ('function' === typeof event.oldXml.getAttribute) {
            if (`${event.oldXml.getAttribute('type')}` !== 'runProgram') {
              return original.apply(caller, args);
            }
          }
        }
      }
      return undefined;
    },
  });
}

function bindMonkeyPatch__Blockly_ZoomControls_prototype_createDom() {
  Blockly.ZoomControls.prototype.createDom =
      monkeyify(Blockly.ZoomControls.prototype.createDom, {
        allowMonkeyInMonkey: 'returnOldMonkey',
        monkey: (caller, args, originalFunction) => {
          const svgGroup_ = originalFunction.apply(caller, args);
          monkeyPatch__AppendExtraButtonForZoomControls_forOfficialVersion(caller);
          return svgGroup_;
        },
      });
}
// https://github.com/google/blockly/blob/23a78c89e4c0f2801768b5c55c7f91ac261f4bc6/core/zoom_controls.js#L187
// eslint-disable-next-line camelcase
function monkeyPatch__AppendExtraButtonForZoomControls_forOfficialVersion(zoomControls_) {
  const { svgGroup_, workspace_ } = zoomControls_;
  // old version || for the official version
  const _createSvgElement_ = Blockly.utils.createSvgElement || Blockly.utils.dom.createSvgElement;

  const rnd = String(Math.random()).substring(2);
  // eslint-disable-next-line no-use-before-define
  _undo.call(this);
  // eslint-disable-next-line no-use-before-define
  _redo.call(this);
  // eslint-disable-next-line no-use-before-define
  _clear.call(this);
  // undo
  function _undo() {
    zoomControls_.undoGroup_ = _createSvgElement_(
      'g',
      { class: 'blocklyZoom' },
      svgGroup_,
    );
    const clip = _createSvgElement_(
      'clipPath',
      {
        id: `blocklyUndoClipPath${rnd}`,
      },
      zoomControls_.undoGroup_,
    );
    _createSvgElement_(
      'rect',
      {
        width: 32,
        height: 32,
        x: -34,
        y: 43,
      },
      clip,
    );
    const undoSvg = _createSvgElement_(
      'image',
      {
        width: Blockly.SPRITE.width,
        height: Blockly.SPRITE.height,
        x: -98,
        y: 15,
        'clip-path': `url(#blocklyUndoClipPath${rnd})`,
      },
      zoomControls_.undoGroup_,
    );
    undoSvg.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      workspace_.options.pathToMedia + Blockly.SPRITE.url,
    );
    Blockly.bindEventWithChecks_(undoSvg, 'mousedown', null, (e) => {
      if (workspace_.undoStack_.length <= 0) return;
      workspace_.markFocused();
      workspace_.undo(false);
      Blockly.Touch.clearTouchIdentifier(); // Don't block future drags.
      e.stopPropagation(); // Don't start a workspace scroll.
      e.preventDefault(); // Stop double-clicking from selecting text.
    });
  }

  // redo
  function _redo() {
    zoomControls_.redoGroup_ = _createSvgElement_(
      'g',
      { class: 'blocklyZoom' },
      svgGroup_,
    );
    const clip = _createSvgElement_(
      'clipPath',
      {
        id: `blocklyRedoClipPath${rnd}`,
      },
      zoomControls_.redoGroup_,
    );
    _createSvgElement_(
      'rect',
      {
        width: 32,
        height: 32,
        x: -34,
        y: 77,
      },
      clip,
    );
    const redoSvg = _createSvgElement_(
      'image',
      {
        width: Blockly.SPRITE.width,
        height: Blockly.SPRITE.height,
        x: -98,
        y: 17,
        'clip-path': `url(#blocklyRedoClipPath${rnd})`,
      },
      zoomControls_.redoGroup_,
    );
    redoSvg.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      workspace_.options.pathToMedia + Blockly.SPRITE.url,
    );
    Blockly.bindEventWithChecks_(redoSvg, 'mousedown', null, (e) => {
      if (workspace_.redoStack_.length <= 0) return;
      workspace_.markFocused();
      workspace_.undo(true);
      Blockly.Touch.clearTouchIdentifier(); // Don't block future drags.
      e.stopPropagation(); // Don't start a workspace scroll.
      e.preventDefault(); // Stop double-clicking from selecting text.
    });
  }

  // clear button
  function _clear() {
    zoomControls_.clearGroup_ = _createSvgElement_(
      'g',
      { class: 'blocklyZoom' },
      svgGroup_,
    );
    const clip = _createSvgElement_(
      'clipPath',
      {
        id: `blocklyClearClipPath${rnd}`,
      },
      zoomControls_.clearGroup_,
    );
    _createSvgElement_(
      'rect',
      {
        width: 32,
        height: 32,
        x: -34,
        y: 0,
      },
      clip,
    );
    const clearSvg = _createSvgElement_(
      'image',
      {
        width: Blockly.SPRITE.width,
        height: Blockly.SPRITE.height,
        x: -98,
        y: 4,
        'clip-path': `url(#blocklyClearClipPath${rnd})`,
      },
      zoomControls_.clearGroup_,
    );
    clearSvg.setAttributeNS(
      'http://www.w3.org/1999/xlink',
      'xlink:href',
      workspace_.options.pathToMedia + Blockly.SPRITE.url,
    );
    Blockly.bindEventWithChecks_(clearSvg, 'mousedown', null, async (e) => {
      workspace_.markFocused();

      const { value } = await kaiAlert.fire({
        title: _t('Delete unused blocks'),
        text: _t('You will not be able to restore these unused blocks.'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#0E6EB8',
        cancelButtonColor: '#B03060',
        confirmButtonText: _t('Yes'),
        cancelButtonText: _t('Cancel'),
      });
      if (value === true) {
        classRoomStore.clearUselessTopBlock();
      }

      Blockly.Touch.clearTouchIdentifier(); // Don't block future drags.
      e.stopPropagation(); // Don't start a workspace scroll.
      e.preventDefault(); // Stop double-clicking from selecting text.
    });
  }
}

// https://github.com/google/blockly/blob/3342a375ff0cee0be42062765d24922be2917170/core/zoom_controls.js#L134
// https://github.com/google/blockly/blob/23a78c89e4c0f2801768b5c55c7f91ac261f4bc6/core/zoom_controls.js#L143
function bindMonkeyPatch__Blockly_ZoomControls_prototype_position() {
  Blockly.ZoomControls.prototype.position = monkeyify(Blockly.ZoomControls.prototype.position, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const res = original.apply(caller, args);
      // if (caller.zoomResetGroup_) {
      //   caller.zoomResetGroup_.setAttribute('transform', 'translate(0, 77)');
      // }
      // caller.zoomInGroup_.setAttribute('transform', 'translate(0, 43)');
      // caller.zoomOutGroup_.setAttribute('transform', 'translate(0, 77)');

      // Not yet initialized.
      if (!caller.verticalSpacing_) {
        return undefined;
      }
      const metrics = caller.workspace_.getMetrics();
      if (!metrics) {
        // There are no metrics available (workspace is probably not visible).
        return undefined;
      }
      caller.left_ = caller.MARGIN_SIDE_ + Blockly.Scrollbar.scrollbarThickness;
      if (metrics.toolboxPosition === Blockly.TOOLBOX_AT_LEFT) {
        caller.left_ += metrics.flyoutWidth;
        if (caller.workspace_.toolbox_) {
          caller.left_ += metrics.absoluteLeft;
        }
      }
      caller.left_ = caller.left_ + 0;
      caller.top_ = caller.top_ + 0;
      caller.svgGroup_.setAttribute(
        'transform',
        `translate(${caller.left_},${caller.top_})`,
      );
      caller.undoGroup_.setAttribute('transform', 'translate(70, 0)');
      caller.redoGroup_.setAttribute('transform', 'translate(70, 0)');
      caller.clearGroup_.setAttribute('transform', 'translate(70, 0)');
      return res;
    },
  });
}

// https://github.com/google/blockly/blob/f8fc748055515e30eb6ae63106b85313824c837f/core/trashcan.js#L324
function bindMonkeyPatch__Blockly_Trashcan_prototype_position() {
  Blockly.Trashcan.prototype.position = monkeyify(Blockly.Trashcan.prototype.position, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const res = original.apply(caller, args);

      // Not yet initialized.
      if (!caller.verticalSpacing_) {
        return undefined;
      }

      const metrics = caller.workspace_.getMetrics();
      if (!metrics) {
        // There are no metrics available (workspace is probably not visible).
        return res;
      }
      caller.left_ = caller.MARGIN_SIDE_ + Blockly.Scrollbar.scrollbarThickness;
      if (metrics.toolboxPosition === Blockly.TOOLBOX_AT_LEFT) {
        caller.left_ += metrics.flyoutWidth;
        if (caller.workspace_.toolbox_) {
          caller.left_ += metrics.absoluteLeft;
        }
      }
      caller.left_ = caller.left_ + 0;
      caller.top_ = caller.top_ + -200;
      caller.svgGroup_.setAttribute(
        'transform',
        `translate(${caller.left_},${caller.top_})`,
      );
      return res;
    },
  });
}

// eslint-disable-next-line no-unused-vars
function bindMonkeyPatch__autoUnexpanded() {
  let treeNode = null;
  if (Blockly.tree) { // for the new version of blockly
    treeNode = Blockly.tree.TreeNode;
  } else {
    treeNode = Blockly.Toolbox.TreeNode;
  }
  treeNode.prototype.onClick_ = monkeyify(treeNode.prototype.onClick_, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const returnVal = original.apply(caller, args);
      // init
      Array.from(caller.toolbox_.tree_.children_)
        .forEach((oneTopCategory) => {
          if (oneTopCategory.id_ !== caller.id_ && caller.parent_.id_ !== oneTopCategory.id_) {
            if (typeof oneTopCategory.setExpanded === 'function') oneTopCategory.setExpanded(false);
          }
        });
      return returnVal;
    },
  });
}

function bindMonkeyPatch__shortenBlockIdLengthTo9__Blockly_Xml_domToWorkspace() {
  Blockly.Xml.domToWorkspace = monkeyify(Blockly.Xml.domToWorkspace, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const xmlDom = args[0];
      const allBlocks = xmlDom.getElementsByTagName('block');
      if (allBlocks) {
        for (let i = 0; i < allBlocks.length; i += 1) {
          const attrId = allBlocks[i].getAttribute('id') || '';
          if (attrId.length !== 9) {
            allBlocks[i].setAttribute('id', Blockly.utils.genUid());
          }
        }
      }
      args[0] = xmlDom;
      return original.apply(caller, args);
    },
  });
}


// fix the position offset after zoom in or zoom out
// https://github.com/google/blockly/blob/d471f5104539843cb2912c2749600c8a80f0cdbe/core/block_dragger.js#L203
function bindMonkeyPatch__fixThePositionOffsetAfterZoominAndZoomout__Blockly_BlockDragger_prototype_dragBlock() {
  Blockly.BlockDragger.prototype.dragBlock = monkeyify(Blockly.BlockDragger.prototype.dragBlock, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (call, args, original) => {
      const [e, currentDragDeltaXY, ...rest] = args;
      if (uiStore && uiStore.getViewsVisibleProgress() > 0) {
        // eslint-disable-next-line operator-assignment, no-param-reassign
        currentDragDeltaXY.x = currentDragDeltaXY.x / 0.8;
        // eslint-disable-next-line operator-assignment, no-param-reassign
        currentDragDeltaXY.y = currentDragDeltaXY.y / 0.8;
      }
      return original.call(call, e, currentDragDeltaXY, ...rest);
    },
  });
}

function bindMonkeyPatch__Blockly_Toolbox_prototype_position() {
  Blockly.Toolbox.prototype.position = monkeyify(Blockly.Toolbox.prototype.position, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args, original) => {
      const returnVal = original.apply(caller, args);
      monkeyPatchFunction__reduceToolboxHeightToPreventBlockingFromRunButton(caller);
      return returnVal;
    },
  });
}
// https://github.com/google/blockly/blob/82871b3d24563388a6f113da8984694e9f37b53e/core/toolbox.js#L243
function monkeyPatchFunction__reduceToolboxHeightToPreventBlockingFromRunButton(caller) {
  const treeDiv = caller.HtmlDiv;
  if (treeDiv) {
    const svg = caller.workspace_.getParentSvg();
    const svgSize = Blockly.svgSize(svg);
    if (caller.horizontalLayout_) {
      // pass
    } else {
      // blocklyToolboxDiv
      // const progress = uiStore.getViewsVisibleProgress();
      // treeDiv.style.height = `${parseInt(svgSize.height, 10) - ((1 - progress) * 80)}px`;
      // set it always retract for the small height screen.
      treeDiv.style.height = `${parseInt(svgSize.height, 10) - 80}px`;
      treeDiv.style.paddingBottom = '40px';
    }
  }
}

// https://github.com/google/blockly/blob/82871b3d24563388a6f113da8984694e9f37b53e/core/block.js#L839
function bindMonkeyPatch__Blockly_Block_prototype_setHelpUrl() {
  Blockly.Block.prototype.setHelpUrl = monkeyify(Blockly.Block.prototype.setHelpUrl, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: () => {},
  });
}
// https://github.com/google/blockly/blob/82871b3d24563388a6f113da8984694e9f37b53e/core/block.js#L839
function bindMonkeyPatch__Blockly_Block_prototype_helpUrl() {
  // Blockly.Block.prototype.helpUrl is a custom function but official function
  // helpUrl need to be assigned the value from you. I assign all the helpUrl as my function
  Blockly.Block.prototype.helpUrl = monkeyify(Blockly.Block.prototype.helpUrl || (() => {}), {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller, args) => {
      const eventType = args[0];
      if (eventType !== 'clicked') return 'https://app.kaisclan.ai/help/block';
      uiStore.setViewsVisible(true);
      uiStore.setBlockHelpVisible(true);
      classRoomStore.setSelectedBlock(caller);
      return undefined;
    },
  });
}

function bindMonkeyPatch__Blockly_BlockSvg_prototype_showHelp_() {
  Blockly.BlockSvg.prototype.showHelp = monkeyify(Blockly.BlockSvg.prototype.showHelp, {
    allowMonkeyInMonkey: 'returnOldMonkey',
    monkey: (caller) => {
      const url = (typeof caller.helpUrl === 'function') ? caller.helpUrl('clicked') : caller.helpUrl;
      if (url) {
        window.open(url);
      }
    },
  });
}

function bindMonkeyPatch__keepTheValueFromDatabaseInDropdownList() {
  function _addItemToAdditionValueList(caller, newValue) {
    caller._xAdditionValueList = caller._xAdditionValueList || [];
    newValue = `${newValue}`;
    if (!caller._xAdditionValueList.find(v => `${v[1]}` === newValue)) {
      let optionText;
      const optionValue = newValue;
      try {
        const newValueJson = JSON.parse(optionValue);
        if (!checkIfDropdownTextValueObject(newValueJson)) {
          throw new Error('Dropdown value is not a TextValueObject');
        }
        optionText = newValueJson.text;
      } catch (error) {
        optionText = optionValue;
      }
      caller._xAdditionValueList.push([optionText, optionValue]);
    }
  }
  Blockly.FieldDropdown.prototype.doValueUpdate_ = monkeyify(Blockly.FieldDropdown.prototype.doValueUpdate_, {
    allowMonkeyInMonkey: 'return old monkey',
    monkey: (caller, args, oldFunc) => {
      if (!args[1] || !args[1].notAdditionValue) {
        _addItemToAdditionValueList(caller, args[0]);
      }
      return oldFunc.apply(caller, args);
    },
  });
  Blockly.Field.prototype.setValue = monkeyify(Blockly.Field.prototype.setValue, {
    allowMonkeyInMonkey: 'return old monkey',
    monkey: (caller, args, oldFunc) => {
      if (!args[1] || !args[1].notAdditionValue) {
        _addItemToAdditionValueList(caller, args[0]);
      }
      return oldFunc.apply(caller, args);
    },
  });
  Blockly.FieldDropdown.prototype.getOptions = monkeyify(Blockly.FieldDropdown.prototype.getOptions, {
    allowMonkeyInMonkey: 'return old monkey',
    monkey: (caller, args, oldFunc) => {
      let result = oldFunc.apply(caller, args);
      if (Object.prototype.hasOwnProperty.call(caller, '_xAdditionValueList')) {
        let { _xAdditionValueList } = caller;
        _xAdditionValueList = _xAdditionValueList || [];
        const xValueNeedToBeAdded = _xAdditionValueList.filter((xV) => {
          return !result.find(rV => `${rV[1]}` === `${xV[1]}`);
        });
        result = [].concat(xValueNeedToBeAdded, result);
      }
      return result;
    },
  });
}

// eslint-disable-next-line
function bindMonkeyPatch__asyncDropdownListContent__Blockly_FieldDropdown() {
  // put option dict to the instance of dropdown
  Blockly.FieldDropdown.fromJson = monkeyify(Blockly.FieldDropdown.fromJson, {
    allowMonkeyInMonkey: 'return old monkey',
    monkey: (caller, args, original) => {
      const instance = original.apply(caller, args);
      // eslint-disable-next-line
      instance._xOriginalConfig = args[0];
      return instance;
    },
  });

  // check if there is an asyncOptions in the options dict, call it
  Blockly.FieldDropdown.prototype.showEditor_ = monkeyify(Blockly.FieldDropdown.prototype.showEditor_, {
    allowMonkeyInMonkey: 'return old monkey',
    monkey: (caller, args, originalFunction) => {
      const { _xOriginalConfig } = caller;
      let returnVal;
      if (_xOriginalConfig && typeof _xOriginalConfig.asyncOptions === 'function') {
        if (caller._xIsAsyncFetching) return undefined;
        caller._xIsAsyncFetching = true;
        const currentValue = caller.getValue();
        const currentText = caller.getText();
        // console.log(caller.selectedOption_, currentValue, 'ddddss');
        const spaceHolder = `${parseInt(Math.random() * 1000000, 10)}`;
        caller.menuGenerator_ = () => {
          return [
            [spaceHolder, spaceHolder],
            ['Loading...', currentValue],
          ];
        };
        returnVal = originalFunction.apply(caller, args);
        caller.setValue(spaceHolder, { notAdditionValue: true });
        caller.setValue(currentValue);
        Blockly.DropDownDiv.hideIfOwner(caller, true);
        _xOriginalConfig.asyncOptions((error, responseOptions, selected) => {
          if (error) {
            caller.menuGenerator_ = () => {
              return [
                [spaceHolder, spaceHolder],
                [currentText, currentValue],
              ];
            };
            originalFunction.apply(caller, args);
            caller.setValue(spaceHolder, { notAdditionValue: true });
            caller.setValue(currentValue);
            Blockly.DropDownDiv.hideIfOwner(caller, true);
            caller._xIsAsyncFetching = false;
          } else {
            caller.menuGenerator_ = () => {
              return [[spaceHolder, spaceHolder]];
            };
            originalFunction.apply(caller, args);
            caller.setValue(spaceHolder, { notAdditionValue: true });
            // ---
            caller.menuGenerator_ = () => {
              return responseOptions;
            };
            originalFunction.apply(caller, args);
            caller.setValue(selected);
            // caller.setValue('');
            Blockly.DropDownDiv.hideIfOwner(caller, true);
            caller._xIsAsyncFetching = false;
          }
        });
      } else {
        returnVal = originalFunction.apply(caller, args);
      }
      return returnVal;
    },
  });
}

(() => {
  blocklySearchStatehook.injectMonkeyPatchToBlocklyLibrary(Blockly);
  bindMonkeyPatch__Blockly_Block_jsonInit__for_PythonFunctionDefinitions();
  bindMonkeyPatch__keepTheValueFromDatabaseInDropdownList();
  bindMonkeyPatch__asyncDropdownListContent__Blockly_FieldDropdown();

  bindMonkeyPatch__preventRunProgramFromBingDeleted__Blockly_Trashcan_prototype_onDelete_();
  bindMonkeyPatch__Blockly_ZoomControls_prototype_position();
  bindMonkeyPatch__Blockly_Trashcan_prototype_position();
  bindMonkeyPatch__Blockly_ZoomControls_prototype_createDom();
  // bindMonkeyPatch__autoUnexpanded();
  bindMonkeyPatch__shortenBlockIdLengthTo9__Blockly_Xml_domToWorkspace();
  bindMonkeyPatch__fixThePositionOffsetAfterZoominAndZoomout__Blockly_BlockDragger_prototype_dragBlock();
  bindMonkeyPatch__Blockly_Toolbox_prototype_position();

  bindMonkeyPatch__Blockly_Block_prototype_setHelpUrl();
  bindMonkeyPatch__Blockly_Block_prototype_helpUrl();
  bindMonkeyPatch__Blockly_BlockSvg_prototype_showHelp_();
})();
// bindMonkeyPatchForBlockly();

function blocklyInit() {
  // https://github.com/google/blockly/issues/299
  // https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
  Blockly.WorkspaceSvg.prototype.preloadAudio_ = function preloadAudio_(...args) {
    console.log('preloadAudio_ arguments:', args);
  };
  Blockly.utils.genUid = () => `${nanoid(9)}`.slice(0, 9);
}


function proxy__BlockSvg_setWarningText__to__ViewsConsole() {
  Blockly.BlockSvg.prototype.__setWarningTextOriginal = Blockly.BlockSvg.prototype.setWarningText;
  Blockly.BlockSvg.prototype.setWarningText = new Proxy(
    Blockly.BlockSvg.prototype.__setWarningTextOriginal,
    {
      apply: (target, _self, args) => {
        if (args[0]) classRoomStore.addConsoleLog(`[${_t('WARNING')}] [${_t('Block ID')} : ${_self.id}] : ${args[0]}`);
        return target.apply(_self, args);
      },
    },
  );
}

function bindMonkeyPatch__Blockly_Block_jsonInit__for_PythonFunctionDefinitions() {
  const originalJsonInit = Blockly.Block.prototype.jsonInit;
  Blockly.Block.prototype.jsonInit = function _jsonInit(...rest) {
    const initParams = rest[0];
    if (this.isInFlyout) {
      codeBlocklyPythonStatehook.addFunctionFromJsonInit(this.type, initParams);
    }
    return originalJsonInit.apply(this, [...rest]);
  };
}

// eslint-disable-next-line
function monkeyPatch__Blockly_Block_jsonInit__for_BlocklySearch() {
  // create websql data
  // setp 1: reach all the arguments of the jsonInit function so that create the websql data
  // console.log(Array.from(allTheBlockDefinition), 'llll')
  const originalJsonInit = Blockly.Block.prototype.jsonInit;
  Blockly.Block.prototype.jsonInit = function _jsonInit(...rest) {
    if (classRoomStore.isBlockSearchEstablishingMode) {
      log('jsonInit', uiStore.activatedBlocklyToolboxMode, this.type, rest);
      const initParams = rest[0];
      if (this.isInFlyout) {
        Object.entries(initParams).forEach(([_key, _value]) => {
          if (_key.startsWith('message')) {
            classRoomStore.insertBlockSearchingKeys(uiStore.activatedBlocklyToolboxMode, this.type, 'jsonInt', _value);
          }
        });
        codeBlocklyPythonStatehook.addFunctionFromJsonInit(this.type, initParams);
      }
    }
    return originalJsonInit.apply(this, [...rest]);
  };

  const originalAppendField = Blockly.Block.prototype.appendField;
  Blockly.Block.prototype.appendField = function _appendField(...rest) {
    if (classRoomStore.isBlockSearchEstablishingMode) {
      log('appendField', rest);
      if (this.isInFlyout) {
        rest.forEach((content) => {
          if (typeof content === 'string') {
            classRoomStore.insertBlockSearchingKeys(uiStore.activatedBlocklyToolboxMode, this.type, 'appendField', content);
          }
        });
      }
    }
    return originalAppendField.apply(this, [...rest]);
  };

  const originalSetTooltip = Blockly.Block.prototype.setTooltip;
  Blockly.Block.prototype.setTooltip = function _setTooltip(...rest) {
    if (classRoomStore.isBlockSearchEstablishingMode) {
      log('setTooltip', rest, this.isInFlyout, this.type);
      if (this.isInFlyout) {
        rest.forEach((content) => {
          if (typeof content === 'string') {
            classRoomStore.insertBlockSearchingKeys(uiStore.activatedBlocklyToolboxMode, this.type, 'setTooltip', content);
          }
        });
      }
    }
    return originalSetTooltip.apply(this, [...rest]);
  };

  const originalSetHelpUrl = Blockly.Block.prototype.setHelpUrl;
  Blockly.Block.prototype.setHelpUrl = function _setHelpUrl(...rest) {
    if (classRoomStore.isBlockSearchEstablishingMode) {
      log('setHelpUrl', rest);
      if (this.isInFlyout) {
        rest.forEach((content) => {
          if (typeof content === 'string') {
            classRoomStore.insertBlockSearchingKeys(uiStore.activatedBlocklyToolboxMode, this.type, 'setHelpUrl', content);
          }
        });
      }
    }
    return originalSetHelpUrl.apply(this, [...rest]);
  };

  const originalSetCommentText = Blockly.Block.prototype.setCommentText;
  Blockly.Block.prototype.setCommentText = function _setCommentText(...rest) {
    if (classRoomStore.isBlockSearchEstablishingMode) {
      log('setCommentText', rest);
      if (this.isInFlyout) {
        rest.forEach((content) => {
          if (typeof content === 'string') {
            classRoomStore.insertBlockSearchingKeys(uiStore.activatedBlocklyToolboxMode, this.type, 'setCommentText', content);
          }
        });
      }
    }
    return originalSetCommentText.apply(this, [...rest]);
  };
}


export const inject = function inject(el, mapId) {
  proxy__BlockSvg_setWarningText__to__ViewsConsole();

  // monkeyPatch__Blockly_Block_jsonInit(); // replace with blocklySearchStatehook
  blocklyInit();

  definitions();
  const optoins = optionsFor(mapId);
  const workspace = Blockly.inject(el, optoins);
  workspace.registerButtonCallback('createGlobalVariable', classRoomStore.createGlobalVariable);
  workspace.registerButtonCallback('closeSearchResultFlyout', () => {
    if (classRoomStore && classRoomStore.workspace) {
      classRoomStore.workspace.toolbox_.flyout_.hide();
    }
  });

  // this function is used to fix the bug which causes undo button not be ablt to work perporly
  function diySetEnabledWithoutUndoRecord(enabled) {
    const isEnabled = this.isEnabled();
    if (isEnabled !== enabled) { // superClass function
      const eventObj = new Blockly.Events.BlockChange(this, 'disabled', null, this.disabled, !enabled);
      eventObj.recordUndo = false; // !!!!! important
      Blockly.Events.fire(eventObj);
      this.disabled = !enabled;
    }
    if (isEnabled !== enabled) {
      // Blockly.BlockSvg.superClass_.setEnabled.call(this, enabled);
      if (this.rendered && !this.getInheritedDisabled()) {
        this.updateDisabled();
      }
    }
  }
  // workspace.addChangeListener(Blockly.Events.disableOrphans);
  // https://github.com/google/blockly/blob/096d1c46c5066cfa7e59db3b41405b7e854b95d0/core/events.js#L400
  workspace.addChangeListener((event) => {
    if (event.type === Blockly.Events.MOVE ||
        event.type === Blockly.Events.CREATE) {
      if (!event.workspaceId) {
        return;
      }
      const _workspace = Blockly.Workspace.getById(event.workspaceId);
      let block = _workspace.getBlockById(event.blockId);
      if (block) {
        const parent = block.getParent();
        if (parent && parent.isEnabled()) {
          const children = block.getDescendants(false);
          for (let i = 0, child; (child = children[i]); i += 1) {
            // child.setEnabled(true);
            diySetEnabledWithoutUndoRecord.call(child, true);
          }
        } else if ((block.outputConnection || block.previousConnection) &&
                  !_workspace.isDragging()) {
          do {
            // block.setEnabled(false);
            diySetEnabledWithoutUndoRecord.call(block, false);
            block = block.getNextBlock();
          } while (block);
        }
      }
    }
  });
  // establishBlockSearchingDataWithWorkspace(workspace);
  return workspace;
};

export default Blockly;
