/* eslint-disable max-len */
import debug from 'debug';
import classRoomStore from 'store/classRoomStore';
import userStore from 'store/userStore';
import { addRobot } from 'api/classRoom';
import { sendClassRoom, changeAngle } from '../commands';

const log = debug('ui:mqtt');

const CHANGE_ANGLE_TIMEOUT = 5000;
const MAX_ANGLE_ERROR = 10;

const ANGLES = [-30, -60, -90, -120, -150, 30, 60, 90, 120, 150];
let next = 0;
function nextAngle() {
  const angle = ANGLES[next];
  next += 1;
  if (next >= ANGLES.length) next = 0;
  return angle;
}

function match(mac, ssid, classRoomCode) {
  setTimeout(() => {
    const { positions: oldPositions, angle } = classRoomStore.registeringRobots.get(mac);
    const positions = classRoomStore.detectedRobots;
    const identifier = Array.from(oldPositions.keys()).find((id) => {
      if (!positions.has(id)) return false;
      const error = (positions.get(id).a - oldPositions.get(id).a) - angle;
      return Math.abs(error) < MAX_ANGLE_ERROR;
    });
    if (identifier) {
      log('Register matches: %s', mac); // TODO: figure out why it was not running. FIXED: because of the mongoDB version
      addRobot(classRoomCode, {
        _id: mac, identifier, classRoom: classRoomCode, name: 'unused',
      })
        .then((resp) => {
          log('Robot added', resp);
          classRoomStore.addRobot(resp.data);
        });
      sendClassRoom(mac, identifier, ssid, classRoomCode); // when receiving feedback, delete from registering list
    } else {
      log('Register no match');
      classRoomStore.registeringRobots.delete(mac);
    }
  }, CHANGE_ANGLE_TIMEOUT);
}

function registerRobot(mac, ssid, classRoomCode) {
  // is registering this robot, ignore request
  if (classRoomStore.registeringRobots.has(mac)) return;

  log('Start to register robot: %s', mac);
  log('Robot position snapshot: %o', classRoomStore.detectedRobots);

  const angle = nextAngle();
  const currentPositions = new Map(classRoomStore.detectedRobots); // clone
  classRoomStore.registeringRobots.set(mac, { positions: currentPositions, angle });

  changeAngle(mac, ssid, classRoomCode, angle);
  match(mac, ssid, classRoomCode);
}

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
export const executor = (packet) => {
  if (userStore.isStudent) return;

  const { payload: { mac_broadcast: mac } } = packet;
  const { classRoomCode, classRoomSSID } = classRoomStore;
  const robot = classRoomStore.getRobotByMacId(mac);

  if (robot) {
    sendClassRoom(robot._id, robot.identifier, classRoomSSID, classRoomCode);
  } else {
    registerRobot(mac, classRoomSSID, classRoomCode);
  }
};

export default executor;
