import { action, computed, observable } from 'mobx';

// const log = require('debug')('ui:blocklyStore.js');

export class BlocklyStore {
  @observable codeContextArrayVariables = {};
  @observable codeContextListVariables = {};
  @observable isMouseOutOfToolboxBOX = observable.box(true);

  @action
  init() {
    this.setMouseOutOfToolbox(true);
    this.clearAllCodeContextVariable();
  }

  @action
  setMouseOutOfToolbox(value) {
    this.isMouseOutOfToolboxBOX.set(!!value);
  }

  @computed
  get isMouseOutOfToolbox() {
    return this.isMouseOutOfToolboxBOX.get();
  }

  @action
  clearAllCodeContextVariable() {
    this.clearAllListVariables();
    this.clearAllArrayVariables();
  }

  @action
  clearAllArrayVariables() {
    const arrayVariable = this.codeContextArrayVariables;
    Object.keys(arrayVariable)
      .forEach((key) => {
        delete arrayVariable[key];
      });
  }

  @action
  getArrayVariable(arrayName, key) {
    const arrayVariable = this.codeContextArrayVariables[arrayName];
    if (!arrayVariable) return arrayVariable;
    if (key) return arrayVariable[key];
    return arrayVariable;
  }

  @action
  setArrayVariable(arrayName, key, value) {
    this.codeContextArrayVariables[arrayName] =
      this.codeContextArrayVariables[arrayName] || {};
    const arrayVariable = this.codeContextArrayVariables[arrayName];
    if (key) arrayVariable[key] = value;
  }

  @action
  clearAllListVariables() {
    const listVariable = this.codeContextListVariables;
    Object.keys(listVariable)
      .forEach((key) => {
        delete listVariable[key];
      });
  }

  @action
  getListVariable(listName, index) {
    const listVariable = this.codeContextListVariables[listName];
    if (!listVariable) return listVariable;
    if (typeof index === 'number') return listVariable[index];
    return listVariable;
  }

  @action
  setListVariable(listName, index, value) {
    this.codeContextListVariables[listName] =
      this.codeContextListVariables[listName] || [];
    const listVariable = this.codeContextListVariables[listName];
    if (typeof index === 'number') listVariable[index] = value;
  }

  @action
  pushListVariable(listName, value) {
    this.codeContextListVariables[listName] =
      this.codeContextListVariables[listName] || [];
    const listVariable = this.codeContextListVariables[listName];
    listVariable.push(value);
  }

  @action
  popListVariable(listName) {
    this.codeContextListVariables[listName] =
      this.codeContextListVariables[listName] || [];
    const listVariable = this.codeContextListVariables[listName];
    return listVariable.pop();
  }
}

export default new BlocklyStore();
