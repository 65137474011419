import classRoomStore from 'store/classRoomStore';
import _ from 'lodash';
import { debounce } from 'debounce-decorator';

const mapIdUpdate = debounce((mapId) => {
  // classRoomStore.classroomModelStatehook.mapId is not high efficient
  if (`${classRoomStore.classroomModelStatehook.mapId}` !== `${mapId}`) {
    classRoomStore.classroomModelStatehook.mapId = `${mapId}`;
  }
});

let multiKaisEyesCache = {
  // robot_id: {
  //   X:
  //   Y:
  //   A:
  // }
};
const multiKaisEyesCacheLifeTime = 1 * 1e3;
let multiEyesMapId = 0;
let multiKaisEyesCacheLastTime = 0;
const collectMultiEyesData = (payload) => {
  const now = Date.now();
  if ((now - multiKaisEyesCacheLastTime) > multiKaisEyesCacheLifeTime) {
    multiKaisEyesCacheLastTime = now;
    multiKaisEyesCache = {};
  }
  const {
    X, Y, R, A, ID,
  } = payload;
  multiEyesMapId = ID;
  R.forEach((rId, idx) => {
    multiKaisEyesCache[rId] = {
      R: rId,
      X: X[idx],
      Y: Y[idx],
      A: A[idx],
    };
  });
};

const generatePositionData = () => {
  return Object.values(multiKaisEyesCache).reduce((acc, curr) => {
    acc.R.push(curr.R);
    acc.X.push(curr.X);
    acc.Y.push(curr.Y);
    acc.A.push(curr.A);
    acc.L += 1;
    return acc;
  }, {
    R: [],
    X: [],
    Y: [],
    A: [],
    ID: multiEyesMapId,
    L: 0,
  });
};

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
const executeFps = 1e3 / 3; // 3fps
let lastTimeExecuted = 0;
export const executor = (packet) => {
  const now = Date.now();

  const { payload } = packet;
  collectMultiEyesData(payload);

  if ((now - lastTimeExecuted) < executeFps) return;
  lastTimeExecuted = now;

  const positionPayload = generatePositionData();
  const {
    X, Y, R: Robots, A, L: robotLen, ID: mapId,
  } = positionPayload;

  if (_.has(positionPayload, 'ID')) {
    mapIdUpdate(mapId);
  }

  classRoomStore.detectedRobots.clear();
  for (let i = 0; i < robotLen; i += 1) {
    const identifier = parseInt(Robots[i], 10);
    classRoomStore.updateRobotPosition({
      identifier,
      x: X[i],
      y: Y[i],
      a: A[i],
    });

    classRoomStore.detectedRobots.set(identifier, {
      identifier,
      x: X[i],
      y: Y[i],
      a: A[i],
    });
  }
};

export default executor;
