/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-template */
/* eslint-disable max-len */

function _isFunction(func) {
  return 'function' === typeof func;
}

function _parseValueForAllowMonkeyInMonkey(value) {
  return ('' + value).replace(/\s/g, '').toLowerCase();
}

const KEY__MONKEY_MARK = 'I am a monkey ⊂((≧⊥≦))⊃～';
const VALUE__RETURN_OLD_MONKEY = _parseValueForAllowMonkeyInMonkey('return old monkey');

const KEY__ALLOW_MONKEY_IN_MONKEY = 'allowMonkeyInMonkey';

function monkeyify(originalFunction, patchOpt) {
  if (!_isFunction(originalFunction)) throw new Error('You cannot monkeyify a non-function variable! ⊂((≧⊥≦))⊃～');
  if ('function' === typeof patchOpt) patchOpt = { monkey: patchOpt };
  const hasCaller = Object.prototype.hasOwnProperty.call(patchOpt, 'caller');
  const _patchFunc = patchOpt.monkey;
  const _allowMonkeyInMonkey = patchOpt[KEY__ALLOW_MONKEY_IN_MONKEY];
  const generation = parseInt(originalFunction[KEY__MONKEY_MARK], 10) || 0;
  const isMonkey = generation > 0;
  if (isMonkey) {
    if (_parseValueForAllowMonkeyInMonkey(_allowMonkeyInMonkey) === VALUE__RETURN_OLD_MONKEY) return originalFunction;
    if (_allowMonkeyInMonkey !== true) throw new Error('You cannot put a monkey into another monkey if without allowMonkeyInMonkey configuration! ⊂((≧⊥≦))⊃～');
  }

  function monkeyifyFunction() {
    const monkeyThis = this;
    const _caller = hasCaller ? patchOpt.caller : monkeyThis;
    return _patchFunc.call(
      _caller,
      _caller, Array.prototype.slice.call(arguments), originalFunction,
    );
  }
  monkeyifyFunction[KEY__MONKEY_MARK] = generation + 1;
  return monkeyifyFunction;
}
monkeyify.KEY__MONKEY_MARK = KEY__MONKEY_MARK;
monkeyify.VALUE__RETURN_OLD_MONKEY = VALUE__RETURN_OLD_MONKEY;

export default monkeyify;
