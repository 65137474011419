import { types } from 'mobx-state-tree';

const PathfindingPlaceModel = types
  .model('PathfindingPlaceModel', {
    mx: types.optional(types.number, 0),
    my: types.optional(types.number, 0),
    name: types.optional(types.string, 'New place'),
    $isDeleted: types.union(types.undefined, types.boolean),
  })
  .actions((self) => {
    const markDeleted = (bool) => {
      const _self = self;
      _self.$isDeleted = !!bool;
    };

    const removeDeletedKey = () => {
      const _self = self;
      _self.$isDeleted = undefined;
    };

    return {
      markDeleted,
      removeDeletedKey,
    };
  })
  .views(self => ({
    get isDeleted() {
      return !!self.$isDeleted;
    },
  }));

export default PathfindingPlaceModel;
