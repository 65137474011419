/* eslint-disable no-param-reassign */

import { types, flow, detach } from 'mobx-state-tree';
import {
  remove as removeProject,
  create as addProject,
  getAllCategories,
  getProjectsWithNoCategory,
  createCategory,
  updateProject,
} from 'api/project';
import ProjectCategory from 'models/ProjectCategory';
import Project from 'models/Project';

const ProjectStore = types
  .model('ProjectStore', {
    projectsCategories: types.optional(types.array(types.late(() => ProjectCategory)), []),
    projectsState: types.optional(types.enumeration('State', ['pending', 'done', 'error']), 'pending'),
  })
  .views(self => ({
    get projects() {
      return self.projectsCategories.reduce((acc, next) => {
        acc.push(...next.projects);
        return acc;
      }, []);
    },
    get projectsAndRules() {
      const allRules = self.projects.reduce((acc, next) => {
        acc.push(...next.rules);
        return acc;
      }, []);
      return allRules.concat(self.projects);
    },
    get featureCategories() {
      return self.projectsCategories
        .filter(c => c.displayOn === 1)
        .sort((a, b) => a.order - b.order);
    },
    get communityCategories() {
      return self.projectsCategories
        .filter(c => c.displayOn !== 1)
        .sort((a, b) => a.order - b.order);
    },
    get totalCommunityProjects() {
      return self.projectsCategories
        .filter(p => p.displayOn === 2)
        .reduce((acc, next) => {
          acc += next.projects.length;
          return acc;
        }, 0);
    },
    get totalFeatureProjects() {
      return self.projectsCategories
        .filter(p => p.displayOn === 1)
        .reduce((acc, next) => {
          acc += next.projects.length;
          return acc;
        }, 0);
    },
  }))
  .actions((self) => {
    const fetchProjectsWithNoCategory = flow(function* fetchProjectsWithNoCategory() {
      const result = yield getProjectsWithNoCategory();
      if (result.data.length <= 0) {
        return null;
      }

      const communityProjects = result.data
        .filter(p => p.community)
        .map(p => ({ ...p, category: 'A' }));
      const featureProjects = result.data
        .filter(p => !p.community)
        .map(p => ({ ...p, category: 'B' }));

      const communityCategory = ProjectCategory.create({
        _id: 'A',
        name: 'Others projects',
        displayOn: 1,
      });
      const featureCategory = ProjectCategory.create({
        _id: 'B',
        name: 'Others projects',
        displayOn: 2,
      });

      self.projectsCategories.push(communityCategory);
      self.projectsCategories.push(featureCategory);
      if (communityProjects.length > 0)communityCategory.addProject(...communityProjects);
      if (featureProjects.length > 0)featureCategory.addProject(...featureProjects);
      return null;
    });

    const fetchCategories = flow(function* fetchCategories() {
      self.projectsState = 'pending';
      const result = yield getAllCategories();
      const categories = result.data.map(c => ProjectCategory.create(c));
      self.projectsCategories.replace(categories);
      yield fetchProjectsWithNoCategory();
      self.projectsState = 'done';
      return self.projectsCategories;
    });

    const add = flow(function* addProjects(project, api = true) {
      let projectCreated;
      if (api) {
        const { data } = yield addProject(project);
        projectCreated = Project.create(data);
      } else {
        projectCreated = Project.create(...project);
      }
      const category = self.projectsCategories.find(c => c._id === project.get('category'));
      category.projects.push(projectCreated);

      return Promise.resolve(projectCreated);
    });

    const update = flow(function* updateProjects(id, project) {
      const _id = `${id}`;
      const { data } = yield updateProject(_id, project);
      // find original category
      const originalProject = self.projects.find(p => `${p._id}` === `${_id}`);
      detach(originalProject);
      const newCategoryId = project.get('category');
      const newCategory = self.projectsCategories.find(c => `${c._id}` === `${newCategoryId}`);
      newCategory.projects.push(originalProject);
      const dataForUpdate = {
        community: data.community,
        grade: data.grade,
        mapId: data.mapId,
        name: data.name,
        xml: data.xml,
        imageCover: data.imageCover,
        category: newCategory,
      };
      // Object.entries(dataForUpdate).forEach(([k, _v]) => {
      //   originalProject[k] = _v;
      // });
      Object.assign(originalProject, dataForUpdate);
      return originalProject;
    });

    const addCategory = flow(function* addCategory(category) {
      const resp = yield createCategory(category);
      const categoryCreated = ProjectCategory.create(resp.data);

      self.projectsCategories.push(categoryCreated);
      return categoryCreated;
    });

    const remove = flow(function* remove(project, api = true) {
      if (api) {
        yield removeProject(project._id);
      }
      const projectsOnCategory = project.category.projects;
      projectsOnCategory.replace(projectsOnCategory.filter(p => p._id !== project._id));
      return projectsOnCategory;
    });

    const removeCategory = (categoryId) => {
      detach(self.projectsCategories.find(c => c._id === categoryId));
    };

    return {
      fetchCategories,
      add,
      update,
      addCategory,
      removeCategory,
      remove,
    };
  });

export default ProjectStore;
