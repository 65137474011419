export function getBBox_ (element) {
  var height = element.offsetHeight
  var width = element.offsetWidth
  var x = 0
  var y = 0
  do {
    x += element.offsetLeft
    y += element.offsetTop
    element = element.offsetParent
  } while (element)
  return {
    height: height,
    width: width,
    x: x,
    y: y
  }
}

export function isBlockDisabled (block) {
  return (!block) || block.getInheritedDisabled() || `${block.svgGroup_.getAttribute('class')}`.indexOf('blocklyDisabled') > -1;
}
