/* eslint-disable prefer-rest-params */

import monkeyify from 'monkeyify';

const log = require('debug')('lib:MonkeyWebsocket:');

// const OriginalWebSocket = window.WebSocket;
// function MonkeyPatchWebSocket(...rest) {
//   const wsClient = new OriginalWebSocket(...rest);
//   wsClient.addEventListener('message', (event) => {
//     log('onmessage: ', wsClient.url, event);
//   });

//   const originalSend = wsClient.send;
//   wsClient.send = function send() {
//     log('send: ', arguments);
//     return originalSend.apply(this, arguments);
//   };

//   return wsClient;
// }
// window.WebSocket = MonkeyPatchWebSocket;

window.WebSocket = monkeyify(window.WebSocket, {
  allowMonkeyInMonkey: 'returnOldMonkey',
  monkey: (caller, args, Original) => {
    const wsClient = new Original(...args);
    wsClient.addEventListener('message', (event) => {
      log('onmessage: ', wsClient.url, event);
    });

    const originalSend = wsClient.send;
    wsClient.send = function send() {
      log('send: ', arguments);
      return originalSend.apply(this, arguments);
    };
    return wsClient;
  },
});

export default window.WebSocket;
