import api from 'api';

export function recoveryPassword(email) {
  return api
    .post('/student/recoveryPassword', { email })
    .then((ret) => {
      if (ret.status !== 200) {
        throw new Error(ret.statusText);
      }
      return ret.data;
    });
}

export function resetPassword(token, newPassword) {
  return api
    .post(`/student/resetPassword/${token}`, { newPassword })
    .then((ret) => {
      if (ret.status !== 200) {
        throw new Error(ret.statusText);
      }
      return ret.data;
    });
}

export function create(data) {
  return api.post('/student', data);
}
