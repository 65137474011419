import React from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loader from 'components/Loader';
// eslint-disable-next-line import/no-extraneous-dependencies
import Loadable from 'react-loadable';
import Raven from 'raven-js';
// import LogRocket from 'logrocket';
import i18n from '_i18n_';
import { useStatehook } from 'store/statehook/hookFactory';
import { globalStatehook } from 'store/statehook/globalStatehook';
import monkeyify from 'monkeyify';
import { init as initErrorReport, reportError } from 'utils/errorReport';
import api from 'api';
import 'assets/fonts/lato/lato.css';
import 'assets/style.css';
import 'assets/style.print.css';
import 'semantic-ui-less/definitions/globals/site';
import 'semantic-ui-less/definitions/modules/dimmer';
import 'semantic-ui-less/definitions/modules/transition';
import 'semantic-ui-less/definitions/behaviors/api';
import 'semantic-ui-less/definitions/behaviors/form';
import 'semantic-ui-less/definitions/behaviors/state';
import 'semantic-ui-less/definitions/modules/dropdown';
import 'semantic-ui-less/definitions/modules/modal';
import 'semantic-ui-less/definitions/modules/popup';
import 'semantic-ui-less/definitions/modules/search';
import 'semantic-ui-less/definitions/modules/tab';
import 'semantic-ui-less/definitions/modules/checkbox';
import 'semantic-ui-less/definitions/modules/sidebar';
import 'semantic-ui-less/definitions/modules/accordion';
import 'semantic-ui-less/definitions/modules/progress';
import '../semantic-ui-theme/semantic.less';
// import 'semantic-ui-less/semantic.less';
import store from './store';

initErrorReport();
console.reportError = reportError;

const log = require('debug')('ui:App.jsx');

const kaisshipPixel = require('assets/images/kaisship-pixel.gif');

// const RAVEN_READY = process.env.SENTRY_DSN_TESTING;
/** used for fix a Semantic UI bug */
/** https://github.com/Semantic-Org/Semantic-UI/issues/6449#issuecomment-448629796 */
EventTarget.prototype.addEventListener = monkeyify(EventTarget.prototype.addEventListener, {
  allowMonkeyInMonkey: monkeyify.VALUE__RETURN_OLD_MONKEY,
  monkey: (caller, args, originalFunction) => {
    const [type] = args;
    if (caller !== document.querySelector('body') || type !== 'touchmove') {
      originalFunction.apply(caller, args);
    }
  },
});

// if (isProductionMode) {
//   Raven.setDataCallback(function (data) {
//     return data;
//   });
// }

// used to reach category for dropdown box in both EditProjectModal.jsx and NewProjectModal.jsx
const BASE_URL = process.env.API_URL || '/api/';
$.fn.api.settings.api = {
  'get projects category': `${BASE_URL}/projectCategory?q={query}`,
};

// region Routes

const Loading = () => <Loader><h3>Loading...</h3></Loader>;

const Home = Loadable({
  loader: () => import('./routes/Home'),
  loading: Loading,
});
const TeacherLogin = Loadable({
  loader: () => import('./routes/TeacherLogin'),
  loading: Loading,
});
const StudentLogin = Loadable({
  loader: () => import('./routes/StudentLogin'),
  loading: Loading,
});
const RecoveryPassword = Loadable({
  loader: () => import('./routes/RecoveryPassword'),
  loading: Loading,
});
const UserVerify = Loadable({
  loader: () => import('./routes/UserVerify'),
  loading: Loading,
});
const TeacherReset = Loadable({
  loader: () => import('./routes/TeacherReset'),
  loading: Loading,
});
const TeacherRegister = Loadable({
  loader: () => import('./routes/TeacherRegister'),
  loading: Loading,
});
const StudentRegister = Loadable({
  loader: () => import('./routes/StudentRegister'),
  loading: Loading,
});
const QR = Loadable({
  loader: () => import('./routes/QR'),
  loading: Loading,
});
const SwitchClassroom = Loadable({
  loader: () => import('./routes/SwitchClassroom'),
  loading: Loading,
});
const ClassRoom = Loadable({
  loader: () => import('./routes/ClassRoom'),
  loading: Loading,
});
const ClassRoom3dModelAllocationCards = Loadable({
  loader: () => import('./routes/ClassRoom3dModelAllocationCards'),
  loading: Loading,
});

const ClassRoomStudentsLoginCards = Loadable({
  loader: () => import('./routes/ClassRoomStudentsLoginCards'),
  loading: Loading,
});

const WebGLSeparateWindow = Loadable({
  loader: () => import('./routes/WebGL/SeparateWindow'),
  loading: Loading,
});
const SwitchUser = Loadable({
  loader: () => import('./routes/SwitchUser'),
  loading: Loading,
});
const ClassroomAssistanceLogin = Loadable({
  loader: () => import('./routes/ClassroomAssistance/ClassroomAssistanceLogin'),
  loading: Loading,
});
const ClassroomAssistanceWaitingroom = Loadable({
  loader: () => import('./routes/ClassroomAssistance/ClassroomAssistanceWaitingroom'),
  loading: Loading,
});
const ReferralPage = Loadable({
  loader: () => import('./routes/Referral'),
  loading: Loading,
});

const SysOperReferralPointsReview = Loadable({
  loader: () => import('./routes/SysOper/ReferralPointsReview'),
  loading: Loading,
});

// eslint-disable-next-line react/display-name
const ErrorTestPage = () => {
  const a = null;
  a.push('1');
  return <div>Hello {a} ErrorTestPage</div>;
};
// endregion

function checkIfAssistanceDomain() {
  if (process.env.NODE_ENV === 'production') {
    return /^assist.*$/.test(window.location.host);
  }
  return false;
}

const component = (props) => {
  useStatehook({ props }, (useStatehookArgs, forceUpdate, thisStatehook) => {
    store.webStorageStore.setShareStorageItem('APP_VERSION', `${process.env.APP_VERSION}`);

    globalStatehook.setHookStatePath('stores', store);
    store.userStore.loadProfileFromLocalStorage();

    // detect the assistance state
    thisStatehook.statehookAddUnwatcher(api.statehookBox.statehookWatch((event) => {
      if (event && event.pathname === '/api/account/ping' && event.ok && event.response) {
        const assistanceWaitingRoomPathname = '/classroom/assistance_waiting_room';
        const isBeingAssistanted = _.get(event, 'response.data.isBeingAssisted');
        const isAssistanceNamespace = _.get(event, 'response.data.accessToken.namespace') === 'classroom/assistance';
        if (window.location.pathname !== assistanceWaitingRoomPathname) {
          if (!isAssistanceNamespace && isBeingAssistanted) {
            window.location = assistanceWaitingRoomPathname;
          }
        }
        if (window.location.pathname === assistanceWaitingRoomPathname) {
          if (!isAssistanceNamespace && !isBeingAssistanted) {
            window.location = '/';
          }
        }
      }
    }));

    thisStatehook.statehookAddUnwatcher(i18n.watchCurrentLanguage(() => {
      // this.forceUpdate();
      store.webStorageStore.setShareStorageItem('language', i18n.getCurrentLanguage());
      window.location.reload();
    }));

    return () => {
      thisStatehook.statehookUnwatchAll();
    };
  }, React);

  const { location } = props;
  // check if assistance
  if (checkIfAssistanceDomain()) {
    if (!/^\/classroom_assistance\/?/.test(window.location.pathname) &&
        !/^\/switchclassroom\/?/.test(window.location.pathname) &&
        !/^\/classroom\/?/.test(window.location.pathname)) {
      setTimeout(() => {
        window.location.pathname = '/classroom_assistance/login';
      }, 1 * 1e3);
      return <div>hello...</div>;
    }
  }

  const PrivateRoute = ({ component: ProtectedComponent, ...rest }) => (
    <Route
      {...rest}
      render={(_props) => {
        if (store.userStore.currentUser && location.pathname === '/') {
          return <Redirect to={{ pathname: '/switchclassroom' }} />;
        }

        return store.userStore.currentUser
          ? <ProtectedComponent {..._props} exact />
          : <Redirect to={{ pathname: '/' }} />;
      }}
    />
  );
  PrivateRoute.displayName = 'PrivateRoute';

  const IndexRedirect = () => {
    if (store.userStore.currentUser) {
      // </div><Redirect to={{ pathname: '/switchclassroom' }} />;
      setTimeout(async () => {
        let classroomCode = null;
        if (store.userStore.currentUser) {
          const userId = store.userStore.currentUser._id;
          const token = store.webStorageStore.getItemWithNS('jwt', `${userId}##`);
          if (token) {
            await store.accountStateStoreHook.loadUserStore();
            classroomCode = store.accountStateStoreHook.getCurrentClassroom();
          }
        }
        // debugger;
        if (classroomCode) {
          window.location = `/classroom/${classroomCode}`;
        } else {
          window.location = '/switchclassroom';
        }
      }, 1 * 1e3);
      return (
        <div
          style={{ width: '100px', margin: '100px auto', textAlign: 'center' }}
        >
          <p><img style={{ width: '50px' }} src={kaisshipPixel} alt="kai's ship pixel" /></p>
          <p style={{ fontSize: 'large' }}>Redirecting...</p>
        </div>
      );
      // return <Loading />;
    }
    return <Home />;
  };
  IndexRedirect.displayName = 'IndexRedirect';
  return (
    <Switch location={location}>
      <Route exact path="/" component={IndexRedirect} />
      <Route exact path="/classroom_assistance/login" component={ClassroomAssistanceLogin} />
      <Route exact path="/switchuser/:id" component={SwitchUser} />
      <Route exact path="/teacher/login" component={TeacherLogin} />
      <Route exact path="/student/login" component={StudentLogin} />
      <Route exact path="/:perfil/passwordrecovery" component={RecoveryPassword} />
      <Route exact path="/verify/:token" component={UserVerify} />
      <Route exact path="/teacher/reset/:token" component={TeacherReset} />
      <Route exact path="/teacher/register" component={TeacherRegister} />
      <Route exact path="/student/register" component={StudentRegister} />
      <Route exact path="/qr" component={QR} />
      <PrivateRoute exact path="/classroom/assistance_waiting_room" component={ClassroomAssistanceWaitingroom} />
      <PrivateRoute exact path="/switchclassroom" component={SwitchClassroom} />
      <PrivateRoute exact path="/classroom" component={IndexRedirect} />
      <PrivateRoute exact path="/classroom/:id" component={ClassRoom} />
      <PrivateRoute exact path="/classroom/:id/3d-model-allocation-cards" component={ClassRoom3dModelAllocationCards} />
      <PrivateRoute exact path="/classroom/:id/students/login-cards" component={ClassRoomStudentsLoginCards} />
      {/* <PrivateRoute exact path="/map3d" component={WebGLSeparateWindow} /> */}
      <Route exact path="/r/:referralCode" component={ReferralPage} />
      <PrivateRoute exact path="/admin/referral_points_review" component={SysOperReferralPointsReview} />
      <Route exact path="/test/error_test_page" component={ErrorTestPage} />
    </Switch>
  );
};
component.displayName = 'App';
component.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
};
component.defaultProps = {
  location: {
    pathname: '',
  },
};

export default withRouter(component);
