
const jsEnum = (...metadata) => {
  const enumData = [].concat(metadata).reduce(
    (acc, curr) => {
      acc[curr] = Symbol('');
      return acc;
    },
    {},
  );
  function getString(item) {
    const itemKV = Object.entries(enumData).find(row => item === row[1]);
    if (itemKV) return itemKV[0];
    throw new Error('Can not find the string value of the item you gave');
  }
  Object.defineProperty(enumData, 'getString', {
    value: getString,
    configurable: false,
    enumerable: false,
  });

  return Object.freeze(new Proxy(
    enumData,
    {
      get: (target, name) => {
        if (name in target) return target[name];
        throw new Error(`Item \`${name}\` does not in this Enum: \`${JSON.stringify(Object.keys(target))}\`.`);
      },
    },
  ));
};

jsEnum.equals = (item1, item2) => typeof item1 === 'symbol' && typeof item2 === 'symbol' && Object.is(item1, item2);

export default jsEnum;
