import { observable, action, runInAction } from 'mobx';
import _ from 'lodash';
import { resetPassword } from 'api/teacher';
import userStore from 'store/userStore';
import { getNS } from '_i18n_';

const _t = getNS('store/teacherResetStore.js');

require('promise.prototype.finally').shim();


const dehydrateError = (error) => {
  const genericMessage = _.get(
    error,
    'response.data.message',
    _.get(
      error, 'response.errmsg',
      _.get(
        error, 'response.statusText',
        _t('Please, check the errors bellow.'),
      ),
    ),
  );
  return {
    genericMessage,
    errors: error.errors,
  };
};

class TeacherResetStore {
  @observable isResettingPassword = false;
  @observable resetPasswordError = undefined;

  @action resetPassword = action('resetPassword', (history, token, newPassword) => {
    this.isResettingPassword = true;
    return resetPassword(token, newPassword)
      .then((resp) => {
        userStore.setCurrentUser(resp);
        runInAction('updating reset password then state', () => {
          this.resetPasswordError = undefined;
          history.push('/switchclassroom');
        });
      })
      .catch((err) => {
        runInAction('updating reset password catch state', () => {
          this.resetPasswordError = dehydrateError(err);
        });
      })
      .finally(() => {
        runInAction('updating reset password finally state', () => {
          this.isResettingPassword = false;
        });
      });
  });
}

const teacherResetStore = new TeacherResetStore();

export default teacherResetStore;
export { TeacherResetStore };
