import _ from 'lodash';
import {
  postAllConfig,
} from 'api/appConfig';
import { statehookify } from './hookFactory';


export default statehookify('appConfigStatehook', {
  startPolling() {
    let next = null;
    next = () => {
      setTimeout(() => {
        this.refreshAllAppConfig().finally(next);
      }, 10 * 1e3);
    };
    next();
  },
  async refreshAllAppConfig() {
    const allConfigurations = await postAllConfig({
      APP_VERSION: process.env.APP_VERSION,
      lastUpdate: this.getHookStatePath('$lastUpdate.value'),
      KAISCLAN_SERVER_TYPE: process.env.KAISCLAN_SERVER_TYPE,
    });
    let configData = _.get(allConfigurations, 'data.config_data');
    if (configData) {
      configData = configData.reduce((acc, row) => {
        const rowKey = `[${row.key}]`;
        acc.push({
          [rowKey]: row,
        });
        return acc;
      }, []);
      this.resetHookState();
      return this.setHookStateManyPaths(configData);
    }
    return true;
  },
}, {});
