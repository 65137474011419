/* eslint-disable no-param-reassign */

import { types, flow, getParent } from 'mobx-state-tree';
import {
  updateDescription as updateProjectDescription,
  updateHelp as updateProjectHelp,
  updateTeacherHelp as updateProjectTeacherHelp,
  addRule as addRuleToProject,
  removeRule as removeRuleFromProject,
  moveToProjects as moveProjectToProjects,
  moveToCommunity as moveProjectToCommunity,
  moveToCategory as moveProjectToCategory,
  getHelpField,
} from 'api/project';
import ProjectCategory from 'models/ProjectCategory';
import userStore from 'store/userStore';
import { getNS } from '_i18n_';

const _t = getNS('store/models/Project.js');

const Project = types
  .model('Project', {
    _id: types.identifier(),
    name: types.string,
    description: types.optional(types.string, ''),
    helpHtml: types.optional(types.string, ''),
    teacherHelpHtml: types.optional(types.string, ''),
    teacherHelpId: types.optional(types.string, ''),
    studentHelpId: types.optional(types.string, ''),
    mapId: types.optional(types.number, 0),
    grade: types.optional(types.number, 1),
    imageCover: types.optional(types.string, ''),
    xml: types.string,
    category: types.maybe(types.reference(types.late(() => ProjectCategory))),
    community: types.optional(types.boolean, true),
    parent: types.maybe(types.string),
    rules: types.maybe(types.array(types.union(
      types.late(() => Project),
      types.string,
    ))),
    backgroundColorWhenRule: types.maybe(types.string),
    createdAt: types.string,
    created_by: types.frozen,
    updatedAt: types.string,
  })
  .views(self => ({
    get gradeText() {
      switch (self.grade) {
        case 1:
          return _t('Junior');
        case 2:
          return _t('Intermediate');
        case 3:
          return _t('Advanced');
        case 4:
          return _t('Expert');
        default:
          return null;
      }
    },
    get iCanEdit() {
      const createdById = self.created_by ? self.created_by._id || self.created_by : '';
      return (
        self.created_by &&
        userStore.currentUser &&
        createdById === userStore.currentUser._id
      ) || userStore.isAdmin;
    },
    get parentProjectModel() {
      const projects = getParent(self, 3);
      const rootProject = projects
        .find(p => p.rules.find(r => r._id === self._id));
      return rootProject;
    },
  }))
  .actions((self) => {
    const addRule = flow(function* addRule(rule) {
      const result = yield addRuleToProject(self._id, rule);
      const ruleCreated = Project.create(result.data);
      self.rules.push(ruleCreated);
      return ruleCreated;
    });

    const removeRule = flow(function* removeRule(ruleId) {
      const result = yield removeRuleFromProject(self._id, ruleId);
      self.rules = self.rules.filter(p => p._id !== ruleId);
      return result;
    });

    const updateDescription = flow(function* updateProjectData(description) {
      const result = yield updateProjectDescription(self._id, description);
      self.description = description;
      return result;
    });

    const updateHelp = flow(function* updateProjectData(helpHtml) {
      const result = yield updateProjectHelp(self._id, helpHtml);
      self.helpHtml = helpHtml;
      return result;
    });

    const updateTeacherHelp = flow(function* updateProjectData(teacherHelpHtml) {
      const result = yield updateProjectTeacherHelp(self._id, teacherHelpHtml);
      self.teacherHelpHtml = teacherHelpHtml;
      return result;
    });

    const updateCode = function updateCode(xmlCode) {
      self.xml = xmlCode;
    };

    const moveToProjects = flow(function* moveToProjects(categoryId) {
      const result = yield moveProjectToProjects(self._id, categoryId);
      self.community = false;
      self.moveToCategory(categoryId);
      return result;
    });

    const moveToCommunity = flow(function* moveToCommunity(categoryId) {
      const result = yield moveProjectToCommunity(self._id, categoryId);
      self.community = true;
      self.moveToCategory(categoryId);
      return result;
    });

    const moveToCategory = flow(function* moveToCategory(categoryId) {
      const oldCategory = self.category;
      yield moveProjectToCategory(self._id, categoryId);
      const categories = getParent(self, 3);
      const newCategory = categories.find(c => c._id === categoryId);
      oldCategory.removeProject(self._id);
      newCategory.addProject(self);
      self.category = categoryId;
    });

    const fetchHelpField = flow(function* fetchHelpField() {
      const result = yield getHelpField(self._id);
      self.teacherHelpHtml = result.data.teacherHelpHtml;
      self.helpHtml = result.data.helpHtml;
      return result;
    });

    return {
      addRule,
      removeRule,
      updateCode,
      moveToProjects,
      moveToCommunity,
      updateDescription,
      updateHelp,
      updateTeacherHelp,
      moveToCategory,
      fetchHelpField,
    };
  });

export default Project;
