import _ from 'lodash';
import { byId, update, setClassroomObjectInfo } from 'api/classRoom';
import notificationStatehook from 'store/notificationStatehook';
import { statehookify } from 'store/statehook/hookFactory';
import { debounce } from 'debounce-decorator';

const updateDebounce = debounce(update);

export default statehookify({ hookName: 'classroomModelStateHook', bindThisToBase: true }, {
  get _id() {
    return this.getHookStatePath('_id');
  },
  get mapId() {
    return this.getHookStatePath('mapId');
  },
  set mapId(newValue) {
    this.setMapId(newValue);
  },
  setMapId(newValue) {
    newValue = `${newValue}`;
    this.setHookStatePath('mapId', newValue);
    if (this._id && this._id.length > 0) {
      updateDebounce(this._id, {
        mapId: newValue,
      });
    }
  },
  watchMapId(cb) {
    return this.watchHookStatePath('mapId', cb);
  },
  get availableProjects() {
    return this.getHookStatePath('availableProjects');
  },
  set availableProjects(newValue) {
    this.setHookStatePath('availableProjects', newValue);
  },
  get chatDisabled() {
    return this.getHookStatePath('chatDisabled');
  },
  get chatDisabledStudents() {
    return this.getHookStatePath('chatDisabledStudents');
  },
  get classroomObjects() {
    return this.getHookStatePath('classroomObjects') || {};
  },
  async refresh(classroomCode) {
    const resp = await byId(classroomCode || this._id);
    this.replaceHookState(resp.data);
    this.statehookEmit('refresh', resp.data);
    return resp;
  },
  watchRefresh(cb) {
    return this.statehookOn('refresh', cb);
  },
  isAvailableProject(projectId) {
    const { availableProjects } = this;
    if (!availableProjects) return false;
    if (availableProjects.length > 0) {
      if (typeof availableProjects[0] === 'string') {
        return availableProjects.indexOf(projectId) > -1;
      }
      return !!availableProjects.find(p => p._id === projectId || p.parent === projectId);
    }
    return false;
  },
  doesTheProjectNeedBeUnavailable(projectId) {
    if (this.availableProjects.length > 0) {
      return !this.isAvailableProject(projectId);
    }
    return false;
  },
  isLockedProject(projectId) {
    if (this.availableProjects.length > 0) {
      return this.isAvailableProject(projectId);
    }
    return false;
  },
  removeStudentsFromChatDisabledList(studentId) {
    const newList = this.chatDisabledStudents.reduce((acc, row) => {
      if (row !== studentId) acc.push(row);
      return acc;
    }, []);
    this.setHookStatePath('chatDisabledStudents', newList);
  },
  addStudentsToChatDisabledList(studentId) {
    const newList = this.chatDisabledStudents.reduce((acc, row) => {
      if (row !== studentId) acc.push(row);
      return acc;
    }, []);
    newList.push(studentId);
    this.setHookStatePath('chatDisabledStudents', newList);
  },
  async updateChatDisabledStudents() {
    if (this._id && this._id.length > 0) {
      await update(this._id, {
        chatDisabledStudents: this.chatDisabledStudents,
      });
      await this.refresh().then((httpResp) => {
        notificationStatehook.notify(`notify/classroom_chat/${this._id}/disable`, { chatDisableStudents: _.get(httpResp, 'data.chatDisabledStudents') });
      });
    }
  },
  checkStudentChatDisabled(studentId) {
    return (this.chatDisabledStudents || []).indexOf(studentId) > -1;
  },
  async setClassroomObjectInfo(objectId, name) {
    await setClassroomObjectInfo(this._id, objectId, { name });
    await notificationStatehook.notify(`notify/classroom/${this._id}/object_event/update`, {
      [objectId]: { name },
    }, { messageType: 'update' });
    return true;
  },
}, (statehook) => {
  statehook.setHookState({});
  const namespace = 'mqtt_topic';
  let currentId = null;
  const refreshFunc = () => {
    statehook.refresh();
  };
  statehook.watchHookStatePath('_id', () => {
    if (statehook._id === currentId) return;
    currentId = statehook._id;
    statehook.statehookUnwatchWithNS(namespace);
    // statehook.statehookAddUnwatcher(notificationStatehook.mqttSubscribe(`notify/classroom_chat/${statehook._id}/disable`, refreshFunc), namespace);
    // statehook.statehookAddUnwatcher(notificationStatehook.mqttSubscribe(`notify/classroom/${statehook._id}/object_event/update`, refreshFunc), namespace);
    statehook.statehookAddUnwatcher(notificationStatehook.onNotified(`notify/classroom_chat/${currentId}/disable`, refreshFunc), namespace);
    statehook.statehookAddUnwatcher(notificationStatehook.onNotified(`notify/classroom/${currentId}/object_event/update`, refreshFunc), namespace);
  });
  return () => {
    console.log('object_event unwatcherall');
    statehook.statehookUnwatchWithNS(namespace);
    statehook.statehookUnwatchAll();
  };
});
