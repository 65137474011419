import * as R from 'ramda';

const log = require('debug')('ui:axiosErrorParse.js');

export function execute() {
  log('execute');
  const getMessage = R.path(['response', 'data', 'message']);
  const checkStructMessage = R.pipe(getMessage, R.isNil, R.not);

  const getErrorKey = R.path(['response', 'data', 'error']);
  const checkStructErrorKey = R.pipe(getErrorKey, R.isNil, R.not);

  const getData = R.path(['response', 'data']);
  const checkStructData = R.pipe(getData, R.isNil, R.not);
  return R.cond([
    [checkStructMessage, getMessage],
    [checkStructErrorKey, getErrorKey],
    [checkStructData, getData],
    [R.T, () => {}],
  ]);
}

export function match() {
  log('match');
  const checkType = R.pipe(R.type, R.equals('Error'));
  const getMessage = R.path(['response', 'data', 'message']);
  const checkStructMessage = R.pipe(getMessage, R.isNil, R.not);

  const getErrorKey = R.path(['response', 'data', 'error']);
  const checkStructErrorKey = R.pipe(getErrorKey, R.isNil, R.not);

  const getData = R.path(['response', 'data']);
  const checkStructData = R.pipe(getData, R.isNil, R.not);

  const checkStruct = R.anyPass([checkStructMessage, checkStructErrorKey, checkStructData]);
  return R.allPass([checkType, checkStruct]);
}

export default [R.call(match), R.call(execute)];
