import api from 'api';
import { debounce } from 'debounce-decorator';

export function create(data) {
  return api.post('/class', data);
}

export function update(id, data) {
  return api.patch(`/class/${id}`, data);
}

export function updateSSID(id, data) {
  return api.patch(`/class/${id}/ssid`, data);
}

export function byMe() {
  return api.get('/class');
}

export function byId(id, query) {
  return api.get(`/class/${id}?${new URLSearchParams(query || {})}`);
}

export const saveWork = debounce((classRoomId, work) => api.post(`/class/${classRoomId}/`, work), 800);

export function addStudent(id, students) {
  return api.post(`/class/${id}/students`, students);
}

export function addRobot(id, data) {
  return api.post(`/class/${id}/robots`, data);
}

export function fetchClassRoomAvailableRobots(id) {
  return api.get(`/class/${id}/robots?available=true`);
}

export function fetchClassRoomMineRobots(id) {
  return api.get(`/class/${id}/robots?onlyMine=true`);
}

export function fetchClassRoomStudents(id) {
  return api.get(`/class/${id}/students`);
}

export function removeRobot(id, robotIds) {
  if (!Array.isArray(robotIds)) {
    robotIds = [robotIds];
  }
  return api.post(`/class/${id}/delete_robot_by_numberids`, { robotNumberIds: robotIds });
}

export function removeStudentFromClassRoom(id, studentClassRoomId) {
  return api.delete(`/class/${id}/students/${studentClassRoomId}`);
}

export function getStudentClassroomJointFromClassRoom(id, studentClassRoomId) {
  return api.get(`/class/${id}/students/${studentClassRoomId}`);
}

export function associateStudentToRobotForClassRoom(id, studentId, studentName, robotId) {
  return api.post(`/class/${id}/students/${studentId}/robots`, {
    studentName,
    robotId,
  });
}

export function disassociateStudentFromRobotForClassRoom(id, studentId, robotId) {
  return api.delete(`/class/${id}/students/${studentId}/robots/${robotId}`);
}

export function saveNamedWork(ruleId, classRoomCode, xml, name, mapId, projectRef, workRef) {
  const data = {
    name,
    xml,
    mapId,
  };
  if (ruleId) data.rule = ruleId;
  if (projectRef) data.projectRef = projectRef;
  if (workRef) data.workRef = workRef;
  return api.post(`/class/${classRoomCode}/works`, data);
}

export function getNamedWorkById(workId) {
  return api.get(`/class/works/${workId}`);
}

export function loadNamedWork(classRoomCode) {
  return api.get(`/class/${classRoomCode}/works`);
}
export function removeNamedWork(workId) {
  return api.delete(`/class/works/${workId}`);
}

export function updateNamedWork(workId, { xml, svgCoverXML }) {
  return api.patch(`/class/works/${workId}`, {
    xml,
    svgCoverXML,
  });
}

export function removeClassRoom(id) {
  return api.delete(`/class/${id}`);
}

export function addGlobalVariable(classRoomCode, name, value, readonly) {
  return api.post(`/class/${classRoomCode}/variables`, {
    name,
    value,
    readonly,
  });
}

export function sendInvitationToEmail(classRoomCode, emailContent) {
  return api.post(`/class/${classRoomCode}/sendInvitationToEmail`, {
    ...emailContent,
  });
}

export function markMeEntered(classRoomCode) {
  return api.post(`/class/${classRoomCode}/markMeEntered`, {});
}

export function enableProjects(classRoomCode, projectId) {
  return api.post(`/class/${classRoomCode}/availableProjects`, [projectId]);
}

export function removeAvailableProjects(classRoomCode, projectId) {
  return api.delete(`/class/${classRoomCode}/availableProjects/${projectId}`);
}

export function emptyAvailableProjects(classRoomCode) {
  return api.delete(`/class/${classRoomCode}/availableProjects`);
}

export function setCurrentOperationProject(classRoomCode, currentOperationProject) {
  return api.patch(`/class/${classRoomCode}/currentOperationProject`, {
    currentOperationProject,
  });
}

export function getClassroomAssistanceCodeByStudentId(classroomCode, studentId) {
  return api.get(`/class/${classroomCode}/assistance/code/student/${studentId}`);
}

export function deleteClassroomAssistanceCodeByCode(classroomCode, assistanceCode) {
  return api.delete(`/class/${classroomCode}/assistance/code/${assistanceCode}`);
}

export function getStudentClassroomJointByBothId(studentId, classroomCode) {
  return api.get(`/class/${classroomCode}/student/byid/${studentId}`);
}

export function bindSerialNumber(classroomCode, serialNumber) {
  return api.get(`/class/${classroomCode}/bind_serial_number/${serialNumber}`);
}

export function disableChat(classroomCode, disable) {
  return api.post(`/class/${classroomCode}/chat/disable`, {
    disable: !!disable,
  });
}

export function setClassroomObjectInfo(classroomCode, objectId, data) {
  return api.post(`/class/${classroomCode}/classroom_object/${objectId}`, data);
}

export function setBlocklyReadOnly(classRoomCode, isBlocklyReadOnly) {
  return api.patch(`/class/${classRoomCode}/set-blockly-read-only`, {
    isBlocklyReadOnly: Boolean(isBlocklyReadOnly),
  });
}
