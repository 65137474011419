/* eslint-disable no-param-reassign */
import { flow, types } from 'mobx-state-tree';
import { byId, update } from 'api/classRoom';
import Project from './Project';

const ClassRoomModel = types
  .model('ClassRoomModel', {
    _id: types.optional(types.identifier(), ''),
    name: types.optional(types.string, ''),
    mapId: types.optional(types.string, '0'),
    availableProjects: types.maybe(types.array(types.late(() => Project))),
    ssid: types.maybe(types.string),
    currentOperationTeacherToken: types.maybe(types.string),
    chatDisabled: types.maybe(types.boolean),
    // chatDisabledStudents: types.maybe(types.array(types.sting)),
    deletedAt: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    created_by: types.optional(types.frozen, null),
    updatedAt: types.maybe(types.string),
  })
  .actions((self) => {
    const setMapId = (mapId) => {
      self.mapId = `${mapId}`;
      if (self._id && self._id.length > 0) {
        update(self._id, {
          mapId: self.mapId,
        });
      }
    };

    const refresh = flow(function* refresh() {
      try {
        const resp = yield byId(self._id);
        self.assign(resp.data);
      } catch (e) {
        throw e;
      }
      return self;
    });

    const assign = newData => Object.keys(newData || {}).reduce((acc, currNewKey) => {
      acc[currNewKey] = newData[currNewKey];
      return acc;
    }, self);

    const isAvailableProject = (projectId) => {
      const { availableProjects } = self;
      if (!availableProjects) return false;
      if (availableProjects.length > 0) {
        if (typeof availableProjects[0] === 'string') {
          return availableProjects.indexOf(projectId) > -1;
        }
        return !!availableProjects.find(p => p._id === projectId || p.parent === projectId);
      }
      return false;
    };
    const doesTheProjectNeedBeUnavailable = (projectId) => {
      if (self.availableProjects.length > 0) {
        return !self.isAvailableProject(projectId);
      }
      return false;
    };
    const isLockedProject = (projectId) => {
      if (self.availableProjects.length > 0) {
        return self.isAvailableProject(projectId);
      }
      return false;
    };
    return {
      assign,
      refresh,
      isAvailableProject,
      doesTheProjectNeedBeUnavailable,
      isLockedProject,
      setMapId,
    };
  });

export default ClassRoomModel;
