import api from 'api';

const log = require('debug')('api:account.js');

/* Deprecated */
export function register(data) {
  return api.post('/register', data);
}

export function authenticate(data) {
  log('authenticate', data);
  return api.post('/account/authenticate', data);
}

export function authenticateWithGoogle(data) {
  log('authenticateWithGoogle', data);
  return api.post('/account/authenticate/google_oauth', data);
}

export function authenticateWithAssistanceCode(data) {
  return api.post('/account/authenticate/assistance_code', data);
}

export function verify(token) {
  return api.post(`/account/verify/${token}`);
}

export function ping(data) {
  return api.post('/account/ping', data);
}

export function getStateStoreWithNS(ns) {
  return api.get(`/account/state_store/${ns}`);
}

export function getAllUserStateStore() {
  return api.get(`/account/state_store/user`);
}

export function patchStateStore(dict) {
  return api.patch(`/account/state_store/user`, dict);
}

export function getAllClassroomStateStore(classroom) {
  return api.get(`/account/state_store/classroom/${classroom}`);
}

export function patchAllClassroomStateStore(classroom, dict) {
  return api.patch(`/account/state_store/classroom/${classroom}`, dict);
}
