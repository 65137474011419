import { get as getOneProject } from 'api/project';
import { statehookify } from './hookFactory';

class ProjectHook {
  constructor() {
    statehookify('projectHook', this, {});
  }
  getProject(projectId) {
    return this.getHookStatePath(`project.${projectId}`);
  }
  loadOneProject(projectId) {
    this.setHookStateManyPaths({
      [`loadOneProjectState.${projectId}`]: 'doing',
      [`loadOneProjectState.${projectId}_error`]: '',
    });
    return getOneProject(projectId)
      .then((result) => {
        this.setHookStateManyPaths({
          [`loadOneProjectState.${projectId}`]: 'done',
          [`loadOneProjectState.${projectId}_error`]: '',
          [`project.${projectId}`]: result.data,
        });
      })
      .catch((e) => {
        this.setHookStateManyPaths({
          [`loadOneProjectState.${projectId}`]: 'failed',
          [`loadOneProjectState.${projectId}_error`]: e.message,
        });
      });
  }
}

export default new ProjectHook();
