/* eslint-disable no-param-reassign */

import {
  types,
  // getParent,
} from 'mobx-state-tree';
import Project from './Project';

const Work = types
  .model('Work', {
    _id: types.string,
    name: types.string,
    projectRef: types.maybe(types.string),
    workRef: types.maybe(types.string),
    createdAt: types.string,
    created_by: types.string,
    updatedAt: types.string,
    xml: types.string,
    svgCoverXML: types.optional(types.string, ''),
    mapId: types.optional(types.number, 1),
    rule: types.maybe(types.late(() => Project)),
    classRoom: types.string,
  })
  .actions(self => ({
    update({
      name,
      updatedAt,
      xml,
      svgCoverXML,
      mapId,
      classroom,
    }) {
      if (name !== undefined) self.name = name;
      if (updatedAt !== undefined) self.updatedAt = updatedAt;
      if (mapId !== undefined) self.mapId = mapId;
      if (classroom !== undefined) self.classroom = classroom;
      if (xml !== undefined) self.xml = xml;
      if (svgCoverXML !== undefined) self.svgCoverXML = svgCoverXML;
    },
    updateCode(xmlCode) {
      self.xml = xmlCode;
    },
  }))
  .views(self => ({
    get helpHtml() {
      return self.rule ? self.rule.helpHtml : undefined;
    },
    get teacherHelpHtml() {
      return self.rule ? self.rule.teacherHelpHtml : undefined;
    },
  }));

export default Work;
