import debug from 'debug';
import classRoomStore from 'store/classRoomStore';
import mqttStore from 'store/mqttStore';
import mapSpecs from '../../routes/ClassRoom/map_specs';

const log = debug('ui:mqtt');

const COEFFICIENT_DISTANCE = 0.764;
const COEFFICIENT_ANGLE = 0.481;

export function changeAngle(mac, ssid, classRoomCode, angle) {
  log('send change angle command for %s: %d', mac, angle);
  const step1 = angle * COEFFICIENT_ANGLE;
  const motorParam = (step1 > 0 ? ((4 * 17) + (1 * 256)) : ((4 * 17) + (2 * 256)));
  mqttStore.publish(`to/robot/${mac}/${classRoomCode}`, JSON.stringify({
    motor: {
      L: 1,
      S: [Math.abs(parseInt(step1, 10))],
      P: [motorParam],
    },
    id: classRoomCode,
    index: 0,
    mode: 1,
    priority: 3,
  }));
}

export function parking(classroom, identifier) {
  const { mapId } = classRoomStore;
  const { x: destinationX, y: destinationY } = mapSpecs[mapId].parkingPosition(identifier);

  const robot = classRoomStore.detectedRobots.get(identifier);

  const delX = (destinationX - (robot.x || 0));
  const delY = (destinationY - (robot.y || 0));
  let angleCal = 0;
  if (delY === 0) {
    if (delX > 0) angleCal = 90;
    if (delX < 0) angleCal = 270;
  } else {
    if (delX < 0 && delY < 0) angleCal = 180 + ((Math.atan(delX / delY) / Math.PI) * 180);
    if (delX < 0 && delY > 0) angleCal = 360 - ((Math.atan(-delX / delY) / Math.PI) * 180);
    if (delX > 0 && delY < 0) angleCal = 180 - ((Math.atan(-delX / delY) / Math.PI) * 180);
    if (delX > 0 && delY > 0) angleCal = (Math.atan(delX / delY) / Math.PI) * 180;
    if (delX === 0 && delY > 0) angleCal = 0;
    if (delX === 0 && delY < 0) angleCal = 180;
  }
  let errAngle = angleCal - (robot.a || 0);
  if (angleCal > (robot.a || 0)) {
    if (errAngle > 180) errAngle -= 360;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (errAngle < -180) errAngle += 360;
  }
  let errAngleParking = 0 - angleCal;
  if (angleCal < 0) {
    if (errAngleParking > 180) errAngleParking -= 360;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (errAngleParking < -180) errAngleParking += 360;
  }
  const angletogo = errAngle * COEFFICIENT_ANGLE;
  let steptogo = Math.sqrt((delX * delX) + (delY * delY)) * COEFFICIENT_DISTANCE;
  const angletogoParking = errAngleParking * COEFFICIENT_ANGLE;
  if (steptogo < 10 * COEFFICIENT_DISTANCE) {
    steptogo = 0;
    // angletogo = 0;
    // angletogoParking = 0;
  }
  // eslint-disable-next-line
  const step1 = angletogo;
  // eslint-disable-next-line
  const step2 = steptogo;
  // eslint-disable-next-line
  const step3 = angletogoParking;
  mqttStore.publish(`to/robot/${classroom}/${identifier}`, JSON.stringify({
    motor: {
      L: 3,
      A: [parseInt(-step1, 10), parseInt(step2, 10), parseInt(-step3, 10)],
      B: [parseInt(step1, 10), parseInt(step2, 10), parseInt(step3, 10)],
      G: 4,
      ANG: parseInt(angleCal, 10),
    },
    id: 'parking',
    index: 0,
    mode: 1,
  }));
}

export function sendClassRoom(mac, identifier, ssid, classRoomCode) {
  log('send register success for %s: %d', mac, identifier);
  mqttStore.publish(`to/robot/${mac}/${classRoomCode}`, JSON.stringify({
    classRoomCode, identifier,
  }));
}
