import {
  getAllAllocationFromClassroom,
  allocateModelIdToQRCodeFromClassroom,
  unallocateModelIdToQRCodeFromClassroom,
} from 'api/threeDModel';
import mqttStore from 'store/mqttStore';
import classRoomStudentsStatehook from 'store/classRoomStudentsStatehook';
import classRoomStore from 'store/classRoomStore';
import threeDModelStatehook from 'store/threeDModelStatehook';

import { statehookify } from './statehook/hookFactory';

const log = require('debug')('ui:statehook:threeDModelAllocationStatehook.js');


export default statehookify('threeDModelAllocationStatehook', {
  fetch3DModelAllocation(classRoomCode) {
    this.setQueryStatus(classRoomCode, 'fetching');
    return getAllAllocationFromClassroom(classRoomCode)
      .then((res) => {
        this.setHookStatePath([classRoomCode, '3DModelsAllocationQueryLastTime'], Date.now());
        this.setAllocationData(classRoomCode, res.data);
        this.setQueryStatus(classRoomCode, 'successful');
        return res.data;
      })
      .catch((err) => {
        this.setQueryStatus(classRoomCode, 'failed');
        throw err;
      });
  },
  isQueryFetching(classRoomCode) {
    return this.getQueryStatus(classRoomCode) === 'fetching';
  },
  isQuerySuccessful(classRoomCode) {
    return this.getQueryStatus(classRoomCode) === 'successful';
  },
  isQueryFailed(classRoomCode) {
    return this.getQueryStatus(classRoomCode) === 'failed';
  },
  setQueryStatus(classRoomCode, status) {
    return this.setHookStatePath([classRoomCode, '3DModelsAllocationQueryStatus'], status);
  },
  getQueryStatus(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModelsAllocationQueryStatus']);
  },
  watchQueryStatus(classRoomCode, cb) {
    return this.watchHookStatePath([classRoomCode, '3DModelsAllocationQueryStatus'], cb);
  },

  isSettingFetching(classRoomCode) {
    return this.getSettingStatus(classRoomCode) === 'fetching';
  },
  isSettingSuccessful(classRoomCode) {
    return this.getSettingStatus(classRoomCode) === 'successful';
  },
  isSettingFailed(classRoomCode) {
    return this.getSettingStatus(classRoomCode) === 'failed';
  },
  setSettingStatus(classRoomCode, status) {
    return this.setHookStatePath([classRoomCode, '3DModelsAllocationSettingStatus'], status);
  },
  getSettingStatus(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModelsAllocationSettingStatus']);
  },
  setSettingHttpResult(classRoomCode, result) {
    return this.setHookStatePath([classRoomCode, '3DModelsAllocationSettingHttpResult'], result);
  },
  getSettingHttpResult(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModelsAllocationSettingHttpResult']);
  },
  watchSettingStatus(classRoomCode, cb) {
    return this.watchHookStatePath([classRoomCode, '3DModelsAllocationSettingStatus'], cb);
  },

  setAllocationData(classRoomCode, data) {
    return this.setHookStatePath([classRoomCode, '3DModelsAllocationData'], data);
  },
  getAllocationData(classRoomCode) {
    return this.getHookStatePath([classRoomCode, '3DModelsAllocationData']);
  },
  getAllocationDataByQRCode(classRoomCode, qrcode) {
    const allData = this.getAllocationData(classRoomCode) || [];
    return allData.find(row => `${row.qrcodeNumber}` === `${qrcode}`);
  },
  fetchAllocationDataIfNeeded(classRoomCode) {
    const lastTime = this.getHookStatePath([classRoomCode, '3DModelsAllocationQueryLastTime']) || 0;
    if (Date.now() - lastTime < (3 * 1e3)) {
      return Promise.resolve(this.getAllocationData(classRoomCode));
    }
    return this.fetch3DModelAllocation(classRoomCode);
  },
  watchAllocationData(classRoomCode, cb) {
    return this.watchHookStatePath([classRoomCode, '3DModelsAllocationData'], cb);
  },
  allocate(classRoomCode, allocationData) {
    const currentData = this.getAllocationData(classRoomCode) || [];
    const data = currentData.reduce((acc, row) => {
      acc[row.qrcodeNumber] = 'string' === typeof row.threeDModel ? row.threeDModel : row.threeDModel._id;
      return acc;
    }, {});
    return this.setAllAllocations(classRoomCode, Object.assign(data, allocationData));
  },
  setAllAllocations(classRoomCode, allocationData) {
    this.setSettingStatus(classRoomCode, 'fetching');
    return allocateModelIdToQRCodeFromClassroom(classRoomCode, allocationData)
      .then((res) => {
        this.setSettingHttpResult(classRoomCode, res);
        this.setSettingStatus(classRoomCode, 'successful');

        return this.fetch3DModelAllocation(classRoomCode)
          .then((fetchRes) => {
            this._notifyAllocationData(classRoomCode);
            return fetchRes;
          });
        //
      })
      .catch((err) => {
        this.setSettingHttpResult(classRoomCode, err.response);
        this.setSettingStatus(classRoomCode, 'failed');
        throw err;
      });
  },
  unallocate(classRoomCode, qrcodeNumber) {
    this.setSettingStatus(classRoomCode, 'fetching');
    return unallocateModelIdToQRCodeFromClassroom(classRoomCode, qrcodeNumber)
      .then((res) => {
        this.setSettingHttpResult(classRoomCode, res);
        this.setSettingStatus(classRoomCode, 'successful');
        return this.fetch3DModelAllocation(classRoomCode)
          .then((fetchRes) => {
            this._notifyUnallocationData(classRoomCode, qrcodeNumber);
            return fetchRes;
          });
      })
      .catch((err) => {
        this.setSettingHttpResult(classRoomCode, err.response);
        this.setSettingStatus(classRoomCode, 'failed');
        throw err;
      });
  },
  _notifyAllocationData(classRoomCode, eventType) {
    if (eventType !== 'allocate' && eventType !== 'unallocate') {
      eventType = 'allocate';
    }
    // classRoomStudentsStatehook
    const _allocationData = this.getAllocationData(classRoomCode);
    log(_allocationData, '_allocationData');
    if (_allocationData) {
      const dataToSend = _allocationData.reduce((acc, curr) => {
        acc.R.push(parseInt(curr.qrcodeNumber, 10));
        acc.M.push(curr.threeDModel._id);

        // student name
        const robot = classRoomStore.getRobotByIdentifier(curr.qrcodeNumber);
        let studentName = '';
        if (robot) {
          const studentForThisRobot = classRoomStudentsStatehook.findStudentByTheirRobotMacAddress(classRoomCode, robot._id);
          if (studentForThisRobot) {
            studentName = studentForThisRobot.name;
          }
        }
        acc.student_name.push(studentName);

        return acc;
      }, { R: [], M: [], student_name: [] });
      mqttStore.publish(
        `to/classroom/${classRoomCode}/3d/model/allocation`,
        JSON.stringify(dataToSend),
      );
      threeDModelStatehook.sendModelEventNotificationToMqtt(classRoomCode, eventType, dataToSend);
      // notificationStatehook.sendClassroomNotification(
      //   classRoomCode,
      //   '3d/model/allocation',
      //   {
      //     title: 'A 3D Model has been allocated',
      //     data: dataToSend,
      //   },
      // );
    }
  },
  _notifyUnallocationData(classRoomCode, unallocatedList) {
    const eventType = 'unallocate';
    if (!Array.isArray(unallocatedList)) {
      unallocatedList = [unallocatedList];
    }
    threeDModelStatehook.sendModelEventNotificationToMqtt(classRoomCode, eventType, {
      R: unallocatedList,
    });
  },
}, {});
