/* eslint-disable no-param-reassign */

import { flow, types } from 'mobx-state-tree';
import ProjectStore from './ProjectStore';
import WorkStore from './WorkStore';

const BaseProjectStore = types
  .model('BaseProjectStore', {
    classRoomCode: types.string,
    activeWorkId: types.maybe(types.string),
    activeProjectId: types.maybe(types.string),
  })
  .actions(self => ({
    setActiveProject(projectId) {
      self.activeProjectId = projectId;
      self.activeWorkId = undefined;
    },
    setActiveWork(workId) {
      self.activeProjectId = undefined;
      self.activeWorkId = workId;
    },
  }));

export default types
  .compose(BaseProjectStore, ProjectStore, WorkStore)
  .actions(self => ({
    afterCreate: flow(function* afterCreate() {
      yield self.fetchCategories();
      return yield self.fetchWorks();
    }),
  }))
  .views(self => ({
    get activeProject() {
      const projectAndRules = self.projectsAndRules;
      return projectAndRules.find(p => p._id === self.activeProjectId);
    },
    get activeWork() {
      return self.works.find(w => w._id === self.activeWorkId);
    },
  }))
  .named('ProjectsStore');
