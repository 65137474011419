import { getTemplateNS } from '_i18n_';
import advancedXML from './advanced.xml';
import advancedTemplateBlock from './advanced_template_block.xml';
import beginnerXML from './beginner.xml';
import beginnerTemplateBlock from './beginner_template_block.xml';

const KEY_I18N_ROOT_KEY = 'studentToolbox';

function templateBlock(template, blockName, blockContent) {
  // todo: 1. fix the bug the code didn't refresh
  const tag = `{template-block{${blockName}}template-block}`;
  if (template.indexOf(tag) > -1) {
    return template.replace(RegExp(`${tag}`, 'g'), blockContent);
  }
  return template;
}

export const getStudentAdvancedXML = () => {
  const _tt = getTemplateNS([KEY_I18N_ROOT_KEY, 'advanced.xml']);
  return _tt(templateBlock(advancedXML, 'advanced_template_block', advancedTemplateBlock));
};

export const getStudentBeginnerXML = () => {
  const _tt = getTemplateNS([KEY_I18N_ROOT_KEY, 'beginner.xml']);
  // return _tt(beginnerXML);
  return _tt(templateBlock(beginnerXML, 'beginner_template_block', beginnerTemplateBlock));
};

export const getAllStudentXML = () => {
  const advanced = getStudentAdvancedXML();
  const beginner = getStudentBeginnerXML();
  const advancedXmlParser = new DOMParser();
  const advancedXMLDOM = advancedXmlParser.parseFromString(advanced, 'application/xml');

  const beginnerXmlParser = new DOMParser();
  const beginnerXMLDOM = beginnerXmlParser.parseFromString(beginner, 'application/xml');

  const aEle = advancedXMLDOM.documentElement;
  const bEle = beginnerXMLDOM.documentElement;
  Array.from(bEle.children).forEach((c) => {
    aEle.appendChild(c);
  });
  const serializer = new XMLSerializer();
  const xmlText = serializer.serializeToString(advancedXMLDOM);
  return xmlText;
};
