import {
  pickAll,
  pipe,
  type,
  equals,
  call,
} from 'ramda';

const log = require('debug')('ui:exceptionErrorParse.js');

export function execute() {
  log('execute');
  return pickAll(['message']);
}

export function match() {
  log('match');
  return pipe(type, equals('Error'));
}

export default [call(match), call(execute)];
