import {
  path,
  pipe,
  toPairs,
  map,
  objOf,
  lensProp,
  lift,
  set,
  identity,
  pick,
  converge,
  merge,
  pathEq,
  call,
} from 'ramda';

const log = require('debug')('ui:mongoDbValidationErrorParse.js');

/**
 * Extract errors, convert to an array of {{ field: {string}, message: {string} }} format.
 *
 * @returns {*} - An function that can be called with one param.
 */
export function execute() {
  log('execute');
  // Pickup errors
  const mapError = it => ({ field: it[0], message: it[1].message });
  const pickErrors = path(['response', 'data', 'errors']);
  const getErrors = pipe(pickErrors, toPairs, map(mapError), objOf('errors'));

  // Get generic message
  const messageLens = lensProp('message');
  const pickMessage = path(['response', 'data', 'message']);
  const setMessage = lift(set(messageLens))(pickMessage, identity);
  const getMessage = pipe(setMessage, pick(['message']));

  return converge(merge, [getErrors, getMessage]);
}

export function matchExecution() {
  log('matchExecution');
  return pathEq(['response', 'status'], 400);
}

export default [call(matchExecution), call(execute)];
