/* eslint-disable import/first */
import './systemMonkeyPatch/WebSocket';
import React from 'react';
import { render } from 'react-dom';
import { Router } from 'react-router-dom';
// import { spy } from 'mobx-react-devtools';
import { spy } from 'mobx';
import { Provider } from 'mobx-react';
// import LogRocket from 'logrocket';
import store from './store';
import history from './history';
import App from './App';
import { changeLanguage } from '_i18n_';

const isProductionMode = process.env.NODE_ENV === 'production';
// if (isProductionMode) LogRocket.init('jqqabp/kaisclan-ui');

const rootEl = document.getElementById('root');
// const rootEl = document.body;
// spy((event) => {
//   console.log('#################### syp ####################', event);
// });

new Promise((resolve) => {
  // if (isProductionMode && !window.location.protocol.startsWith('https:')) {
  //   window.location.protocol = 'https:';
  //   return;
  // }
  resolve();
})
  .then(() => {
    // store.appConfigStatehook.startPolling();
    return store.appConfigStatehook.refreshAllAppConfig();
  })
  .then(() => {
    // i18nInit({
    //   // lng: window.navigator.language.split('-')[0],
    //   lng: 'spanish',
    // });
    const language = store.webStorageStore.getShareStorageItem('language');
    changeLanguage(language || 'en');
  })
  .then(() => {
    const providerContent = { ...store, history };
    const renderApp = (AppComponent) => {
      render(
        <Provider {...providerContent}>
          <Router history={history}>
            <AppComponent />
          </Router>
        </Provider>
        , rootEl,
      );
    };
    renderApp(App);

    if (module.hot) {
      module.hot.accept('./App', () => {
        // eslint-disable-next-line global-require
        const NewRoot = require('./App').default;
        renderApp(NewRoot);
      });
    }
  });

