const Blockly = require('blockly');
const { parse } = require('acorn');
const _ = require('lodash');
const { extractAllArgItems } = require('store/statehook/codeBlocklyStatehook');

function genBlocklyUid(...rest) {
  return Blockly.utils.genUid(...rest);
  // return 'id1234567';
}

class BlocklyJavaScript2XML {
  constructor(ast, blocklyDef) {
    this._init(ast, blocklyDef);
    this.XML = this.typeHub(this.AST_Object);
  }
  _init(ast, blocklyDef) {
    this._initSideEffects();
    this.AST_Object = ast;
    this.blocklyDef = blocklyDef;
    this.XML = '';
    this.errors = [];
  }
  _initSideEffects() {
    this.sideEffects = {};
    this.sideEffects.varDefs = {};
    this.sideEffects.funcDefs = {};
    this.sideEffects.blockSetup = [];
    this.sideEffects.blockLoop = [];
    this.sideEffects.blockEvent = [];
  }
  findErrorsByASTRange(range) {
    const { start, end } = range;
    return this.errors.filter((err) => {
      const s1 = err.section.start;
      const e1 = err.section.end;
      const s2 = start;
      const e2 = end;

      // s1[  s2[  e2]  e1]
      if (s1 <= s2 && e2 <= e1) return true;
      // s2[  s1[  e1]  e2]
      if (s2 <= s1 && e1 <= e2) return true;
      // s1[   e1]   s1[    e1]
      //   s2[         e2]
      if (s1 <= s2 && e1 >= s2) return true;
      if (s1 <= e2 && e1 >= e2) return true;
      return false;
    });
  }
  tool_add_error_by_sectionJSON(errorObj, ectionJSON) {
    this.errors.push({
      error: errorObj,
      section: {
        start: ectionJSON.start,
        end: ectionJSON.end,
      },
    });
  }
  tool_xmlArray_to_xmlNext(xmlArray) {
    if (!xmlArray) return '';
    if (!xmlArray.length) return '';
    const xmlArrayCopy = Array.from(xmlArray);
    let firstEle = null;
    // deal with newline(\n) in the first element
    do {
      firstEle = xmlArrayCopy.shift();
    } while (!firstEle && xmlArrayCopy.length > 0);
    if (!firstEle) return '';
    const firstXmlArray = `${firstEle}`.split('\n');
    const regexRes = /^(.*)(<\/.*>)$/.exec(firstXmlArray[firstXmlArray.length - 1]);
    const begin = firstXmlArray
      .slice(0, firstXmlArray.length - 1)
      .concat(regexRes['1'])
      .join('\n');
    const end = regexRes['2'];
    const content = this.tool_xmlArray_to_xmlNext(xmlArrayCopy);
    let ret = '';
    if (content) {
      ret = `${begin}<next>${content}</next>${end}`;
    } else {
      ret = `${begin}${end}`;
    }
    return ret;
  }

  typeHub(ast) {
    if (!Array.isArray(ast)) {
      ast = [ast];
    }
    if (!ast.length) return '';
    return ast.reduce((acc, row) => {
      const { type } = row;
      const fn = this[`type_${type}`];
      if ('function' === typeof fn) {
        let result = fn.call(this, row);
        if (!Array.isArray(result)) {
          result = [result];
        }
        acc = acc.concat(result);
      }
      return acc;
    }, []);
  }


  sideEffects_declare_variable(varName) {
    if (!this.sideEffects.varDefs[varName]) {
      const id = genBlocklyUid();
      this.sideEffects.varDefs[varName] = {
        id,
        name: varName,
        xml: `<variable id="${id}">${varName}</variable>`,
      };
    }
    return this.sideEffects.varDefs[varName];
  }
  sideEffects_gen_section_variable_xml() {
    return Object.values(this.sideEffects.varDefs).map(r => r.xml).join('\n');
  }

  sideEffects_gen_seection_function_xml() {
    return Object.values(this.sideEffects.funcDefs).reduce((acc, curr) => `${acc}\n${curr.xml}`, '');
  }

  type_Program(acornJSON) {
    const allBodyXmlArray = this.typeHub(acornJSON.body);
    return [
      '<xml xmlns="https://developers.google.com/blockly/xml">',
      `
        <variables>
          ${this.sideEffects_gen_section_variable_xml()}
        </variables>
          ${this.sideEffects_gen_seection_function_xml()}
        <block type="runProgram" id="${genBlocklyUid()}" deletable="false" x="270" y="50">
          <statement name="SETUP">
            ${this.tool_xmlArray_to_xmlNext(allBodyXmlArray)}
          </statement>
          <statement name="LOOP">
            ${this.tool_xmlArray_to_xmlNext(this.sideEffects.blockLoop)}
          </statement>
          <statement name="EVENTS">
            ${this.tool_xmlArray_to_xmlNext(this.sideEffects.blockEvent)}
          </statement>
        </block>`,
      '</xml>',
    ].join('');
  }

  // check if this function is returnable function
  // {
  //   "type": "BlockStatement",
  //   "start": 14,
  //   "end": 31,
  //   "body": [{
  //     "type": "ReturnStatement",
  //     "start": 20,
  //     "end": 29,
  //     "argument": {
  //       "type": "Literal",
  //       "start": 27,
  //       "end": 28,
  //       "value": 1,
  //       "raw": "1"
  //     }
  //   }]
  // }

  type_FunctionDeclaration(acornJSON) {
    const blockStatement = _.get(acornJSON, 'body');
    const functionName = _.get(acornJSON, 'id.name');
    const functionParams = _.get(acornJSON, 'params') || [];

    const content = blockStatement.body.length <= 0 ? '' : this.typeHub(blockStatement.body);
    // for function params
    // - step1 create inner variable
    // - step2 create global variable
    let functionParamsXML = '';
    if (functionParams.length) {
      const paramsXML = functionParams.map((row) => {
        // add global variable for the params
        const { id: varid } = this.sideEffects_declare_variable(row.name);
        // return inner variable
        return `<arg name="${row.name}" varid="${varid}" />`;
      }).join('\n');
      functionParamsXML = `<mutation>${paramsXML}</mutation>`;
    }

    const lastElement = _.last(_.get(blockStatement, 'body'));
    const returnable = 'ReturnStatement' === _.get(lastElement, 'type'); // body.body[last].type
    let returnStatement = '';
    if (returnable) {
      const returnArg = _.get(lastElement, 'argument'); // body.body[last].type
      returnStatement = `
      <value name="RETURN">
        ${this.section_lang_var(returnArg)}
      </value>`;
    }
    const functionId = genBlocklyUid();
    const existingLength = Object.keys(this.sideEffects.funcDefs).length;
    this.sideEffects.funcDefs[functionName] = {
      name: functionName,
      args: functionParams,
      id: functionId,
      returnable,
      xml:
        `<block type="${returnable ? 'procedures_defreturn' : 'procedures_defnoreturn'}" id="${functionId}" x="${(270 + 500) + (400 * existingLength)}" y="50">
        ${functionParamsXML}
        <field name="NAME">${functionName}</field>
        <comment pinned="false" h="80" w="160">Describe this function...</comment>
        <statement name="STACK">
          ${this.tool_xmlArray_to_xmlNext(content)}
        </statement>
        ${returnStatement}
      </block>`,
    };
    return null;
  }

  type_BlockStatement(sectionJSON) {
    // debugger;
    // const content = this.tool_xmlArray_to_xmlNext(this.typeHub(sectionJSON.body));
    if (sectionJSON.type === 'BlockStatement') {
      const content = this.typeHub(sectionJSON.body);
      return content;
    }
    return '';
  }

  type_ExpressionStatement(acornJSON) {
    const { expression } = acornJSON;
    return this.typeHub(expression);
  }

  type_CallExpression__userDefined(sectionJSON) {
    const { arguments: callArgs, callee } = sectionJSON;
    const functionName = _.get(callee, 'name');
    const userDefinedFunctionInfo = this.sideEffects.funcDefs[functionName];
    const { returnable, args: defArgs } = userDefinedFunctionInfo;
    const blocklyUUID = genBlocklyUid();

    // def variables
    const allArgsVariable = defArgs.map((argNode) => {
      return this.sideEffects_declare_variable(argNode.name);
    });

    if (returnable && allArgsVariable.length) {
      // own both retuan and args
      const argsXml = callArgs.map((row, idx) => {
        return `
        <value name="ARG${idx}">
          ${this.section_lang_var(row)}
        </value>
        `;
      }).join('');
      return `<block type="procedures_callreturn" id="${blocklyUUID}">
        <mutation name="${functionName}">
          ${allArgsVariable.map(row => `<arg name="${row.name}"></arg>`).join('\n')}
        </mutation>
        ${argsXml}
      </block>`;
      // end
    } else if (returnable && !allArgsVariable.length) {
      return `<block type="procedures_callreturn" id="${blocklyUUID}">
        <mutation name="${functionName}"></mutation>
      </block>`;
      // end
    } else if (!returnable && allArgsVariable.length) {
      // begin
      const argsXml = callArgs.map((row, idx) => {
        return `
        <value name="ARG${idx}">
          ${this.section_lang_var(row)}
        </value>
        `;
      }).join('');
      return `<block type="procedures_callnoreturn" id="${blocklyUUID}">
        <mutation name="${functionName}">
          ${allArgsVariable.map(row => `<arg name="${row.name}"></arg>`).join('\n')}
        </mutation>
        ${argsXml}
      </block>`;
      // end
    } else if (!returnable && !allArgsVariable.length) {
      return `<block type="procedures_callnoreturn" id="${blocklyUUID}">
        <mutation name="${functionName}"></mutation>
      </block>`;
    }
    return '';
  }

  type_CallExpression__special__add_loop(sectionJSON) {
    const { arguments: args } = sectionJSON;
    const [argDo] = args;
    // directly deal with the "ArrowFunctionExpression", "FunctionExpression"
    if (argDo.type !== 'ArrowFunctionExpression' && argDo.type !== 'FunctionExpression') {
      this.tool_add_error_by_sectionJSON(new Error('Only supports a function as the second paramter!'), sectionJSON);
      return '';
    }
    const blockStatmentInTheFunc = _.get(argDo, 'body');
    this.sideEffects.blockLoop = this.sideEffects.blockLoop.concat(this.typeHub(blockStatmentInTheFunc));
    console.log(this.sideEffects.blockLoop, 'this.sideEffects.blockLoop');
    return '';
  }

  type_CallExpression__special__repeat_times(sectionJSON) {
    const { arguments: args } = sectionJSON;
    const [argTime, argDo] = args;
    // directly deal with the "ArrowFunctionExpression", "FunctionExpression"
    if (argDo.type !== 'ArrowFunctionExpression' && argDo.type !== 'FunctionExpression') {
      this.tool_add_error_by_sectionJSON(new Error('Only supports a function as the second paramter!'), sectionJSON);
      return '';
    }
    const blockStatmentInTheFunc = _.get(argDo, 'body');

    return `<block type="customized_controls_repeat_ext" id="${genBlocklyUid()}">
    <value name="TIMES">
      ${this.section_lang_var(argTime, 'block')}
    </value>
    <statement name="DO">
      ${this.tool_xmlArray_to_xmlNext(this.typeHub(blockStatmentInTheFunc))}
    </statement>
  </block>`;
  }

  type_CallExpression__special__count_with(sectionJSON) {
    const { arguments: args } = sectionJSON;
    const [argStart, argEnd, argStepBy, argDo] = args;
    // directly deal with the "ArrowFunctionExpression", "FunctionExpression"
    if (argDo.type !== 'ArrowFunctionExpression' && argDo.type !== 'FunctionExpression') {
      this.tool_add_error_by_sectionJSON(new Error('Only supports a function as the last paramter!'), sectionJSON);
      return '';
    }
    const argDoParams = argDo.params;
    const argDoCounterName = _.get(argDoParams, '[0].name') || 'counter';

    const blockStatmentInTheFunc = _.get(argDo, 'body');

    const variableInfo = this.sideEffects_declare_variable(argDoCounterName);
    return `<block type="customized_controls_for" id="${genBlocklyUid()}">
      <field name="VAR" id="${variableInfo.id}">${variableInfo.name}</field>
      <value name="FROM">
        ${this.section_lang_var(argStart, 'shadow')}
      </value>
      <value name="TO">
        ${this.section_lang_var(argEnd, 'shadow')}
      </value>
      <value name="BY">
        ${this.section_lang_var(argStepBy, 'shadow')}
      </value>
      <statement name="DO">
        ${this.tool_xmlArray_to_xmlNext(this.typeHub(blockStatmentInTheFunc))}
      </statement>
    </block>`;
  }

  type_CallExpression(sectionJSON) {
    // {
    //   "type": "CallExpression",
    //   "start": 108,
    //   "end": 123,
    //   "callee": {
    //     "type": "Identifier",
    //     "start": 108,
    //     "end": 113,
    //     "name": "func3"
    //   },
    //   "arguments": [{
    //     "type": "Literal",
    //     "start": 114,
    //     "end": 115,
    //     "value": 1,
    //     "raw": "1"
    //   }, {
    //     "type": "Literal",
    //     "start": 116,
    //     "end": 119,
    //     "value": "2",
    //     "raw": "'2'"
    //   }, {
    //     "type": "Identifier",
    //     "start": 121,
    //     "end": 122,
    //     "name": "a"
    //   }]
    // }
    const { type, callee, arguments: args } = sectionJSON;
    if (type === 'CallExpression') {
      let blocklyType = '';
      if (callee.type === 'Identifier') {
        blocklyType = callee.name;
      }
      // debugger;
      // check if the called function is a perdefined function
      const specialFunc = this[`type_CallExpression__special__${blocklyType}`];
      if ('function' === typeof specialFunc) {
        return specialFunc.call(this, sectionJSON);
      }
      // check if the called function is a user-defined function instead of a blockly
      const userDefinedFunction = this.sideEffects.funcDefs[blocklyType];
      if (userDefinedFunction) {
        return this.type_CallExpression__userDefined(sectionJSON, userDefinedFunction);
      }
      const blocklyDef = this.blocklyDef[blocklyType];
      if (!blocklyDef) {
        this.tool_add_error_by_sectionJSON(new Error(`Undefined block or function: ${blocklyType}`), sectionJSON);
        return '';
      }

      const allDefArgs = extractAllArgItems(blocklyDef) || [];
      if (args.length < allDefArgs.length) { // check if the given param is insufficient.
        this.tool_add_error_by_sectionJSON(
          new Error(`Params are insufficent: Function ${blocklyType} needs ${allDefArgs.length} param(s) but got only ${args.length} param(s).`),
          sectionJSON,
        );
        return '';
      }
      const isEventBlockly = blocklyDef.previousStatement === 'on_event';
      let xml = '';
      xml += `<block type="${blocklyType}" id="${genBlocklyUid()}">`;
      allDefArgs.forEach((row, idx) => {
        //  {type: "field_dropdown", name: "ROBOT", options: ƒ}
        //  {type: "input_value", name: "AMOUNT", check: "Number"}
        //
        // <value name="X">
        //   <shadow type="math_number" id="gD8ePvDbC">
        //     <field name="NUM">60</field>
        //   </shadow>
        // </value>
        //
        const argFromJSCode = args[idx];
        //   "arguments": [{
        //     "type": "Literal",
        //     "start": 114,
        //     "end": 115,
        //     "value": 1,
        //     "raw": "1"
        //   }, {
        //     "type": "Literal",
        //     "start": 116,
        //     "end": 119,
        //     "value": "2",
        //     "raw": "'2'"
        //   }, {
        //     "type": "Identifier",
        //     "start": 121,
        //     "end": 122,
        //     "name": "a"
        //   }]
        const { type: argType, name: argName } = row;
        if (argType === 'field_image') return;
        if (argType === 'field_dropdown') {
          // let argOptionsArray = null;
          // if ('function' === typeof argOptions) {
          //   argOptionsArray = argOptions();
          // } else if (Array.isArray(argOptions)) {
          //   argOptionsArray = argOptions;
          // }
          //
          // const optValue = argOptionsArray ? _.get(argOptionsArray, '[0][1]') : argFromJSCode.value;
          const optValue = argFromJSCode.value/* for number or string */ || argFromJSCode.name/* for variable */;
          xml += `<field name="${argName}">${optValue}</field>`;
          return;
        }
        if (argType === 'input_statement' && argName === 'CALLBACK') {
          xml += `<statement name="${argName}">
              ${this.tool_xmlArray_to_xmlNext(this.typeHub(argFromJSCode.body))}
            </statement>`;
          console.log(argFromJSCode, 'argFromJSCode');
        }

        const argXmlified = this.section_lang_var(argFromJSCode, 'shadow');
        xml += `<value name="${argName}">${argXmlified}</value>`;
      });
      xml += '</block>';
      if (isEventBlockly) {
        this.sideEffects.blockEvent = this.sideEffects.blockEvent.concat([xml]);
        return '';
      }
      return xml;
    }
    return '';
  }


  type_IfStatement(sectionJSON) {
    const xml = [];
    if (sectionJSON.type === 'IfStatement') {
      xml.push(`<block type="customized_controls_if" id="${genBlocklyUid()}">`);
      let elseIfNumber = 0;
      let elseNumber = 0;
      // [1 [2 [3 [4]]]]/* AST */ ===> [1],[2],[3],[4]/* blockly xml */
      // const allIfStatement = [];
      const bodyXml = [];
      const __extractIfStatement = (__sectionJSON, deepIndex) => {
        const { test, consequent, alternate } = __sectionJSON;
        bodyXml.push(`<value name="IF${deepIndex}">`);
        bodyXml.push(this.section_condition_to_xml(test));
        bodyXml.push('</value>');
        bodyXml.push(`<statement name="DO${deepIndex}">`);
        const statementContent = this.typeHub(consequent.body);

        const xmlNext = this.tool_xmlArray_to_xmlNext(statementContent);
        bodyXml.push(xmlNext);
        bodyXml.push('</statement>');
        if (alternate) {
          if (alternate.type === 'IfStatement') { // else if
            elseIfNumber += 1;
            __extractIfStatement(alternate, deepIndex + 1);
          } else if (alternate.type === 'BlockStatement') { // else
            elseNumber += 1;
            bodyXml.push('<statement name="ELSE">');
            bodyXml.push(this.tool_xmlArray_to_xmlNext(this.typeHub(alternate)));
            bodyXml.push('</statement>');
          }
        }
      };

      __extractIfStatement(sectionJSON, 0);
      xml.push(`<mutation elseif="${elseIfNumber}" else="${elseNumber}"></mutation>`);
      xml.push(bodyXml.join(''));
      xml.push('</block>');
    }
    return xml.join('');
  }

  type_VariableDeclaration(sectionJSON) {
    // {
    //   "type": "VariableDeclaration",
    //   "start": 0,
    //   "end": 11,
    //   "declarations": [{
    //     "type": "VariableDeclarator",
    //     "start": 6,
    //     "end": 10,
    //     "id": {
    //       "type": "Identifier",
    //       "start": 6,
    //       "end": 7,
    //       "name": "a"
    //     },
    //     "init": {
    //       "type": "Literal",
    //       "start": 9,
    //       "end": 10,
    //       "value": 1,
    //       "raw": "1"
    //     }
    //   }],
    //   "kind": "const"
    // }
    const { type, declarations } = sectionJSON;
    if (type === 'VariableDeclaration') {
      return declarations.map((row) => {
        const { id, name } = this.sideEffects_declare_variable(row.id.name);
        if (row.init) {
          const rightXML = this.section_lang_var(row.init);
          return `<block type="variables_set" id="${genBlocklyUid()}">
          <field name="VAR" id="${id}">${name}</field>
          <value name="VALUE">${rightXML}</value>
          </block>`;
        }
        return '';
      }).join('\n');
    }
    return '';
  }

  type_AssignmentExpression(sectionJSON) {
    // {
    //   "type": "AssignmentExpression",
    //   "start": 0,
    //   "end": 4,
    //   "operator": "=",
    //   "left": {
    //     "type": "Identifier",
    //     "start": 0,
    //     "end": 1,
    //     "name": "b"
    //   },
    //   "right": {
    //     "type": "Literal",
    //     "start": 3,
    //     "end": 4,
    //     "value": 3,
    //     "raw": "3"
    //   }
    // }
    const {
      type,
      left,
      operator,
      right,
    } = sectionJSON;
    if (type === 'AssignmentExpression') {
      const { id } = this.sideEffects_declare_variable(left.name);
      if (operator === '=') {
        const rightXML = this.section_lang_var(right);
        return `<block type="variables_set" id="${genBlocklyUid()}">
          <field name="VAR" id="${id}">${left.name}</field>
          <value name="VALUE">${rightXML}</value>
          </block>`;
      }
      const op = ({
        '+=': '+',
        '-=': '-',
        '*=': '*',
        '/=': '/',
        '^=': '^',
      })[operator];
      if (op) {
        const rightXML = this.type_BinaryExpression(Object.assign({}, sectionJSON, {
          type: 'BinaryExpression',
          operator: op,
        }));
        return `<block type="variables_set" id="${genBlocklyUid()}">
          <field name="VAR" id="${id}">${left.name}</field>
          <value name="VALUE">${rightXML}</value>
          </block>`;
      }
    }
    return '';
  }
  type_UnaryExpression(sectionJSON, sectionType = 'block') {
    if (sectionJSON.type === 'UnaryExpression') {
      const { operator, argument: arg } = sectionJSON;
      if (operator === '!') {
        let xml = '';
        xml += `<${sectionType} type="logic_negate" id="${genBlocklyUid()}">`;
        xml += '<value name="BOOL">';
        xml += this.section_lang_var(arg);
        xml += '</value>';
        // xml += this.section_condition_to_xml(args, sectionType);
        xml += `</${sectionType}>`;
        return xml;
      } else if (operator === '-' || operator === '+') {
        // transform negative UnaryExpression to (0 - var)
        return this.type_BinaryExpression(Object.assign({}, sectionJSON, {
          type: 'BinaryExpression',
          left: { type: 'Literal', value: 0, raw: '0' },
          operator,
          right: sectionJSON.argument,
        }), sectionType);
      }
    }
    return '';
  }
  type_BinaryExpression(sectionJSON, sectionType = 'block') {
    if (sectionJSON.type === 'BinaryExpression') {
      const { left, operator, right } = sectionJSON;
      const xml = [];
      const mathOperator = ({
        '+': 'ADD',
        '-': 'MINUS',
        '*': 'MULTIPLY',
        '/': 'DIVIDE',
        '^': 'POWER',
      })[operator];
      if (mathOperator) {
        xml.push(`<block type="math_arithmetic" id="${genBlocklyUid()}">`);
        xml.push(`<field name="OP">${mathOperator}</field>`);

        xml.push('<value name="A">');
        xml.push(this.section_lang_var(left));
        xml.push('</value>');
        xml.push('<value name="B">');
        xml.push(this.section_lang_var(right));
        xml.push('</value>');

        xml.push(`</block>`);
        return xml.join('');
      }

      const op = ({
        '===': 'EQ',
        '==': 'EQ',

        '!==': 'NEQ',
        '!=': 'NEQ',

        '<': 'LT',

        '=<': 'LTE',
        '<=': 'LTE',

        '>': 'GT',

        '=>': 'GTE',
        '>=': 'GTE',
      })[operator];
      if (!op) throw new Error(`Invalid operator ${operator}`);

      xml.push(`<${sectionType} type="customized_logic_compare" id="${genBlocklyUid()}">`);
      xml.push(`<field name="OP">${op}</field>`);
      xml.push('<value name="A">');
      xml.push(this.section_lang_var(left));
      xml.push('</value>');
      xml.push('<value name="B">');
      xml.push(this.section_lang_var(right));
      xml.push('</value>');

      xml.push(`</${sectionType}>`);
      return xml.join('');
    }
    return '';
  }

  type_UpdateExpression(sectionJSON) {
    const { argument: arg, operator, type } = sectionJSON;
    if (type === 'UpdateExpression') {
      const variableInfo = this.sideEffects_declare_variable(_.get(arg, 'name'));
      let xml = '';
      xml += `<block type="math_change" id="${genBlocklyUid()}">`;
      xml += `<field name="VAR" id="${variableInfo.id}">${variableInfo.name}</field>
      <value name="DELTA">
        <shadow type="math_number" id="${genBlocklyUid()}">
          <field name="NUM">${operator === '++' ? '1' : '-1'}</field>
        </shadow>
      </value>`;
      xml += '</block>';
      return xml;
    }
    return '';
  }

  type_WhileStatement(sectionJSON, sectionType = 'block') {
    const { body, test, type } = sectionJSON;
    if (type === 'WhileStatement') {
      const bodyBody = _.get(body, 'body');
      let xml = '';
      xml += `<${sectionType} type="customized_controls_whileUntil" id="${genBlocklyUid()}">`;

      xml += '<field name="MODE">WHILE</field>';

      xml += '<value name="BOOL">';
      xml += this.section_condition_to_xml(test);
      xml += '</value>';

      xml += `<statement name="DO">`;
      xml += this.tool_xmlArray_to_xmlNext(this.typeHub(bodyBody));
      xml += `</statement>`;

      xml += `</${sectionType}>`;
      return xml;
    }
    return '';
  }

  type_BreakStatement(sectionJSON) {
    if (sectionJSON.type === 'BreakStatement') {
      return `<block type="customized_controls_flow_statements" id="${genBlocklyUid()}">
        <field name="FLOW">BREAK</field>
      </block>`;
    }
    return '';
  }
  type_ContinueStatement(sectionJSON) {
    if (sectionJSON.type === 'ContinueStatement') {
      return `<block type="customized_controls_flow_statements" id="${genBlocklyUid()}">
        <field name="FLOW">CONTINUE</field>
      </block>`;
    }
    return '';
  }
  type_ReturnStatement(sectionJSON) {
    const { type, argument: arg } = sectionJSON;
    if (type === 'ReturnStatement') {
      let xml = '';
      xml += `<block type="procedures_ifreturn" id="${genBlocklyUid()}">`;

      xml += `<mutation value="${arg ? '1' : '0'}"></mutation>`;
      xml += `<value name="CONDITION">
        <block type="logic_boolean" id="${genBlocklyUid()}">
          <field name="BOOL">TRUE</field>
        </block>
      </value>`;
      if (arg) {
        xml += `<value name="VALUE">
          ${this.section_lang_var(arg)}
        </value>`;
      }
      xml += '</block>';
      return xml;
    }
    return '';
  }

  section_LogicalExpression(sectionJSON, sectionType = 'block') {
    const {
      type,
      left,
      operator,
      right,
    } = sectionJSON;
    if (type === 'LogicalExpression') {
      let xml = '';
      xml += `<${sectionType} type="logic_operation" id="${genBlocklyUid()}">
        <field name="OP">${operator === '||' ? 'OR' : 'AND'}</field>`;
      xml += '<value name="A">';
      xml += this.section_lang_var(left);
      xml += '</value>';
      xml += '<value name="B">';
      xml += this.section_lang_var(right);
      xml += '</value>';
      xml += `</${sectionType}>`;
      return xml;
    }
    return '';
  }

  section_condition_to_xml(sectionJSON, sectionType = 'block') {
    const { type } = sectionJSON;
    if (type === 'UnaryExpression') {
      return this.type_UnaryExpression(sectionJSON, sectionType);
    } else if (type === 'BinaryExpression') {
      return this.type_BinaryExpression(sectionJSON, sectionType);
    } else if (type === 'LogicalExpression') {
      return this.section_LogicalExpression(sectionJSON, sectionType);
    } else if (type === 'Identifier') {
      return this.section_Identifier(sectionJSON, sectionType);
    } else if (type === 'Literal') {
      return this.section_Literal(sectionJSON, sectionType);
    }
    return null;
  }

  section_Literal(section, sectionType = 'block') {
    //   {
    //     "type": "Literal",
    //     "start": 3,
    //     "end": 4,
    //     "value": 3,
    //     "raw": "3"
    //   }
    const { type, value, raw } = section;
    if (type === 'Literal') {
      const typeofValue = typeof value;
      const blocklyUid = genBlocklyUid();
      if (typeofValue === 'string') {
        return `<${sectionType} type="text" id="${blocklyUid}"><field name="TEXT">${value}</field></${sectionType}>`;
      } else if (typeofValue === 'number') {
        return `<${sectionType} type="math_number" id="${blocklyUid}"><field name="NUM">${value}</field></${sectionType}>`;
      } else if (typeofValue === 'boolean') {
        const rawCapital = `${raw}`.toUpperCase();
        return `<${sectionType} type="logic_boolean" id="${blocklyUid}"><field name="BOOL">${rawCapital}</field></${sectionType}>`;
      }
    }
    return '';
  }
  section_Identifier(section/** , sectionType = 'block' */) {
    // {
    //   "type": "Identifier",
    //   "start": 31,
    //   "end": 52,
    //   "name": "ANGLE__field_dropdown"
    // }
    const { type, name } = section;
    if (type === 'Identifier') {
      const varInfo = this.sideEffects_declare_variable(name);
      // blockly_compressed.js?e98f:194 : Uncaught (in promise) TypeError: Shadow blocks cannot have variable references
      return `<block type="variables_get" id="${genBlocklyUid()}"><field name="VAR" id="${varInfo.id}">${varInfo.name}</field></block>`;
    }
    return '';
  }
  section_lang_var(sectionJSON, sectionType = 'block') {
    //   {
    //     "type": "Literal",
    //     "start": 3,
    //     "end": 4,
    //     "value": 3,
    //     "raw": "3"
    //   }
    const { type } = sectionJSON;
    if (type === 'Literal') {
      return this.section_Literal(sectionJSON, sectionType);
    } else if (type === 'Identifier') {
      return this.section_Identifier(sectionJSON, sectionType);
    } else if (type === 'BinaryExpression' || type === 'UnaryExpression') {
      return this.section_condition_to_xml(sectionJSON, sectionType);
    } else if (type === 'CallExpression') {
      return this.type_CallExpression(sectionJSON);
    } else if (type === 'LogicalExpression') {
      return this.section_LogicalExpression(sectionJSON, sectionType);
    }
    return null;
  }
  getAST() {
    return this.AST_Object;
  }
  getXML() {
    return this.XML[0];
  }
}

// export default BlocklyJavaScript2XML;


// const { parse } = require('acorn');

// const code = `
//   if (a === 1) {
//     b = 1
//   } else if (a === 2) {
//     b = 2
//   } else {
//     b = 3
//   }
// `;
function convertJavaScript2XML(jscode, definedFunctions) {
  const ext = {};
  try {
    ext.AST = parse(jscode);
    console.log(ext.AST, 'AST....');
    if (ext.AST) {
      const instance = new BlocklyJavaScript2XML(ext.AST, definedFunctions);
      return [null, instance, ext];
    }
    return [null, null, ext];
  } catch (error) {
    return [error, null, ext];
  }
}

// export { BlocklyJavaScript2XML };
export { convertJavaScript2XML };
