import _ from 'lodash';
import debug from 'debug';
import { getNS } from '_i18n_';

const _t = getNS('codeChecker.js');

const log = debug('js:codeChecker');

const PATH_ROBOT_ID = 'callee.object.arguments.1.value';
const PATH_BLOCKLY_ID_NODE = 'callee.object.arguments.0.properties';
const PATH_ARGUMENTS_0 = 'arguments.0.value';
// const PATH_ARGUMENTS_1 = 'arguments.1.value';
// const PATH_ARGUMENTS_2 = 'arguments.2.value';

const PATH_ROBOT_CHECKER_FUNCTION_R = 'callee.name';
const PATH_ROBOT_CHECKER_ROBOT_ID = 'arguments.1.value';
const PATH_ROBOT_CHECKER_BLOCKLY_ID_NODE = 'arguments.0.properties';


const checkResolve = (astNode, resolve) => {
  // log('checkResolve() %o', astNode);
  resolve();
};

const checkReject = (astNode, blocklyId, errMsg, reject) => {
  log('checkReject() %o, %o', blocklyId, errMsg);
  reject({
    blocklyId,
    error: new Error(errMsg),
  });
};


function findRobot(id, classRoomStore) {
  return classRoomStore.getRobotByIdentifier(id);
}
function findObject(id, classRoomStore) {
  // return classRoomStore.classRoomObjects.values().find(o => o.identifier === id);
  return classRoomStore.getObjectByIdentifier(id);
}
function findBlocklyId(node, path) {
  const properties = _.property(path || PATH_BLOCKLY_ID_NODE)(node);
  if (!properties) return null;
  return _.property('value.value')(properties.find(p => _.property('key.name')(p) === 'blocklyId'));
}
function findSensor(id, sensor, classRoomStore) {
  const robot = findRobot(id, classRoomStore);
  if (!robot) return null;
  return Object.values(robot.sensors).find(s => `s${s}`.toLowerCase() === sensor.toLowerCase());
}

function readSensorChecker(node, resolve, rejects, classRoomStore) {
  log('readSensorChecker() %o', node);
  const robotIdentifier = _.property(PATH_ROBOT_ID)(node);
  const fnArgs0 = _.property(PATH_ARGUMENTS_0)(node);
  const blocklyId = findBlocklyId(node);
  if (robotIdentifier >= 100) { // greater than 100 is assets
    return false;
  }
  if (!findRobot(robotIdentifier, classRoomStore)) {
    // checkReject(node, blocklyId, `Robot ${robotIdentifier} is not exist.`, rejects);
    return {
      blocklyId,
      error: `Robot ${robotIdentifier} is not exist.`,
    };
  }
  if (!findSensor(robotIdentifier, fnArgs0, classRoomStore)) {
    return {
      blocklyId,
      error: _t('First configure the sensor for this robot.'),
    };
  }
  return false;
}

function robotChecker(node, resolve, rejects, classRoomStore) {
  const funName = _.property(PATH_ROBOT_CHECKER_FUNCTION_R)(node);
  if (funName === 'R') {
    const robotId = _.property(PATH_ROBOT_CHECKER_ROBOT_ID)(node);
    if (robotId >= 100) { // greater than 100 is assets
      return false;
    }
    if (!findRobot(robotId, classRoomStore) && !findObject(robotId, classRoomStore)) {
      const blocklyId = findBlocklyId(node, PATH_ROBOT_CHECKER_BLOCKLY_ID_NODE);
      if (blocklyId) {
        return {
          blocklyId,
          error: _t('Robot {{robotId}} is not exist!', { robotId }),
        };
      }
    }
  }
  return false;
}

function sensorChecker(_sensorNumber) {
  const sensorNumber = `${_sensorNumber}`.toUpperCase();
  return (node, resolve, rejects, classRoomStore) => {
    const robotIdentifier = _.property(PATH_ROBOT_ID)(node);
    const blocklyId = findBlocklyId(node);
    if (!findSensor(robotIdentifier, sensorNumber, classRoomStore)) {
      return {
        blocklyId,
        error: _t('First configure the sensor for this robot.'),
      };
    }
    return false;
  };
}

function checkAnyRobotIsOffline(node, resolve, rejects, classRoomStore) {
  const robotIdentifier = _.property(PATH_ROBOT_ID)(node);
  if (robotIdentifier) {
    const robot = classRoomStore.getRobotByIdentifier(robotIdentifier);
    if (_.get(robot, 'status.online')) {
      return null;
    }
    return {
      blocklyId: findBlocklyId(node),
      error: _t('The Robot 🤖️ {{robotIdentifier}} is offline.', { robotIdentifier }),
    };
  }
  return null;
}

const callExpressionChecker = {
  readSensor: readSensorChecker,
  servoOpen: sensorChecker('S2'),
  servoClose: sensorChecker('S2'),
  ledAdjustBrightness: sensorChecker('S3'),
  ledStripLight: sensorChecker('S4'),
  ledStripLightOff: sensorChecker('S4'),
  ledStripMode: sensorChecker('S4'),
  tubeDisplayShow: sensorChecker('S57'),
  ledMatrixDisplayShow: sensorChecker('S58'),
  relaySwitchOff: sensorChecker('S7'),
  ledStripLightOn: sensorChecker('S4'),
  sendIRCommand: sensorChecker('S6'),
  buzzerBuzz: sensorChecker('S5'),
};

export const createCallExpressionChecker = (params) => {
  const { classRoomStore } = params;

  return (node, r, j) => {
    const fnName = _.property('callee.property.name')(node);
    const robotCheckResult = robotChecker(node, r, j, classRoomStore);
    if (robotCheckResult && robotCheckResult.error) {
      checkReject(node, robotCheckResult.blocklyId, robotCheckResult.error, j);
      return;
    }
    const checkRobotOnlineResult = checkAnyRobotIsOffline(node, r, j, classRoomStore);
    if (checkRobotOnlineResult && checkRobotOnlineResult.error) {
      checkReject(node, checkRobotOnlineResult.blocklyId, checkRobotOnlineResult.error, j);
    }
    if (typeof callExpressionChecker[fnName] === 'function') {
      const checkResult = callExpressionChecker[fnName](node, r, j, classRoomStore);
      if (checkResult && checkResult.error) {
        checkReject(node, checkResult.blocklyId, checkResult.error, j);
      } else {
        checkResolve(node, r, j);
      }
    } else {
      checkResolve(node, r, j);
    }
  };
};

export const createMemberExpressionChecker = (params) => {
  const { classRoomStore } = params;

  return (node, r, j) => {
    checkResolve(node, r, j, classRoomStore);
  };
};

