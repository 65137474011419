import { getStudentClassroomJointByBothId } from 'api/classRoom';
import { statehookify } from './hookFactory';

class StudentClassroomJointHook {
  constructor() {
    statehookify('studentClassroomJointHook', this, {});
  }
  getStudentClassroom(studentId, classroomId) {
    return this.getHookStatePath(`studentClassroomJoint.${studentId}.${classroomId}`);
  }
  loadStudentClassroom(studentId, classroomId) {
    this.setHookStateManyPaths({
      [`loadingStudentClassroomJointState.${studentId}.${classroomId}`]: 'doing',
      [`loadingStudentClassroomJointState.${studentId}.${classroomId}_error`]: '',
    });
    return getStudentClassroomJointByBothId(studentId, classroomId)
      .then((result) => {
        this.setHookStateManyPaths({
          [`loadingStudentClassroomJointState.${studentId}.${classroomId}`]: 'done',
          [`loadingStudentClassroomJointState.${studentId}.${classroomId}_error`]: '',
          [`studentClassroomJoint.${studentId}.${classroomId}`]: result.data,
        });
      })
      .catch((e) => {
        this.setHookStateManyPaths({
          [`loadingStudentClassroomJointState.${studentId}.${classroomId}`]: 'failed',
          [`loadingStudentClassroomJointState.${studentId}.${classroomId}_error`]: e.message,
        });
      });
  }
}

export default new StudentClassroomJointHook();
