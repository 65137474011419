import api from 'api';

export function createNewSpreadsheet(classroomCode, oauthCode) {
  const urlSearch = new URLSearchParams();
  urlSearch.set('oauth_code', oauthCode);
  return api.get(`/class/${classroomCode}/google_sheets/create_kaisdata_spreadsheets?${urlSearch.toString()}`);
}

export function appendData(classroomCode, oauthCode, sheetId, values) {
  const urlSearch = new URLSearchParams();
  urlSearch.set('oauth_code', oauthCode);
  urlSearch.set('spreadsheetId', sheetId);
  return api.post(`/class/${classroomCode}/google_sheets/append_data?${urlSearch.toString()}`, {
    values,
  });
}
