import classRoomStore from 'store/classRoomStore';
import debug from 'debug';

const log = debug('ui:mqtt');

/**
 * Listen the topic and execute the executor function.
 * This will called when some commend received the status update
 *
 * @function
 * @memberof Mqtt.CommandStatus#
 * @ignore
 * @param {KaisClanSchemaCommandStatus} packet - The payload in JSON structure.
 * @see {@link Mqtt.CommandStatus.schema JSON structure}
 * @todo Remove current command from queue, payload.id
 * @todo Execute the next command in queue if exist
 */
export const executor = (packet) => {
  const { payload } = packet;

  const {
    S, R: Robot, STATUS,
  } = payload;

  log('Sensors changed %O for robot "%s"', S, Robot);

  classRoomStore
    .updateRobotSensors({
      identifier: Robot,
      s: S,
      status: STATUS,
    });
};

export default executor;
