import userStore from 'store/userStore';
import notificationStatehook from 'store/notificationStatehook';
import classRoomStudentsStatehook from 'store/classRoomStudentsStatehook';
import classRoomStore from 'store/classRoomStore';
import { statehookify } from './statehook/hookFactory';

// const log = require('debug')('ui:statehook:classroomChatStatehook.js');

class ClassroomChatStatehook {
  constructor() {
    statehookify('classroomChatStatehook', this, {});
  }
  mqttSubscribersWaterfall = {}
  mqttSubscribeWaterfallMessageInClassroom(classroomCode) {
    if (this.mqttSubscribersWaterfall[classroomCode]) {
      return this.mqttSubscribersWaterfall[classroomCode];
    }
    const unwatcher = [];

    const process = (data) => {
      const { notification } = data;
      this.updateHookStatePath(`${classroomCode}.waterfall.messages`, (oldValue) => {
        oldValue = oldValue || [];
        oldValue.push(notification);
        return oldValue;
      });
    };
    if (`${userStore.currentUser.role}`.toLowerCase() === 'teacher') {
      unwatcher.push(notificationStatehook.onNotified(`notify/classroom_chat/${classroomCode}/waterfall/messages/#`, process));
      // unwatcher.push(notificationStatehook.mqttSubscribe(`notify/classroom_chat/${classroomCode}/waterfall/messages/#`));
    } else {
      const myId = userStore.currentUser._id;
      // unwatcher.push(notificationStatehook.mqttSubscribe(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_everyone`));
      // unwatcher.push(notificationStatehook.mqttSubscribe(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_user/${myId}`));
      unwatcher.push(notificationStatehook.onNotified(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_everyone`, process));
      unwatcher.push(notificationStatehook.onNotified(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_user/${myId}`, process));
    }
    // unwatcher.push(notificationStatehook.watchTopic(`notify/classroom_chat/${classroomCode}/waterfall/messages/#`, (eventType, path, newValue) => {

    // }));
    this.mqttSubscribersWaterfall[classroomCode] = () => {
      if (this.mqttSubscribersWaterfall[classroomCode]) {
        while (unwatcher.length > 0) unwatcher.pop()();
      }
      this.mqttSubscribersWaterfall[classroomCode] = null;
      delete this.mqttSubscribersWaterfall[classroomCode];
    };
    return this.mqttSubscribersWaterfall[classroomCode];
  }

  mqttUnsubscribeWaterfallMessageInClassroom(classroomCode) {
    if (this.mqttSubscribersWaterfall[classroomCode]) {
      this.mqttSubscribersWaterfall[classroomCode]();
    }
  }

  watchWaterfallMessageInClassroom(classroomCode, cb) {
    return notificationStatehook.onNotified(`notify/classroom_chat/${classroomCode}/waterfall/messages/#`, cb);
  }

  getWaterfallMessageInClassroom(classroomCode) {
    const rootTopic = `${classroomCode}.waterfall.messages`;
    // return this.get(rootTopic);
    return this.getHookStatePath(rootTopic);
  }

  sendWaterfallMessageToEveryone(classroomCode, msg) {
    const { chatDisabled } = classRoomStore.classroomModelStatehook;
    if (chatDisabled) {
      console.warn('Room chat has been disabled!');
    } else {
      notificationStatehook.notify(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_everyone`, {
        msg,
        userId: userStore.currentUser._id,
        username: userStore.currentUser.name,
        t: Date.now(),
        role: `${userStore.currentUser.role}`.toLowerCase(),
      }, {
        messageType: 'to_everyone',
      });
    }
  }

  sendWaterfallMessageToUser(classroomCode, userId, msg) {
    const studentName = classRoomStudentsStatehook.getStudentName(classroomCode, userId);
    if (!studentName) return;
    notificationStatehook.notify(`notify/classroom_chat/${classroomCode}/waterfall/messages/to_user/${userId}`, {
      msg,
      userId: userStore.currentUser._id,
      username: userStore.currentUser.name,
      to: userId,
      toUsername: studentName,
      t: Date.now(),
      role: `${userStore.currentUser.role}`.toLowerCase(),
    }, {
      messageType: 'to_user',
      toUserId: userId,
    });
  }
}

export default new ClassroomChatStatehook();
