import _ from 'lodash';
import { statehookify } from '../hookFactory';

const KEY_DOCUMENT_SIZE = 'window.document.size';
const KEY_MOUSE_POSITION = 'window.mouse.position';

const throttleAndDebounce = (execuor, time) => {
  const throttleFunc = _.throttle(execuor, time);
  const debounceFunc = _.debounce(execuor, time);
  return (e) => {
    throttleFunc(e);
    debounceFunc(e);
  };
};

class GlobalStatehook {
  _lastTimeRefreshMousePosition = 0
  constructor() {
    statehookify('globalStatehook', this, {
      http_notifications: {},
    });
    this._init();
  }
  _init() {
    window.addEventListener('load', (e) => {
      this._initWindowResize(e);
    }, false);
    document.addEventListener('mousemove', throttleAndDebounce((e) => {
      return this._refreshMousePosition(e);
    }, 0.3 * 1e3), false);
  }
  _refreshDocumentSize() {
    this.setHookStatePath(KEY_DOCUMENT_SIZE, {
      clientHeight: document.body.clientHeight,
      clientWidth: document.body.clientWidth,
    });
  }
  _initWindowResize() {
    this._refreshDocumentSize();
    window.addEventListener('resize', throttleAndDebounce((e) => {
      return this._refreshDocumentSize(e);
    }, 1 * 1e3), false);
    // window.addEventListener('resize', ((e) => {
    //   return this._refreshDocumentSize(e);
    // }), false);
  }
  _refreshMousePosition(e) {
    const now = Date.now();
    if (now - this._lastTimeRefreshMousePosition < 250) return;
    this._lastTimeRefreshMousePosition = now;
    this.setHookStatePath(KEY_MOUSE_POSITION, {
      shiftKey: e.shiftKey,
      target: e.target,
      clientX: e.clientX,
      clientY: e.clientY,
      layerX: e.layerX,
      layerY: e.layerY,
      movementX: e.movementX,
      movementY: e.movementY,
      offsetX: e.offsetX,
      offsetY: e.offsetY,
      pageX: e.pageX,
      pageY: e.pageY,
      screenX: e.screenX,
      screenY: e.screenY,
      x: e.x,
      y: e.y,
    });
  }

  watchDocumentSize(cb) {
    return this.watchHookStatePath(KEY_DOCUMENT_SIZE, cb);
  }
  get documentSize() {
    return this.getHookStatePath(KEY_DOCUMENT_SIZE) || {};
  }
  watchMousePosition(cb) {
    return this.watchHookStatePath(KEY_MOUSE_POSITION, cb);
  }
  get mousePosition() {
    return this.getHookStatePath(KEY_MOUSE_POSITION) || {};
  }

  watchNewHttpNotificationsWithNS(ns, cb) {
    return this.watchHookStatePath(`http_notifications.${ns}`, cb);
  }
  newHttpNotificationDoc(httpNotificationDoc) {
    if (!Array.isArray(httpNotificationDoc)) {
      httpNotificationDoc = [httpNotificationDoc];
    }
    httpNotificationDoc.forEach((row) => {
      const { namespace } = row;
      return this.updateHookStatePath(['http_notifications', namespace], (oldValue) => {
        return [].concat(oldValue || [], [row]);
      });
    });
  }
  emptyHttpNotificationWithNS(ns) {
    return this.deleteHookStatePath(`http_notifications.${ns}`);
  }
  get httpNotifications() {
    return this.getHookStatePath('http_notifications');
  }
  getHttpNotificationsWithNS(ns) {
    return this.getHookStatePath(['http_notifications', ns]);
  }
}
export default new GlobalStatehook();
