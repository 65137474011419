import Ajv from 'ajv';

const ajv = new Ajv();

export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/response/(.+)/(.+)',
    },
    payload: {
      type: 'object',
    },
  },
  required: ['topic', 'payload'],
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
