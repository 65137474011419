
import { parse as p } from 'acorn';
import { full } from 'acorn/dist/walk';
import _ from 'lodash';

const baseOpt = { ecmaVersion: 8 };
export function parse(jsCode, opt) {
  return p(jsCode, { ...baseOpt, ...opt });
}

function pickUp(AST, _picker, cb) {
  const picker = _picker || {};
  const results = [];
  full(AST, (node) => {
    const type = _.property('type')(node);
    if (typeof picker[type] === 'function') {
      results.push(node);
    }
    if (type === 'Program') {
      if (cb) {
        cb(results);
      }
    }
  });
}

export function walk(AST, _picker) {
  const picker = _picker || {};
  return new Promise((resolve) => {
    pickUp(AST, picker, (res) => {
      const results = [];
      const resLen = res.length;
      if (resLen === 0) {
        resolve(results);
        return;
      }
      let finishedIndex = -1;

      function fullResults(arg0, type, functionName) {
        results.push({ ...arg0, type, functionName });
        finishedIndex += 1;
        if (finishedIndex === resLen - 1) {
          return results;
        }
        return false;
      }

      res.forEach((node) => {
        const type = _.property('type')(node);
        const fnName = _.property('callee.property.name')(node);
        const fn = picker[type];
        if (typeof fn === 'function') {
          new Promise((pickerResolve, pickerRejects) => {
            setTimeout(fn, 0, node, pickerResolve, pickerRejects); // avoid block
          }).then((_res) => {
            const returnResult = fullResults(_res, type, fnName);
            if (returnResult) resolve(returnResult);
          }, (err) => {
            const returnResult = fullResults(err, type, fnName);
            if (returnResult) resolve(returnResult);
          });
        } else {
          finishedIndex += 1;
          if (finishedIndex === resLen - 1) resolve(results);
        }
      });
    });
  });
}
