import Ajv from 'ajv';

const ajv = new Ajv();

export const schema = {
  definitions: {
    data: {
      type: 'array',
      items: { type: 'number' },
      minItems: 1,
    },
  },
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/positions_update',
    },
    payload: {
      type: 'object',
      properties: {
        R: {
          $ref: '#/definitions/data',
        },
        X: {
          $ref: '#/definitions/data',
        },
        Y: {
          $ref: '#/definitions/data',
        },
        A: {
          $ref: '#/definitions/data',
        },
        L: {
          type: 'number',
        },
      },
      required: ['R', 'X', 'Y', 'A', 'L'],
    },
  },
  required: ['topic', 'payload'],
  additionalProperties: true,
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
