/* eslint-disable class-methods-use-this */
import { action, observable, computed } from 'mobx';

const log = require('debug')('lib:webStorageStore');

const KEY__SHARE_STORAGE = '###KAISCLAN_SHARE_STORAGE###';

export class WebStorageStore {
  @observable _nameSpace = observable.box('');
  @observable _localStorage = observable.map({});
  @observable _isSessionStorage = observable.box(false);
  @observable _shareStorage = observable.map({});

  @computed get nameSpace() {
    log('get nameSpace()', this._nameSpace.get());
    return this._nameSpace.get();
  }

  constructor() {
    log('constructor()', this._nameSpace.get());
    this.initStorageFromLocal();
    // this.initSessionStorage();
  }

  initStorageFromLocal() {
    log('initStorageFromLocal()', this._nameSpace.get());
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    this._localStorage.clear();
    const ns = `${this.nameSpace}`;
    const newLocalStorageMap = {};
    const newShareStorageMap = {};
    for (let i = 0, len = _storageObj.length; i < len; i += 1) {
      const keyNS = _storageObj.key(i);
      if (keyNS.startsWith(KEY__SHARE_STORAGE)) {
        const key = keyNS.replace(KEY__SHARE_STORAGE, ''); // get the share storage key without name space
        const value = _storageObj.getItem(keyNS);
        newShareStorageMap[key] = value;
      }
      if (keyNS.startsWith(ns)) {
        const key = keyNS.replace(ns, ''); // get the key without name space
        const value = _storageObj.getItem(keyNS);
        newLocalStorageMap[key] = value;
      }
    }
    if (Object.keys(newLocalStorageMap).length > 0) {
      this._localStorage.replace(newLocalStorageMap);
    }
    if (Object.keys(newShareStorageMap).length > 0) {
      this._shareStorage.replace(newShareStorageMap);
    }
  }


  @action hasShareStorageItem(key) {
    const keyNS = `${KEY__SHARE_STORAGE}${key}`;
    return (keyNS in localStorage);
  }
  @action setShareStorageItem(key, value) {
    this._shareStorage.set(key, value);
    const keyNS = `${KEY__SHARE_STORAGE}${key}`;
    return localStorage.setItem(keyNS, value);
  }

  @action getShareStorageItem(key) {
    const keyNS = `${KEY__SHARE_STORAGE}${key}`;
    const valueInLocal = localStorage.getItem(keyNS);
    this.setShareStorageItem(key, valueInLocal);
    return valueInLocal;
  }

  @action removeShareStorageItem(key) {
    const keyNS = `${KEY__SHARE_STORAGE}${key}`;
    this._shareStorage.delete(key);
    return localStorage.removeItem(keyNS);
  }

  @action clearShareStorage() {
    log('clearShareStorage()', this._nameSpace.get());
    return this._shareStorage.clear();
  }

  @action useSessionStorage(...args) {
    log('useSessionStorage()', this._nameSpace.get());
    const isUsing = !!(args.length === 0 ? true : args[0]);
    const currentState = this._isSessionStorage.get();
    if (currentState !== isUsing) { // isn't as same as the current state?
      // Step 1: copy the environment to opposide
      if (isUsing) {
        this.copyLocalStorage2SessionStorage();
      } else {
        this.copySessionStorage2LocalStorage();
      }
      // Step 2: set the new storage position
      this._isSessionStorage.set(isUsing);
      // Step 3: init the storage
      this.initStorage();
    }
    return currentState;
  }

  // local to session
  // eslint-disable-next-line
  copyLocalStorage2SessionStorage() {
    log('copyLocalStorage2SessionStorage()', this._nameSpace.get());
    for (let i = 0, len = localStorage.length; i < len; i += 1) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);
      sessionStorage.setItem(key, value);
    }
  }
  // session to local
  // eslint-disable-next-line
  copySessionStorage2LocalStorage() {
    log('copySessionStorage2LocalStorage()', this._nameSpace.get());
    for (let i = 0, len = sessionStorage.length; i < len; i += 1) {
      const key = sessionStorage.key(i);
      const value = sessionStorage.getItem(key);
      localStorage.setItem(key, value);
    }
  }

  @action setNameSpace(ns) {
    log('setNameSpace()', ns, this._nameSpace.get());
    this._nameSpace.set(ns);
  }

  @action setItem(key, val) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    this._localStorage.set(key, val);
    const keyNS = `${this.nameSpace}${key}`;
    return _storageObj.setItem(keyNS, val);
  }

  @action setItemWithNS(key, val, ns) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    if (ns === this.nameSpace) {
      return this.setItem(key, val);
    }
    const keyNS = `${ns}${key}`;
    return _storageObj.setItem(keyNS, val);
  }

  @action getItem(key) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    const keyNS = `${this.nameSpace}${key}`;
    if (Object.prototype.hasOwnProperty.call(_storageObj, keyNS)) {
      const valueInLocal = _storageObj.getItem(keyNS);
      this.setItem(key, valueInLocal);
      return valueInLocal;
    }
    return undefined;
  }

  @action getItemWithNS(key, ns) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    if (ns === this.nameSpace) {
      return this.getItem(key);
    }
    const keyNS = `${ns}${key}`;
    return _storageObj.getItem(keyNS);
  }

  @action removeItem(key) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    this._localStorage.delete(key);
    const keyNS = `${this.nameSpace}${key}`;
    return _storageObj.removeItem(keyNS);
  }

  @action removeItemWithNS(key, ns) {
    const _storageObj = this._isSessionStorage.get() ? sessionStorage : localStorage;
    if (ns === this.nameSpace) {
      return this.removeItem(key);
    }
    const keyNS = `${ns}${key}`;
    return _storageObj.removeItem(keyNS);
  }
  // eslint-disable-next-line class-methods-use-this
  @action setSessionItem(k, v) {
    return sessionStorage.setItem(k, v);
  }
  // eslint-disable-next-line class-methods-use-this
  @action getSessionItem(k) {
    return sessionStorage.getItem(k);
  }
  // eslint-disable-next-line class-methods-use-this
  @action removeSessionItem(k) {
    return sessionStorage.removeItem(k);
  }
}

const webStorage = new WebStorageStore();
export default webStorage;
