import mqttStore from 'store/mqttStore';
import { escapeRegExp } from 'utils';
import { statehookify } from './statehook/hookFactory';

export default statehookify('notificationStatehook', {
  mqttSubscribeUnwatchers: {},
  mqttSubscribe(subTopic) {
    subTopic = `${subTopic}`;
    // check if already subscribed
    if ('function' === typeof this.mqttSubscribeUnwatchers[subTopic]) {
      return this.mqttSubscribeUnwatchers[subTopic];
    }
    const _processor = (inTopic, payload) => {
      const payloadString = payload.toString();
      // eslint-disable-next-line no-undef-init
      let payloadJSON = undefined;
      try {
        payloadJSON = JSON.parse(payloadString);
      } catch (error) {
        //
        console.error(error, inTopic, payloadString, 'Error occured when notificationStatehook parse payload to a JSON');
      }
      if (payloadJSON === undefined) {
        payloadJSON = payloadString;
      }
      const inTopicPath = `${inTopic}`.split('/');
      this.setHookStatePath(inTopicPath, payloadJSON);
    };
    const unwatcher = mqttStore.onTopic(subTopic, _processor);
    this.mqttSubscribeUnwatchers[subTopic] = () => {
      if ('function' === typeof this.mqttSubscribeUnwatchers[subTopic]) {
        unwatcher();
      }
      delete this.mqttSubscribeUnwatchers[subTopic];
    };
    return this.mqttSubscribeUnwatchers[subTopic];
  },
  mqttUnsubscribe(subTopic) {
    if ('function' === typeof this.mqttSubscribeUnwatchers[subTopic]) {
      return this.mqttSubscribeUnwatchers[subTopic]();
    }
    return null;
  },
  notify(subTopic, data, extra) {
    subTopic = `${subTopic}`;
    extra = extra || {};
    return mqttStore.publish(subTopic, JSON.stringify({
      data,
      ...extra,
      connUUID: mqttStore.connUUID,
    }));
  },
  onNotified(subTopic, cb) {
    if ('function' !== typeof cb) {
      throw new Error('The callback function must be provided!');
    }
    const _processor = (inTopic, payload) => {
      const payloadString = payload.toString ? payload.toString() : `${payload}`;
      // eslint-disable-next-line no-undef-init
      let payloadJSON = undefined;
      try {
        payloadJSON = JSON.parse(payloadString);
      } catch (error) {
        //
        console.error(error, inTopic, payloadString, 'Error occured when notificationStatehook parse payload to a JSON');
      }
      if (payloadJSON === undefined) {
        payloadJSON = payloadString;
      }
      cb({
        topic: inTopic,
        payload: payloadString,
        notification: payloadJSON,
      });
    };
    return mqttStore.onTopic(subTopic, _processor);
  },
  watchTopic(subTopic, cb) {
    subTopic = `${subTopic}`;
    if (subTopic.indexOf('+') > -1 || subTopic.indexOf('#') > -1) {
      subTopic = escapeRegExp(subTopic);
      // eslint-disable-next-line
      subTopic = new RegExp('^' + subTopic.replace(/\\\+/g, '[^/]+').replace(/#/g, '.*') + '$');
      return this.watchHookState((eventType, path, value) => {
        if (subTopic.test(path.join('/'))) {
          cb(eventType, path, value);
        }
      });
    } else {
      return this.watchHookStatePath(subTopic.split('/'), cb);
    }
  },
}, {});
