import classRoomStore from 'store/classRoomStore';
import { tryParseInt } from 'utils';
// import { parking } from '../commands';

export const executor = (packet) => {
  // if (userStore.isStudent) return;
  const { payload } = packet;
  const {
    identifier, sensor,
  } = payload;
  const sensorData = sensor.reduce((acc, s, index) => {
    acc[`sensor${index + 1}`] = tryParseInt(s);
    return acc;
  }, {});
  const robot = classRoomStore.getRobotByIdentifier(identifier);
  if (robot) {
    classRoomStore.updateRobotInfo(robot._id, { sensors: sensorData });
  }
};

export default executor;
