import _ from 'lodash';
import classRoomStore from 'store/classRoomStore';
import { sensorSpecs } from '../../../../sensor_specs';

function robotFindSensor(robotId, sensorId) {
  const robot = classRoomStore.getRobotByIdentifier(robotId);
  if (!robot) {
    return false;
  }
  return Object.values(robot.sensors).find(s => `S${s}`.toLowerCase() === `${sensorId}`.toLowerCase());
}

export const setSensorMissingWarningText = (block, robotId, sensorId) => {
  const sensorName = sensorSpecs[sensorId] ? sensorSpecs[sensorId].name || `#${sensorId}` : `#${sensorId}`;
  const errorMessage = `Please configure the robot sensor "${sensorName}" to enable this blockly.`;
  // block.setWarningText(errorMessage);
  // block.setColour(COLOR_WARNING);
  classRoomStore.addBlockError({
    blocklyId: block.id,
    error: new Error(errorMessage),
  });
};

export const setRobotOfflineWarningText = (block, robotId) => {
  let errorMessage = `The Robot 🤖️#${robotId} is offline.`;
  if (`${robotId}` === '0') {
    errorMessage = 'Please add robots for this classroom';
  }
  // block.setWarningText(errorMessage);
  // block.setColour(COLOR_WARNING);
  classRoomStore.addBlockError({
    blocklyId: block.id,
    error: new Error(errorMessage),
  });
};

export const isSensorOnRobot = (robotId, sensorId) => robotFindSensor(robotId, sensorId);

export const checkSensorOnRobot = (block, robotId, sensorId) => {
  if (!isSensorOnRobot(robotId, sensorId)) {
    setSensorMissingWarningText(block, robotId, sensorId);
    return false;
  }
  return true;
};

export const checkRobotOffine = (block, robotId) => {
  const robot = classRoomStore.getRobotByIdentifier(robotId);
  if (!_.get(robot, 'status.online')) {
    setRobotOfflineWarningText(block, robotId);
    return false;
  }
  return true;
};

export const cleanBlockWarning = (block) => {
  classRoomStore.cleanBlockErrorsWarning(block.id);
};

export const cleanAllBlockWarning = () => {
  classRoomStore.cleanBlockErrorsWarning();
};

// robot exist check
export const setRobotNoExistionWarningText = (block, robotId) => {
  let errorMessage = `The Robot 🤖️#${robotId} does not exist.`;
  if (`${robotId}` === '0') {
    errorMessage = 'Please add robots for this classroom';
  }
  classRoomStore.addBlockError({
    blocklyId: block.id,
    error: new Error(errorMessage),
  });
};

export const checkRobotOrObjectExistion = (block, robotId) => {
  const robot = classRoomStore.getRobotByIdentifier(robotId);
  if (robot) return true;
  const objectBox = classRoomStore.getObjectByIdentifier(robotId);
  if (objectBox) return true;
  setRobotNoExistionWarningText(block, robotId);
  return false;
};

export const addErrorForNoClassroomVariable = (block, errorText) => {
  let error = null;
  if (errorText) {
    if (errorText instanceof Error) {
      error = errorText;
    } else {
      error = new Error(errorText);
    }
  } else {
    error = new Error('No variable in the classroom! Please define one.');
  }
  classRoomStore.addBlockError({
    blocklyId: block.id,
    error,
  });
};
