import api from 'api';

export const getAll = () => api.get('/pathfinding');

export const create = data => api.post('/pathfinding', { ...data });

export const update = (_id, data) => api.patch(`/pathfinding/${_id}`, { ...data });

export const get = _id => api.get(`/pathfinding/${_id}`);

export const getMapDefaultPathfinding = mapId => api.get(`/pathfinding/map/${mapId}`);

export const setMapDefaultPathfinding = (mapId, pathfindingId) => api.post(`/pathfinding/map/${mapId}`, { pathfindingId });

export const getAllMapDefautPathfinding = () => api.get('pathfinding/map');
