import Ajv from 'ajv';

const ajv = new Ajv();

/**
 * Define the schema for the packet used by {@link validator}.
 *
 * ### The robot must publish with follow payload.
 *
 * **Payload definition**
 *
 * ```JSON
 * { "id": string, "finished": boolean }
 * ```
 *
 * **Payload description**
 *
 * * **id** - *Command id response*
 * * **finished** - *true if the command already finish the execution*
 *
 * @listens subscribe:to/classroom/{CLASS_ROOM_CODE}/command_status
 * @constant
 * @typedef {{ id: string, finished: boolean }} KaisClanSchemaCommandStatus
 * @name schema
 * @memberof Mqtt.CommandStatus
 * @example
 * { "id": "ABCASDSIHOIO", "finished": true }
 */
export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/camera_status',
    },
    payload: {
      type: 'object',
      properties: {
        ws_server: { type: 'string' },
        app_status: { type: 'number' },
      },
      required: ['ws_server', 'app_status'],
    },
  },
  required: ['topic', 'payload'],
};

/**
 * Function to validate packet object using {@link schema} definition.
 *
 * @function match
 * @memberof Mqtt.CommandStatus
 * @listens subscribe:to/classroom/{CLASS_ROOM_CODE}/command_status
 * @see {@link Mqtt.CommandStatus.schema Packet Schema}
 * @returns {boolean} - True if the {@link Mqtt.CommandStatus.executor} function should be execute.
 */
const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
