import { nanoid } from 'nanoid';
import { genStudentName } from 'utils';
import {
  addStudent,
  associateStudentToRobotForClassRoom,
  removeStudentFromClassRoom,
  fetchClassRoomStudents,
} from 'api/classRoom';
import notificationStatehook from './notificationStatehook';
import { statehookify } from './statehook/hookFactory';

class ClassRoomStudentsStatehook {
  _unwatcherList = [];
  _unwatchOnlineDetectiveList = [];

  constructor() {
    statehookify('classRoomStudentsStatehook', this, {});
    this.tick();
  }

  getHowManyChangesNeedToBeSaved(classroomCode) {
    return this.getHookStatePath([classroomCode, 'howManyChangesNeedToBeSaved']) || 0;
  }

  increaseHowManyChangesNeedToBeSaved(classroomCode, number) {
    this.updateHookStatePath([classroomCode, 'howManyChangesNeedToBeSaved'], (oldValue) => {
      oldValue = oldValue || 0;
      return oldValue + (number || 1);
    });
  }

  resetHowManyChangesNeedToBeSaved(classroomCode) {
    this.updateHookStatePath([classroomCode, 'howManyChangesNeedToBeSaved'], () => {
      return 0;
    });
  }

  watchHowManyChangesNeedToBeSaved(classroomCode, cb) {
    return this.watchHookStatePathAfter([classroomCode, 'howManyChangesNeedToBeSaved'], cb);
  }

  async tick() {
    if (parseInt(Date.now() / 1e3, 10) % 1 === 0) this.checkAllClassRoomStudentOnline();
    setTimeout(this.tick.bind(this), 1 * 1e3);
  }
  checkAllClassRoomStudentOnline() {
    Object.keys(this.getHookState() || {})
      .forEach((classroomCode) => {
        this.checkStudentOnline(classroomCode);
      });
  }
  checkStudentOnline(classroomCode) {
    Object.keys(this.getHookStatePath([classroomCode, 'students']) || {})
      .forEach((studentId) => {
        const onlineState = this.getOnlineState(classroomCode, studentId);
        const path = [classroomCode, 'students', studentId, 'status', 'online'];
        if (onlineState !== this.getHookStatePath(path)) {
          this.setHookStatePath(path, onlineState);
        }
      });
  }

  unwatchAll() {
    while (this._unwatcherList.length) this._unwatcherList.pop()();
    this.unwatchAllOnlineDetective();
  }
  unwatchAllOnlineDetective() {
    while (this._unwatchOnlineDetectiveList.length) this._unwatchOnlineDetectiveList.pop()();
  }

  update(path, cb) {
    return this.updateHookStatePath(path, cb);
  }

  setSaving(classroomCode) {
    return this.setHookStatePath([classroomCode, 'student_saving_state'], { state: 'doing' });
  }
  isSaving(classroomCode) {
    return 'doing' === this.getSavingState(classroomCode);
  }

  setSaveSuccessfully(classroomCode) {
    return this.setHookStatePath([classroomCode, 'student_saving_state'], { state: 'success' });
  }
  isSaveSuccessfully(classroomCode) {
    return 'success' === this.getSavingState(classroomCode);
  }

  setSaveFailed(classroomCode, error) {
    return this.setHookStatePath([classroomCode, 'student_saving_state'], { state: 'failed', error });
  }
  getSaveFailedError(classroomCode) {
    return this.getHookStatePath([classroomCode, 'student_saving_state', 'error']);
  }
  isSaveFailed(classroomCode) {
    if ('failed' === this.getSavingState(classroomCode)) {
      return this.getSaveFailedError(classroomCode);
    }
    return null;
  }

  setSavePending(classroomCode) {
    return this.setHookStatePath([classroomCode, 'student_saving_state'], { state: 'pending' });
  }
  isSavePending(classroomCode) {
    return 'pending' === this.getSavingState(classroomCode);
  }

  getSavingState(classroomCode) {
    return this.getHookStatePath([classroomCode, 'student_saving_state', 'state']);
  }

  watchSavingState(classroomCode, cb) {
    return this.watchHookStatePath([classroomCode, 'student_saving_state'], cb);
  }

  async refresh(classroomCode) {
    this.setSaving(classroomCode);
    const res = await fetchClassRoomStudents(classroomCode);
    this.updateHookStatePath([classroomCode, 'students'], (oldValue) => {
      const studentsJSON = res.data;
      const newValue = Object.values(studentsJSON).reduce((acc, s) => {
        const robotId = s.robots && s.robots.length > 0 ? s.robots[0]._id : undefined;
        acc[s.student._id] = {
          ...s.student,
          studentClassRoomId: s._id,
          robotId,
          classRoomCode: s.classRoom,
          checked: false,
          lastPingAt: undefined,
          robots: s.robots,
          status: {
            online: false,
            lastMQTTPing: null,
          },
          isNew: false,
          _checked: false,
        };
        return acc;
      }, {});
      Object.values(oldValue || {})
        .filter((oldStu) => {
          return oldStu.isNew;
        })
        .forEach((oldStu) => {
          newValue[oldStu._id] = oldStu;
        });
      return newValue;
    });
    this.setSaveSuccessfully(classroomCode);
    return res;
  }

  addStudent(classroomCode, _studentInfo) {
    const studentInfo = Object.assign({}, {
      _id: nanoid(),
      name: genStudentName(),
      _checked: false,
      status: {
        online: false,
        lastMQTTPing: null,
      },
      isNew: true,
    }, _studentInfo || {});
    studentInfo.name = studentInfo.name.replace(/^\s+|\s+$/g, '').replace(/[^a-zA-Z0-9_]+/g, '_');
    this.setHookStatePath([classroomCode, 'students', studentInfo._id], studentInfo);
    return studentInfo;
  }
  getStudents(classroomCode) {
    return this.getHookStatePath([classroomCode, 'students']);
  }
  watchStudents(classroomCode, cb) {
    return this.watchHookStatePathAfterOrSame([classroomCode, 'students'], cb);
  }

  getStudent(classroomCode, studentId) {
    return this.getHookStatePath([classroomCode, 'students', studentId]);
  }

  save(classroomCode) {
    const students = Object.values(this.getStudents(classroomCode)).map(student => ({
      _id: student._id,
      username: student.name,
      name: student.name.replace(/^\s+|\s+$/g, '').replace(/[^a-zA-Z0-9_]+/g, '_'),
      robotId: student.robotId,
      isNew: student.isNew,
    }));
    const newStudents = students.filter(s => s.isNew && s.name && s.name !== '');
    const updateStudents = students.filter(s => s._id && !s.isNew);
    return Promise.all([
      (newStudents.length <= 0 ? [] : addStudent(classroomCode, newStudents)
        .then(() => {
          newStudents.forEach((s) => {
            this.deleteHookStatePath([classroomCode, 'students', s._id]);
          });
        })),
      ...updateStudents
        .map(s => associateStudentToRobotForClassRoom(classroomCode, s._id, s.name, s.robotId)),
    ]).then(() => {
      return this.refetch(classroomCode)
        .then((newClassroomStudentInfoRes) => {
          // notification
          const { data: newClassroomStudentInfo } = newClassroomStudentInfoRes;
          notificationStatehook.notify(`notify/classroom/${classroomCode}/student_event/update`, {
            students: Array.from(newClassroomStudentInfo).map((curr) => {
              return {
                studentName: curr.student.name,
                robotQRNumber: parseInt((curr.robots && curr.robots.length > 0) ? curr.robots[0].identifier : 0, 10),
              };
            }),
          }, {
            messageType: 'update',
          });
          // notification end
          return newClassroomStudentInfo;
        });
    });
  }
  async saveRemoving(classroomCode) {
    // await this.save(classroomCode);
    const removeStudent = Object.values(this.getStudents(classroomCode) || {})
      .filter(stu => !!stu._checked);
    return Promise.all(removeStudent.map((student) => {
      const { studentClassRoomId } = student;
      this.deleteHookStatePath([classroomCode, 'students', student._id]);
      if (!studentClassRoomId) return true;
      return removeStudentFromClassRoom(classroomCode, studentClassRoomId);
    }))
      .then(() => {
        return this.refetch(classroomCode);
      });
  }

  async refetch(classroomCode) {
    return this.refresh(classroomCode);
  }

  setAllStudentCheckedState(classroomCode, checked) {
    const allStudents = this.getHookStatePath([classroomCode, 'students']) || {};
    this.setHookStatePath([classroomCode, 'students'], Object.entries(allStudents).reduce((acc, [_id, oneStudent]) => {
      oneStudent._checked = !!checked;
      return Object.assign(acc, { [_id]: oneStudent });
      // this.setHookStatePath([classroomCode, 'students', oneStudent._id, '_checked'], !!checked);
    }, {}));
  }
  setStudentCheckedState(classroomCode, studentId, checked) {
    return this.setHookStatePath([classroomCode, 'students', studentId, '_checked'], !!checked);
  }
  getStudentCheckedState(classroomCode, studentId) {
    return this.getHookStatePath([classroomCode, 'students', studentId, '_checked']);
  }
  getCheckedStudents(classroomCode) {
    return Object.entries(this.getStudents(classroomCode) || {})
      .reduce((acc, [studentId, studentInfo]) => {
        if (this.getStudentCheckedState(classroomCode, studentId)) {
          acc[studentId] = studentInfo;
        }
        return acc;
      }, {});
  }
  watchStudentsCheckedState(classroomCode, cb) {
    return this.watchHookStatePath([classroomCode, 'students', /.*/, '_checked'], cb);
  }

  setStudentName(classroomCode, studentId, name) {
    name = name.replace(/^\s+|\s+$/g, '').replace(/[^a-zA-Z0-9_]+/g, '_');
    return this.setHookStatePath([classroomCode, 'students', studentId, 'name'], name);
  }
  getStudentName(classroomCode, studentId) {
    return this.getHookStatePath([classroomCode, 'students', studentId, 'name']);
  }
  watchStudentName(classroomCode, studentId, cb) {
    return this.watchHookStatePathAfter([classroomCode, 'students', studentId, 'name'], cb);
  }

  setStudentRobotMac(classroomCode, studentId, robotId) {
    if (-1 === this.getAllOccupiedRobots(classroomCode).indexOf(robotId)) {
      return this.setHookStatePath([classroomCode, 'students', studentId, 'robotId'], robotId);
    }
    return null;
  }
  getStudentRobotMac(classroomCode, studentId) {
    return this.getHookStatePath([classroomCode, 'students', studentId, 'robotId']);
  }
  watchStudentRobot(classroomCode, studentId, cb) {
    return this.watchHookStatePathAfter([classroomCode, 'students', studentId, 'robotId'], cb);
  }
  findStudentByTheirRobotMacAddress(classroomCode, robotId) {
    if (!robotId) return undefined;
    const allStudents = Object.values(this.getStudents(classroomCode) || {});
    return allStudents.find(stu => `${stu.robotId}` === `${robotId}`);
  }

  setLastMQTTPingTime(classroomCode, studentId, date) {
    if (this.hasHookStatePath([classroomCode, 'students', studentId])) {
      if (!(date instanceof Date)) date = new Date(date);
      return this.setHookStatePath([classroomCode, 'students', studentId, 'status', 'lastMQTTPing'], date || new Date());
    }
    return null;
  }
  getLastMQTTPingTime(classroomCode, studentId) {
    return this.getHookStatePath([classroomCode, 'students', studentId, 'status', 'lastMQTTPing']);
  }
  getOnlineState(classroomCode, studentId) {
    const lastPing = this.getHookStatePath([classroomCode, 'students', studentId, 'status', 'lastMQTTPing']);
    if (lastPing) {
      return Date.now() - lastPing.getTime() < (4 * 1e3);
    }
    return false;
  }
  watchOnlineState(classroomCode, studentId, cb) {
    return this.watchHookStatePath([classroomCode, 'students', studentId, 'status', 'lastMQTTPing'], (eventType, path) => {
      const receiveStudentId = path[2];
      const lastPing = this.getHookStatePath([classroomCode, 'students', receiveStudentId, 'status', 'lastMQTTPing']);
      const lastOnline = this.getHookStatePath([classroomCode, 'students', receiveStudentId, 'status', 'online']);
      if (lastPing) {
        const currentOnlineState = (Date.now() - lastPing.getTime() < (4 * 1e3));
        if (currentOnlineState !== lastOnline) {
          this.getHookStatePath([classroomCode, 'students', receiveStudentId, 'status', 'online'], currentOnlineState);
          cb(classroomCode, receiveStudentId, currentOnlineState);
        }
      }
    });
  }

  isNew(classroomCode, studentId) {
    return !!this.getHookStatePath([classroomCode, 'students', studentId, 'isNew']);
  }

  getAllOccupiedRobots(classroomCode) {
    return Object.values(this.getHookStatePath([classroomCode, 'students']) || {}).reduce((acc, stu) => {
      if (stu.robotId && stu.robotId !== 'none') {
        acc.push(stu.robotId);
      }
      return acc;
    }, []);
  }
}

export default new ClassRoomStudentsStatehook();
