import React, { Component } from 'react';
import PropTypes from 'prop-types';
import kaiInASpaceship from '../../assets/images/kai-in-a-spaceship-277x173.png';

class Loader extends Component {
  state = {
    isDisplayEsterEgg: true,
  }
  componentWillMount() {
    const isDisplayEsterEgg = Math.floor(Math.random() * 100) === 0;
    this.setState({ ...this.state, isDisplayEsterEgg });
  }
  componentDidMount() {
    // start this animation if the loading is slow
    const startTimeoutHandle = setTimeout(() => {
      this.animationStart();
    }, 0.5 * 1e3);
    this.stopTimeout = () => {
      clearTimeout(startTimeoutHandle);
    };
  }

  componentWillUnmount() {
    if (typeof this.stopAnimation === 'function') this.stopAnimation();
    if (typeof this.stopTimeout === 'function') this.stopTimeout();
  }

  animationStart() {
    const { isDisplayEsterEgg } = this.state;
    const duration = isDisplayEsterEgg ? 0.3 * 1e3 : 1 * 1e3;
    const animation = () => {
      $(this.imgEle.current)
        .transition({
          animation: 'jiggle',
          silent: true,
          duration: `${duration}ms`,
        })
        .transition({
          animation: 'fly left',
          silent: true,
          duration: `${duration}ms`,
        })
        .transition({
          animation: 'fly right',
          silent: true,
          duration: `${duration}ms`,
        });
    };
    const intervalHandle = setInterval(animation, duration * 3);
    this.stopAnimation = () => clearInterval(intervalHandle);
    animation();
  }

  imgEle = React.createRef();
  render() {
    const { children } = this.props;
    const { isDisplayEsterEgg } = this.state;
    return (
      <div className="ui active inverted dimmer">
        <img ref={this.imgEle} src={kaiInASpaceship} alt="Kai loves you..." width="200" />
        {isDisplayEsterEgg && <h3>Crazy robot is Loading...</h3>}
        {!isDisplayEsterEgg && children}
        {/* <div className="ui indeterminate text loader">{children}</div> */}
      </div>
    );
  }
}

Loader.displayName = 'Loader';

Loader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
};

Loader.defaultProps = {
  children: 'Loading',
};

export default Loader;
