/* eslint-disable no-use-before-define */
const hasOwnPropertyCall = (caller, arg1) => {
  return Object.prototype.hasOwnProperty.call(caller, arg1);
};

function getDropdownTextValueObject(dropdownContent) {
  let returnVariable = null;
  try {
    returnVariable = JSON.parse(dropdownContent);
    if (!checkIfDropdownTextValueObject(returnVariable)) return null;
  } catch (error) {
    // console.reportError(error, dropdownContent);
    returnVariable = null;
  }
  return returnVariable;
}
export { getDropdownTextValueObject };

function getDropdownValue(dropdownContent) {
  const returnVariable = getDropdownTextValueObject(dropdownContent);
  if (returnVariable) {
    return returnVariable.value;
  }
  return dropdownContent;
}
export { getDropdownValue };

function getDropdownText(dropdownContent) {
  const returnVariable = getDropdownTextValueObject(dropdownContent);
  if (returnVariable) {
    return returnVariable.text;
  }
  return dropdownContent;
}
export { getDropdownText };

function checkIfDropdownTextValueObject(textValueObj) {
  return hasOwnPropertyCall(textValueObj, 'text') && hasOwnPropertyCall(textValueObj, 'value');
}
export { checkIfDropdownTextValueObject };
