import { getNamedWorkById } from 'api/classRoom';
import { statehookify } from './hookFactory';

class ClassroomWorkHook {
  constructor() {
    statehookify('classroomWorkHook', this, {});
  }
  getWork(workId) {
    return this.getHookStatePath(`work.${workId}`);
  }
  loadOneWork(workId) {
    this.setHookStateManyPaths({
      [`loadOneWorkState.${workId}`]: 'doing',
      [`loadOneWorkState.${workId}_error`]: '',
    });
    return getNamedWorkById(workId)
      .then((result) => {
        this.setHookStateManyPaths({
          [`loadOneWorkState.${workId}`]: 'done',
          [`loadOneWorkState.${workId}_error`]: '',
          [`work.${workId}`]: result.data,
        });
      })
      .catch((e) => {
        this.setHookStateManyPaths({
          [`loadOneWorkState.${workId}`]: 'failed',
          [`loadOneWorkState.${workId}_error`]: e.message,
        });
      });
  }
}

export default new ClassroomWorkHook();
