/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import { getNS } from '_i18n_';
import { action, observable, computed, runInAction } from 'mobx';
import { autobind } from 'core-decorators';
import { debounce } from 'debounce-decorator';
// import Raven from 'raven-js';
import QRCode from 'qrcode';
import {
  byId,
  saveWork,
  updateNamedWork,
  addGlobalVariable,
  markMeEntered,
  setCurrentOperationProject,
  addRobot,
  removeRobot,
  updateSSID,
  disableChat,
} from 'api/classRoom';
import { update as updateProject } from 'api/project';
import debug from 'debug';
import beautify from 'js-beautify';
import _ from 'lodash';
import { statusNotify } from 'utils/kaiAlert';
import { popupError, dehydrateError } from 'utils/error';
import Blockly from 'workspaces/basic';
import projectHook from 'store/statehook/projectHook';
import { globalStatehook } from 'store/statehook/globalStatehook';
import classroomWorkHook from 'store/statehook/classroomWorkHook';
import accountStateStoreHook from 'store/statehook/accountStateStoreHook';
import classroomModelStatehook from 'store/classroomModelStatehook';

import { toolboxFor } from 'workspaces/basic/options';
import ProjectStore from 'store/ProjectStore';
import userStore from 'store/userStore';
import webStorageStore from 'store/webStorageStore';
import blocklyStore from 'store/blocklyStore';
import mqttStore from 'store/mqttStore';
import classroomChatStatehook from 'store/classroomChatStatehook';
import ClassRoomModel from 'store/models/ClassRoomModel';
import threeDModelStatehook from 'store/threeDModelStatehook';
import threeDModelAllocationStatehook from 'store/threeDModelAllocationStatehook';
import {
  jsCodeParser,
  // jsEnum,
  tryParseInt,
} from 'utils';
import { saveRobotSensorConfiguration } from 'api/robot';
import { statehookify } from './statehook/hookFactory';
import mqttProcessor from '../mqttProcessor';
import { sensorSpecs } from '../routes/ClassRoom/sensor_specs';
import mapSpecs from '../routes/ClassRoom/map_specs';
import { createCallExpressionChecker, createMemberExpressionChecker } from '../routes/ClassRoom/codeChecker';
import blocklyTools from '../routes/ClassRoom/utils';
import uiStore from './uiStore';

const _t = getNS('store/classRoomStore.js');

const log = debug('ui:classRoomStore');

const SENSORS_STATUS_UPDATE_TIMEOUT = 15 * 1e3;
const CAMERA_STATUS_UPDATE_TIMEOUT = 15 * 1e3;
const ROBOT_STATUS_UPDATE_TIMEOUT = 20 * 1e3;
const ROBOT_POSITION_UPDATE_TIMEOUT = 2.0 * 1e3;
const ROBOT_POSITION_SET_TO_NONE_TIMEOUT = 5 * 1e3;
const OBJECT_STATUS_UPDATE_TIMEOUT = 10 * 1e3;
const STATUS_CHECK_INTERVAL = 1 * 1e3;
const MAX_SENSOR_DATA_HISTORY = 0.5 * 1e3;
const MAX_BATTERY_HISTORY = 0.5 * 1e3;
const MAX_POSITION_HISTORY = 0.5 * 1e3;
const DEFAULT_XML = '<xml><block deletable="false" type="runProgram" movable="true" x="220" y="20" /></xml>';

const STATEHOOK_CONSOLE_PATH = 'consoleLog';

function initSensorStatus() {
  const status = {
    updatedAt: 0,
  };
  // eslint-disable-next-line no-return-assign
  Object.keys(sensorSpecs).forEach(sensorId => status[sensorId] = { status: 0, online: false });
  return status;
}

function initRobot() {
  return {
    /* ---- instant value ----- */
    x: null,
    y: null,
    a: null,
    s: {}, // instant
    b: 0, // battery
    imOwner: false,
    /* ----- history data ---------*/
    sensorsData: [], // history
    positionsData: [], // history
    batteryData: [],
    /* ------- status ----------*/
    status: {
      status: 2, online: false, updatedAt: 0,
    },
    outOfCamera: true,
    sensorsStatus: initSensorStatus(),
    /* ---- config ---------*/
    sensors: {
      sensor1: '1',
      sensor2: '1',
      sensor3: '2',
      sensor4: '13',
    },
  };
}

/**
 * Define Class Room Store
 */
export class ClassRoomStore {
  classroomModelStatehook = classroomModelStatehook;
  @observable classRoomModel = ClassRoomModel.create();
  @observable blocklyCode = {};
  @observable classRoomCode = '';
  @observable classRoomSSID = '';
  @observable classRoomName = '';
  @observable classRoomCreatedBy = '';
  @observable projectStore = undefined;
  @observable classRoomStudents = [];
  @observable classRoomRobots = observable.map({});
  @observable classRoomObjects = observable.map({});
  @observable workspace = undefined;
  @observable highlightedRobot = undefined;
  @observable highlightedObjectId = undefined;

  @observable workSpaceIsRunningBox = observable.box(false);
  @observable workSpaceRunningStatusBox = observable.box('STOPPED');

  @observable jsCodeParseErrors = observable.map({});
  @observable jsCodeCheckerState = undefined;
  globalVariables = {};
  // jsCodeCheckerStateEnum =
  //  jsEnum('unknown', 'checking', 'cleaning', 'succeeded', 'failed', 'flashing');
  jsCodeCheckerStateEnum = {
    unknown: 'unknown',
    checking: 'checking',
    cleaning: 'cleaning',
    succeeded: 'succeeded',
    failed: 'failed',
    flashing: 'flashing',
  };
  @observable blockErrorsFlashingState = undefined;
  // blockErrorsFlashingStateEnum = jsEnum('flashing', 'highlight', 'normal');
  blockErrorsFlashingStateEnum = {
    flashing: 'flashing',
    highlight: 'highlight',
    normal: 'normal',
  };
  @observable runtimeVariables = observable.map({
    mapMark: observable.map({}),
  });

  sandboxWorker = null;

  // store the state of registerRobotSensors function
  // registerRobotSensorsStateEnums =
  //   jsEnum('pending', 'sent', 'received', 'verificationfailed', 'completed', 'timeout');
  registerRobotSensorsStateEnums = {
    pending: 'pending',
    sent: 'sent',
    received: 'received',
    verificationfailed: 'verificationfailed',
    completed: 'completed',
    timeout: 'timeout',
  };
  @observable registerRobotSensorsState = observable.map({});

  @observable selectedBlockInfo =
    observable({
      blockId: null,
      blockType: null,
      blocklyElement: null,
      clickTimes: 0,
    });

  @observable blockErrors = observable.map({});

  @observable jsCodeOfSetupBlock = '';
  @observable jsCodeOfLoopBlock = '';
  @observable jsCodeOfEventsBlock = '';

  offTopicList = [];
  qrcodeDataURL = null;

  constructor() {
    statehookify('classroomStore', this, {
      classroomDoc: {},
    });

    this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.unknown;
    this.blockErrorsFlashingState = this.blockErrorsFlashingStateEnum.normal;
    log('ClassRoomStore constructor()');
    this.startStatusChecker();
  }

  @computed get mapId() {
    if (this.classroomModelStatehook._id) { // dynamic mapId from carme
      const mapId = parseInt(this.classroomModelStatehook.mapId, 10) || 0;
      if (!mapSpecs[mapId]) return 0;
      return mapId;
    } else if (this.projectSelected) {
      const { parentProjectModel } = this.projectSelected;
      return parentProjectModel ? parentProjectModel.mapId || 0 : 0;
    } else if (this.workSelected) {
      return this.workSelected.mapId;
    }
    return 0;
  }

  get originalJavaScriptCode() {
    return this.blocklyCode.JavaScript || '';
  }

  @computed get formattedJavaScriptCode() {
    if (!this.workspace) {
      return '';
    }
    const code = this.blocklyCode.JavaScript || '';
    return beautify(code, {
      indent_size: 2,
      indent_char: ' ',
      indent_with_tabs: false,
      eol: '\n',
      end_with_newline: false,
      indent_level: 0,
      preserve_newlines: true,
      max_preserve_newlines: 10,
      space_in_paren: false,
      space_in_empty_paren: false,
      jslint_happy: false,
      space_after_anon_function: false,
      brace_style: 'collapse',
      unindent_chained_methods: false,
      break_chained_methods: false,
      keep_array_indentation: false,
      unescape_strings: false,
      wrap_line_length: 0,
      e4x: false,
      comma_first: false,
      operator_position: 'before-newline',
    });
  }

  @computed get projectHtmlDocumentation() {
    if (this.projectSelected) {
      return this.projectSelected.helpHtml;
    } else if (this.workSelected) {
      return this.workSelected.helpHtml;
    }
    return undefined;
  }

  @computed get projectTeacherHtmlDocumentation() {
    if (this.projectSelected) {
      return this.projectSelected.teacherHelpHtml;
    } else if (this.workSelected) {
      return this.workSelected.teacherHelpHtml;
    }
    return undefined;
  }

  @computed get projectTeacherHelpId() {
    if (this.projectSelected) {
      return this.projectSelected.teacherHelpId;
    } else if (this.workSelected) {
      return this.workSelected.teacherHelpId;
    }
    return undefined;
  }

  @computed get projectStudentHelpId() {
    if (this.projectSelected) {
      return this.projectSelected.studentHelpId;
    } else if (this.workSelected) {
      return this.workSelected.studentHelpId;
    }
    return undefined;
  }

  @computed get projectOwner() {
    if (this.projectSelected) {
      return this.projectSelected.created_by;
    }
    return undefined;
  }

  @computed get projectSelected() {
    return this.projectStore
      ? this.projectStore.activeProject
      : undefined;
  }

  @computed get workSelected() {
    return this.projectStore
      ? this.projectStore.activeWork
      : undefined;
  }

  @computed get isTemporaryWorkspace() {
    return !this.workSelected && !this.projectSelected;
  }

  getHighlightedObject() {
    return this.classRoomObjects
      .values()
      .find(value => `${value.identifier}` === `${this.highlightedObjectId}`);
  }

  @action addBlockError({ blocklyId, error }) {
    const content = (this.blockErrors.get(blocklyId) || []);
    content.push(error);
    this.blockErrors.set(blocklyId, content);
  }

  @action blockErrorWarningVisible(visible) {
    const errorBlocks = [];
    this.blockErrors.keys().forEach((blocklyId) => {
      const blockErrors = this.blockErrors.get(blocklyId);
      if (blockErrors && blockErrors.length > 0) {
        const block = this.workspace.getBlockById(blocklyId);
        if (!block) return;
        if (!visible) {
          block.setWarningText(null);
          return;
        }
        if (!blocklyTools.isBlockDisabled(block)) {
          block.setWarningText(blockErrors[0].message || blockErrors[0]);
          errorBlocks.push(blocklyId);
        } else {
          block.setWarningText(null);
        }
      }
    });
    return errorBlocks;
  }

  @action blockErrorsFlash(on) {
    const that = this;
    log('blockErrorsFlash() %o, %o', on, this.blockErrorsFlashingState === this.blockErrorsFlashingStateEnum.flashing);
    if (
      on &&
      this.blockErrorsFlashingState === this.blockErrorsFlashingStateEnum.flashing
    ) {
      return;
    }
    clearInterval(this.blockErrorsFlashingIntervalerHD);
    this.blockErrorsFlashingIntervalerHD = 0;
    if (this.blockErrors.size <= 0) return;
    if (on) {
      this.blockErrorsFlashingState = this.blockErrorsFlashingStateEnum.flashing;

      const thisIntervaler = Date.now();
      this.blockErrorsFlashingIntervalerHD = thisIntervaler;
      // eslint-disable-next-line
      (function _interval(timeout) {
        const now = Date.now();
        // just flicker 3 seconds
        if (now - that.blockErrorsFlashingIntervalerHD > (3 * 1e3)) {
          that.blockErrorsKeepWarningColour(true);
          return;
        }

        if (that.blockErrorsFlashingIntervalerHD !== thisIntervaler) return;

        // it's very important that put setTimeout before the logic code
        setTimeout(_interval, timeout, timeout);
        that.blockErrors.keys().forEach((blocklyId) => {
          const b = that.workspace.getBlockById(blocklyId);
          const errorList = that.blockErrors.get(blocklyId);
          if (!b || !errorList || errorList.length === 0) return;
          if (blocklyTools.isBlockDisabled(b)) {
            if (b._originalColour) b.setColour(b._originalColour);
            b.setDisabled(true);
            return;
          }
          b._originalColour = b._originalColour || b.getColour();
          b._warningColour = '#FBBD08';
          b.setColour(b._warningColour);
          setTimeout(() => {
            b.setColour(b._originalColour);
          }, 0.5 * 1e3);
          // // using this to avoid of the colour won't change when a block has 2 errors.
          // if (Math.floor(now / (0.5 * 1e3)) % 2) {
          //   b.setColour(b._warningColour);
          // } else {
          // }
        });
      })(1 * 1e3);
    } else {
      this.blockErrorsFlashingState = this.blockErrorsFlashingStateEnum.normal;
      this.blockErrors.keys().forEach((blocklyId) => {
        const b = that.workspace.getBlockById(blocklyId);
        const errorList = that.blockErrors.get(blocklyId);
        if (!b || !errorList || errorList.length === 0) return;
        setTimeout(() => {
          if (b._originalColour) b.setColour(b._originalColour);
        }, 0);
      });
    }
  }

  blockErrorsKeepWarningColour(isKeep) {
    clearInterval(this.blockErrorsFlashingIntervalerHD);
    this.blockErrorsFlashingIntervalerHD = 0;
    if (this.blockErrors.size <= 0) return;
    if (isKeep) {
      this.blockErrors.keys().forEach((blocklyId) => {
        const b = this.workspace.getBlockById(blocklyId);
        if (!b) return;
        b._originalColour = b._originalColour || b.getColour();
        b.setColour(b._warningColour || '#FBBD08');
      });

      this.blockErrorsFlashingState = this.blockErrorsFlashingStateEnum.highlight;
    } else {
      this.blockErrors.keys().forEach((blocklyId) => {
        const b = this.workspace.getBlockById(blocklyId);
        if (!b) return;
        if (b._originalColour) b.setColour(b._originalColour);
      });
      this.blockErrorsFlashingState = this.blockErrorsFlashingStateEnum.normal;
    }
  }

  blockErrorsWarningPerform(show) {
    const errorBlocks = [];
    this.blockErrors.keys().forEach((blocklyId) => {
      const blockErrors = this.blockErrors.get(blocklyId);
      if (blockErrors && blockErrors.length > 0) {
        const block = this.workspace.getBlockById(blocklyId);
        if (!block) return;
        if (!show) {
          block.setWarningText(null);
          return;
        }
        if (!blocklyTools.isBlockDisabled(block)) {
          block.setWarningText(blockErrors[0].message || blockErrors[0]);
          errorBlocks.push(blocklyId);
        } else {
          block.setWarningText(null);
        }
      }
    });
    return errorBlocks;
  }

  getBlockErrorsByBlocklyId(blocklyId) {
    return this.blockErrors.get(blocklyId) || [];
  }

  @action.bound cleanBlockErrors() {
    this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.cleaning;
    log('cleanBlockErrors');
    this.blockErrors.keys().forEach((blocklyId) => {
      setTimeout(() => {
        if (this.workspace) {
          const blockObject = this.workspace.getBlockById(blocklyId);
          if (blockObject) blockObject.setWarningText(null);
        }
      }, 0);
    });
    this.blockErrorsFlash(false);
    // this.blockErrorsFlashBlock.clear();
    this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.unknown;
    this.blockErrors.clear(); // = observable.map({});
  }

  @action.bound cleanBlockErrorsWarning(argBlocklyId) {
    const _do = (blocklyId) => {
      this.blockErrors.delete(blocklyId);
      const blockObject = this.workspace.getBlockById(blocklyId);
      if (blockObject) {
        blockObject.setWarningText(null);
        if (blockObject._originalColour) blockObject.setColour(blockObject._originalColour);
      }
    };
    if (!argBlocklyId) {
      this.blockErrors.keys().forEach(_do);
    } else {
      _do(argBlocklyId);
    }
  }

  // blockErrorsFlashBlock = new Map();
  blockErrorsFlashingIntervalerHD = 0;
  codeChecker = undefined;

  @action async checkJsCode() {
    if (this.jsCodeCheckerState === this.jsCodeCheckerStateEnum.checking
      || this.jsCodeCheckerState === this.jsCodeCheckerStateEnum.cleaning) return false;
    this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.checking;
    this.codeChecker = this.codeChecker
      ||
      {
        CallExpression: createCallExpressionChecker({ classRoomStore: this }),
        MemberExpression: createMemberExpressionChecker({ classRoomStore: this }),
      };
    this.cleanBlockErrors();
    const AST = jsCodeParser.parse(`(async function(){
      ${this.formattedJavaScriptCode}
    })`);
    const parseResult = await jsCodeParser.walk(AST, this.codeChecker);

    const parseError = parseResult.filter(p => p.error);
    log('parseError %o', parseError);
    if (parseError && parseError.length > 0) {
      this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.failed;
      parseError.forEach((pE) => {
        this.addBlockError({ ...pE });
      });
      this.blockErrorsFlash(true);
      return false;
    }
    this.jsCodeCheckerState = this.jsCodeCheckerStateEnum.succeeded;
    return true;
  }


  detectedRobots = new Map();
  registeringRobots = new Map();

  @action clean() {
    this.cleanBlockErrors();
    this.setBlocklyCode({ xml: DEFAULT_XML });
    this.classRoomCode = '';
    this.qrcodeDataURL = null;
    this.classRoomSSID = '';
    this.classRoomName = '';
    this.classRoomCreatedBy = '';
    this.projectStore = undefined;
    this.classRoomStudents = [];
    this.classRoomRobots = observable.map({});
    this.classRoomObjects = observable.map({});

    // reset the mqttStore
    mqttStore.reset();

    if (this.workspace) this.workspace.removeChangeListener(this.workspaceChangeListener);
    this.workspace = undefined;
    this.highlightedRobot = undefined;
    this.highlightedObjectId = undefined;
    blocklyStore.init(); // clean
  }

  // 0 -> offline  1 -> calibration  2 -> estimation
  // server is the ip address of websocket server on Android app
  @observable cameraStatus = {
    status: 0,
    server: '',
    updatedAt: 0,
    online: false,
    isMatDetected: false,
  };
  __statusCheckTimer;

  @action updateCameraStatus(server, status) {
    this.cameraStatus.server = server;
    this.cameraStatus.status = status;
    this.cameraStatus.updatedAt = Date.now();
    // 0: camera mqtt online, but can't find map, 1: camera mqtt online, found the map
    if (`${status}` === '0') {
      this.cameraStatus.isMatDetected = false;
    } else if (`${status}` === '1') {
      this.cameraStatus.isMatDetected = true;
    }
  }

  @action startStatusChecker() {
    // this.statusTick();
    const thisIntervaler = Date.now();
    // log('startStatusChecker() %s %s', this.__statusCheckTimer, thisIntervaler);
    this.__statusCheckTimer = thisIntervaler;

    const that = this;
    // eslint-disable-next-line
    (function thisInterval(timeout) {
      if (that.__statusCheckTimer !== thisIntervaler) return;
      that.statusTick();
      // eslint-disable-next-line
      setTimeout(thisInterval, timeout, timeout);
    })(STATUS_CHECK_INTERVAL);
  }

  @action stopStatusChecker() {
    // clearInterval(this.__statusCheckTimer);
    // this.__statusCheckTimer = undefined;
    log('stopStatusChecker() was DEPRECATED you don\'t need to call it. classRoomStore startStatusChecker() launch in it\'s contrstructor and you don\'t call stopStatusChecker() manually.', this.__statusCheckTimer);
  }

  @action statusTick() {
    const now = Date.now();
    // check camera status
    const { updatedAt: cu } = this.cameraStatus;
    // this.cameraStatus.online = !(now - cu > CAMERA_STATUS_UPDATE_TIMEOUT ||
    //   (this.cameraStatus.status !== 1 && this.cameraStatus.status !== 2));
    this.cameraStatus.online = !(now - cu > CAMERA_STATUS_UPDATE_TIMEOUT);
    // check robot status
    this.classRoomRobots.values().forEach((r) => {
      setTimeout(() => {
        const { updatedAt: rsu } = r.status;
        r.status.online = now - rsu <= ROBOT_STATUS_UPDATE_TIMEOUT;
        let rpu = 0;
        if (r.positionsData.length > 0) {
          rpu = r.positionsData[r.positionsData.length - 1].time;
        }
        if (now - rpu > ROBOT_POSITION_UPDATE_TIMEOUT) {
          r.outOfCamera = true;
          if (now - rpu > ROBOT_POSITION_SET_TO_NONE_TIMEOUT) {
            const timeoutPosition = { x: -999, y: -999, a: 0 };
            this.emitOutOfBoundaryIfNeeded(r, timeoutPosition);
            Object.assign(r, timeoutPosition);
          }
        } else if (r.outOfCamera && mapSpecs[this.mapId].inBoundary(r)) { // inward
          r.outOfCamera = false;
        } else if (!r.outOfCamera && mapSpecs[this.mapId].outBoundary(r)) { // outward
          r.outOfCamera = true;
        }
        // check robot's sensors status
        const { updatedAt: su } = r.sensorsStatus;
        Object.keys(sensorSpecs).forEach((sensorId) => {
          r.sensorsStatus[sensorId].online =
            !(now - su > SENSORS_STATUS_UPDATE_TIMEOUT ||
              r.sensorsStatus[sensorId] === 0);
        });
      }, 0);
    });
    this.classRoomObjects.values().forEach((r) => {
      setTimeout(() => {
        const { time: rsu } = r;
        if (!r.status || r.status.outOfCamera) {
          this.classRoomObjects.delete(r.identifier);
          // un-highlight object
          if (this.highlightedObjectId === r.identifier) this.highlightedObjectId = undefined;
        } else {
          this.updateObjectStatus(r.identifier, {
            outOfCamera: !(now - rsu <= OBJECT_STATUS_UPDATE_TIMEOUT),
          });
        }
      }, 0);
    });
    this.classRoomPing();
  }

  classRoomPing() {
    const now = Date.now();
    if (parseInt(now / 1e3, 10) % 5 === 0) { // every 5 second
      this.checkCurrentOperationTeacherToken();
      this.checkRencentNotification();
    }
    if (parseInt(now / 1e3, 10) % 3 === 0) { // every 3 second
      this.studentMqttPing();
    }
  }

  checkCurrentOperationTeacherToken() {
    if (userStore) {
      userStore.removePingAction('getCurrentOperationTeacherToken');
      if (_.get(userStore, 'currentUser') && this.classRoomCode && !userStore.isStudent) {
        userStore.addPingAction('getCurrentOperationTeacherToken', {
          classRoomCode: this.classRoomCode,
        }, (data) => {
          userStore.removePingAction('getCurrentOperationTeacherToken');
          const { currentOperationTeacherToken } = data;
          if (currentOperationTeacherToken &&
            currentOperationTeacherToken !== userStore.currentUserAccessToken) {
            console.log('checkTheClassroomSingleLogin logout');
            log('checkTheClassroomSingleLogin logout');
            userStore.logout();
            window.location = '/';
          }
        });
      }
    }
  }

  checkRencentNotification() {
    if (userStore) {
      userStore.removePingAction('getRecentNotifications');
      if (_.get(userStore, 'currentUser') && this.classRoomCode && !userStore.isStudent) {
        userStore.addPingAction('getRecentNotifications', null, (data) => {
          userStore.removePingAction('getRecentNotifications');
          globalStatehook.newHttpNotificationDoc(data);
        });
      }
    }
  }

  studentMqttPing() {
    if (_.get(userStore, 'currentUser') && this.classRoomCode && userStore.isStudent) {
      // task 1: mqtt ping
      if (mqttStore) {
        mqttStore.publish(
          `to/classroom/${this.classRoomCode}/student/ping`,
          JSON.stringify({
            student_id: userStore.currentUser._id,
          }),
        );
      }
    }
  }

  @action setHighlightedRobot(id) {
    // this.highlightedRobot = this.classRoomRobots.values().find(value => value.identifier === id);
    this.highlightedRobot = this.getRobotByIdentifier(id);
  }

  @action setHighlightedObject(id) {
    this.highlightedObjectId = id;
  }

  @action setBlocklyCode(code) {
    this.blocklyCode = code;
    this.statehookEmit('set_blockly_code', code);
  }

  /* Deprecated */
  saveWork(codeToSave) {
    const {
      classRoomCode,
      workSelected,
      projectSelected,
      projectOwner,
    } = this;
    return this.saveWorkWithEnvironment(
      codeToSave,
      {
        classRoomCode,
        workSelected,
        projectSelected,
        projectOwner,
      },
    );
  }
  /**
   * Save work to database
   *
   * @param {!Object} codeToSave - XML and JS code to save
   * @todo Add Debounce
   * @returns {IPromiseBasedObservable<any>} - Promise saving work
   */
  saveWorkWithEnvironment = debounce(action(async function forLintSaveWork(
    codeToSave,
    environment,
  ) {
    log('Saving work onWorkspaceChange', codeToSave, environment);
    this.setBlocklyCode(codeToSave);
    const {
      classRoomCode,
      workSelected,
      projectSelected,
      projectOwner,
    } = environment;

    if (workSelected) {
      workSelected.updateCode(codeToSave.xml);

      const svgCoverXML = this.generateWorkCoverSvgXML();
      const dataForUpdateNamedWork = { xml: codeToSave.xml };
      if (svgCoverXML) Object.assign(dataForUpdateNamedWork, { svgCoverXML });
      updateNamedWork(
        workSelected._id,
        dataForUpdateNamedWork,
      ).then((res) => {
        workSelected.update(res.data);
      });
    } else if (projectSelected) {
      if (projectOwner === userStore.currentUser._id || userStore.isAdmin) {
        projectSelected.updateCode(codeToSave.xml);
        updateProject(projectSelected._id, codeToSave.xml)
          .then((result) => {
            if (userStore.isAdmin) {
              statusNotify({
                type: 'success',
                position: 'top',
                title: _t('You are Admin, save successful.'),
                timer: 5 * 1e3,
              });
            }
            return result;
          })
          .catch((error) => {
            let errorD = dehydrateError(error);
            popupError(errorD, _t('Error on saving your new work'));
            errorD = errorD ? `<p>${errorD}</p>` : '';
            statusNotify({
              type: 'warning',
              title: `<p style="color: #FFF">${_t('Oops!! Save failed, you are not the owner of this project.')}</p>${errorD}`,
              background: 'orange',
            });
          });
      } else {
        statusNotify({
          position: 'top',
          title: _t(`Save this as your project so you don't loose any work`),
          timer: 5 * 1e3,
        });
      }
    } else { // temporary workspace
      log(codeToSave.xml, 'saveWork');
      // patchStateStore({
      //   current_workspace_type: 'tmp',
      //   current_workspace_ref_id: '',
      // });
      accountStateStoreHook.patchCurrentClassroomInfoWithDebounce(classRoomCode, 'tmp', '');
      saveWork(classRoomCode, codeToSave);
    }
  }), 650);

  @autobind
  @action async createGlobalVariable() {
    // eslint-disable-next-line no-alert
    // const variableName = prompt('New variable name:');
    const { value: variableData } = await kaiAlert.fire({
      title: 'Create a new Class Variable',
      html:
        `
        <input id="create-global-variable-name-input" class="swal2-input" placeholder="${_t('Enter a new classroom variable name')}">
        <input id="create-global-variable-value-input" class="swal2-input" placeholder="${_t('Enter a new classroom variable value')}">
        <input type="checkbox" id="create-global-variable-readonly-checkbox">
        <label for="create-global-variable-readonly-checkbox" class="swal2-checkbox" >
          <span class="swal2-label">${_t('Read Only?')}</span>
        </label>
        `,
      focusConfirm: false,
      preConfirm: () => (
        {
          name: document.getElementById('create-global-variable-name-input').value,
          value: document.getElementById('create-global-variable-value-input').value,
          readonly: document.getElementById('create-global-variable-readonly-checkbox').checked,
        }
      ),
    });
    if (variableData && variableData.name) {
      const _value = (Number.isNaN(Number(variableData.value))) ? variableData.value : Number(variableData.value);
      const resp = await addGlobalVariable(
        this.classRoomCode,
        variableData.name,
        _value,
        variableData.readonly,
      );
      mqttStore.publish(
        `to/classroom/${this.classRoomCode}/variables_update_all`,
        JSON.stringify(resp.data),
      );
      kaiAlert.fire({
        // toast: true,
        // position: 'top',
        type: 'success',
        title: _t('New classroom variable {{variableName}} saved', { variableName: variableData.name }),
        showConfirmButton: false,
        timer: 3 * 1e3,
      });
    }
  }

  /**
   * Fetch class room by code and select as active.
   *
   * @param {!string} id - Class room id
   * @returns {IPromiseBasedObservable<T>} - An fromPromise result
   */
  @action fetchAndSelectClassRoom(_classRoomCode) {
    const classRoomCode = _classRoomCode || this.classRoomCode;
    if (!classRoomCode) return Promise.reject(new Error('classroom code is empty'));
    return byId(classRoomCode)
      .then((resp) => {
        const promise = this.setClassRoom(resp.data)
          .then(() => resp.data);
        return promise;
      });
  }

  @action.bound
  reviseTopBlock() {
    const topBlock = this.workspace.getTopBlocks().filter(ele => ele.type === 'runProgram')[0];
    if (topBlock) { // to fix the bug that if the root block's position is a negative number.
      // topBlock.svgPath_.removeAttribute('fill');
      topBlock.setDeletable(false); // set top block is undeletable
      const { x, y } = topBlock.getRelativeToSurfaceXY();
      const offsetX = 220;
      const offsetY = 20;

      let byX = 0;
      let byY = 0;
      if (x < offsetX) byX = Math.abs(x) + offsetX;
      if (y < offsetY) byY = Math.abs(y) + offsetY;
      topBlock.moveBy(byX, byY);
    } else {
      const tXML = Blockly.Xml.textToDom(DEFAULT_XML);
      Blockly.Xml.domToWorkspace(tXML, this.workspace);
      console.info('Not found topBlock, so created one. %o', this.workspace.getTopBlocks());
    }
  }

  async setXML(xml) {
    this.workspace.clear();
    const tXML = Blockly.Xml.textToDom(xml || DEFAULT_XML);
    console.log(xml, tXML, 'tttt');
    Blockly.Xml.domToWorkspace(tXML, this.workspace);
    this.reviseTopBlock();
    const code = this.workspaceToCode();
    this.setBlocklyCode({ xml, JavaScript: code });
    this.workspace.clearUndo();
  }

  getXML() {
    if (this.workspace) {
      const xmlDom = Blockly.Xml.workspaceToDom(this.workspace);
      return Blockly.Xml.domToPrettyText(xmlDom);
    }
    return null;
  }

  @action async setWork(workId, xml) {
    log('setWork %o , %o', workId, xml);
    // patchStateStore({
    //   current_workspace_type: 'work',
    //   current_workspace_ref_id: workId,
    // });
    this.clearProject(); // !being before next line is very important
    accountStateStoreHook.patchCurrentClassroomInfoWithDebounce(this.classRoomCode, 'work', workId);

    this.projectStore.setActiveWork(workId);
    if (this.workspace) {
      this.workspace.clear();
      setTimeout(() => {
        this.workspace.clear();
        const tXML = Blockly.Xml.textToDom(xml || DEFAULT_XML);
        Blockly.Xml.domToWorkspace(tXML, this.workspace);
        this.reviseTopBlock();
        const code = this.workspaceToCode();
        this.setBlocklyCode({ xml, JavaScript: code });
        this.workspace.clearUndo();
      }, 600);
    } else {
      this.setBlocklyCode({ xml });
    }
    this.clearConsoleLogList();
  }

  @action resetWorkspace() {
    //    when an old classroom load after the teacher_toolbox.xml remove or rename any blockly.
    // fix https://groups.google.com/forum/#!topic/blockly/VdqYUH4ZfQM

    this.workspace.clear();
    this.blocklyCode.xml = DEFAULT_XML;
    const tDefaultXML = Blockly.Xml.textToDom(this.blocklyCode.xml);
    Blockly.Xml.domToWorkspace(tDefaultXML, this.workspace);

    const xmlDom = Blockly.Xml.workspaceToDom(this.workspace);
    const xmlText = Blockly.Xml.domToPrettyText(xmlDom);
    const code = this.workspaceToCode();
    return { xml: xmlText, JavaScript: code };
  }

  @action async setProject(projectId, xml) {
    log('setProject %o , %o', projectId, xml);
    this.clearWork(); // !being before next line is very important
    // patchStateStore({
    //   current_workspace_type: 'project',
    //   current_workspace_ref_id: projectId,
    // });
    accountStateStoreHook.patchCurrentClassroomInfoWithDebounce(this.classRoomCode, 'project', projectId);

    this.projectStore.setActiveProject(projectId);
    if (this.workspace) {
      this.workspace.clear();
      setTimeout(() => {
        this.workspace.clear();
        const tXML = Blockly.Xml.textToDom(xml);
        Blockly.Xml.domToWorkspace(tXML, this.workspace);
        this.reviseTopBlock();
        const code = this.workspaceToCode();
        this.setBlocklyCode({ xml, JavaScript: code });
        this.workspace.clearUndo();
      }, 600);
    } else {
      this.setBlocklyCode({ xml });
    }
    this.clearConsoleLogList();
    setCurrentOperationProject(this.classRoomCode, projectId);
  }

  @action clearWork = () => {
    this.projectStore.setActiveWork(undefined);
    // webStorageStore.removeItem(`${this.classRoomCode}/work`);
    accountStateStoreHook.patchCurrentClassroomInfoWithDebounce(this.classRoomCode, '', '');
  };

  @action clearProject = () => {
    this.projectStore.setActiveProject(undefined);
    // webStorageStore.removeItem(`${this.classRoomCode}/project`);
    accountStateStoreHook.patchCurrentClassroomInfoWithDebounce(this.classRoomCode, '', '');
  };

  @action async setProjectStore(classRoomId) {
    // const localWork = webStorageStore.getItem(`${classRoomId}/work`);
    // const localProject = webStorageStore.getItem(`${classRoomId}/project`);
    await accountStateStoreHook.loadClassroomStore(classRoomId);
    const {
      current_workspace_type: currentWorkspaceType,
      current_workspace_ref_id: currentWorkspaceRefId,
    } = accountStateStoreHook.getClassroomStore(classRoomId);

    let localProject;
    let localWork;
    switch (currentWorkspaceType) {
      case 'project':
        localProject = currentWorkspaceRefId;
        break;
      case 'work':
        localWork = currentWorkspaceRefId;
        break;
      default:
        break;
    }

    console.log(localWork, localProject, 'localWork and localProject in setProjectStore');

    let _xml = DEFAULT_XML;
    if (localProject) {
      await projectHook.loadOneProject(localProject);
      const projectData = projectHook.getProject(localProject);
      if (!projectData) {
        localProject = null;
      } else {
        _xml = projectData.xml;
      }
    } else if (localWork) {
      await classroomWorkHook.loadOneWork(localWork);
      const workDoc = classroomWorkHook.getWork(localWork);
      if (!workDoc) {
        localWork = null;
      } else {
        _xml = workDoc.xml;
      }
    }
    this.projectStore = ProjectStore.create({
      classRoomCode: classRoomId,
      activeWorkId: localWork,
      activeProjectId: localProject,
    });
    if (localProject) {
      await this.setProject(localProject, _xml);
    } else if (localWork) {
      await this.setWork(localWork, _xml);
    } else {
      // temporary workspace
      // reading xml from studentClassroomJoint
      // studentClassroomJointHook.loadStudentClassroom()
      await accountStateStoreHook.loadClassroomStore(classRoomId);
      _xml = accountStateStoreHook.getClassroomStore(classRoomId, 'current_classroom_workspace_xml') || DEFAULT_XML;
    }

    this.setBlocklyCode({ xml: _xml || DEFAULT_XML });
  }

  @action async setClassRoom(classRoom) {
    const oldClassroomCode = this.classRoomCode;
    log('setClassRoom : old classroom code %o', oldClassroomCode);
    log('setClassRoom : new classroom %o', classRoom);
    const self = this;
    mqttStore.reset();

    this.setHookStatePath('classroomDoc', Object.assign({}, classRoom));
    this.setHookStatePath('classroomCode', classRoom._id);

    await this.classroomModelStatehook.refresh(classRoom._id);

    this.classRoomCode = classRoom._id;
    this.qrcodeDataURL = null;
    this.classRoomSSID = classRoom.ssid;
    this.classRoomName = classRoom.name;
    this.classRoomStudents = classRoom.students;
    this.classRoomCreatedBy = classRoom.created_by;
    uiStore.isBlocklyReadOnly = classRoom.isBlocklyReadOnly;
    // receive the change of isBlocklyReadOnly from mqtt
    this.offTopicList.push(this.mqttReceiveSetBlocklyReadOnly(({ isBlocklyReadOnly }) => {
      uiStore.isBlocklyReadOnly = Boolean(isBlocklyReadOnly);
    }));


    if (typeof this.classRoomCreatedBy !== 'string') {
      this.classRoomCreatedBy = this.classRoomCreatedBy._id;
    }

    webStorageStore.setItem('currentClassroomCode', this.classRoomCode);

    markMeEntered(this.classRoomCode);

    this.updateAllGlobalVariables(classRoom.globalVariables);

    await this.setProjectStore(classRoom._id);

    // Merge students and available robots in classRoom
    const studentsRobots = classRoom.students.map(it => it.robots);

    // observeArray convert to normal array
    const flatteningStudentsRobots = studentsRobots.reduce((acc, curr) => {
      acc = curr.concat(acc);
      return acc;
    }, []);

    const robots = classRoom.robots.map(it => it); // observeArray convert to normal array
    // const allRobots = flatteningStudentsRobots.concat(classRoom.robots);
    const allRobots = [].concat(flatteningStudentsRobots, robots);
    log('allRobots %o, %o, %o', allRobots, flatteningStudentsRobots, robots);
    const classRoomRobots = this.classRoomRobots || observable.map({});
    this.classRoomRobots = classRoomRobots;
    classRoomRobots.replace(allRobots.reduce((acc, next) => {
      acc[next._id] = initRobot();
      Object.assign(acc[next._id], next);
      return acc;
    }, {}));
    log('classRoomStore.classRoomRobots %o : ', classRoomRobots);
    // this.classRoomRobots = observable.map(classRoomRobots);
    this.initAllRobotSensorsIfNeeded();

    log('userStore.isStudent : %o', userStore.isStudent);
    if (userStore.isStudent) {
      log('this.classRoomStudents : %o', this.classRoomStudents);
      log('userStore.currentUser : %o', userStore.currentUser);
      const student = this.classRoomStudents.find(s => s.student._id === userStore.currentUser._id);
      log('the student are found  : %o', student);
      if (student && student.robots.length > 0) {
        // by default, highlight students' first own robot.
        this.setHighlightedRobot(student.robots[0].identifier);
      }
    }

    const onTopic = (topic, msg) => {
      const rawMessage = msg.toString();

      try {
        const payload = JSON.parse(rawMessage);
        mqttProcessor(topic, payload, mqttStore.publish);
      } catch (error) {
        const adicionalData = {
          mqtt_topic: topic,
          mqtt_rawMessage: rawMessage,
          classRoomCode: self.classRoomCode,
          classRoomSSID: self.classRoomSSID,
          classRoomName: self.classRoomName,
        };
        console.reportError(error, { extra: adicionalData });
        // Raven.captureException(error, { extra: adicionalData });
      }
    };
    this.offTopicList.push(mqttStore.onTopic(`to/classroom/${self.classRoomCode}/#`, onTopic));
    if (self.classRoomSSID) this.offTopicList.push(mqttStore.onTopic(`to/classroom/${self.classRoomSSID}/#`, onTopic)); // auto register

    // === three D model ===
    // threeDModelStatehook.mqttUnsubscribeModelEvent(oldClassroomCode);
    // threeDModelStatehook.mqttSubscribeModelEvent(self.classRoomCode);
    // === three D model END ===

    // === classroom waterfall message ===
    // unsubscribe
    classroomChatStatehook.mqttUnsubscribeWaterfallMessageInClassroom(oldClassroomCode);
    // subscribe
    classroomChatStatehook.mqttSubscribeWaterfallMessageInClassroom(self.classRoomCode);
    // === classroom waterfall message END ===

    // 3d model
    threeDModelStatehook.load3DModel(self.classRoomCode);
    // 3d model allocation
    threeDModelAllocationStatehook.fetch3DModelAllocation(self.classRoomCode);
  }

  // https://developers.google.com/blockly/guides/configure/web/events#listening_to_events
  @action.bound workspaceChangeListener() {
    this.setSelectedBlock(Blockly.selected); // selected block
    // if (e.type === Blockly.Events.BLOCK_MOVE) {
    //   this.blockErrorsFlash(false);
    //   this.cleanBlockErrors();
    //   // if (e.element === 'selected') {
    //   //   // this.blockErrorsFlash(false);
    //   // }
    // }
  }

  @action workspaceToCode(argWorkspace) {
    const workspace = argWorkspace || this.workspace;
    if (workspace) {
      return Blockly.JavaScript.workspaceToCode(workspace);
    }
    return null;
  }

  @action setWorkspace(workspace) {
    log('setWorkspace : %o', workspace);
    this.workspace = workspace;
    this.workspace.removeChangeListener(this.workspaceChangeListener);
    this.workspace.addChangeListener(this.workspaceChangeListener);
    this.workspace.clear();
    const tXML = Blockly.Xml.textToDom(this.blocklyCode.xml);
    log('this.blocklyCode.xml', this.blocklyCode.xml);
    log('this.workspace', this.workspace);
    try {
      Blockly.Xml.domToWorkspace(tXML, this.workspace);
    } catch (error) {
      log('ERROR HAPPENED -- Blockly.Xml.domToWorkspace', error);
      // it will throw an error
      //    when an old classroom load after the teacher_toolbox.xml remove or rename any blockly.
      // fix https://groups.google.com/forum/#!topic/blockly/VdqYUH4ZfQM
      const { xml: originalXML } = this.blocklyCode;
      this.workspace.clear();
      this.blocklyCode.xml = DEFAULT_XML;
      const tDefaultXML = Blockly.Xml.textToDom(this.blocklyCode.xml);
      Blockly.Xml.domToWorkspace(tDefaultXML, this.workspace);

      const extraData = {
        workspace_originalXML: originalXML,
        workspace_newXML: this.blocklyCode.xml,
        classRoomCode: this.classRoomCode,
        classRoomSSID: this.classRoomSSID,
        classRoomName: this.classRoomName,
      };
      // Raven.captureException(error, { extra: extraData });
      console.reportError(error, { extra: extraData });
    }
    this.reviseTopBlock();
    this.workspace.clearUndo();
  }

  @action undoWorkspace() {
    if (!this.workspace) return false;
    this.workspace.undo(false);
    return this.undoStack;
  }

  @computed get undoStack() {
    if (!this.workspace) return [];
    return this.workspace.undoStack_ || [];
  }

  @action redoWorkspace() {
    if (!this.workspace) return false;
    this.workspace.undo(true);
    return this.redoStack;
  }

  @computed get redoStack() {
    if (!this.workspace) return [];
    return this.workspace.redoStack_ || [];
  }

  @action getRobotByIdentifier(identifier) {
    return this.classRoomRobots.values().find(r => `${r.identifier}` === `${identifier}`);
  }

  @action getRobotByMacId(_id) {
    return this.classRoomRobots.values().find(o => `${o._id}` === `${_id}`);
  }

  @action getRobotsByStudentName(studentName) {
    if (!studentName) return null;
    return _.get(
      this.classRoomStudents.find(s => `${_.get(s, 'student.name')}`.toLowerCase() === `${studentName}`.toLowerCase()),
      'robots',
    );
  }

  @action getObjectByIdentifier(identifier) {
    return this.classRoomObjects.values().find(o => `${o.identifier}` === `${identifier}`);
  }

  @action getObjectByMacId(_id) {
    return this.classRoomObjects.values().find(r => `${r._id}` === `${_id}`);
  }

  @action addRobot(robot) {
    this.classRoomObjects.delete(robot.identifier);
    const initedRobot = initRobot();
    const sensors = Object.assign({}, initedRobot.sensors, robot.sensors, { sensor3: '2' });
    this.classRoomRobots.set(
      robot._id,
      Object.assign(initRobot(), robot, { sensors }),
    );
  }

  @action arrangeRobots(newRobotsDataDict) {
    const { classRoomCode } = this;
    // gain adding robot
    const robotsToAddOrUpdate = Object.entries(newRobotsDataDict).reduce((acc, [mac, robotId]) => {
      // do not touch both mac and id equals old value
      const thisRobot = this.classRoomRobots.get(mac);
      if (!thisRobot || `${thisRobot.identifier}` !== `${robotId}`) {
        acc[mac] = robotId;
      }
      return acc;
    }, {});
    // gain delete robot
    let robotsToDelete = {};
    if (false) { // disable it for the multi-kai's eye
      robotsToDelete = Object.entries(this.classRoomRobots.toJSON())
        .reduce((acc, [mac, robotInfo]) => {
          // do not touch both mac and id equals old value
          const robotId = robotInfo.identifier;
          if (!newRobotsDataDict[mac]) {
            acc[mac] = robotId;
          }
          return acc;
        }, {});
    }

    return Promise.resolve()
      .then(() => {
        // delete
        const allPromiseForDelete = [];
        Object.entries(robotsToDelete).forEach(([mac]) => {
          const robotDataInMachine = this.classRoomRobots.get(mac);
          if (robotDataInMachine) {
            allPromiseForDelete.push(Promise.resolve()
              .then(() => {
                if (userStore.isAdmin || userStore.isTeacher) {
                  return removeRobot(classRoomCode, mac);
                }
                return null;
              }).then(() => {
                this.removeRobot(robotDataInMachine);
              }));
          }
        });
        return Promise.all(allPromiseForDelete).then((promiseResults) => {
          console.log('promiseResults allPromiseForDelete', promiseResults);
        })
          .catch((error) => {
            // const details = _.get(error, 'response.data.result')
            kaiAlert.popupError(_.get(error, 'response.data.error') || error.message, 'Error code:');
          });
      })
      .then(() => {
        // add
        const allPromiseForAdd = [];
        console.log('robotsToAddOrUpdate', robotsToAddOrUpdate);
        Object.entries(robotsToAddOrUpdate).forEach(([mac, robotId]) => {
          if (parseInt(robotId, 10) > 0) {
            allPromiseForAdd.push(Promise.resolve()
              .then(() => {
                const robotProfile = {
                  _id: mac,
                  identifier: robotId,
                  classRoom: classRoomCode,
                  name: 'unused',
                };
                if (userStore.isAdmin || userStore.isTeacher) {
                  return addRobot(classRoomCode, robotProfile);
                }
                return {
                  data: {
                    ...robotProfile,
                    ...{
                      updatedAt: (new Date()).toISOString(),
                      createdAt: (new Date()).toISOString(),
                      online: true,
                      type: 'robot',
                    },
                  },
                };
              })
              .then((resp) => {
                log('robot added to cloud:', resp.data);
                this.addRobot(resp.data);
                return resp.data;
              }));
          }
        });
        return Promise.all(allPromiseForAdd).then((promiseResults) => {
          console.log('promiseResults allPromiseForAdd', promiseResults);
        })
          .catch((error) => {
            let details = _.get(error, 'response.data.error') || error.message;
            details = `${details}, mac: ${_.get(error, 'response.data.data._id')}, numberid: ${_.get(error, 'response.data.data.identifier')}`;
            kaiAlert.popupError(details, 'Error code:');
            console.reportError(error);
          });
      });
  }

  @action apiRemoveRobot(robot) {
    const { classRoomCode } = this;
    return removeRobot(classRoomCode, robot._id)
      .then((resp) => {
        this.removeRobot(robot);
        return resp;
      });
  }
  @action removeRobot(robot) {
    this.classRoomRobots.delete(robot._id);
  }

  @action emitOutOfBoundaryIfNeeded(robot, changes) {
    const {
      x, y,
    } = changes;
    const { x: oldX, y: oldY } = robot;
    const identifier = changes.identifier || robot.identifier;
    let event = null;
    if ((oldX >= 0 && oldX <= 800) && (oldY >= 0 && oldY <= 800)) {
      if ((x < 0 || x > 800) || (y < 0 || y > 800)) {
        // inside to outside
        event = 'outOfBoundary';
      }
    }
    if ((oldX < 0 || oldX > 800) || (oldY < 0 || oldY > 800)) {
      if ((x >= 0 && x <= 800) && (y >= 0 && y <= 800)) {
        // outside to inside
        event = 'intoBoundary';
      }
    }
    if (event === 'outOfBoundary') {
      log(x, y, oldX, oldY, event, !!this.sandboxWorker, 'emitOutOfBoundaryIfNeeded');
      if (!this.sandboxWorker) return;
      this.sandboxWorker.postMessage(JSON.parse(JSON.stringify({
        type: 'event',
        eventName: 'onRobotWithBoundaryChanged',
        robotId: identifier,
        isOutOfCamera: true,
      })));
    } else if (event === 'intoBoundary') {
      // no event emited in this situaction
      log(x, y, oldX, oldY, event, !!this.sandboxWorker, 'emitOutOfBoundaryIfNeeded');
    }
  }
  @action updateRobotPosition(changes) {
    const {
      identifier, x, y, a,
    } = changes;
    // const robot = this.classRoomRobots.values().find(it => it.identifier === identifier);
    const robot = this.getRobotByIdentifier(identifier);
    const now = Date.now();
    if (robot) {
      this.emitOutOfBoundaryIfNeeded(robot, changes);
      Object.assign(robot, { x, y, a }); // change the position data to robot
      if (robot.positionsData.length >= MAX_POSITION_HISTORY) robot.positionsData.shift();
      robot.positionsData.push({ time: now, data: { x, y, a } });
    } else {
      const object = this.classRoomObjects.get(identifier);
      const statusInfo = {
        type: 'object',
        time: now,
      };
      const changesData = Object.assign({}, changes, statusInfo);
      if (object) { // important
        Object.assign(object, changesData);
        this.updateObjectStatus(identifier, {
          outOfCamera: false,
          createdAt: Date.now(),
        });
      } else {
        changesData.status = changesData.status || {};
        Object.assign(changesData.status, {
          outOfCamera: false,
          createdAt: Date.now(),
        });
        this.classRoomObjects.set(identifier, changesData);
      }
    }
  }

  @action updateRobotStatus(identifier, status, battery) {
    // const robot = this.classRoomRobots.values().find(it => it.identifier === identifier);
    const robot = this.getRobotByIdentifier(identifier);
    if (robot) {
      const now = Date.now();
      robot.b = battery;
      if (robot.batteryData.length >= MAX_BATTERY_HISTORY) robot.batteryData.shift();
      robot.batteryData.push({ time: now, data: battery });
      robot.status = Object.assign(robot.status, {
        status, updatedAt: now, battery,
      });
    }
  }

  @action updateRobotSensors(changes) {
    const {
      identifier, s, status,
    } = changes;
    log('Sensor update: %d -> s: %o, status: %o', identifier, s, status);
    // const robot = this.classRoomRobots.values().find(it => it.identifier === identifier);
    const robot = this.getRobotByIdentifier(identifier);
    if (robot) {
      if (robot.sensorsData.length >= MAX_SENSOR_DATA_HISTORY) robot.sensorsData.shift();
      robot.s = s;
      robot.sensorsData.push({ time: Date.now(), data: s });
      // eslint-disable-next-line no-restricted-syntax
      for (const prop in status) {
        // eslint-disable-next-line no-prototype-builtins
        if (status.hasOwnProperty(prop)) {
          robot.sensorsStatus[prop] = robot.sensorsStatus[prop] || {};
          robot.sensorsStatus[prop].status = status[prop];
        }
      }

      robot.sensorsStatus.updatedAt = Date.now();
    }
  }

  @action updateRobotInfo(robotId, data) {
    const robot = this.classRoomRobots.get(robotId);
    if (!robot) {
      this.classRoomRobots.set(robotId, Object.assign({}, data));
    } else {
      Object.assign(robot, data);
    }
  }

  @action updateObjectStatus(objectId, data) {
    // const anObject = this.classRoomObjects.values().find(it => it.identifier === objectId);
    const anObject = this.getObjectByIdentifier(objectId);
    if (anObject) {
      const previousStatus = anObject.status || {};
      anObject.status = Object.assign(
        previousStatus,
        data,
        {
          updatedAt: Date.now(),
        },
      );
    }
  }

  @action updateAllGlobalVariables(globalVariables) {
    // this.globalVariables = globalVariables
    //   ? Object.keys(globalVariables)
    //   : [];
    this.globalVariables = globalVariables || {};
    if (this.workspace) {
      const toolbox = toolboxFor(this.mapId);
      this.workspace.updateToolbox(toolbox);
    }
  }

  @action setGlobalVariables(variables) {
    this.updateAllGlobalVariables(Object.assign(this.globalVariables, variables));
  }

  @action registerRobotSensors(robotObjectOrId, sensors, xmlSensors) {
    const that = this;
    const typeofArg1 = typeof robotObjectOrId;
    const robotId = (typeofArg1 === 'string' || typeofArg1 === 'number') ? robotObjectOrId : robotObjectOrId._id;
    // const robot = this.classRoomRobots.values().find(r => robotId === r._id);
    const robot = this.getRobotByMacId(robotId);
    if (!robot) return Promise.reject(new Error(_t('Robot  {{robotId}} does not exist in this class room', { robotId })));
    const state = this.registerRobotSensorsState.get(robot.identifier);

    if (state !== undefined
      && state !== this.registerRobotSensorsStateEnums.pending
      && state !== this.registerRobotSensorsStateEnums.completed
      && state !== this.registerRobotSensorsStateEnums.timeout
      && state !== this.registerRobotSensorsStateEnums.verificationfailed
    ) return Promise.reject(new Error(_t(`This robot's sensors register state is {{state}} now, you can not send another request in this situation.`, { state })));

    // state pending
    this.registerRobotSensorsState
      .set(robot.identifier, this.registerRobotSensorsStateEnums.pending);

    // Publish by MQTT
    const packet = {
      sensor_update: {
        list: [
          tryParseInt(sensors.sensor1),
          tryParseInt(sensors.sensor2),
          tryParseInt(sensors.sensor3),
          tryParseInt(sensors.sensor4),
        ],
      },
    };

    return new Promise((resolve, reject) => {
      let isTimeout = false;
      let isFinish = false;
      const subscribeTopic = `to/classroom/${that.classRoomCode}/sensor_feedback`;
      // eslint-disable-next-line
      const offTopic = mqttStore.onTopic(subscribeTopic, function onMsg(topic, message) {
        if (subscribeTopic !== topic) return;
        const payload = JSON.parse(message.toString());
        const { identifier, sensor } = payload;
        if (sensor &&
          !isTimeout &&
          !isFinish &&
          parseInt(identifier, 10) === parseInt(robot.identifier, 10)) {
          // eslint-disable-next-line
          if (verifyResponse(payload)) {
            // eslint-disable-next-line
            runInAction('registerRobotSensors mission complete', missionComplete);
          }
        }
      });
      mqttStore.publish(
        `to/robot/${that.classRoomCode}/${robot.identifier}`,
        JSON.stringify(packet), { qos: 1 },
      );

      // state sent
      that.registerRobotSensorsState
        .set(robot.identifier, that.registerRobotSensorsStateEnums.sent);

      // eslint-disable-next-line
      const timeouter = setTimeout(action(missionTimeout), 10 * 1e3);

      function verifyResponse(payload) {
        const { sensor } = payload;
        const hasDifference = sensor
          .find((a, index) => parseInt(a, 10) !== packet.sensor_update.list[index]);
        if (hasDifference) {
          // state verificationfailed
          that.registerRobotSensorsState
            .set(robot.identifier, that.registerRobotSensorsStateEnums.verificationfailed);

          reject(new Error(_t('Oops! Something went wrong - Response data from robot does not match with request data.')));
          return false;
        }
        return true;
      }
      function missionComplete() {
        isTimeout = false;
        isFinish = true;
        offTopic();
        clearTimeout(timeouter);
        // Update on Store
        that.updateRobotInfo(robot._id, { sensors, xmlSensors });
        // state completed
        that.registerRobotSensorsState
          .set(robot.identifier, that.registerRobotSensorsStateEnums.completed);

        resolve();
      }
      function missionTimeout() {
        isTimeout = true;
        isFinish = true;
        offTopic();
        // state completed
        that.registerRobotSensorsState
          .set(robot.identifier, that.registerRobotSensorsStateEnums.timeout);

        reject(new Error(_t(`Please check Robot {{robotId}} if online.`, { robotId: robot.identifier }))); // Did not receive the response from the robot. Please try again.'));
      }
    });
  }

  async initAllRobotSensorsIfNeeded() {
    log('initAllRobotSensorsIfNeeded');
    const values =
      this.classRoomRobots.values ?
        this.classRoomRobots.values() :
        Object.values(this.classRoomRobots);
    log('initAllRobotSensorsIfNeeded', values);
    return Promise.all(values.map((robot) => {
      console.log('initAllRobotSensorsIfNeeded', robot._id);
      return this.initRobotSensorsIfNeeded(robot._id);
    }));
  }

  async initRobotSensorsIfNeeded(robotId) {
    log('initRobotSensorsIfNeeded', robotId);
    const robot = this.classRoomRobots.get(robotId);
    if (!robot) return Promise.reject(new Error(`Do not exist this robot which id is ${robotId} in this class room`));
    const { sensors } = robot;
    const sensorsValue = Object.values(sensors || {});
    console.log('initAllRobotSensorsIfNeeded', sensorsValue, robotId);
    if (sensorsValue.length <= 0) {
      return this.initRobotSensors(robotId);
    } else {
      let isNeedInit = true;
      sensorsValue.forEach((svalue) => {
        if (`${svalue}` !== '1') {
          isNeedInit = false;
        }
      });
      if (isNeedInit) {
        return this.initRobotSensors(robot);
      }
      return Promise.resolve();
    }
  }

  async initRobotSensors(robotObjectOrId) {
    const that = this;
    const typeofArg1 = typeof robotObjectOrId;
    console.log('robotObjectOrId', robotObjectOrId);
    const robotId = (typeofArg1 === 'string' || typeofArg1 === 'number') ? robotObjectOrId : robotObjectOrId._id;
    // const robot = this.classRoomRobots.values().find(r => robotId === r._id);
    const robot = this.getRobotByMacId(robotId);
    if (!robot) return Promise.reject(new Error(`Do not exist this robot which id is ${robotId} in this class room`));

    const sensors = {
      sensor1: '1',
      sensor2: '1',
      sensor3: '2',
      sensor4: '13',
    };
    const xmlSensors = `
    <xml xmlns="http://www.w3.org/1999/xhtml">
      <block type="DIGITAL_AND_ANALOG_Connector" id="reCBVFJsjW" deletable="false" movable="false" x="550" y="30"></block>
      <block type="SERIAL_Connector" id="p9Rs08AuQs" deletable="false" movable="false" x="550" y="160"></block>
      <block type="SERVO_Connector" id="5eiv$bGGlP" deletable="false" movable="false" x="550" y="290">
        <statement name="SENSOR">
          <block type="Gripper" id="kaisclan_Gripper"></block>
        </statement>
      </block>
      <block type="ULTRASONIC_Connector" id="3J2MGkb9Lv" deletable="false" movable="false" x="550" y="420">
        <statement name="SENSOR">
          <block type="Ultrasonic_Distance_Sensor" id="kaisclan_Ultrasonic_Distance_Sensor"></block>
        </statement>
      </block>
    </xml>`;
    that.updateRobotInfo(robot._id, { sensors, xmlSensors });
    return saveRobotSensorConfiguration(robot._id, sensors, xmlSensors);
  }

  getConsoleLogList() {
    return this.getHookStatePath(STATEHOOK_CONSOLE_PATH) || [];
  }
  consoleLogIdCounter = 0;
  addConsoleLog(text) {
    // this.consoleLogList.push({
    //   text,
    //   time: Date.now(),
    // });
    this.updateHookStatePath(STATEHOOK_CONSOLE_PATH, (oldValue) => {
      oldValue = oldValue || [];
      oldValue.push({
        // eslint-disable-next-line no-plusplus
        id: this.consoleLogIdCounter++,
        text,
        time: Date.now(),
      });
      return oldValue;
    });
  }
  clearConsoleLogList() {
    this.updateHookStatePath(STATEHOOK_CONSOLE_PATH, (oldValue) => {
      oldValue = oldValue || [];
      oldValue.splice(0, oldValue.length);
      return oldValue;
    });
  }
  watchConsoleLog(cb) {
    cb = cb || (() => {});
    return this.watchHookStatePath(STATEHOOK_CONSOLE_PATH, (eventType, path, value) => {
      cb(value);
    });
  }

  @action clearUselessTopBlock() {
    const xmlParser = new DOMParser();
    const xmlDoc = xmlParser.parseFromString(this.blocklyCode.xml, 'application/xml');
    const allTopBlock = xmlDoc.documentElement.childNodes;
    for (let i = 0; i < allTopBlock.length; i += 1) {
      const oneBlock = allTopBlock[i];
      if (
        oneBlock.getAttribute &&
        oneBlock.getAttribute('type') !== 'runProgram' &&
        oneBlock.getAttribute('disabled') === 'true'
      ) {
        xmlDoc.documentElement.removeChild(oneBlock);
      }
    }

    const serializer = new XMLSerializer();
    const xmlText = serializer.serializeToString(xmlDoc);
    this.workspace.clear();
    const tXML = Blockly.Xml.textToDom(xmlText);
    Blockly.Xml.domToWorkspace(tXML, this.workspace);
    this.workspace.clearUndo();
  }

  @action generateWorkCoverSvgXML() {
    let xmlText = '';
    let xmlForReturn = '';
    try {
      const serializer = new XMLSerializer();
      const svgElement = this.workspace.getParentSvg();
      xmlText = serializer.serializeToString(svgElement);
      const xmlParser = new DOMParser();
      const workspaceSvg = xmlParser.parseFromString(xmlText, 'image/svg+xml');
      const workspaceSvgDocument = workspaceSvg.documentElement;
      workspaceSvgDocument.removeAttribute('width');
      workspaceSvgDocument.removeAttribute('height');
      // workspaceSvgDocument.removeChild(workspaceSvgDocument.getElementsByTagName('defs')[0]);

      const blocklyWorkspace = workspaceSvgDocument.getElementsByClassName('blocklyWorkspace')[0];
      const blocklyTrash0 = blocklyWorkspace.getElementsByClassName('blocklyTrash')[0];
      if (blocklyTrash0) blocklyWorkspace.removeChild(blocklyTrash0);

      const blocklyBubbleCanvas0 = blocklyWorkspace.getElementsByClassName('blocklyBubbleCanvas')[0];
      if (blocklyBubbleCanvas0) blocklyWorkspace.removeChild(blocklyBubbleCanvas0);

      const blocklyScrollbarBackground0 = blocklyWorkspace.getElementsByClassName('blocklyScrollbarBackground')[0];
      if (blocklyScrollbarBackground0) blocklyWorkspace.removeChild(blocklyScrollbarBackground0);

      const blocklyZoomCollections = Array.from(blocklyWorkspace.getElementsByClassName('blocklyZoom'));
      if (blocklyZoomCollections.length > 0) {
        let currentNode = blocklyZoomCollections[0];
        let zoomParent = null;
        do {
          zoomParent = currentNode.parentNode;
          if (zoomParent === blocklyWorkspace) {
            if (currentNode) blocklyWorkspace.removeChild(currentNode);
            zoomParent = null;
          } else {
            currentNode = zoomParent;
          }
        } while (zoomParent);
      }
      Array.from(blocklyWorkspace.getElementsByClassName('blocklyZoom'))
        .forEach((oneBlocklyZoom) => {
          if (oneBlocklyZoom) blocklyWorkspace.removeChild(oneBlocklyZoom);
        });

      const blocklyBlockCanvas = blocklyWorkspace.getElementsByClassName('blocklyBlockCanvas')[0];

      const allDraggableBlocks = blocklyBlockCanvas.getElementsByClassName('blocklyDraggable');
      const rootBlock = Array.from(allDraggableBlocks).find(b => !b.getAttribute('class').includes('blocklyDisabled'));
      // ** you can't use `for in` or `for of` to iterate a HTMLCollections **
      // because after you removeChild index 0,
      // the child index 1 will automatically become the index 0 immediately.
      // then you remove the index 1, the index 1 will be empty.
      // the HTMLCollections object is dynamic
      const allDisabledBlocks = blocklyBlockCanvas.getElementsByClassName('blocklyDisabled');
      // Array.from(allDisabledBlocks).forEach((item) => {
      //   blocklyBlockCanvas.removeChild(item);
      // });
      // ** why we use this way? **
      // because it has diffence between dev environment and product environment
      let loopMaximum = 1000;
      // eslint-disable-next-line
      while (allDisabledBlocks[0] && loopMaximum-- > 0) blocklyBlockCanvas.removeChild(allDisabledBlocks[0]);

      blocklyBlockCanvas.setAttribute('transform', 'translate(-50,0) scale(0.7)');
      // root block
      rootBlock.setAttribute('transform', 'translate(0,0)');
      xmlForReturn = (new XMLSerializer()).serializeToString(workspaceSvg);
    } catch (error) {
      log('error happened when call generateWorkCoverSvgXML()', xmlText, error);
      console.reportError(error);
      const adicionalData = {
        xmlText,
        classRoomCode: this.classRoomCode,
        classRoomSSID: this.classRoomSSID,
        classRoomName: this.classRoomName,
      };
      // Raven.captureException(error, { extra: adicionalData });
      console.reportError(error, { extra: adicionalData });
    }
    // log(xmlForReturn, 'xmlForReturn');
    return xmlForReturn;
  }

  @action generateWorkCoverImg(_callback) {
    const callback = _callback || (() => { });
    const canvas = document.createElement('canvas'); // Not shown on page
    const ctx = canvas.getContext('2d');
    const loader = new Image(); // Not shown on page
    const width = 390;
    const height = 135;
    loader.width = width;
    canvas.width = width;
    loader.height = height;
    canvas.height = height;
    loader.onload = () => {
      ctx.drawImage(loader, 0, 0, loader.width, loader.height);
      callback(null, canvas.toDataURL());
    };
    const svgAsXML = this.generateWorkCoverSvgXML();
    loader.src = `data:image/svg+xml,${encodeURIComponent(svgAsXML)}`;
  }

  @action setSelectedBlock(blocklyElement) {
    const newBlockId = _.get(blocklyElement, 'id') || null;
    if (this.selectedBlockInfo.blockId === newBlockId) {
      this.selectedBlockInfo.clickTimes += 1;
    } else {
      this.selectedBlockInfo.clickTimes = 0;
    }
    this.selectedBlockInfo.blockId = newBlockId;
    this.selectedBlockInfo.blocklyElement = blocklyElement || null;
    this.selectedBlockInfo.blockType = _.get(blocklyElement, 'type') || null;
  }

  async getQRCodeDataURL() {
    if (!this.qrcodeDataURL) {
      this.qrcodeDataURL = await QRCode.toDataURL(this.classRoomCode, { errorCorrectionLevel: 'H', width: 300 });
    }
    return this.qrcodeDataURL;
  }

  async showClassroomCodeQRCodeInfoPopBox(_params) {
    const params = _params || {};
    let {
      onShareButtonClick,
      // onHelpButtonClick,
      onClose,
    } = params;
    onShareButtonClick = onShareButtonClick || (() => {});
    // onHelpButtonClick = onHelpButtonClick || (() => {});
    onClose = onClose || (() => {});

    const { classRoomCode, classRoomName } = this;
    const dataURL = await this.getQRCodeDataURL();

    const topicRegister = `to/classroom/${classRoomCode}/camera_register`;
    let offTopic;
    await kaiAlert.fire({
      title: classRoomName,
      footer: `<div id="notice_text_for_scanning" style="text-align:center">
                <div>${_t('Scan the class code with either the &#34;Kai&#39;s Eye&#34; phone')}</div>
                <div>${_t('or')}</div>
                <div>${_t('using the &#34;Kai&#39;s Virtual&#34; viewer found in the app store.')}</div></div>`,
      imageUrl: dataURL,
      html: `<p>${classRoomCode}</p>
              <button id="sw2-qrcode-share" class="ui button icon big blue">
                <i class="share square icon"></i>
                ${_t('Share')}
              </button>
              <!--
              <button id="sw2-qrcode-help" class="ui button icon big green">
                <i class="question icon"></i>
                ${_t('Help')}
              </button>
              -->
              `,
      allowEnterKey: false,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      onBeforeOpen() {
        const content = kaiAlert.getContent();
        const querySelector = content.querySelector.bind(content);
        const shareButton = querySelector('#sw2-qrcode-share');
        // const helpButton = querySelector('#sw2-qrcode-help');

        shareButton.addEventListener('click', () => {
          onShareButtonClick();
        });
        // helpButton.addEventListener('click', () => {
        //   kaiAlert.close();
        //   onHelpButtonClick();
        // });
      },
      onOpen() {
        log('onOpen');
        offTopic = mqttStore.onTopic(topicRegister, async (topic, msg) => {
          if (!topicRegister.includes(topic)) return;
          log('onTopic');
          const payload = JSON.parse(msg.toString());
          await updateSSID(classRoomCode, payload);
          if (offTopic) offTopic();
          kaiAlert.close();
        });
      },
      onClose() {
        if (offTopic) offTopic();
        onClose();
      },
    });
  }

  generateGoogleSheetData() {
    const robots = this.classRoomRobots.values();

    // sensors sheets
    const sensorsSheets = robots.reduce((acc, r) => {
      const content = r.sensorsData
        .map(it => Object.assign(
          {
            Time: it.time,
            QRCodeId: r.identifier,
          },
          Object.entries(it.data).reduce((dataAcc, [k, value]) => {
            const newKey = sensorSpecs[k] ? sensorSpecs[k].name : k;
            const newValue = _.isArrayLike(value) ? Array.from(value).join(', ') : value;
            return Object.assign(dataAcc, {
              [newKey]: newValue,
            });
          }, {}),
        ));
      return acc.concat(content);
    }, []);

    // positions sheets
    const positionsSheets = robots.reduce((acc, r) => {
      let currentRobotTotalDistance = 0; // an new robot
      let lastX = null;
      let lastY = null;
      const content = r.positionsData
        .map((it) => {
          let distance = null;
          const currentX = it.data.x;
          const currentY = it.data.y;
          if (lastX === null || lastY === null) {
            distance = 0;
          } else {
            const distanceX = Math.abs(lastX - currentX);
            const distanceY = Math.abs(lastY - currentY);
            distance = Math.sqrt((distanceX ** 2) + (distanceY ** 2));
          }
          lastX = Number(currentX);
          lastY = Number(currentY);
          if (Number.isNaN(lastX)) lastX = 0;
          if (Number.isNaN(lastY)) lastY = 0;
          currentRobotTotalDistance += distance;

          return {
            Time: it.time,
            QRCodeId: r.identifier,
            X: it.data.x,
            Y: it.data.y,
            Angle: it.data.a,
            Distance: distance,
            'Total Distance': currentRobotTotalDistance,
          };
        });
      // return { name: _t('Robot #{{robotId}} Positions', { robotId: r.identifier }), data: content };
      return acc.concat(content);
    }, []);

    // battery sheets
    const batterySheets = robots.reduce((acc, r) => {
      const content = r.batteryData
        .map(it => (
          {
            Time: it.time,
            QRCodeId: r.identifier,
            Battery: it.data,
          }
        ));
      // return { name: _t('Robot #{{robotId}} Battery', { robotId: r.identifier }), data: content };
      return acc.concat(content);
    }, []);
    function ascSortByTime(o1, o2) {
      if (o1.Time > o2.Time) return 1;
      if (o1.Time < o2.Time) return -1;
      return 0;
    }
    return {
      Sensors: sensorsSheets.sort(ascSortByTime),
      Positions: positionsSheets.sort(ascSortByTime),
      Battery: batterySheets.sort(ascSortByTime),
    };
  }

  disableChat(enable) {
    const { classRoomCode } = this;
    return disableChat(classRoomCode, enable);
  }

  /**
   * `mqttPublishSetBlocklyReadOnly` is a function that publishes a message to the MQTT broker to set
   * the Blockly editor to read-only mode
   * @param isBlocklyReadOnly - Boolean
   * @returns A promise.
   */
  mqttPublishSetBlocklyReadOnly(isBlocklyReadOnly) {
    return mqttStore.publish(
      `to/classroom/${this.classRoomCode}/setBlocklyReadOnly`,
      JSON.stringify({
        isBlocklyReadOnly: Boolean(isBlocklyReadOnly),
      }),
    );
  }

  /**
   * `mqttReceiveSetBlocklyReadOnly` is a function that takes a callback function as an argument. It
   * returns a function that subscribes to the topic
   * `to/classroom/${this.classRoomCode}/setBlocklyReadOnly` and calls the callback function with the
   * payload of the message
   * @param cb - a callback function that will be called when the message is received.
   * @returns A function that can be used to unsubscribe from the topic.
   */
  mqttReceiveSetBlocklyReadOnly(cb) {
    return mqttStore.onTopic(`to/classroom/${this.classRoomCode}/setBlocklyReadOnly`, async (topic, msg) => {
      const payload = JSON.parse(msg.toString());
      cb(payload);
    });
  }
}

export default new ClassRoomStore();
