/* eslint-disable quote-props */
/* eslint-disable max-len */
export default () => Promise.resolve({
  info: {
    RTL: false,
    thousand_place: ',',
    currency_symbol: '$',
    fist_day_of_week: 7,
    // date_time_format: '|',
  },
  translations: {
    // ===routes/ClassRoom/components/SensorWidget/SensorWidgetForS15.jsx===
    'SensorWidgetForS15.jsx': {
      'Temperature': 'Temp',
    },
  },
});
