import _ from 'lodash';
import Blockly from 'blockly';
import userStore from 'store/userStore';
import uiStore from 'store/uiStore';
import classRoomStore from 'store/classRoomStore';
import threeDModelStatehook from 'store/threeDModelStatehook';
import { escapeRegExp } from 'utils';
// import studentToolboxBeginner from './studentToolbox/beginner.xml';
// import studentToolboxAdvanced from './studentToolbox/advanced.xml';
// import studentMap1Toolbox from './student_toolbox_map1.xml';
// import teacherToolboxBeginner from './teacherToolbox/beginner.xml';
// import teacherToolboxAdvanced from './teacherToolbox/advanced.xml';
// import teacherMap1Toolbox from './teacher_toolbox_map1.xml';
import { getStudentAdvancedXML, getStudentBeginnerXML, getAllStudentXML } from './studentToolbox/getStudentToolboxXML';
import { getTeacherAdvancedXML, getTeacherBeginnerXML/** , getAllTeacherXML */ /** as same as getAllStudentXML()  */} from './teacherToolbox/getTeacherToolboxXML';

// convert the 3d model url to the 3d mode information instead of the url itself
function parse_3D_model_url(xmlString) {
  const { classRoomCode } = classRoomStore;
  const allModels = threeDModelStatehook.get3DModelsFromClassroom(classRoomCode);
  if (allModels.length === 0) return xmlString;
  const defaultOne = allModels[0];
  const optionText = `${_.get(defaultOne, 'modelTitle')} / ${_.get(defaultOne, 'created_by.name')}`;
  const optionValue = {
    text: optionText,
    value: _.get(defaultOne, 'modelOBJZipURL'),
  };
  const subStr = '<field name="THREE_D_MODEL_URL">a_variable_for_options_js_file</field>';
  const toSubStr = `<field name="THREE_D_MODEL_URL">${JSON.stringify(optionValue)}</field>`;
  return `${xmlString}`.replace(RegExp(`${escapeRegExp(subStr)}`, 'gi'), toSubStr);
}

// universal xml converter for all of your needs.
function parse_xml(xmlString) {
  let parsedXmlString = parse_3D_model_url(xmlString);
  return parsedXmlString;
}

// export function toolboxFor(mapId) {
export function toolboxFor() {
  // const params = typeof _params === 'string' ? { mapId: _params } : _params;
  const { currentUser } = userStore;
  const role = (currentUser) ? `${currentUser.role}`.toLowerCase() : '';
  const isTeacher = role === 'teacher';
  // const isStudent = role === 'student';
  const isAdmin = role === 'admin';
  // const teacherToolboxRef = (mapId === 5 ? teacherMap1Toolbox : teacherToolbox);
  // const studentToolboxRef = (mapId === 1 ? studentMap1Toolbox : studentToolbox);
  let studentToolboxRef = null;
  let teacherToolboxRef = null;
  if (uiStore.isAdvancedModeToolbox) {
    studentToolboxRef = getStudentAdvancedXML();
    teacherToolboxRef = getTeacherAdvancedXML();
  } else if (uiStore.isBeginnerModeToolbox) {
    studentToolboxRef = getStudentBeginnerXML();
    teacherToolboxRef = getTeacherBeginnerXML();
  } else {
    studentToolboxRef = getAllStudentXML();
    teacherToolboxRef = studentToolboxRef;
  }
  studentToolboxRef = parse_xml(studentToolboxRef);
  teacherToolboxRef = parse_xml(teacherToolboxRef);

  const adminToolboxRef = teacherToolboxRef;

  if (isAdmin) return Blockly.Xml.textToDom(adminToolboxRef);
  if (isTeacher) return Blockly.Xml.textToDom(teacherToolboxRef);
  return Blockly.Xml.textToDom(studentToolboxRef);
}

export default function optionsFor(mapId) {
  return {
    toolbox: toolboxFor(mapId),
    comments: true,
    maxBlocks: Infinity,
    trashcan: true,
    toolboxPosition: 'start',
    horizontalLayout: false,
    css: true,
    // media: 'https://blockly-demo.appspot.com/static/media/',
    media: '/assets/classroom/media/',
    scrollbars: true,
    sounds: true,
    oneBasedIndex: true,
    zoom: {
      controls: true,
      wheel: false,
      scaleSpeed: 1.25,
    },
    disable: false,
    id: mapId,
    renderer: 'zelos',
  };
}
