import api from 'api';

export function uploadToClass(classCode, file, _opt) {
  // the `onUploadProgress` property will to be able to capture the progress of file upload
  const opt = _opt || {};
  return api.post(`/media/classroom/${classCode}`, file, opt);
}

export function getAllMediaByClassCode(classCode) {
  return api.get(`/media/classroom/${classCode}`);
}

export function getAllMediaByUser() {
  return api.get('/media/user');
}

export function uploadToUserRange(file, _opt) {
  const opt = _opt || {};
  return api.post('/media/user', file, opt);
}

export function remove(mediaId, _opt) {
  const opt = _opt || {};
  return api.delete(`/media/${mediaId}`, opt);
}
