import { cond, pipe, when, identity, T } from 'ramda';
import positionsUpdate from './communication/positionsUpdate';
import sensorsUpdate from './communication/sensorsUpdate';
import cameraStatus from './communication/cameraStatus';
import robotStatus from './communication/robotStatus';
import robotRegister from './communication/robotRegister';
import registerFeedback from './communication/registerFeedback';
import variablesUpdate from './communication/variablesUpdate';
import variablesSet from './communication/variablesSet';
import studentPing from './communication/studentPing';
import sensorFeedback from './communication/sensorFeedback';
import request from './communication/request';
import response from './communication/response';

/**
 * Payload are validate using JSON Schema.
 *
 * @param {!string} topic - Topic received
 * @param {!Object} payload - Payload object
 * @param {!func} publish - Function used to publish new packets
 * @see https://tools.ietf.org/html/draft-zyp-json-schema-03
 */
export default function processor(topic, payload, publish) {
  const resolve = cond([
    positionsUpdate,
    studentPing,
    request,
    response,
    cameraStatus,
    sensorsUpdate,
    robotStatus,
    robotRegister,
    registerFeedback,
    variablesUpdate,
    variablesSet,
    sensorFeedback,
    // eslint-disable-next-line no-console
    [T, m => console.warn('Message failed to pass the schema validation: %o', m)],
  ]);

  const publishIfConditionalResolved = pipe(
    resolve,
    when(identity, publish),
  );

  publishIfConditionalResolved({ topic, payload });
}
