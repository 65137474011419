/* eslint-disable no-param-reassign,jsdoc/require-param */

import { action, runInAction, observable } from 'mobx';
import { fromPromise, whenWithTimeout, FULFILLED } from 'mobx-utils';
import { find, propEq } from 'ramda';
import debug from 'debug';
import {
  addRobot as addRobotToClassRoom,
  fetchClassRoomAvailableRobots,
  removeRobot as removeRobotFromClassRoom,
  removeStudentFromClassRoom,
  associateStudentToRobotForClassRoom,
  disassociateStudentFromRobotForClassRoom,
} from 'api/classRoom';

const log = debug('ui:store');

/**
 * Define Class room robots store
 */
class ClassRoomRobotsStore {
  @observable robots = [];
  @observable students = [];
  @observable removeStudentResult = undefined;
  @observable contextOperationRobotId = undefined;

  /**
   * Replace students list
   *
   * @param {!Array} students - Student list to be replaced
   */
  @action setStudents = (students) => {
    log('Students list', students);
    this.students.replace(students);
  };

  /**
   * Make a request api to get all robots.
   * Will update `this.robots` when promise was resolved
   *
   * @example
   * const result = fetchRobots('ABCXYZ');
   * when (
   *  () => result.state === FULFILLED,
   *  () => console.log('Robots ', result.value)
   * )
   * @param {!string} classRoomId - Class room ID used to get robots
   * @returns {IPromiseBasedObservable<T>} - An fromPromise result
   */
  @action fetchRobots = (classRoomId) => {
    const result = fromPromise(fetchClassRoomAvailableRobots(classRoomId));
    whenWithTimeout(
      () => result.state === FULFILLED,
      () => {
        runInAction('pushing robots', () => this.robots.replace(result.value.data));
      },
      4000,
    );
    return result;
  };

  /**
   * Add/create robot to the class room.
   * When promise resolved, will push the new robot to `this.robots`.
   *
   * @param {!string} classRoomId - Class room ID where to add
   * @param {!Object} robot - The robot to add
   * @returns {IPromiseBasedObservable<T>} - An fromPromise result
   */
  @action addRobot = async (classRoomId, robot) => addRobotToClassRoom(classRoomId, robot)
    .then((resp) => {
      runInAction('add robot to robots list', () => {
        const r = this.robots.find(it => it._id === resp.data._id);
        if (!r) {
          this.robots.push(resp.data);
        } else {
          Object.assign(r, robot);
        }
      });
      return resp.data;
    });

  /**
   * Remove robot to the class room.
   * This will not remove delete the robot, just remove from this class room.
   * When the promise was resolved, {@link findAndRemoveRobot}
   * is used to remove the robot from store.
   *
   * @param {!string} classRoomId - Class room ID where to add.
   * @param {!string} robotId - Robot ID from database.
   * @returns {IPromiseBasedObservable<T>} - An fromPromise result
   */
  @action removeRobot = (classRoomId, robotId) =>
    fromPromise(removeRobotFromClassRoom(classRoomId, robotId)
      .then(() => (this.findAndRemoveRobot(robotId))));

  /**
   * Remove student to the class room.
   * This will not remove delete the student, just remove from this class room.
   * When the promise was resolved, {@link findAndRemoveStudent}
   * is used to remove the student from store.
   *
   * @param {!string} classRoomId - Class room ID where to add.
   * @param {!string} studentClassRoomId - Student class room ID from database.
   * <b>This is NOT an student ID</b>
   * @returns {IPromiseBasedObservable<T>} - An fromPromise result
   */
  @action removeStudent = (classRoomId, studentClassRoomId) =>
    removeStudentFromClassRoom(classRoomId, studentClassRoomId)
      .then(() => (this.findAndRemoveStudent(studentClassRoomId)));

  /**
   * Lookup robot by Id in store and remove it.
   *
   * @param {!string} robotId - Robot ID to remove.
   * @returns {T} - Robot removed.
   */
  @action findAndRemoveRobot = (robotId) => {
    const robotToRemove = this.robots.find(q => q._id === robotId);
    this.robots.remove(robotToRemove);
    return robotToRemove;
  }

  /**
   * Lookup student in store and add the robot.
   *
   * @param {!string} studentId - Student to lookup.
   * @param {!Object} robot - Robot that will be added to student.
   * @returns {T} - Student with new robot.
   */
  @action findStudentAndAddRobot = (studentId, robot) => {
    const student = this.students.find(q => q.student._id === studentId);
    student.robots.push(robot);
    return student;
  }

  /**
   * Lookup the student in store and remove it.
   *
   * @param {!string} studentClassRoomId - Student ID to remove.
   * @returns {T} - Student that was removed from store.
   */
  @action findAndRemoveStudent = (studentClassRoomId) => {
    const findById = find(propEq('_id', studentClassRoomId));
    const studentToRemove = findById(this.students);
    if (studentToRemove.robots.length > 0) {
      this.robots.replace([...this.robots.slice(), ...studentToRemove.robots.slice()]);
    }
    this.students.replace(this.students.filter(it => it._id !== studentToRemove._id));
    return studentToRemove;
  }

  /**
   * Lookup for the student in store and remove the robot association.
   *
   * @param {!string} studentId - Student Id to lookup.
   * @param {!string} robotId - Robot Id to remove from this student.
   * @returns {T} - The robot removed.
   */
  @action findAndDisassociateRobotForStudent = (studentId, robotId) => {
    const student = this.students.find(q => q.student._id === studentId);
    if (!student) {
      throw new Error('Student not found!');
    }
    const robot = student.robots.find(q => q._id === robotId);
    student.robots = student.robots.filter(q => q._id !== robotId);
    return robot;
  }

  /**
   * Associate the robot with student.
   * When promise resolved,
   *  - {@link findAndRemoveRobot} is used to remove robot from available robots.
   *  - {@link findStudentAndAddRobot} is used to add robot to the student.
   *
   * @param {!string} classRoomId - Class room ID.
   * @param {!string} studentId - Student Id.
   * @param {!string} robotId - Robot Id that will be associated to the student.
   * @returns {IPromiseBasedObservable<T>} - An fromPromise object
   */
  @action associateStudentToRobot = (classRoomId, studentId, studentName, robotId) => {
    const result =
      fromPromise(associateStudentToRobotForClassRoom(
        classRoomId,
        studentId,
        studentName,
        robotId,
      ));

    whenWithTimeout(
      () => result.state === FULFILLED,
      () => {
        const robotRemoved = this.findAndRemoveRobot(robotId);
        this.findStudentAndAddRobot(studentId, robotRemoved);
      },
      4000,
    );

    return result;
  }

  /**
   * Remove robot association from student.
   * When promise resolved,
   *  - {@link findAndDisassociateRobotForStudent} is used to remove robot from student.
   *  - The new robot is added to available robots in store `this.robots.push`
   *
   * @param {!string} classRoomId - The class room ID
   * @param {!string} studentId - Student Id that will remove robot.
   * @param {!string} robotId - Robot Id to remove from student.
   * @returns {IPromiseBasedObservable<T>} - fromPromise object
   */
  @action disassociateStudentFromRobot = (classRoomId, studentId, robotId) => {
    const result =
      fromPromise(disassociateStudentFromRobotForClassRoom(classRoomId, studentId, robotId));
    whenWithTimeout(
      () => result.state === FULFILLED,
      () => {
        if (result.value.status === 202) {
          const robot = this.findAndDisassociateRobotForStudent(studentId, robotId);
          runInAction('bring back robot to available', () => this.robots.push(robot));
        }
      },
      4000,
    );

    return result;
  }

  @action updateRobotSensorsConfig(robotId, sensors) {
    const robot = this.robots.find(r => r._id === robotId);
    robot.sensors = sensors;
  }

  @action cleanRobots() {
    this.robots.clear();
  }
}

/**
 * By default, export a store instance.
 */
export default new ClassRoomRobotsStore();

export { ClassRoomRobotsStore };
