/* eslint-disable no-param-reassign */

import { detach, flow, types, getSnapshot as mobxGetSnapshot } from 'mobx-state-tree';
import {
  // getAllByMe,
  create,
  update,
  // getMapDefaultPathfinding,
  // setMapDefaultPathfinding,
  // getAllMapDefautPathfinding,
} from 'api/pathFinding';
import PathfindingPlaceModel from './PathfindingPlaceModel';

const MATRIX_CELL_SCALE_BY_MAP = 20;
const MATRIX_MARGIN_TOP = 4;
const MATRIX_MARGIN_LEFT = 4;
const MATRIX_MARGIN_BOTTOM = 4;
const MATRIX_MARGIN_RIGHT = 4;


const PathfindingModel = types
  .model('PathfindingModel', {
    _id: types.optional(types.identifier(), ''),
    name: types.optional(types.string, ''),
    places: types.optional(types.union(
      types.map(PathfindingPlaceModel),
      types.array(PathfindingPlaceModel),
    ), {}),
    mapId: types.optional(types.number, 0),
    matrix: types.optional(types.array(types.array(types.number)), []),
    matrixCellScaleByMap: types.optional(types.number, MATRIX_CELL_SCALE_BY_MAP),
    matrixMarginTop: types.optional(types.number, MATRIX_MARGIN_TOP),
    matrixMarginLeft: types.optional(types.number, MATRIX_MARGIN_LEFT),
    matrixMarginBottom: types.optional(types.number, MATRIX_MARGIN_BOTTOM),
    matrixMarginRight: types.optional(types.number, MATRIX_MARGIN_RIGHT),
    description: types.optional(types.string, ''),
    deletedAt: types.maybe(types.string),
    createdAt: types.maybe(types.string),
    created_by: types.optional(types.frozen, null),
    updatedAt: types.maybe(types.string),
    $isUnsaved: types.union(types.undefined, types.boolean),
  })
  .views(self => ({
    get isUnsaved() {
      return !!self.$isUnsaved;
    },
  }))
  .actions((self) => {
    const addPlace = (name, placeObj) => {
      self.places.set(name, placeObj);
      self.places.get(name).$isDeleted = false;
      self.setUnsaved(true);
    };
    const setPlaceProperty = (placeName, propertyName, value) => {
      self.places.get(placeName)[propertyName] = value;
      self.setUnsaved(true);
    };
    const deletePlace = (name) => {
      self.places.get(name).$isDeleted = true;
      self.setUnsaved(true);
    };
    const undeletePlace = (name) => {
      self.places.get(name).$isDeleted = false;
      self.setUnsaved(true);
    };
    const setPlaces = (places) => {
      self.places = places;
      self.setUnsaved(true);
    };
    const setMatrix = (matrixData) => {
      self.matrix = matrixData;
      self.setUnsaved(true);
    };
    const setMapId = (mapId) => {
      self.mapId = mapId;
      self.setUnsaved(true);
    };
    const setUnsaved = (trueFalse) => {
      self.$isUnsaved = trueFalse;
    };
    const setName = (newName) => {
      self.name = newName;
      self.setUnsaved(true);
    };
    const getSnapshot = () => mobxGetSnapshot(self);
    const save = flow(function* save() {
      self.setMatrix(self.matrix
        .map(l2 => l2.map(n => n === 0 ? 0 : 1)));
      // const snapShot = Object.create(self.getSnapshot());
      self.places.forEach((p, pName) => {
        if (p.isDeleted) {
          self.places.delete(pName);
        } else {
          p.removeDeletedKey();
        }
      });
      self.removeIsUnsavedKey();
      let result;
      if (self._id) { // update
        result = yield update(self._id, self);
      } else {
        result = yield create(self);
      }
      self.setUnsaved(false);
      detach(self);
      return result;
    });

    const merge = (newData) => {
      Object.assign(self, newData);
    };

    const removeIsUnsavedKey = () => {
      // destroy(self.$isUnsaved);
      self.$isUnsaved = undefined;
    };

    return {
      addPlace,
      setPlaceProperty,
      deletePlace,
      undeletePlace,
      setPlaces,
      setMatrix,
      setMapId,
      setUnsaved,
      setName,
      getSnapshot,
      save,
      merge,
      removeIsUnsavedKey,
    };
  });

export default PathfindingModel;
