import api from 'api';

export function create(data) {
  return api.post('/project', data);
}

export function getAll() {
  return api.get('/project');
}

export function get(projectId) {
  return api.get(`/project/${projectId}`);
}

export function remove(id) {
  return api.delete(`/project/${id}`);
}

export function update(id, xml) {
  return api.patch(`/project/${id}`, {
    xml,
  });
}

export function updateProject(id, data) {
  return api.put(`/project/${id}`, data);
}

export function updateDescription(id, description) {
  return api.patch(`/project/${id}`, {
    description,
  });
}

export function updateHelp(id, helpHtml) {
  // return api.patch(`/project/${id}`, {
  //   helpHtml,
  // });
  const formData = new FormData();
  formData.append('help_file', new Blob([helpHtml], { type: 'text/html' }));
  return api.post(`/project/${id}/help/student`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function updateTeacherHelp(id, teacherHelpHtml) {
  // return api.patch(`/project/${id}`, {
  //   teacherHelpHtml,
  // });
  const formData = new FormData();
  formData.append('help_file', new Blob([teacherHelpHtml], { type: 'text/html' }));
  return api.post(`/project/${id}/help/teacher`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function moveToProjects(id, categoryId) {
  return api.patch(`/project/${id}`, {
    community: false,
    category: categoryId,
  });
}

export function moveToCommunity(id, categoryId) {
  return api.patch(`/project/${id}`, {
    community: true,
    category: categoryId,
  });
}

export function moveToCategory(projectId, categoryId) {
  return api.post(`/project/${projectId}/moveToCategory/${categoryId}`);
}

export function addRule(projectId, ruleData) {
  return api.post(`/project/${projectId}/rule`, ruleData);
}

export function removeRule(projectId, ruleId) {
  return api.delete(`/project/${projectId}/rule/${ruleId}`);
}

export function removeCategory(categoryId) {
  return api.delete(`/projectCategory/${categoryId}`);
}

export function createCategory(category) {
  return api.post('/projectCategory', category, {
    'Content-Type': 'multipart/form-data',
  });
}

export function getAllCategories(category) {
  return api.get('/projectCategory', category);
}

export function getProjectsWithNoCategory() {
  return api.get('/project/no-category');
}

export function getHelpField(projectId) {
  return api.get(`/project/${projectId}/help/bothHelp`);
}
