import { detach, flow, getParent, types } from 'mobx-state-tree';
import { removeCategory as removeCategoryApi } from 'api/project';
import userStore from 'store/userStore';
import Project from './Project';

const ProjectCategory = types
  .model('ProjectCategory', {
    _id: types.identifier(),
    name: types.string,
    displayOn: types.optional(types.number, 2), // 1 = Kai's Projects, 2 = Community
    order: types.maybe(types.number),
    imageCover: types.optional(types.string, ''),
    // eslint-disable-next-line no-use-before-define
    projects: types.optional(types.array(types.late(() => Project)), []),
  })
  .actions(self => ({
    remove: flow(function* remove() {
      yield removeCategoryApi(self._id);
      const parent = getParent(self, 2);
      parent.removeCategory(self._id);
    }),
    removeProject(projectId) {
      detach(self.projects.find(p => p._id === projectId));
      self.projects.replace(self.projects.filter(c => c._id !== projectId));
    },
    addProject(project) {
      self.projects.push(project);
    },
    getCategoryIdByProjectId(projectId) {
      const projectObj = self.projects.find(p => p._id === projectId);
      let parentObj = null;
      if (!projectObj) return undefined;
      if (projectObj.parent) {
        if (typeof projectObj.parent === 'string') {
          parentObj = self.projects.find(p => p._id === projectObj.parent);
        } else {
          parentObj = projectObj.parent;
        }
      } else {
        parentObj = projectObj; // no parent means it's top level project
      }
      if (parentObj) return parentObj.category;
      return undefined;
    },
    has(projectId) {
      return !!self.allProjectsAndRules.find(p => p._id === projectId);
    },
    hasIn(projectIds) {
      const pIdList = Array.isArray(projectIds) ? projectIds : [projectIds];
      return !!pIdList.find(pId => self.has(pId));
    },
  }))
  .views(self => ({
    get iCanEdit() {
      const createdById = self.created_by ? (self.created_by._id || self.created_by) : '';
      return self._id !== '0' && (createdById === userStore.currentUser._id || userStore.isAdmin);
    },
    get allProjectsAndRules() {
      const allProjects = [];
      self.projects.forEach((p) => {
        allProjects.push(p);
        if (p.rules && p.rules.forEach) {
          p.rules.forEach((r) => {
            allProjects.push(r);
          });
        }
      });
      return allProjects;
    },
  }));

export default ProjectCategory;
