import {
  getNS,
} from '_i18n_';
import {
  pipe,
  isNil,
  not,
  propEq,
  ifElse,
  F,
} from 'ramda';
import {
  extractErrors,
  buildErrorChecker,
} from './error';
import * as importJsCodeParser from './jsCodeParser';
import importJsEnum from './jsEnum';
import _monkeyify from './monkeyify';

const _t = getNS('utils/index.js');

/**
 * Encode string to Base64
 *
 * @param {!string} str - Value to be encoded
 * @see https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
 * @returns {string} - Base64 of value
 */
export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(encodeURIComponent(str).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) => String.fromCharCode(`0x${p1}`),
  ));
}

/*
 * Check if state property is "pending".
 * @see https://goo.gl/hfmAEQ
 * @param {?Object} from - fromPromise object
 * @returns {Boolean} - True, if still pending
 */
export function isPending(from) {
  const isNotNil = pipe(isNil, not);
  const isStatePending = propEq('state', 'pending');
  const checkIfIsPending = ifElse(isNotNil, isStatePending, F);
  return checkIfIsPending(from);
}

/**
 * Extract isPending, error and hasErrorFor from fromPromise.
 *
 * @todo Should not use FROM in several places
 * @param {?Object} from - An fromPromise to be extracted
 * @returns {{isPending: boolean, error: object, hasErrorFor: function}} -
 * An object contain isPending, error and {@link hasErrorFor}.
 */
export function extractPromiseResult(from) {
  const error = extractErrors(from);
  const hasErrorFor = buildErrorChecker(error);

  const resultOf = ifElse(
    isNil,
    () => ({
      isPending: false,
      error: undefined,
      hasErrorFor: F,
    }),
    p => ({
      isPending: isPending(p),
      error,
      hasErrorFor,
    }),
  );

  return resultOf(from);
}

export function tryParseInt(val, defaultVal = 1) {
  try {
    const n = parseInt(val, 10);
    return n > 0 ? n : defaultVal;
  } catch (err) {
    return defaultVal;
  }
}

export function tryParseIntOrOriginal(number, radix) {
  try {
    const parsed = parseInt(number, radix || 10);
    if (Number.isNaN(parsed)) return number;
    return parsed;
  } catch (err) {
    return number;
  }
}

export function isTouchScreenDevice() {
  if (('ontouchstart' in window) || (window.DocumentTouch && document instanceof window.DocumentTouch)) {
    return true;
  }

  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  const mq = (query) => {
    return window.matchMedia(query).matches;
  };

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
  return mq(query);
}

export function isSafari() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && (ua.indexOf('chrome') === -1); // found safari but don't found chrome = safari
}

/**
 * https://stackoverflow.com/questions/3007480/determine-if-user-navigated-from-mobile-safari/29696509#29696509
 * @returns boolean
 */
export function isiOS() {
  const ua = navigator.userAgent.toLowerCase();
  if (ua.indexOf('ipad') > -1 || ua.indexOf('iphone') > -1) return true;
  if (isTouchScreenDevice()) {
    // eslint-disable-next-line no-use-before-define
    if (isChromeOniOS()) return true;
    if (isSafari()) return true;
  }
  return false;
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function isChrome() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('safari') > -1 && ua.indexOf('chrome') > -1; // found safari and found chrome = chrome
}

export function isChromeOniOS() {
  const ua = navigator.userAgent.toLowerCase();
  return ua.match('CriOS'.toLowerCase());
}

export function _setReliableInterval(fn, t, ...rest) {
  let isGoOn = true;
  // eslint-disable-next-line
  (async function worker(t) {
    if (!isGoOn) return;
    await fn.call(null, ...rest);
    setTimeout(worker, t, t);
  })(t);

  return () => {
    if (!isGoOn) throw new Error(_t('The interval has alreadly stopped.'));
    isGoOn = false;
  };
}

export function _sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

export const replaceObject = (obj, refill) => {
  Object.keys(obj).forEach((key) => {
    // eslint-disable-next-line
    delete obj[key];
  });
  if (refill) Object.assign(obj, refill);
  return obj;
};

export const generateStudentLoginCardQRCodeURL = (studentname, classRoomCode) => {
  const q = encodeURIComponent(`student_login|${studentname}|${classRoomCode}`);
  return `https://${process.env.WEBSITE_APP_URL || 'app.kaisclan.ai'}/qr?q=${q}`;
};

export const JSONParse = (...rest) => {
  try {
    return JSON.parse(...rest);
  } catch (error) {
    return error;
  }
};
const adjectiveList = [
  'Active', 'Adorable', 'Gentle', 'Modest', 'Clever', 'Cheerful', 'Considerate',
  'Courageous', 'Brave', 'Creative', 'Cultured', 'Disciplined', 'Energetic',
  'Expressive', 'Friendly', 'Frank', 'Kind', 'Outgoing', 'Polite', 'Pushy', 'Selfless',
  'Talented', 'Thoughtful', 'Understanding', 'Upright',
];
const nounList = [
  'Fox', 'Dog', 'Yak', 'Tiger', 'Horse', 'Mouse', 'Rabbit', 'Bear',
  'Marmot', 'Monkey', 'Koala', 'Kangaroo', 'KiwiBird', 'Deer',
  'Giraffe', 'Elephant', 'Unicorn', 'Cat', 'Chick', 'Wolf', 'Fish',
  'Seal', 'Shark', 'Leopard',
];
export const genStudentName = () => {
  const finalNameArr = [];
  finalNameArr.push(adjectiveList[Math.floor(Math.random() * adjectiveList.length)]);
  finalNameArr.push(nounList[Math.floor(Math.random() * nounList.length)]);
  return finalNameArr.join('');
};

export const bindObjectFunctions = (obj) => {
  Object.keys(obj).forEach((f) => {
    if (Object.prototype.hasOwnProperty.call(obj, f)) {
      if (typeof obj[f] === 'function') {
        obj[f] = obj[f].bind(obj);
      }
    }
  });
  return obj;
};

export const toBundle = (arrayLike, bundleSize) => {
  const result = [];
  let currPos = 0;
  while (currPos < arrayLike.length) {
    result.push(arrayLike.slice(currPos, currPos += bundleSize));
  }
  return result;
};


const regExpForEscape = /[.*+\-?^${}()|/[\]\\]/g;
function escapeRegExp(regExp) {
  // $& means the whole matched string;
  if (regExp instanceof RegExp) regExp = `${regExp.source}`;
  return (`${regExp}`).replace(regExpForEscape, '\\$&');
}

export const toDatetimeLocal = (date) => {
  const ten = (i) => {
    return (i < 10 ? '0' : '') + i;
  };
  const YYYY = date.getFullYear();
  const MM = ten(date.getMonth() + 1);
  const DD = ten(date.getDate());
  const HH = ten(date.getHours());
  const II = ten(date.getMinutes());
  const SS = ten(date.getSeconds());
  return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS;
};

export const string2ArrayBuffer = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; i += 1) {
    view[i] = s.charCodeAt(i) & 0xFF;
  }
  return buf;
};

export const downloadFile = (filename, blob) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob); // create object url
  a.download = `${filename}`;
  if (!isSafari()) a.target = '_blank'; // error 'WebKitBlobResource: 1' will happened when you use safari with target = '_blank'
  a.click();
  setTimeout(() => {
    URL.revokeObjectURL(a.href);
  }, 5 * 1e3);
};

export const jsCodeParser = importJsCodeParser;
export const jsEnum = importJsEnum;

export const setReliableInterval = _setReliableInterval;
export const sleep = _sleep;

export const monkeyify = _monkeyify;

export { escapeRegExp };
