import {
  lensProp,
  view,
  lift,
  set,
  identity,
  prop,
  pick,
  match,
  nth,
  evolve,
  trim,
  pipe,
  pathEq,
  call,
} from 'ramda';

const log = require('debug')('ui:mongoDbQueryErrorParse.js');

const E11000Pattern = /(E11000\s)(.+)(:\s\{.+)/mi;


/**
 * Parse MongoDB query error.
 *
 * @returns {*} - Return an function that can be called with response param.
 */
export function execute() {
  log('execute');
  const messageLens = lensProp('message');
  const messageQueryLens = lensProp('errmsg');
  const viewMessageQuery = view(messageQueryLens);
  const setQueryMessage = lift(set(messageLens))(viewMessageQuery, identity);
  const getQueryMessage = pipe(prop('response'), prop('data'), setQueryMessage, pick(['message']));
  const matchMessage = match(E11000Pattern);
  const extractMessageOnly = pipe(matchMessage, nth(2));
  const transformMessage = evolve({
    message: pipe(extractMessageOnly, trim),
  });

  return pipe(getQueryMessage, transformMessage);
}

export function matchExecution() {
  log('matchExecution');
  return pathEq(['response', 'data', 'code'], 11000);
}

export default [call(matchExecution), execute];
