import Ajv from 'ajv';

const ajv = new Ajv();

export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/student/ping',
    },
    payload: {
      type: 'object',
      properties: {
        student_id: { type: 'string' },
      },
      required: ['student_id'],
    },
  },
  required: ['topic', 'payload'],
  additionalProperties: true,
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
