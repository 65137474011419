import api from 'api';

/**
 * Send a API request to recovery the password.
 *
 * @param {!string} email - User e-mail that will be used to recovery the password.
 * @throws {Error} throw error when promise resolved with status !== 200.
 */
export function recoveryPassword(email) {
  return api
    .post('/teacher/recoveryPassword', { email })
    .then((ret) => {
      if (ret.status !== 200) {
        throw new Error(ret.statusText);
      }
      return ret.data;
    });
}

/**
 * Update user password by pass with token.
 * Usually the user request token using {@link recoveryPassword}.
 *
 * @param {!string} token - User token to be used.
 * @param {!string} newPassword - The new user password.
 * @throws {Error} throw error when promise resolved with status !== 200.
 */
export function resetPassword(token, newPassword) {
  return api
    .post(`/teacher/resetPassword/${token}`, { newPassword })
    .then((ret) => {
      if (ret.status !== 200) {
        throw new Error(ret.statusText);
      }
      return ret.data;
    });
}

/**
 * Create an new teacher.
 *
 * @param {!Object} data - Teacher data info.
 */
export function create(data) {
  return api.post('/teacher', data);
}
