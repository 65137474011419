/* eslint-disable arrow-body-style */
import moment from 'moment';
import { globalStatehook } from 'store/statehook/globalStatehook';
import enTranslationGenerator from './translations/en';
import spanishTranslationGenerator from './translations/spanish';
import frenchTranslationGenerator from './translations/french';

export const getLanguageInfo = function getLanguageInfo(item) {
  return globalStatehook.getHookStatePath([].concat(['i18n', 'currentTranslation', 'info'], item));
};

const contentParser = {
  html: str => Array.from(str).map((char) => {
    if (char === ' ') return '&nbsp;';
    return `&#${char.charCodeAt(0)};`;
  }).join(''),
};

const tagParsers = [
  {
    name: 'datetime tag',
    tag_name: 'datetime',
    parser: (infoDict, translationText) => {
      const datetimeFormat = getLanguageInfo('date_time_format');
      if (datetimeFormat) {
        const tagName = infoDict.tag_name || '';
        translationText = translationText.replace(RegExp(`{${tagName}{(.*?)}${tagName}}`, 'g'), (matched, keyName) => {
          return moment(keyName).format(datetimeFormat);
        });
      }
      return translationText;
    },
  },
  {
    name: 'date tag',
    tag_name: 'date',
    parser: (infoDict, translationText) => {
      const dateFormat = getLanguageInfo('date_format');
      if (dateFormat) {
        const tagName = infoDict.tag_name || '';
        translationText = translationText.replace(RegExp(`{${tagName}{(.*?)}${tagName}}`, 'g'), (matched, keyName) => {
          return moment(keyName).format(dateFormat);
        });
      }
      return translationText;
    },
  },
  {
    name: 'time tag',
    tag_name: 'time',
    parser: (infoDict, translationText) => {
      const timeFormat = getLanguageInfo('time_format');
      if (timeFormat) {
        const tagName = infoDict.tag_name || '';
        translationText = translationText.replace(RegExp(`{${tagName}{(.*?)}${tagName}}`, 'g'), (matched, keyName) => {
          return moment(keyName).format(timeFormat);
        });
      }
      return translationText;
    },
  },
  {
    name: 'variable tag',
    tag_name: '',
    parser: (infoDict, translationText, variable) => {
      if (variable) {
        const tagName = infoDict.tag_name || '';
        Object.entries(variable).forEach(([varName, val]) => {
          translationText = translationText.replace(RegExp(`{${tagName}{${varName}}${tagName}}`, 'g'), val);
        });
      }
      return translationText;
    },
  },
];

const timeoutRanClearRecordMissingTranslation = 1;
let lastHandleRanRecordMissingTranslation = 0;
const recordMissingTranslation = function recordMissingTranslation(nsArr) {
  const currentLanguage = globalStatehook.getHookStatePath(['i18n', 'currentLanguage']);
  const path = ['i18n', 'missingTranslation', currentLanguage].concat(nsArr);
  const ret = globalStatehook.setHookStatePath(path, {
    time: Date.now(),
  });
  clearTimeout(lastHandleRanRecordMissingTranslation);
  lastHandleRanRecordMissingTranslation = setTimeout(() => {
    localStorage.setItem('##I18N##missingTranslation', JSON.stringify(globalStatehook.getHookStatePath(['i18n', 'missingTranslation'])));
  }, timeoutRanClearRecordMissingTranslation * 1e3);
  return ret;
};

const timeoutRanClearUnrecordMissingTranslation = 1;
let lastHandleRanUnrecordMissingTranslation = 0;
const unrecordMissingTranslation = function unrecordMissingTranslation(nsArr) {
  const currentLanguage = globalStatehook.getHookStatePath(['i18n', 'currentLanguage']);
  const path = ['i18n', 'missingTranslation', currentLanguage].concat(nsArr);
  const ret = globalStatehook.deleteHookStatePath(path);

  clearTimeout(lastHandleRanUnrecordMissingTranslation);
  lastHandleRanUnrecordMissingTranslation = setTimeout(() => {
    localStorage.setItem('##I18N##missingTranslation', JSON.stringify(globalStatehook.getHookStatePath(['i18n', 'missingTranslation'])));
  }, timeoutRanClearUnrecordMissingTranslation * 1e3);
  return ret;
};

const parseTag = function parseTag(translationText, ...rest) {
  tagParsers.forEach((aTagParser) => {
    translationText = aTagParser.parser(
      { tag_name: aTagParser.tag_name },
      translationText,
      ...rest,
    );
  });
  return translationText;
};

export const getNS = ns => (key, variable, opt) => {
  if (!ns) ns = [];
  if (!Array.isArray(ns)) ns = [ns];
  const path = [].concat(['i18n', 'currentTranslation', 'translations'], ns, key);
  let translationText = globalStatehook.getHookStatePath(path);
  if (!translationText) {
    recordMissingTranslation(path);
    if (Array.isArray(key)) {
      translationText = key[key.length - 1];
    } else {
      translationText = key;
    }
  }
  translationText = `${translationText}`.trim();
  translationText = parseTag(translationText, variable, opt);
  if (opt) {
    if (contentParser[opt.parser]) {
      translationText = contentParser[opt.parser](translationText);
    }
  }
  return translationText;
};

export const getTemplateNS = ns => (template, variable, opt) => {
  if (!ns) ns = [];
  if (!Array.isArray(ns)) ns = [ns];
  const r = /{i18n{(.*?)}i18n}/g;
  return template.replace(r, (matched, keyName) => {
    const path = [].concat(['i18n', 'currentTranslation', 'translations'], ns, keyName);
    let translationText = globalStatehook.getHookStatePath(path);
    if (translationText) {
      unrecordMissingTranslation(path);
    } else {
      recordMissingTranslation(path);
    }
    translationText = translationText || keyName;
    translationText = `${translationText}`.trim();
    translationText = parseTag(translationText, variable, opt);
    if (opt) {
      if (contentParser[opt.parser]) {
        translationText = contentParser[opt.parser](translationText);
      }
    }
    return translationText;
  });
};

export const changeLanguage = (lang) => {
  globalStatehook.setHookStatePath('i18n.currentLanguage', lang);
};

export const getCurrentLanguage = () => globalStatehook.getHookStatePath('i18n.currentLanguage');

export const watchCurrentLanguage = cb => globalStatehook.watchHookStatePath('i18n.currentLanguage', cb);

watchCurrentLanguage(() => {
  switch (globalStatehook.getHookStatePath('i18n.currentLanguage')) {
    case 'french': {
      frenchTranslationGenerator().then((translation) => {
        globalStatehook.setHookStatePath('i18n.currentTranslation', translation);
        moment.locale('fr');
      });
      break;
    }
    case 'spanish': {
      spanishTranslationGenerator().then((translation) => {
        globalStatehook.setHookStatePath('i18n.currentTranslation', translation);
        moment.locale('es-us');
      });
      break;
    }
    case 'en':
    default: {
      enTranslationGenerator().then((translation) => {
        globalStatehook.setHookStatePath('i18n.currentTranslation', translation);
        moment.locale('en-gb');
      });
      break;
    }
  }
});

export default {
  changeLanguage,
  getNS,
  getTemplateNS,
  getCurrentLanguage,
  watchCurrentLanguage,
  getLanguageInfo,
};
