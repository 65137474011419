/* eslint-disable no-unused-vars */
import classRoomStudentsStatehook from 'store/classRoomStudentsStatehook';

export const executor = (packet) => {
  const { payload, topic } = packet;
  const studentId = payload.student_id;
  const [_, classRoomCode] = /to\/classroom\/(.+)\/student\/ping/.exec(topic);
  classRoomStudentsStatehook.setLastMQTTPingTime(classRoomCode, studentId, new Date());
};

export default executor;
