import Ajv from 'ajv';

const ajv = new Ajv();

export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/variables_update_all',
    },
    payload: {
      type: 'object',
    },
  },
  required: ['topic', 'payload'],
  additionalProperties: true,
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
