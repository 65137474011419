import {
  escapeRegExp,
} from 'utils';
import { statehookify } from './hookFactory';

// const log = require('debug')('statehook:blocklySearchStatehook.js');

function asyncNext(funcArr, upperArgs, idx) {
  idx = idx || 0;
  if (typeof funcArr[idx] !== 'function') return;
  upperArgs = upperArgs || [];
  funcArr[idx]((args) => {
    asyncNext(funcArr, args, idx + 1);
  }, upperArgs);
}

function appendKaiCategoryPathIntoBlockElement(children, categoryPath) {
  categoryPath = categoryPath || [];
  for (let index = 0; index < children.length; index += 1) {
    const element = children[index];
    const { tagName } = element;
    if (tagName === 'category') {
      const attributeName = element.getAttribute('name');
      appendKaiCategoryPathIntoBlockElement(element.children, [].concat(categoryPath, `${attributeName}`));
    } else if (tagName === 'block') {
      element.setAttribute('x-category-path', JSON.stringify(categoryPath));
    }
  }
}

const factory = (hookName) => {
  return statehookify({ hookName, bindThisToBase: true }, {
    get isBlockSearchEstablishingMode() {
      return this.getHookStatePath('isBlockSearchEstablishingMode');
    },
    set isBlockSearchEstablishingMode(v) {
      return this.setHookStatePath('isBlockSearchEstablishingMode', v);
    },
    get BlocklyLibrary() {
      return this.getHookStatePath('BlocklyLibrary');
    },
    set BlocklyLibrary(v) {
      return this.setHookStatePath('BlocklyLibrary', v);
    },
    get toolboxXmlDom() {
      return this.getHookStatePath('toolboxXmlDom');
    },
    set toolboxXmlDom(v) {
      return this.setHookStatePath('toolboxXmlDom', v);
    },
    get toolboxXmlText() {
      return this.getHookStatePath('toolboxXmlText');
    },
    set toolboxXmlText(v) {
      return this.setHookStatePath('toolboxXmlText', v);
    },
    get indexes() {
      return this.getHookStatePath('indexes') || {};
    },
    set indexes(v) {
      throw new Error('Cannot allocate a value to indexes property!');
    },
    injectMonkeyPatchToBlocklyLibrary(BlocklyLibrary) {
      this.BlocklyLibrary = BlocklyLibrary;
      return this.injectMonkeyPatchToSingleBlock(BlocklyLibrary.Block.prototype);
    },
    injectMonkeyPatchToSingleBlock(oneBlock) {
      if (oneBlock._blocklySearchStatehook_injected) return;
      oneBlock._blocklySearchStatehook_injected = true;
      const thisHook = this;
      const {
        jsonInit,
        appendField,
        setTooltip,
        setHelpUrl,
        setCommentText,
      } = oneBlock;
      oneBlock.jsonInit = function _jsonInit(...args) {
        return jsonInit.apply(this, thisHook.collectByJsonInit(args, this));
      };
      oneBlock.appendField = function _appendField(...args) {
        return appendField.apply(this, thisHook.collectByAppendField(args, this));
      };
      oneBlock.setTooltip = function _setTooltip(...args) {
        return setTooltip.apply(this, thisHook.collectBySetTooltip(args, this));
      };
      oneBlock.setHelpUrl = function _setHelpUrl(...args) {
        return setHelpUrl.apply(this, thisHook.collectBySetHelpUrl(args, this));
      };
      oneBlock.setCommentText = function _setCommentText(...args) {
        return setCommentText.apply(this, thisHook.collectBySetCommentText(args, this));
      };
    },
    // differnt methods for collecting block data from different functions in blockly
    collect(blocklyType, ns, data) {
      this.setHookStatePath(['indexes', blocklyType, 'keywords', ns], data);
    },
    collectByJsonInit(args, thisBlock) {
      if (this.isBlockSearchEstablishingMode) {
        if (thisBlock.isInFlyout) {
          const initParams = args[0];
          Object.entries(initParams).forEach(([_key, _value]) => {
            if (_key.startsWith('message')) {
              this.collect(thisBlock.type, 'jsonInt', _value);
            }
          });
          // TODO: codeBlocklyPythonStatehook.addFunctionFromJsonInit(this.type, initParams);
        }
      }
      return args;
    },
    collectByAppendField(args, thisBlock) {
      if (this.isBlockSearchEstablishingMode) {
        if (thisBlock.isInFlyout) {
          args.forEach((content) => {
            if (typeof content === 'string') {
              this.collect(thisBlock.type, 'appendField', content);
            }
          });
        }
      }
      return args;
    },
    collectBySetTooltip(args, thisBlock) {
      if (this.isBlockSearchEstablishingMode) {
        if (thisBlock.isInFlyout) {
          args.forEach((content) => {
            if (typeof content === 'string') {
              this.collect(thisBlock.type, 'setTooltip', content);
            }
          });
        }
      }
      return args;
    },
    collectBySetHelpUrl(args, thisBlock) {
      if (this.isBlockSearchEstablishingMode) {
        if (thisBlock.isInFlyout) {
          args.forEach((content) => {
            if (typeof content === 'string') {
              this.collect(thisBlock.type, 'setHelpUrl', content);
            }
          });
        }
      }
      return args;
    },
    collectBySetCommentText(args, thisBlock) {
      if (this.isBlockSearchEstablishingMode) {
        if (thisBlock.isInFlyout) {
          args.forEach((content) => {
            if (typeof content === 'string') {
              this.collect(thisBlock.type, 'setCommentText', content);
            }
          });
        }
      }
      return args;
    },
    establishIndexes(toolboxXmlText, blocklyWorkspace, BlocklyLibrary) {
      BlocklyLibrary = BlocklyLibrary || this.BlocklyLibrary;
      if (this.isBlockSearchEstablishingMode) return;
      this.isBlockSearchEstablishingMode = true;
      let toolboxXmlDom = null;
      if (typeof toolboxXmlText === 'string') {
        toolboxXmlDom = BlocklyLibrary.Xml.textToDom(toolboxXmlText);
      } else {
        toolboxXmlDom = toolboxXmlText;
        toolboxXmlText = (new XMLSerializer()).serializeToString(toolboxXmlDom);
      }
      appendKaiCategoryPathIntoBlockElement(toolboxXmlDom.children);
      this.toolboxXmlDom = toolboxXmlDom;
      this.toolboxXmlText = toolboxXmlText;
      const originalXmlDom = blocklyWorkspace.options.languageTree;
      blocklyWorkspace.updateToolbox(toolboxXmlDom);
      const allTheBlockDefinition = toolboxXmlDom.getElementsByTagName('block');
      // chunk every 10 blocks
      Array.from(allTheBlockDefinition).reduce((acc, curr, idx) => {
        acc.push(curr);
        if (acc.length === 10 || idx + 1 === allTheBlockDefinition.length) {
          blocklyWorkspace.toolbox_.flyout_.show(acc);
          return [];
        }
        return acc;
      }, []);
      blocklyWorkspace.toolbox_.flyout_.show([]);
      blocklyWorkspace.toolbox_.flyout_.setVisible(false);
      this.isBlockSearchEstablishingMode = false;
      // back to original
      blocklyWorkspace.updateToolbox(originalXmlDom);
      console.info('blocklySearchStatehook: flyout init finished');
    },
    search(key, cb) {
      let keywordArray = null;
      let error = null;
      let resultFromIndexes = null;
      asyncNext([(next) => {
        if (key && `${key}`.length >= 2) {
          keywordArray = key.split(' ');
          this.findInIndexes(keywordArray, (_error, _resultFromIndexes) => {
            resultFromIndexes = _resultFromIndexes;
            next();
          });
        }
      }, () => {
        const toolboxRef = this.toolboxXmlDom;
        const resultFilded = Object.keys(resultFromIndexes || {}).reduce((acc, blockName) => {
          const res = toolboxRef.querySelector(`[type="${blockName}"]`);
          if (res) acc.push(res);
          return acc;
        }, []);
        cb(null, {
          error,
          results: resultFilded,
          keywordArray,
          keyword: key,
        });
      }]);
    },

    findInIndexes(keys, cb) {
      function searchKeywordsInDBKeyWords(searchKeys, dbKeys) {
        searchKeys = Array.from(searchKeys);
        const dbKeysLinear = dbKeys.join(', ');
        while (searchKeys.length) {
          const seK = searchKeys.pop();
          if (seK) {
            const isHit = RegExp(`.*${escapeRegExp(seK)}.*`, 'i').test(dbKeysLinear);
            if (!isHit) return false;
          }
        }
        return true;
      }
      if (!keys) return cb(null, null);
      keys = Array.isArray(keys) ? keys : [keys];
      if (keys.length === 0) return cb(null, null);
      const searchInThisDB = this.indexes || {};
      const results = Object.entries(searchInThisDB)
        .reduce((acc, [blockName, content]) => {
          const keysForThisBlock = Object.values(searchInThisDB[blockName].keywords);
          keysForThisBlock.push(blockName); // add the block name to its key pool
          const isHit = searchKeywordsInDBKeyWords(keys, keysForThisBlock);
          if (isHit) {
            acc[blockName] = content;
          }
          return acc;
        }, {});
      return cb(null, results);
    },
  });
};


export default factory('blocklySearchStatehook_default');
export { factory };
