import { isStateTreeNode } from 'mobx-state-tree';
import userStore from 'store/userStore';
import teacherRecoveryStore from './teacherRecoveryStore';
import teacherResetStore from './teacherResetStore';
import classRoomStore from './classRoomStore';
import classRoomStudentsStore from './classRoomStudentsStore';
import classRoomRobotsStore from './classRoomRobotsStore';
import uiStore from './uiStore';
import userMediaStore from './userMediaStore';
import webStorageStore from './webStorageStore';
import pathfindingStore from './pathfindingStore';
import systemConfigStore from './systemConfigStore';
import blocklyStore from './blocklyStore';
import { globalStatehook } from './statehook/globalStatehook';
import threeDModelStatehook from './threeDModelStatehook';
import classroomAssistanceHook from './statehook/classroomAssistanceHook';
import accountStateStoreHook from './statehook/accountStateStoreHook';
import projectHook from './statehook/projectHook';
import classroomWorkHook from './statehook/classroomWorkHook';
import studentClassroomJointHook from './statehook/studentClassroomJointHook';
import googlesheetsHook from './statehook/googlesheetsHook';
import appConfigStatehook from './statehook/appConfigStatehook';

/**
 * Export stores in an single object
 * @todo use readdirSync api to read files from this directorty and export
 */
export default {
  userStore,
  classRoomStore,
  classRoomStudentsStore,
  classRoomRobotsStore,
  teacherRecoveryStore,
  teacherResetStore,
  uiStore,
  userMediaStore,
  webStorageStore,
  pathfindingStore,
  systemConfigStore,
  blocklyStore,
  globalStatehook,
  threeDModelStatehook,
  classroomAssistanceHook,
  accountStateStoreHook,
  projectHook,
  classroomWorkHook,
  studentClassroomJointHook,
  googlesheetsHook,
  appConfigStatehook,
};

/**
 * Validate agains mobx-state-tree
 *
 * @see https://github.com/mobxjs/mobx-state-tree/issues/365#issuecomment-355936283
 * @param type
 * @returns {function(*, *)}
 */
export function modelOf(type) {
  const fn = (props, propName) => {
    if (isStateTreeNode(props[propName]) && type.is(props[propName])) {
      return null;
    }
    return new Error(`Invalid value for prop ${propName}`);
  };

  fn.isRequired = (props, propName) => !(propName in props) ? new Error(`Missing prop ${propName} in props`) : fn(props, propName);

  return fn;
}
