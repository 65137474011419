import {
  cond,
  T,
  pipe,
  prop,
  objOf,
  converge,
  invoker,
  always,
  identity,
  ifElse,
  isNil,
  propEq,
  not,
  both,
  has,
  curry,
  compose,
  any,
  gt,
  length,
  reduce,
  lensProp,
  view,
  type,
  equals,
} from 'ramda';
import mongoDbQueryErrorParse from './mongoDbQueryErrorParse';
import mongoDbValidationErrorParse from './mongoDbValidationErrorParse';
import exceptionErrorParse from './exceptionErrorParse';
import expressValidatorErrorParse from './expressValidatorErrorParse';
import axiosErrorParse from './axiosErrorParse';

/**
 * Extract and format error from serve response.
 *
 * @see https://goo.gl/Ty4rn2
 * @param {Object} response - The response from api request.
 * @returns {Object} - Dehydrated error.
 */
export function dehydrateError(response) {
  const resolve = cond([
    expressValidatorErrorParse,
    mongoDbQueryErrorParse,
    mongoDbValidationErrorParse,
    axiosErrorParse,
    exceptionErrorParse,
    [T, pipe(prop('statusText'), objOf('message'))],
  ]);

  return resolve(response);
}

/**
 * If from is defined and is rejected, dehydrate error.
 *
 * @param {?Object} from - An fromPromise result.
 * @returns {Object} - Error dehydrated.
 */
export function extractErrors(from) {
  const caseConfig = {
    rejected: dehydrateError,
  };
  const caseExec = converge(invoker(1, 'case'), [always(caseConfig), identity]);
  const getCaseResult = ifElse(isNil, identity, caseExec);
  return getCaseResult(from);
}

/**
 * Build a function that check if there are any error for the specific property.
 *
 * @see https://goo.gl/yk74Qr
 * @param {?Object} errors - An object that contain all errors.
 * @example <caption>Check if there are error on property name.</caption>
 * const errors = [ {field: 'name'}, {field: 'location'} ]
 * const hasErrorFor = buildErrorChecker(errors);
 * hasErrorFor('name') // true
 * hasErrorFor('age') // false
 * @returns {Function} - Function that check if there are error for the field specified in param.
 */
export function buildErrorChecker(errors) {
  const fieldEq = propEq('field');
  const isNotNil = pipe(isNil, not);
  const getErrorsOrEmptyArray = ifElse(both(isNotNil, has('errors')), prop('errors'), always([]));
  const checkErrorField = curry((obj, field) =>
    compose(any(fieldEq(field)), getErrorsOrEmptyArray)(obj));
  return checkErrorField(errors);
}

/**
 * Given an error, display an popup.
 *
 * @see https://goo.gl/U7OTzS
 * @param {!Object|!String|!Array} error - Error to display.
 * @param {string} [title='Ops...'] - Title of alert
 * @param {string} [defaultMessage='Error'] - If non error was found, display this message.
 * @returns {Promise<any>} - Promise answer of swal.
 */
export function popupError(error, title = 'Oops...', defaultMessage = 'Error') {
  // Verify if has errors
  const errorsProp = prop('errors');
  const hasMoreThatZero = pipe(errorsProp, converge(gt, [length, always(0)]));
  const hasErrors = both(has('errors'), hasMoreThatZero);

  // Transform to html
  const getItem = (acc, next) => `${acc}<div class="item">
      <div class="content">
        <div class="header">${next.field}</div>
        <div class="description">${next.message}</div>
      </div>
    </div>`;
  const appendUl = r => `${r}</div>`; // concat not working.. https://github.com/ramda/ramda/issues/1805
  const getList = reduce(getItem, '<div class="ui very relaxed list">');
  const getHtmlErrors = pipe(errorsProp, getList, appendUl);

  const hasMessage = has('message');
  const messageLens = lensProp('message');
  const getMessage = view(messageLens);

  const isString = pipe(type, equals('String'));

  const getBody = cond([
    [hasErrors, getHtmlErrors],
    [hasMessage, getMessage],
    [isString, identity],
    [T, always(defaultMessage)],
  ]);

  const config = { title, html: getBody(error), type: 'error' };

  return kaiAlert.fire(config);
}
