/* eslint-disable import/prefer-default-export */
import api from 'api';

export const get3DModelById = (threeDModelId) => {
  return api.get(`/3d/model/${threeDModelId}`);
};

export const getAll3DModelsFromClassroom = (classRoomCode, opt) => {
  opt = opt || {};
  let pageNumber = opt.pageNumber || 1;
  pageNumber = Math.max(pageNumber, 1);
  const urlSearch = new URLSearchParams();
  urlSearch.set('p', pageNumber);
  return api.get(`/3d/model/classroom/${classRoomCode}?${urlSearch.toString()}`);
};

export const delete3DModelFromClassroom = (classRoomCode, modelId) => {
  return api.delete(`/3d/model/classroom/${classRoomCode}/${modelId}`);
};

export const get3DModelFromClassroom = (classRoomCode, modelId) => {
  return api.get(`/3d/model/classroom/${classRoomCode}/${modelId}`);
};

// global
export const getAll3DModelsFromGlobal = (opt) => {
  opt = opt || {};
  let pageNumber = opt.pageNumber || 1;
  pageNumber = Math.max(pageNumber, 1);
  const urlSearch = new URLSearchParams();
  urlSearch.set('p', pageNumber);
  return api.get(`/3d/model/namespace/global?${urlSearch.toString()}`);
};

export const get3DModelFromGlobal = (modelId) => {
  return api.get(`/3d/model/namespace/global/${modelId}`);
};

export const getAllAllocationFromClassroom = (classRoomCode) => {
  return api.get(`/3d/model/classroom/${classRoomCode}/allocation`);
};

export const allocateModelIdToQRCodeFromClassroom = (classRoomCode, allocationData) => {
  // { qrcode: modelId }
  return api.post(`/3d/model/classroom/${classRoomCode}/allocation`, allocationData);
};

export const unallocateModelIdToQRCodeFromClassroom = (classRoomCode, qrcodeNumber) => {
  // [ qrcode, qrcode ]
  return api.delete(`/3d/model/classroom/${classRoomCode}/allocation/robot/${qrcodeNumber}`);
};

export const modifyModelData = (classRoomCode, modelId, modelData) => {
  return api.patch(`/3d/model/classroom/${classRoomCode}/${modelId}`, modelData);
};

export const accept3DModel = (classRoomCode, data) => {
  return api.post(`/3d/model/classroom/${classRoomCode}/accept3DModel`, data);
};

export const fetchGooglePolyApiAssets = (keywords, category) => {
  const urlSearch = new URLSearchParams();
  const params = 'string' === typeof keywords ? {} : keywords;
  if ('string' === typeof keywords) {
    params.keywords = keywords;
  }
  if ('string' === typeof category) {
    params.category = category;
  }
  Object.keys(params).forEach((key) => {
    urlSearch.set(key, params[key]);
  });
  return api.get(`/3d/google_poly_api/v1/assets?${urlSearch.toString()}`);
};

export const fetchTenorGifApiSearch = (q, tag) => {
  const urlSearch = new URLSearchParams();
  if (q) {
    urlSearch.set('q', q);
  }
  if (tag) {
    urlSearch.set('tag', tag);
  }
  return api.get(`/3d/tenor_gif_api/v1/search?${urlSearch.toString()}`);
};

export const fetchTenorGifApiCategories = () => {
  return api.get('/3d/tenor_gif_api/v1/categories');
};
