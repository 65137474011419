import Blockly from 'blockly';
import { tryParseIntOrOriginal } from 'utils';
import { getDropdownValue } from 'utils/blocklyUtils';
// import mapSpecs from '../../../map_specs';
import classRoomStore from '../../../../../store/classRoomStore';
import blocklyStore from '../../../../../store/blocklyStore';
// import { sensorSpecs } from '../../../sensor_specs';
// import { COLORS } from '../../../dictionary';
// import blockFactory from './blockFactory';
import blockChecker,
{
  blockCheckerDecorator,
  BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
  BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
} from './blockChecker';

const {
  checkSensorOnRobot,
  addErrorForNoClassroomVariable,
} = blockChecker;

// const { jsCodeBy } = blockFactory;

// const log = require('debug')('ui:js_code.js');

function decToHex(number, minimumLength = 0) {
  const hex = Number(number).toString(16);
  if (minimumLength > 0) {
    const howMany0 = minimumLength - hex.length;
    if (howMany0 > 0) {
      return `${'0'.repeat(howMany0)}${hex}`;
    }
  }
  return hex;
}

function parseToColourObjectAnyway(colourObjectStr) {
  let colourObject;
  if ('string' === typeof colourObjectStr) {
    try {
      colourObject = JSON.parse(colourObjectStr);
    } catch (error) {
      colourObject = null;
      console.reportError(error, 'Cannot parse end colour object:', colourObjectStr);
    }
  }
  return Object.assign({
    r: 0,
    g: 0,
    b: 0,
  }, colourObject || {});
}

function parseColourObjectToHex(_colourObject) {
  const colourObject = parseToColourObjectAnyway(_colourObject);
  return `#${decToHex(colourObject.r, 2)}${decToHex(colourObject.g, 2)}${decToHex(colourObject.b, 2)}`;
}

Blockly.JavaScript.setConfig = (block) => {
  const name = block.getFieldValue('NAME');
  const value = Blockly.JavaScript.valueToCode(
    block,
    'VALUE',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await classRoom.setVariable('${name}', ${value}, { id: '${block.id}', priority });`;
};

Blockly.JavaScript.set_classroom_variable = (block, ...rest) => {
  if (!block.getFieldValue('NAME')) {
    addErrorForNoClassroomVariable(block);
  }
  return Blockly.JavaScript.setConfig(block, ...rest);
};

Blockly.JavaScript.getConfig = block => [
  `classRoom.getVariable('${block.getFieldValue('NAME')}')`,
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];

Blockly.JavaScript.get_classroom_variable = Blockly.JavaScript.getConfig;

/*
 * define blockly from here, order by advanced toolbox
 */
Blockly.JavaScript.runProgram = (block) => {
  classRoomStore.jsCodeOfSetupBlock = Blockly.JavaScript.statementToCode(block, 'SETUP');
  classRoomStore.jsCodeOfLoopBlock = Blockly.JavaScript.statementToCode(block, 'LOOP');
  classRoomStore.jsCodeOfEventsBlock = Blockly.JavaScript.statementToCode(block, 'EVENTS');
  return `
F.blockEvents(function (priority) {
  var eventsLock = 0;
  ${classRoomStore.jsCodeOfEventsBlock}
});

await F.blockRun(async function (priority) {
  ${classRoomStore.jsCodeOfSetupBlock}
});

F.blockLoop(async function(priority) {
  ${classRoomStore.jsCodeOfLoopBlock}
});

`;
};
/*
 * PART 1-1: motion --> basic
 */
Blockly.JavaScript.motion_move__set_gear =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `R({blocklyId: '${block.id}'}, ${ROBOT}).setGear(${block.getFieldValue('GEAR')});\n`;
  });
/**
 * Send JSON command to robot to move forward in CM
 */
Blockly.JavaScript.motion_move__move_forwards_distance =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const amount = Blockly.JavaScript.valueToCode(
      block,
      'AMOUNT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveForward(${amount}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to move backward in CM
 */
Blockly.JavaScript.motion_move__move_backwards_distance =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const amount = Blockly.JavaScript.valueToCode(
      block,
      'AMOUNT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveBackward(${amount}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to turn left by degree
 */
Blockly.JavaScript.motion_angle__turn_left =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .turnAngle(-${Blockly.JavaScript.valueToCode(block, 'ANGLE', Blockly.JavaScript.ORDER_ATOMIC)}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to turn right by degree
 */
Blockly.JavaScript.motion_angle__turn_right =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .turnAngle(${Blockly.JavaScript.valueToCode(block, 'ANGLE', Blockly.JavaScript.ORDER_ATOMIC)}, { id: '${block.id}', priority });\n`;
  });
/*
 * PART 1-2: motion --> destination
 * Send JSON command to robot to turn to a traget degree
 */
Blockly.JavaScript.motion_angle__change_to =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .changeToAngle(${Blockly.JavaScript.valueToCode(block, 'ANGLE', Blockly.JavaScript.ORDER_ATOMIC)}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to move to position by target position
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__move_to_position_obj =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: 0, method: 1}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  // moveToObjXY(
  });
/**
 * Send JSON command to robot to move to position by target position,
 * but stop at a distance before arrive
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__stopXYObjWithDistance =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const dt = Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 2}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  // moveToObjXY(
  });
/**
 * generate position object by input X and Y
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.sensing__targetXYObj = (block) => {
  const xt = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_X',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const yt = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_Y',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  // const code = {
  //   objType: 'target_XY',
  //   objValueX: xt,
  //   objValueY: yt,
  // };
  // return [JSON.stringify(code), Blockly.JavaScript.ORDER_ATOMIC];
  const code = `{objType:'target_XY', objValueX:${xt} * 10, objValueY:${yt} * 10}`;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * generate position object by reading a robot's X and Y
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.sensing__robotXYAObj =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xr = `R({blocklyId: '${block.id}'}, ${ROBOT}).x || 0`;
    const yr = `R({blocklyId: '${block.id}'}, ${ROBOT}).y || 0`;
    // const ar = `R({blocklyId: '${block.id}'}, ${ROBOT}).a || 0`;
    const code = `{'objType': 'robot_XYA', 'objValueX': ${xr}, 'objValueY': ${yr}}`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
  });
/**
 * generate position object by reading map mark's X and Y on mat
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.sensing__mapMarkXYObj = () => {
  const code =
    '{objType: \'target_XY\', objValueX: classRoom.getRuntimeVariable("mapMark-mapX") * 10, objValueY: classRoom.getRuntimeVariable("mapMark-mapY") * 10}';
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * calculate distance between position A and position B
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.sensing__distanceOfPositionXYObj = (block) => {
  const positionA = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_XY_A',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const positionB = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_XY_B',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `blocklyCalculatePositionsDistance(${positionA}, ${positionB})\n\n`;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};
Blockly.JavaScript.sensing__positionXYObjOutBoundary = (block) => {
  const position = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_XY',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `blocklyPositionsOutBoundary(${position})\n\n`;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * Send JSON command to robot to find a path to target position by a text name
 */
Blockly.JavaScript.motion_move__go_to_place =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const place = block.getFieldValue('PLACE');
    const mode = 1;
    const xy = `{objType: '${place}', objValueX: 0, objValueY: 0}`;
    const paramsString = `{targetXY: ${xy}, driveDistance: 0, stopDistance: 0, method: 1}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .goTo(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
Blockly.JavaScript.motion_move__go_to_place_with_input_name =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const place = block.getFieldValue('PLACE_NAME');
    const mode = 1;
    const xy = `{objType: '${place}', objValueX: 0, objValueY: 0}`;
    const paramsString = `{targetXY: ${xy}, driveDistance: 0, stopDistance: 0, method: 1}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .goTo(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to find a path to target position
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__go_to_XYPosition_by_the_pathfinding =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const mode = 1;
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{targetXY: ${xy}, driveDistance: 0, stopDistance: 0, method: 1}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .goTo(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * PART 1-4: motion --> position adjustment
 * Send JSON command to robot to move to a target position accurately with multiple step adjustment
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__move_to_XY_obj_with_self_adjustment =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: 0, method: 1}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
    // moveToWithSelfAdjustment
  });
/**
 * Send JSON command to robot to move to a target position accurately with multiple step adjustment,
 * but stop at a distance before arrive
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__move_to_XY_obj_stop_distance_with_self_adjustment =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const dt = Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 1}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * PART 1-5: motion --> interaction
 * Send JSON command to robot to move to a target position accurately,
 * stop at the position where the gripper can just reach the object
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__grabObjAtXY =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const dt = 6; // cm
    const xy = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_XY',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 1}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to move to a target position accurately,
 * stop at the position where the gripper can just drop the object
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_move__releaseObjAtXY =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const dt = 6; // cm
    const xy = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_XY',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 1}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to move to a target position then go by a distance
 * @object position {'targetXY': {objType: 'target_XY', objValueX: X, objValueY: Y}}
 */
Blockly.JavaScript.motion_follow__stopXYObjWithDistance =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const dt = Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const xy =
      Blockly.JavaScript.valueToCode(
        block,
        'POSITION_XY',
        Blockly.JavaScript.ORDER_ATOMIC,
      ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const paramsString = `{'targetXY':${xy}, driveDistance: ${dt} * 10, stopDistance: 0, method: 3}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXY(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  // moveToObjXY(
  });
/**
 * PART 1-6: motion --> special
 * Send JSON command to robot to stop immediately, this is usually triggered by event
 */
Blockly.JavaScript.motion_move__stop =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const mode = 2;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .stop({ id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to move a circle by center angle
 */
Blockly.JavaScript.motion_special__circle =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));

    const dia = parseInt(block.getFieldValue('DIAMETER'), 10);
    const leftRight = parseInt(block.getFieldValue('LEFT_RIGHT'), 10);
    const angle = Blockly.JavaScript.valueToCode(
      block,
      'ANGLE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const direction = 3;
    let speedL = 0;
    let speedR = 0;
    if (dia === 1) {
      if (leftRight === 2) {
        speedL = 4;
        speedR = 10;
      } else if (leftRight === 1) {
        speedL = 10;
        speedR = 4;
      }
    } else if (dia === 2) {
      if (leftRight === 2) {
        speedL = 4;
        speedR = 8;
      } else if (leftRight === 1) {
        speedL = 8;
        speedR = 4;
      }
    } else if (dia === 3) {
      if (leftRight === 2) {
        speedL = 4;
        speedR = 6;
      } else if (leftRight === 1) {
        speedL = 6;
        speedR = 4;
      }
    }
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveGoCircle(${speedL}, ${speedR}, ${direction}, ${angle}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to move a U-turn
 */
Blockly.JavaScript.motion_special__u_turn =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const direction = parseInt(block.getFieldValue('DIRECTION'), 10);
    const rotation = parseInt(block.getFieldValue('ROTATION'), 10);
    const angle = Blockly.JavaScript.valueToCode(
      block,
      'ANGLE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveUTurn(${direction}, ${rotation}, ${angle}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to free move
 */
Blockly.JavaScript.motion_special__free_move =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const leftDirection = parseInt(block.getFieldValue('LEFT_DIR'), 10);
    const leftSpeed = parseInt(block.getFieldValue('LEFT_SPEED'), 10);
    const rightDirection = parseInt(block.getFieldValue('RIGHT_DIR'), 10);
    const rightSpeed = parseInt(block.getFieldValue('RIGHT_SPEED'), 10);
    const time = Blockly.JavaScript.valueToCode(
      block,
      'TIME',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveFree(${leftDirection}, ${leftSpeed}, ${rightDirection}, ${rightSpeed}, ${time}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });

/**
 * PART 2-1: bits --> on-board
 * Send JSON command to robot to open gripper
 * @sensor Gripper (on-board), command only takes effect after sensor are configured
 */
Blockly.JavaScript.motion_servo__open_close_toggle =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    // checkSensorOnRobot(block, ROBOT, 'S2');
    const openClose = Blockly.JavaScript.valueToCode(
      block,
      'OPEN_CLOSE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .servoSwitchTo(${openClose}, { id: '${block.id}', priority });\n`;
  });
/**
 * Read gripper status (Boolean) from real-time robot sensor update variables
 * @sensor Gripper (on-board), command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__gripper =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    // checkSensorOnRobot(block, ROBOT, 'S2');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S2', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * Read ultrasonic sensor value (mm) from real-time robot sensor update variables
 * @sensor Ultrasonic Sensor (on-board), command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__ultrasonic_distance_sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S13');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S13', 'float')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * Send JSON command to robot to beep the on-board buzzer
 * @sensor Buzzer (on-board), always enabled
 * @object {music: {objType: 'sound_RTTTLList',
 * index: 1, cmd: "Don't Care:d=16,o=5,b=125:f,e,f", frequency: 1000, duration: 500}}
 */
Blockly.JavaScript.sound_board_buzzer_play__sound =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const volume = Blockly.JavaScript.valueToCode(
      block,
      'VOLUME',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const music = Blockly.JavaScript.valueToCode(
      block,
      'MUSIC',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{music:${music}, volume: ${volume}}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .boardBuzzerPlay(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to light headlight by color
 * @sensor Headlights (on-board), always enabled
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 */
Blockly.JavaScript.leddiffuser_set_colorObj =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const headlight = block.getFieldValue('HEADLIGHT');
    const color = Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{color:${color}, mode: 1, light: ${headlight}}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT}).diffuserSetColor(${paramsString}, { id: '${block.id}'
  , mode: ${mode}, priority });\n`;
  });
/**
 * Send JSON command to robot to flash headlight
 * @sensor Headlights (on-board), always enabled
 */
Blockly.JavaScript.leddiffuser_blink =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const brightness = Blockly.JavaScript.valueToCode(
      block,
      'BRIGHTNESS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // const ontime = block.getFieldValue('ON_TIME_MS');
    const periodMs = Blockly.JavaScript.valueToCode(
      block,
      'PERIOD_MS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // const offtime = block.getFieldValue('OFF_TIME_MS');
    const dutyMs = 40;
    const mode = 1;
    const paramsString = `{"mode":2, "period":${periodMs}, "duty":${dutyMs}, "brightness":${brightness}}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .diffuserBlink(${paramsString}, { id: '${block.id}', mode: ${mode}, priority } );\n\n`;
  });
/**
 * Send JSON command to robot to swap color when flashing headlight
 * @sensor Headlights (on-board), always enabled
 */
Blockly.JavaScript.leddiffuser_blink_swap =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const enabled = Blockly.JavaScript.valueToCode(
      block,
      'YES_NO',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .diffuserSwapColor(${enabled}, { id: '${block.id}', priority });\n`;
  });
/**
 * PART 2-2: bits --> sound
 * Send JSON command to robot to beep an external buzzer
 * @sensor Buzzer, command only takes effect after sensor are configured
 * @object {music: {objType: 'sound_RTTTLList', index: 1,
 * cmd: "Don't Care:d=16,o=5,b=125:f,e,f", frequency: 1000, duration: 500}}
 */
Blockly.JavaScript.sound_ext_buzzer_play__sound =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S5');
    const volume = Blockly.JavaScript.valueToCode(
      block,
      'VOLUME',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const music = Blockly.JavaScript.valueToCode(
      block,
      'MUSIC',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{music:${music}, volume: ${volume}}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .extBuzzerPlay(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * generate sound object by drop-down list
 * @object {music: {objType: 'sound_RTTTLList', index: 1,
 * cmd: "Don't Care:d=16,o=5,b=125:f,e,f", frequency: 1000, duration: 500}}
 */
Blockly.JavaScript.sensing__soundRTTTLListObj = (block) => {
  const listIndex = block.getFieldValue('LIST');
  const obj = `{objType: 'sound_RTTTLList', index: ${listIndex}}`;
  return [obj, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * generate sound object by raw RTTTL command
 * @object {music: {objType: 'sound_RTTTLList', index: 1,
 * cmd: "Don't Care:d=16,o=5,b=125:f,e,f", frequency: 1000, duration: 500}}
 */
Blockly.JavaScript.sensing__soundRTTTLCommandObj = (block) => {
  const RTTTLCommand = String(block.getFieldValue('COMMAND')).replace('"', '');
  const obj = `{objType: 'sound_RTTTLCommand', cmd: "${RTTTLCommand}"}`;
  return [obj, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * generate sound object by frequency and duration
 * @object {music: {objType: 'sound_RTTTLList', index: 1,
 * cmd: "Don't Care:d=16,o=5,b=125:f,e,f", frequency: 1000, duration: 500}}
 */
Blockly.JavaScript.sensing__soundBeepObj = (block) => {
  const frequency = Blockly.JavaScript.valueToCode(
    block,
    'FREQUENCY',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const duration = Blockly.JavaScript.valueToCode(
    block,
    'DURATION',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const obj = `{objType: 'sound_Beep', frequency: ${frequency}, duration: ${duration}}`;
  return [obj, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * read sound level (%) from real-time robot sensor update variables
 * @sensor Sound Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Sound_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S43');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S43', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });

/**
 * PART 2-3: bits --> sight
 * generate color object by color picker
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 */
Blockly.JavaScript.sensing__colorPickerObj = (block) => {
  const colour = block.getFieldValue('COLOUR');
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(colour);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);
  // const obj = `{"objType": "color_RGB", "r": ${r}, "g": ${g}, "b": ${b}}`;
  const obj = JSON.stringify({
    objType: 'color_RGB',
    r: parseInt(r, 10),
    g: parseInt(g, 10),
    b: parseInt(b, 10),
  });
  return [obj, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * generate color object by color RGB value
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 */
Blockly.JavaScript.sensing__colorRGBObj = (block) => {
  // const colour = block.getFieldValue('COLOUR');
  const r = Blockly.JavaScript.valueToCode(
    block,
    'R',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const g = Blockly.JavaScript.valueToCode(
    block,
    'G',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const b = Blockly.JavaScript.valueToCode(
    block,
    'B',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const obj = `{objType: 'color_RGB', r: ${r}, g: ${g}, b: ${b}}`;
  return [obj, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * read color from real-time robot sensor update variables and generate color object
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 * @sensor Color Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Color_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S55');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S55', 'color_RGB', 1)`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * Send JSON command to robot to light an external single LED
 * @sensor Single LED (including Bright LED and LED),
 * command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_white__adjust_brightness =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S3');
    const brightness = Blockly.JavaScript.valueToCode(
      block,
      'BRIGHTNESS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledAdjustBrightness(${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to blink an external single LED
 * @sensor Single LED (including Bright LED and LED),
 * command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_white__blink =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S3');
    const frequency = Blockly.JavaScript.valueToCode(
      block,
      'FREQUENCY',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledBlink(${frequency}, { id: '${block.id}', priority });\n`;
  });
/**
 * read brightness value (%) from real-time robot sensor update variables
 * @sensor Single LED (including Bright LED and LED),
 * command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Bright_LED =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S3');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S3', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * Send JSON command to robot to light an external RGB-LED, change all color
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__adjust_all_color =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    const color = Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{"mode":1, "index":5, "color":${color}}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripAdjustColorOfIndex(${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external RGB-LED, change one LED unit color by index
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__adjust_index_color =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    const color = Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const indexOfLED = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{"mode":1, "index":${indexOfLED}, "color":${color}}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripAdjustColorOfIndex(${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external RGB-LED, change all brightness
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__adjust_all_brightness =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    const brightness = Blockly.JavaScript.valueToCode(
      block,
      'BRIGHTNESS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const indexOfLED = 5;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripAdjustBrightnessOfIndex(${indexOfLED}, ${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external RGB-LED, change one LED unit brightness by index
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__adjust_index_brightness =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    const brightness = Blockly.JavaScript.valueToCode(
      block,
      'BRIGHTNESS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const indexOfLED = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripAdjustBrightnessOfIndex(${indexOfLED}, ${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external RGB-LED, set animation with different mode
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__light_mode =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    const mode = block.getFieldValue('MODE');
    const frequency = Blockly.JavaScript.valueToCode(
      block,
      'FREQUENCY',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripMode(${mode}, ${frequency}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external RGB-LED, reset to default color and brightness
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_strip__reset =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripReset({ id: '${block.id}', priority });\n`;
  });
/**
 * read color from real-time robot sensor update variables and generate color object
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 * @sensor RGB-LED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__led_strip__read_rgb_by_index_number =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S4');
    let index = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // index = parseInOf(index, 10) || 1;
    index = tryParseIntOrOriginal(index, 10);
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S4', 'color_RGB', ${index})`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display, by 4 numbers (each 0~9)
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_4digi_tube__display_all_4digi =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const valueA = Blockly.JavaScript.valueToCode(
      block,
      'A',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const valueB = Blockly.JavaScript.valueToCode(
      block,
      'B',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const valueC = Blockly.JavaScript.valueToCode(
      block,
      'C',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const valueD = Blockly.JavaScript.valueToCode(
      block,
      'D',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeDisplayAll4Digi(${valueA}, ${valueB}, ${valueC}, ${valueD}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display, set 1 numbers (each 0~9) by index
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_4digi_tube__display_index_4digi =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const indexOfNumber = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const valueDigi = Blockly.JavaScript.valueToCode(
      block,
      'DIGI',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeDisplayIndex4Digi(${indexOfNumber}, ${valueDigi}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display,
 * set to time MM:SS layout with 2 numbers
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_4digi_tube__display_time =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const mode = 3;
    const timeMin = Blockly.JavaScript.valueToCode(
      block,
      'MINUTES',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const timeSec = Blockly.JavaScript.valueToCode(
      block,
      'SECONDS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeDisplayTime(${mode}, ${timeMin}, ${timeSec}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display,
 * set to time MM:SS layout with 2 numbers, start countdown
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 * will trigger event after countdown to 00:00 @event
 */
Blockly.JavaScript.sight_4digi_tube__display_time_countdown =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const mode = 6;
    const timeMin = Blockly.JavaScript.valueToCode(
      block,
      'MINUTES',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const timeSec = Blockly.JavaScript.valueToCode(
      block,
      'SECONDS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeDisplayTime(${mode}, ${timeMin}, ${timeSec}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display, set a bit on/off by index
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_4digi_tube__enable_index =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const indexOfNumber = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const valueEnable = Blockly.JavaScript.valueToCode(
      block,
      'ENABLE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    let enableCode = 0;
    if (valueEnable === 'true') {
      enableCode = 1;
    }
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeEnableIndex4Digi(${indexOfNumber}, ${enableCode}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 4-Digi Display, change display brightness
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_4digi_tube__set_brightness =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const brightness = block.getFieldValue('LIST');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .digiTubeAdjustBrightness(${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * read number (0~9) from real-time robot sensor update variables
 * @sensor 4-Digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__The_4_Digit_Display =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S57');
    const index = Blockly.JavaScript.valueToCode(
      block,
      'INDEX',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S57', 'int', ${index -
      1})`,
      Blockly.JavaScript.ORDER_ATOMIC,
    ];
  });
/**
   * Send JSON command to robot to light an external OLED with a string
   * @sensor OLED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_OLED_display__set_text =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S54');
    const text = Blockly.JavaScript.valueToCode(
      block,
      'TEXT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const line = block.getFieldValue('LINE');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .oledDisplayText(${text}, ${line}, { id: '${block.id}', priority });\n`;
  });
/**
   * Send JSON command to robot to light an external OLED with a number 0~9999
   * @sensor OLED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_OLED_display__set_number =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S54');
    const number = Blockly.JavaScript.valueToCode(
      block,
      'NUMBER',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .oledDisplayNumber(${number}, { id: '${block.id}', priority });\n`;
  });
/**
   * Send JSON command to robot to light an external OLED with a string
   * @sensor OLED, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_OLED_display__set_icon =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S54');
    const icon = block.getFieldValue('ICON');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .oledDisplayIcon(${icon}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 8x8 LED matrix with a 8x8 matrix object
 * @sensor 8x8 LED Matrix, command only takes effect after sensor are configured
 * @object {matrix_8_8: [0, 0, 0, 0, 0, 0, 0, 0]}
 */
Blockly.JavaScript.sight_led_matrix_display__show =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S58');
    const pixels = Blockly.JavaScript.valueToCode(
      block,
      'PIXELS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledMatrixDisplayRawPixels(${pixels}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 8x8 LED matrix with a string,
 * will keep scrolling until next command
 * @sensor 8x8 LED Matrix, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_matrix_display__set_text =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S58');
    const text = Blockly.JavaScript.valueToCode(
      block,
      'TEXT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledMatrixDisplayText(${text}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 8x8 LED matrix, simulate a progress bar
 * @sensor 8x8 LED Matrix, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_matrix_display__set_progress =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S58');
    const progress = Blockly.JavaScript.valueToCode(
      block,
      'PROGRESS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledMatrixDisplayProgress(${progress}, { id: '${block.id}', priority });\n`;
  });
/**
 * Send JSON command to robot to light an external 8x8 LED matrix, set display brightness
 * @sensor 8x8 LED Matrix, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sight_led_matrix_display__set_brightness =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S58');
    const brightness = block.getFieldValue('LIST');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledMatrixDisplayAdjustBrightness(${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * read 8x8 LED matrix value from real-time robot sensor
 * update variables and generate 8x8 matrix object
 * @sensor 8x8 LED Matrix, command only takes effect after sensor are configured
 * @object {matrix_8_8: [0, 0, 0, 0, 0, 0, 0, 0]}
 */
Blockly.JavaScript.sensing__led_matrix_display =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S58');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S58', 'matrix_8_8', 1)`,
      Blockly.JavaScript.ORDER_ATOMIC,
    ];
  });
/**
 * generate 8x8 matrix object by drop down list
 * @object {matrix_8_8: [0, 0, 0, 0, 0, 0, 0, 0]}
 */
const MATRIX_8_8_UP = [24, 60, 90, 153, 24, 24, 24, 24];
const MATRIX_8_8_DOWN = [24, 24, 24, 24, 153, 90, 60, 24];
const MATRIX_8_8_LEFT = [16, 32, 64, 255, 255, 64, 32, 16];
const MATRIX_8_8_RIGHT = [8, 4, 2, 255, 255, 2, 4, 8];
const MATRIX_8_8_CROSS_V = [24, 24, 24, 255, 255, 24, 24, 24];
const MATRIX_8_8_CROSS_X = [129, 66, 36, 24, 24, 36, 66, 129];
const MATRIX_8_8_CHECK = [0, 1, 3, 6, 140, 216, 112, 32];
const MATRIX_8_8_HEART = [0, 102, 255, 255, 255, 126, 60, 24];
const MATRIX_8_8_BLANK = [0, 0, 0, 0, 0, 0, 0, 0];

Blockly.JavaScript.sensing__led_matrix_display_constant = (block) => {
  const con = parseInt(block.getFieldValue('LIST'), 10);
  let code = MATRIX_8_8_BLANK;
  switch (con) {
    case 1:
      code = MATRIX_8_8_UP;
      break;
    case 2:
      code = MATRIX_8_8_DOWN;
      break;
    case 3:
      code = MATRIX_8_8_LEFT;
      break;
    case 4:
      code = MATRIX_8_8_RIGHT;
      break;
    case 5:
      code = MATRIX_8_8_CROSS_V;
      break;
    case 6:
      code = MATRIX_8_8_CROSS_X;
      break;
    case 7:
      code = MATRIX_8_8_CHECK;
      break;
    case 8:
      code = MATRIX_8_8_HEART;
      break;
    case 9:
      code = MATRIX_8_8_BLANK;
      break;
    default:
    // code block
  }
  return [`{matrix_8_8: [${code}]}`, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * generate 8x8 matrix object by 8x8 check
 * @object {matrix_8_8: [0, 0, 0, 0, 0, 0, 0, 0]}
 */
Blockly.JavaScript.sensing__led_matrix_display_pixels = (block) => {
  const v0 =
    ((block.getFieldValue('C00') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C01') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C02') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C03') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C04') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C05') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C06') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C07') === 'TRUE' ? 1 : 0) * 1);
  const v1 =
    ((block.getFieldValue('C10') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C11') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C12') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C13') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C14') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C15') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C16') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C17') === 'TRUE' ? 1 : 0) * 1);
  const v2 =
    ((block.getFieldValue('C20') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C21') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C22') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C23') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C24') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C25') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C26') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C27') === 'TRUE' ? 1 : 0) * 1);
  const v3 =
    ((block.getFieldValue('C30') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C31') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C32') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C33') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C34') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C35') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C36') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C37') === 'TRUE' ? 1 : 0) * 1);
  const v4 =
    ((block.getFieldValue('C40') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C41') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C42') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C43') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C44') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C45') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C46') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C47') === 'TRUE' ? 1 : 0) * 1);
  const v5 =
    ((block.getFieldValue('C50') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C51') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C52') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C53') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C54') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C55') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C56') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C57') === 'TRUE' ? 1 : 0) * 1);
  const v6 =
    ((block.getFieldValue('C60') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C61') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C62') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C63') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C64') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C65') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C66') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C67') === 'TRUE' ? 1 : 0) * 1);
  const v7 =
    ((block.getFieldValue('C70') === 'TRUE' ? 1 : 0) * 128) +
    ((block.getFieldValue('C71') === 'TRUE' ? 1 : 0) * 64) +
    ((block.getFieldValue('C72') === 'TRUE' ? 1 : 0) * 32) +
    ((block.getFieldValue('C73') === 'TRUE' ? 1 : 0) * 16) +
    ((block.getFieldValue('C74') === 'TRUE' ? 1 : 0) * 8) +
    ((block.getFieldValue('C75') === 'TRUE' ? 1 : 0) * 4) +
    ((block.getFieldValue('C76') === 'TRUE' ? 1 : 0) * 2) +
    ((block.getFieldValue('C77') === 'TRUE' ? 1 : 0) * 1);
  return [
    `{matrix_8_8: [${v0}, ${v1}, ${v2}, ${v3}, ${v4}, ${v5}, ${v6}, ${v7}]}`,
    Blockly.JavaScript.ORDER_ATOMIC,
  ];
};
/**
 * PART 2-4: bits --> environmental
 * read light sensor value (%) from real-time robot sensor update variables
 * @sensor Light Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Light_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S39');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S39', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read temperature value (°C) or humidity value (%) from real-time robot sensor update variables
 * @sensor Temperature & Humidity Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__humidity_sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S15');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S15', 'float', ${block.getFieldValue('INDEX')})`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read UV level (%) from real-time robot sensor update variables
 * @sensor UV Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__UV_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S45');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S45', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read moisture level (%) from real-time robot sensor update variables
 * @sensor Moisture Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Moisture_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S38');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S38', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read water wetness level (%) from real-time robot sensor update variables
 * @sensor Water Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Water_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S41');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S41', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read temperature (℃) from real-time robot sensor update variables
 * @sensor Waterproof Temperature Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Waterproof_Temperature_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S14');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S14', 'float')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read PH value from real-time robot sensor update variables
 * @sensor Moisture Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__PH_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S48');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S48', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read BMP180 Barometer value from real-time robot sensor update variables
 * @sensor BMP180 Barometer, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__BMP180_Barometer =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S62');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S62', 'float')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read air pressure (kPa) or humidity (%) or temperature (℃)
 * from real-time robot sensor update variables
 * @sensor BME280 Atmospheric Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__BME280_Atmospheric_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S61');
    const index = block.getFieldValue('INDEX');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S61', '', ${index})`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * PART 2-5: bits --> action
 * Send JSON command to robot to light an external 8x8 LED matrix, set display brightness
 * @sensor IR Emitter, command only takes effect after sensor are configured
 */
Blockly.JavaScript.irTransmitter =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S6');
    const command = Blockly.JavaScript.valueToCode(
      block,
      'COMMAND',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .sendIRCommand(${command}, { id: '${block.id}', priority });\n`;
  });
/**
 * read IR receiver raw code (long int) from real-time robot sensor update variables
 * @sensor IR Receiver, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__irReceiver =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S16');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S16', 'string')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * generate IR raw code, it's a number
 */
Blockly.JavaScript.sensing__irRawCode = (block) => {
  const RAW = block.getFieldValue('CODE');
  return [
    `${RAW}`,
    Blockly.JavaScript.ORDER_FUNCTION_CALL,
  ];
};
/**
 * Send JSON command to robot to control an external realy, set on/off
 * @sensor Relay, command only takes effect after sensor are configured
 */
Blockly.JavaScript.relaySwitch =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S7');
    const onOff = Blockly.JavaScript.valueToCode(
      block,
      'ON_OFF',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const delay = Blockly.JavaScript.valueToCode(
      block,
      'DELAY',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const newID = `${block.id.substring(0, 7)}02`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT}).relaySwitchTo(${onOff}, { id: '${block.id}', priority });\nawait F.wait('${delay}', 'blockid=${block.id}&priority='+priority);\nawait R({blocklyId: '${newID}'}, ${ROBOT}).relaySwitchTo(0, { id: '${newID}', priority });\n`;
  });
/**
 * read Relay (%) from real-time robot sensor update variables
 * @sensor Relay, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__relay =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S7');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S7', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Rotary Angel Sensor (%) from real-time robot sensor update variables
 * @sensor Rotary Angel Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Rotary_Angel_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S37');
    return [
      `Math.round(R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S37', 'int') * 270 / 100)`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Linear Potentiometer (%) from real-time robot sensor update variables
 * @sensor Linear Potentiometer, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Linear_Potentiometer =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S44');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S44', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
* enable pulse sensor
* @sensor Pulse Sensor, command only takes effect after sensor are configured
*/
Blockly.JavaScript.Sensor_Pulse_Sensor_enable =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S47');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .startPulseSensor({ id: '${block.id}', priority });\n\n`;
  });
/**
 * reset Photo Electric Counter and start counting
 * @sensor Photo Electric Counter, command only takes effect after sensor are configured
 */
Blockly.JavaScript.Sensor_Photo_Electric_Counter_reset =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S31');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .resetPhotoElectricCounter({ id: '${block.id}', priority });\n\n`;
  });
/**
 * read Photo Electric Counter number from real-time robot sensor update variables
 * @sensor Photo Electric Counter, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Photo_Electric_Counter =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S31');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S31', 'int')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read touch status true/false value from real-time robot sensor update variables
 * @sensor Touch Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Touch_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S27');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S27', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read pressed status true/false from real-time robot sensor update variables
 * @sensor Push Button, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Push_Button =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S22');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S22', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read push status true/false from real-time robot sensor update variables
 * @sensor Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Switch =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S32');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S32', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read IR Reflective Sensor detected-status true/false from real-time robot sensor update variables
 * @sensor IR Reflective Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__ir_reflective_sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S33');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S33', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Collision Sensor closed-status true/false from real-time robot sensor update variables
 * @sensor Collision Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__limit_switch =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S30');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S30', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Tilt Switch tilt-status true/false from real-time robot sensor update variables
 * @sensor Tilt Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__tilt_switch =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S25');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S25', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Pulse Sensor from real-time robot sensor update variables
 * @sensor Tilt Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Pulse_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S47');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S47', 'number')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read PIR Motion Sensor active-status true/false from real-time robot sensor update variables
 * @sensor PIR Motion Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__human_body_ir_sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S29');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S29', 'boolean')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read Laser Ranging Sensor distance (mm) from real-time robot sensor update variables
 * @sensor Laser Ranging Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__Laser_Ranging_Sensor =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S56');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S56', 'float')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read 3-Axis Digital Compass angle (degree) from real-time robot sensor update variables
 * @sensor 3-Axis Digital Compass, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__read_3Axis_Digital_Compass_by_xyz =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S52');
    const xyzIndex = block.getFieldValue('XYZ');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S52', '', ${xyzIndex})`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * read MPU6050_Accelerometer and Gyro (degree) from real-time robot sensor update variables
 * @sensor MPU6050 Accelerometer & Gyro, command only takes effect after sensor are configured
 */
Blockly.JavaScript.sensing__MPU6050_Accelerometer_and_Gyro =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    checkSensorOnRobot(block, ROBOT, 'S53');
    const xyzIndex = block.getFieldValue('XYZ');
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S53', '', ${xyzIndex})`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
Blockly.JavaScript.sensing__read_S100_microbit_last_message =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // DO NOT need to check if microbit is online/offline
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT})
    .getLastInterruptHistoryContentOfSensor(${ROBOT}, 'S100')`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * PART 3-1: events --> sensor event
 * set listening event from IR Receiver with IR-code
 * @sensor IR Receiver, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__irReceiver_Received =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const command = Blockly.JavaScript.valueToCode(
      block,
      'COMMAND',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `
        R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S16', async (val) => {
          console.log(eventsLock, 'eventsLock', 'S16');
          if (eventsLock>0) return;
          eventsLock+=1;
          if (val === ${command}) {
            ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
          }
          eventsLock-=1;
        });
      `;
  });
/**
 * set listening event from Touch Sensor touching
 * @sensor Touch Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__touch_sensor_touching =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
          R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S70', async () => {
            console.log(eventsLock, 'eventsLock', 'S70');
            if (eventsLock>0) return;
            eventsLock+=1;
            ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
            eventsLock-=1;
          });
        `;
  });
/**
 * set listening event from Button press-down
 * @sensor Button, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__button_pushed =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
      F.addRobotSensorEventListener(${ROBOT}, 'S71', async () => {
          ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
        }, 'blockid=${block.id}');
      `;
  });

/**
 * set listening event from Switch turn-on
 * @sensor Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__switch_on =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
        R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S73', async () => {
          console.log(eventsLock, 'eventsLock', 'S73');
          if (eventsLock>0) return;
          eventsLock+=1;
          ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
          eventsLock-=1;
        });
      `;
  });
/**
 * set listening event from Switch turn-off
 * @sensor Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__switch_off =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
          R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S77', async () => {
            console.log(eventsLock, 'eventsLock', 'S77');
            if (eventsLock>0) return;
            eventsLock+=1;
            ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
            eventsLock-=1;
          });
        `;
  });
/**
 * set listening event from IR Reflective Sensor object detected
 * @sensor IR Reflective Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__ir_reflective_sensor =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
          R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S76', async () => {
            console.log(eventsLock, 'eventsLock', 'S76');
            if (eventsLock>0) return;
            eventsLock+=1;
            ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
            eventsLock-=1;
          });
        `;
  });
/**
 * set listening event from Collision Sensor closed
 * @sensor Collision Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__limit_switch =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S75', async () => {
              console.log(eventsLock, 'eventsLock', 'S75');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from 4-digi Display countdown
 * @sensor 4-digi Display, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__4_digi_countdown =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S57', async () => {
              console.log(eventsLock, 'eventsLock', 'S57');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Tilt Switch tilt detected
 * @sensor Tilt Switch, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__tilt_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S72', async () => {
              console.log(eventsLock, 'eventsLock', 'S72');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from PIR Motion Sensor active
 * @sensor PIR Motion Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__human_body_ir_sensor =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S74', async () => {
              console.log(eventsLock, 'eventsLock', 'S74');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Hall Sensor active
 * @sensor Hall Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__magnetic_field_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S23', async () => {
              console.log(eventsLock, 'eventsLock', 'S23');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Vibration Sensor vibration detected
 * @sensor Vibration Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__vibration_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S24', async () => {
              console.log(eventsLock, 'eventsLock', 'S24');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Knock Sensor knock detected
 * @sensor Knock Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__knock_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S26', async () => {
              console.log(eventsLock, 'eventsLock', 'S26');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Pulse Sensor finished
 * @sensor Pulse Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__pulse_sensor_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S47', async () => {
              console.log(eventsLock, 'eventsLock', 'S47');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/**
 * set listening event from Gesture Sensor detected by different gestures
 * @sensor Gesture Sensor, command only takes effect after sensor are configured
 */
Blockly.JavaScript.events__gesture_detected =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const command = block.getFieldValue('COMMAND');
    return `
          R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S63', async (val) => {
            console.log(eventsLock, 'eventsLock', 'S63');
            if (eventsLock>0) return;
            eventsLock+=1;
            if (val === ${command}) {
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
            }
            eventsLock-=1;
          });
        `;
  });
/**
 * PART 3-2: events --> system event
 * set listening event from robot just drive out of boundary
 */
Blockly.JavaScript.events__out_of_boundary =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
        R({blocklyId: '${block.id}'}, ${ROBOT}).onOutOfBoundary(async (val) => {
          console.log(eventsLock, 'eventsLock', 'onOutOfBoundary');
          if (eventsLock>0) return;
          eventsLock+=1;
          ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
          eventsLock-=1;
        });
      `;
  });
/**
 * PART 3-2: events --> system event
 * set listening event from robot just drive out of boundary
 */
Blockly.JavaScript.events__on_edge_trigger_of_variable = (block) => {
  const param1 = Blockly.JavaScript.valueToCode(
    block,
    'PARAM1',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const condition = block.getFieldValue('CONDITION');
  const param2 = Blockly.JavaScript.valueToCode(
    block,
    'PARAM2',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  // eslint-disable-next-line
  return `
    F.addEventChecker(async () => {
      return ${param1} ${condition} ${param2};
    }, async () => {
      console.log(eventsLock, 'eventsLock', 'onEdgeTrigger');
      ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
    })
  `;
};
Blockly.JavaScript.events__message_from_S100_microbit =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `
            R({blocklyId: '${block.id}'}, ${ROBOT}).onEvent('S100', async () => {
              console.log(eventsLock, 'eventsLock', 'S100');
              if (eventsLock>0) return;
              eventsLock+=1;
              ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
              eventsLock-=1;
            });
          `;
  });
/*
// events for sensors
Object.values(sensorSpecs)
  .filter(sensor => sensor.mode === "event" || sensor.event)
  .forEach(sensor => {
    let eventVarName = sensor.event ? sensor.event.eventVarName : null;
    eventVarName = eventVarName || sensor.eventVarName;
    // Blockly.JavaScript[`events__${eventVarName}`] = block => `
    //   R({blocklyId: '${block.id}'}, ${getDropdownValue(block.getFieldValue('ROBOT'))})
    //    .onEvent('${sensor.id}', async () => {
    //       console.log(eventsLock, 'eventsLock', '${sensor.id}');
    //       if (eventsLock>0) return;
    //       eventsLock+=1;
    //       ${Blockly.JavaScript.statementToCode(block, 'CALLBACK')}
    //       eventsLock-=1;
    //     });
    //     `;
    Blockly.JavaScript[`events__${eventVarName}`] = block =>
      jsCodeBy("sensor_event")(block, {
        ROBOT: block.getFieldValue("ROBOT"),
        SENSOR_ID: sensor.id,
        CALLBACK: Blockly.JavaScript.statementToCode(block, "CALLBACK")
      });
  });
  */
/**
 * PART 4: timing
 * delay seconds, can be overrule by event
 */
Blockly.JavaScript.control__wait = (block) => {
  const timeout = Blockly.JavaScript.valueToCode(
    block,
    'TIMEOUT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await F.wait(${timeout}, 'blockid=${block.id}&priority='+priority);\n`;
};
/**
 * read current time from browser
 */
Blockly.JavaScript.sensing__value_of_datetime = (block) => {
  const format = block.getFieldValue('FORMAT');
  const code = `F.time('${format}')`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
/**
 * read CPU time (second) from browser
 */
Blockly.JavaScript.sensing__current_second = () => [
  'parseInt(Date.now()/1000, 10)',
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];
/**
 * read CPU time (millisecond) from browser
 */
Blockly.JavaScript.sensing__current_millisecond = () => [
  'Date.now()',
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];

/**
 * PART 5: print
 * print any variables to console
 */
Blockly.JavaScript.log = (block) => {
  const value = Blockly.JavaScript.valueToCode(
    block,
    'CONTENT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await F.log(${value}, 'blockid=${block.id}&priority='+priority);\n`;
};
Blockly.JavaScript.send_chat = (block) => {
  const value = Blockly.JavaScript.valueToCode(
    block,
    'CONTENT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await classRoom.sendChatToEveryone(${value}, {blocklyId: '${block.id}'});\n`;
};
/**
 * convert any variable to text
 */
Blockly.JavaScript.customized_logic_totext = block => [
  `String(${Blockly.JavaScript.valueToCode(
    block,
    'INPUT_VARIABLE',
    Blockly.JavaScript.ORDER_ATOMIC,
  )})`,
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];

/**
 * PART 6: logic
 * customized if
 */
Blockly.JavaScript.customized_controls_if = Blockly.JavaScript.controls_if;
/**
 * customized compare
 */
Blockly.JavaScript.customized_logic_compare = Blockly.JavaScript.logic_compare;
/**
 * compare color A and B if the contents are the same
 * @object {color: {objType: 'color_RGB', r: 255, g: 0, b: 0}}
 */
Blockly.JavaScript.sensing__objCompare = (block) => {
  const obj1 =
    Blockly.JavaScript.valueToCode(
      block,
      'OBJ_1',
      Blockly.JavaScript.ORDER_ATOMIC,
    ) || '{}';
  const obj2 =
    Blockly.JavaScript.valueToCode(
      block,
      'OBJ_2',
      Blockly.JavaScript.ORDER_ATOMIC,
    ) || '{}';
  return [
    `(JSON.stringify(${obj1}) === JSON.stringify(${obj2}))`,
    Blockly.JavaScript.ORDER_ATOMIC,
  ];
};
/**
 * if a number is insode a range
 */
Blockly.JavaScript.output_logic__range = (block) => {
  const number = Blockly.JavaScript.valueToCode(
    block,
    'NUMBER',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const inout = block.getFieldValue('INOUT');
  const min = Blockly.JavaScript.valueToCode(
    block,
    'MIN',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const max = Blockly.JavaScript.valueToCode(
    block,
    'MAX',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  let code = '';
  if (inout === 'INSIDE') {
    code = `${number} >= ${min} && ${number} <= ${max}`;
  }
  if (inout === 'OUTSIDE') {
    code = `${number} < ${min} || ${number} > ${max}`;
  }
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

/**
 * PART 7: loop
 * customized while until
 */
// instead of the official function: Blockly.JavaScript.controls_whileUntil;
// https://github.com/google/blockly/blob/master/generators/javascript/loops.js#L63
Blockly.JavaScript.customized_controls_whileUntil = (block) => {
  // Do while/until loop.
  const until = block.getFieldValue('MODE') === 'UNTIL';
  let argument0 =
    Blockly.JavaScript.valueToCode(
      block,
      'BOOL',
      until
        ? Blockly.JavaScript.ORDER_LOGICAL_NOT
        : Blockly.JavaScript.ORDER_NONE,
    ) || 'false';
  let branch = Blockly.JavaScript.statementToCode(block, 'DO');
  branch = Blockly.JavaScript.addLoopTrap(branch, block.id);
  if (until) {
    argument0 = `!${argument0}`;
  }
  return `while (${argument0}) {
    ${branch}
    await F.blocklySleep(0.1*1e3);
  }
  `;
};
/**
 * customized repeat
 */
Blockly.JavaScript.customized_controls_repeat_ext =
  Blockly.JavaScript.controls_repeat_ext;
/**
 * customized for loop
 */
Blockly.JavaScript.customized_controls_for = Blockly.JavaScript.controls_for;
/**
 * customized break
 */
Blockly.JavaScript.customized_controls_flow_statements =
  Blockly.JavaScript.controls_flow_statements;

/**
 * PART 9: math
 * get angle by angle picker
 */
Blockly.JavaScript.sensing__constant_angle = (block) => {
  const angle = block.getFieldValue('ANGLE');
  const code = `${angle}`;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};
/**
 * convert a text to number
 */
Blockly.JavaScript.customized_logic_tonumber = block => [
  `Number(${Blockly.JavaScript.valueToCode(
    block,
    'INPUT_VARIABLE',
    Blockly.JavaScript.ORDER_ATOMIC,
  )})`,
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];
/**
 * read dice from camera
 */
Blockly.JavaScript.read__dice_value = () => [
  'classRoom.getDiceValue()',
  Blockly.JavaScript.ORDER_FUNCTION_CALL,
];

/**
 * PART 10: array
 */
/**
 * function blocks definition start
 */
// https://github.com/google/blockly/blob/master/generators/javascript/procedures.js#L32
Blockly.JavaScript.procedures_defreturn = (block) => {
  // Define a procedure with a return value.
  const funcName = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('NAME'),
    Blockly.Procedures.NAME_TYPE,
  );
  let branch = Blockly.JavaScript.statementToCode(block, 'STACK');
  if (Blockly.JavaScript.STATEMENT_PREFIX) {
    const id = block.id.replace(/\$/g, '$$$$'); // Issue 251.
    branch =
      Blockly.JavaScript.prefixLines(
        Blockly.JavaScript.STATEMENT_PREFIX.replace(/%1/g, `'${id}'`),
        Blockly.JavaScript.INDENT,
      ) + branch;
  }
  if (Blockly.JavaScript.INFINITE_LOOP_TRAP) {
    branch =
      Blockly.JavaScript.INFINITE_LOOP_TRAP.replace(/%1/g, `'${block.id}'`) +
      branch;
  }
  let returnValue =
    Blockly.JavaScript.valueToCode(
      block,
      'RETURN',
      Blockly.JavaScript.ORDER_NONE,
    ) || '';
  if (returnValue) {
    returnValue = `${Blockly.JavaScript.INDENT}return ${returnValue};\n`;
  }
  const args = [];
  for (let i = 0; i < block.arguments_.length; i += 1) {
    args[i] = Blockly.JavaScript.variableDB_.getName(
      block.arguments_[i],
      Blockly.Variables.NAME_TYPE,
    );
  }
  args.push('extra');
  let code = `async function ${funcName}(${args.join(', ')}) {
var priority = extra.priority;
${branch}${returnValue}\n}`;

  code = Blockly.JavaScript.scrub_(block, code);
  // Add % so as not to collide with helper functions in definitions list.
  Blockly.JavaScript.definitions_[`%${funcName}`] = code;
  return null;
};

// https://github.com/google/blockly/blob/master/generators/javascript/procedures.js#L67
Blockly.JavaScript.procedures_defnoreturn =
  Blockly.JavaScript.procedures_defreturn;

// https://github.com/google/blockly/blob/master/generators/javascript/procedures.js#L70
Blockly.JavaScript.procedures_callreturn = (block) => {
  // Call a procedure with a return value.
  const funcName = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('NAME'),
    Blockly.Procedures.NAME_TYPE,
  );
  const args = [];
  for (let i = 0; i < block.arguments_.length; i += 1) {
    args[i] =
      Blockly.JavaScript.valueToCode(
        block,
        `ARG${i}`,
        Blockly.JavaScript.ORDER_COMMA,
      ) || 'null';
  }
  // args.unshift('priority');
  args.push(`{ id: '${block.id}', priority}`);
  const code = `await ${funcName}(${args.join(', ')})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

// https://github.com/google/blockly/blob/master/generators/javascript/procedures.js#L83
Blockly.JavaScript.procedures_callnoreturn = (block) => {
  // Call a procedure with no return value.
  const funcName = Blockly.JavaScript.variableDB_.getName(
    block.getFieldValue('NAME'),
    Blockly.Procedures.NAME_TYPE,
  );
  const args = [];
  for (let i = 0; i < block.arguments_.length; i += 1) {
    args[i] =
      Blockly.JavaScript.valueToCode(
        block,
        `ARG${i}`,
        Blockly.JavaScript.ORDER_COMMA,
      ) || 'null';
  }
  // args.unshift('priority');
  args.push(`{ id: '${block.id}', priority}`);
  const code = `await ${funcName}(${args.join(', ')});\n`;
  return code;
};

// Blockly.JavaScript.customized_procedures_mutatorcontainer =
//   Blockly.JavaScript.procedures_mutatorcontainer;

// Blockly.JavaScript.customized_procedures_mutatorarg =
//   Blockly.JavaScript.procedures_mutatorarg;

// Blockly.JavaScript.customized_procedures_ifreturn =
//   Blockly.JavaScript.procedures_ifreturn;

// === function blocks definition end
/**
 * array declare
 */
Blockly.JavaScript.array_declare = (block) => {
  const arrayName = block.getFieldValue('ARRAY_NAME');
  const namespaceForArrayVariable = '%array_declare__';
  // important
  Blockly.JavaScript.definitions_[
    [namespaceForArrayVariable, arrayName].join('')
  ] = `var ${arrayName} = {};\n`;
  blocklyStore.clearAllArrayVariables();
  Object.keys(Blockly.JavaScript.definitions_).forEach((defkey) => {
    if (defkey.startsWith(namespaceForArrayVariable)) {
      blocklyStore.setArrayVariable(arrayName.replace(namespaceForArrayVariable, ''));
    }
  });
  return null;
};
/**
 * array set item
 */
Blockly.JavaScript.array_set_item = (block) => {
  const arrayVariableName = block.getFieldValue('ARRAY_NAME');
  const elementKey = Blockly.JavaScript.valueToCode(
    block,
    'KEY',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const elementValue = Blockly.JavaScript.valueToCode(
    block,
    'VALUE',
    Blockly.JavaScript.ORDER_ATOMIC,
  );

  let code = `
if(typeof ${arrayVariableName} !== 'undefined'){
  ${arrayVariableName}[${elementKey}] = ${elementValue};
}
`;
  if (!arrayVariableName || arrayVariableName === 'none' || !elementKey) { code = `/*\n${code}\n*/`; }
  return code;
};
/**
 * array get item
 */
Blockly.JavaScript.array_get_item = (block) => {
  const arrayVariableName = block.getFieldValue('ARRAY_NAME');
  // const elementKey = block.getFieldValue('KEY');
  const elementKey = Blockly.JavaScript.valueToCode(
    block,
    'KEY',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  if (!arrayVariableName || arrayVariableName === 'none' || !elementKey) {
    return ['undefined', Blockly.JavaScript.ORDER_ATOMIC];
  }
  return [
    `(typeof ${arrayVariableName} !== 'undefined'?${arrayVariableName}[${elementKey}]:undefined)`,
    Blockly.JavaScript.ORDER_ATOMIC,
  ];
};
/**
 * get array length
 */
Blockly.JavaScript.array_length = (block) => {
  const arrayVariableName = block.getFieldValue('ARRAY_NAME');
  if (!arrayVariableName || arrayVariableName === 'none') {
    return [0, Blockly.JavaScript.ORDER_ATOMIC];
  }
  return [
    `(typeof ${arrayVariableName} !== 'undefined'?Object.keys(${arrayVariableName}).length:0)`,
    Blockly.JavaScript.ORDER_ATOMIC,
  ];
};
/**
 * PART 10: effect
 * effect onFire
 */
Blockly.JavaScript.the3d__object_effect_onFire_frozen = (block) => {
  const objId = block.getFieldValue('EFFECT_OBJECT');
  const animationId = block.getFieldValue('EFFECT_ANIMATION');
  const fxParamObject = {
    FX: parseInt(animationId, 10) || 0,
    OBJ: parseInt(objId, 10) || 0,
  };
  const fxParamString = JSON.stringify(fxParamObject);
  return `await classRoom.send3DEffect(${fxParamString}, { id: '${block.id}', priority });\n`;
};
/**
 * effect nil
 */
Blockly.JavaScript.the3d__object_effect_nil_effect = (block) => {
  const objId = block.getFieldValue('EFFECT_OBJECT');
  const fxParamObject = {
    OBJ: parseInt(objId, 10) || 0,
  };
  const fxParamString = JSON.stringify(fxParamObject);
  return `await classRoom.send3DEffect(${fxParamString}, { id: '${block.id}', priority });\n`;
};
/**
 * effect flying
 */
Blockly.JavaScript.the3d__object_effect_flying_height = (block) => {
  const objId = parseInt(block.getFieldValue('EFFECT_OBJECT'), 10) || 0;
  const height = Blockly.JavaScript.valueToCode(
    block,
    'VALUE_OF_KEY_Y',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await classRoom.send3DEffect({"OBJ":${objId}, "Y":${height}}, { id: '${block.id}', priority });\n`;
};
/**
 * Last part: Beginner toolbox extra blockly
 * for beginner, turn angle by drop down list
 */
Blockly.JavaScript.motion_angle__turn =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .turnAngle(${block.getFieldValue('ANGLE')}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, servo open/close
 * @sensor Servo (on-board)
 */
Blockly.JavaScript.motion_servo__beginner__open_close_toggle =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const openClose =
      `${block.getFieldValue('OPEN_CLOSE')}`.toLowerCase() === 'open';
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .servoSwitchTo(${openClose}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, robot turn to angle
 */
Blockly.JavaScript.motion_angle__look =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .changeToAngle(${block.getFieldValue('ANGLE')}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, robot move to X Y
 */
Blockly.JavaScript.motion_move__move_to_XY =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const yr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const xy = `{objType: 'target_XY', objValueX: ${xr} * 10, objValueY: ${yr} * 10}`;
    const paramsString = `{"targetXY":${xy}, driveDistance: 0, stopDistance: 0, method: 1}`;
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  // moveToObjXY(
  });
/**
 * for beginner, read X Y Angle from a robot
 */
Blockly.JavaScript.sensing__robotXYA =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    // cleanBlockWarning(block);
    // checkRobotOffine(block, ROBOT);
    const data = block.getFieldValue('DATA');
    if (data === 'a') {
      return [
        `R.getProperty({blocklyId: '${block.id}'}, ${ROBOT}, '${data}')`,
        Blockly.JavaScript.ORDER_FUNCTION_CALL,
      ];
    }
    return [
      `R.getProperty({blocklyId: '${block.id}'}, ${ROBOT}, '${data}') / 10`,
      Blockly.JavaScript.ORDER_FUNCTION_CALL,
    ];
  });
/**
 * for beginner, robot follow another object by half distance
 */
Blockly.JavaScript.beginner__motion_mirror__follow =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const ROBOT_TAR = block.getFieldValue('TARGET');
    const xy = `{objType: 'target_XY', objValueX: R({blocklyId: '${block.id}'}, ${ROBOT_TAR})
  .x || 0, objValueY: R({blocklyId: '${block.id}'}, ${ROBOT_TAR}).y || 0}`;
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: 100, method: 2}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXY(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  // moveToObjXY(
  });
/**
 * for beginner, robot grab object
 */
Blockly.JavaScript.motion_special__grab_object =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const yr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const dt = 6;
    const xy = `{objType: 'target_XY', objValueX: ${xr} * 10, objValueY: ${yr} * 10}`;
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 1}`;
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * for beginner, robot drop object
 */
Blockly.JavaScript.motion_special__release_object =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const yr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const dt = 6;
    const xy = `{objType: 'target_XY', objValueX: ${xr} * 10, objValueY: ${yr} * 10}`;
    const paramsString = `{'targetXY':${xy}, driveDistance: 0, stopDistance: ${dt} * 10, method: 1}`;
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToObjXYAccurately(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * for beginner, robot drive circle
 */
Blockly.JavaScript.beginner__motion_special__drive_in_circle =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const rotation = parseInt(block.getFieldValue('LEFT_RIGHT'), 10);
    const angle = 360;
    const direction = 1;
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveUTurn(${direction}, ${rotation}, ${angle}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * for beginner, robot play sound using on-board buzzer, always enabled
 */
Blockly.JavaScript.beginner__play_song =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const index = block.getFieldValue('MUSIC');
    const paramsString = `{music: {objType: 'sound_RTTTLList', index: ${index}}, volume: 20}`; // const paramsString = JSON.stringify(params);
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .boardBuzzerPlay(${paramsString}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });
/**
 * for beginner, robot set headlights state
 */
Blockly.JavaScript.leddiffuser_set_on_state =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const color = block.getFieldValue('HEADLIGHT_STATE_ON') === 'TRUE' ? '#ffffff' : '#000000';
    // const colorObject = hexToRgb(color);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const colorObject = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
    const mode = 1;
    const params = {
      mode: 1,
      light: 4,
      color: colorObject,
    };
    const paramsString = JSON.stringify(params);
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .diffuserSetColor(${paramsString}, { id: '${block.id}', mode: ${mode}, priority } );\n\n`;
  });
/**
 * for beginner, robot set headlights color (on-board), always enabled
 */
Blockly.JavaScript.leddiffuser_set_color =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const color = block.getFieldValue('COLOR_PICKER');
    // const colorObject = hexToRgb(color);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    const colorObject = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    };
    const mode = 1;
    const params = {
      mode: 1,
      light: 4,
      color: colorObject,
    };
    const paramsString = JSON.stringify(params);
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .diffuserSetColor(${paramsString}, { id: '${block.id}', mode: ${mode}, priority } );\n\n`;
  });
/**
 * for beginner, robot set Single LED on/off
 * @sensor Single LED
 */
Blockly.JavaScript.beginner__LED_turn_onOff =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const brightness = block.getFieldValue('ON_OFF');
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledAdjustBrightness(${brightness}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, robot set RGB-LED color one by one
 * @sensor RGB-LED
 */
Blockly.JavaScript.sight_led_strip_4_colors =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const robot = ROBOT;
    const color1 = Blockly.JavaScript.valueToCode(
      block,
      'COLOR_1',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const color2 = Blockly.JavaScript.valueToCode(
      block,
      'COLOR_2',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const color3 = Blockly.JavaScript.valueToCode(
      block,
      'COLOR_3',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const color4 = Blockly.JavaScript.valueToCode(
      block,
      'COLOR_4',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const colorExpressionArray = [];
    Array.from([color1, color2, color3, color4]).forEach((color) => {
      let colorR = '0';
      let colorG = '0';
      let colorB = '0';
      try {
        const obj = JSON.parse(color);
        if (obj.objType === 'color_RGB') {
          colorR = obj.r;
          colorG = obj.g;
          colorB = obj.b;
        }
        colorExpressionArray.push(`{"r":${colorR}, "g":${colorG}, "b":${colorB}}`);
      } catch (e) {
        colorExpressionArray.push(color);
      }
    });
    const paramsString = `{"mode":1, "colors":[${colorExpressionArray.join(',')}]}`; // const paramsString = JSON.stringify(params);
    return `await R({blocklyId: '${block.id}'}, ${robot})
  .ledStripAdjustColorOfColorArray(${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, robot set 8x8 LED Matrix pixels by drop down list
 * @sensor 8x8 LED Matrix
 */
Blockly.JavaScript.beginner__sight_led_matrix_display__show =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const index = parseInt(block.getFieldValue('CODE'), 10);
    let code = MATRIX_8_8_BLANK;
    switch (index) {
      case 1:
        code = MATRIX_8_8_UP;
        break;
      case 2:
        code = MATRIX_8_8_DOWN;
        break;
      case 3:
        code = MATRIX_8_8_LEFT;
        break;
      case 4:
        code = MATRIX_8_8_RIGHT;
        break;
      case 5:
        code = MATRIX_8_8_CROSS_V;
        break;
      case 6:
        code = MATRIX_8_8_CROSS_X;
        break;
      case 7:
        code = MATRIX_8_8_CHECK;
        break;
      case 8:
        code = MATRIX_8_8_HEART;
        break;
      case 9:
        code = MATRIX_8_8_BLANK;
        break;
      default:
      // code block
    }
    const pixels = `{matrix_8_8: [${code}]}`;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledMatrixDisplayRawPixels(${pixels}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, robot set 8x8 LED Matrix pixels by string,
 * will keep scrolling until next LED matrix command
 * @sensor 8x8 LED Matrix
 */
Blockly.JavaScript.beginner__sight_led_matrix_display__set_text =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const text = JSON.stringify(block.getFieldValue('TEXT'));
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
      .ledMatrixDisplayText(${text}, { id: '${block.id}', priority });\n`;
  });
/**
 * for beginner, system listens to button press event
 * @sensor Button
 */
Blockly.JavaScript.beginner__events__button_pushed =
  Blockly.JavaScript.events__button_pushed;
/**
 * for beginner, system listens to robot out of boundary event
 */
Blockly.JavaScript.beginner__events__out_of_boundary =
  Blockly.JavaScript.events__out_of_boundary;
/**
 * for beginner, math + - x /
 */
Blockly.JavaScript.beginner_math_arithmetic =
  Blockly.JavaScript.math_arithmetic;
/**
 * for beginner, read ultrasonic sensor
 * @sensor
 */
Blockly.JavaScript.beginner__sensing__ultrasonic_distance_sensor =
  Blockly.JavaScript.sensing__ultrasonic_distance_sensor;

Blockly.JavaScript.is_robot_position_in_the_area =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xy = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_XY',
      Blockly.JavaScript.ORDER_ATOMIC,
    ) || "{objType: 'target_XY', objValueX: 0, objValueY: 0}";
    const distance = Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );

    return [
      `R({blocklyId: '${block.id}'}, ${ROBOT}).isInArea(${xy}, ${distance} * 10)`,
      Blockly.JavaScript.ORDER_ATOMIC,
    ];
  });

Blockly.JavaScript.microbit__send_message_to_microbit =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const text = Blockly.JavaScript.valueToCode(
      block,
      'TEXT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
      .sendMessageToS100Microbit(${text}, { id: '${block.id}', priority });\n`;
  });

Blockly.JavaScript.sensing__value_of_text_equals = (block) => {
  const textA = Blockly.JavaScript.valueToCode(
    block,
    'TEXTA',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const textB = Blockly.JavaScript.valueToCode(
    block,
    'TEXTB',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `F.textEquals(${textA}, ${textB})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.JavaScript.sensing__value_of_text_contains = (block) => {
  const textA = Blockly.JavaScript.valueToCode(
    block,
    'TEXTA',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const textB = Blockly.JavaScript.valueToCode(
    block,
    'TEXTB',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `F.textIncludes(${textA}, ${textB})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.JavaScript.sensing__value_of_text_append = (block) => {
  const textA = Blockly.JavaScript.valueToCode(
    block,
    'TEXTA',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const textB = Blockly.JavaScript.valueToCode(
    block,
    'TEXTB',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `F.textConcat(${textA}, ${textB})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.JavaScript.sensing__value_of_text_length = (block) => {
  const text = Blockly.JavaScript.valueToCode(
    block,
    'TEXT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `F.textLength(${text})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
Blockly.JavaScript.sensing__value_of_text_substring = (block) => {
  const text = Blockly.JavaScript.valueToCode(
    block,
    'TEXT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const begin = Blockly.JavaScript.valueToCode(
    block,
    'FROM',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const end = Blockly.JavaScript.valueToCode(
    block,
    'TO',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const code = `F.textSubStartTo(${text}, ${begin}, ${end})`;
  return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};
/* sandbox
 *              _____                    _____                    _____                    _____                    _____                   _______
 *             /\    \                  /\    \                  /\    \                  /\    \                  /\    \                 /::\    \                ______
 *            /::\    \                /::\    \                /::\____\                /::\    \                /::\    \               /::::\    \              |::|   |
 *           /::::\    \              /::::\    \              /::::|   |               /::::\    \              /::::\    \             /::::::\    \             |::|   |
 *          /::::::\    \            /::::::\    \            /:::::|   |              /::::::\    \            /::::::\    \           /::::::::\    \            |::|   |
 *         /:::/\:::\    \          /:::/\:::\    \          /::::::|   |             /:::/\:::\    \          /:::/\:::\    \         /:::/~~\:::\    \           |::|   |
 *        /:::/__\:::\    \        /:::/__\:::\    \        /:::/|::|   |            /:::/  \:::\    \        /:::/__\:::\    \       /:::/    \:::\    \          |::|   |
 *        \:::\   \:::\    \      /::::\   \:::\    \      /:::/ |::|   |           /:::/    \:::\    \      /::::\   \:::\    \     /:::/    / \:::\    \         |::|   |
 *      ___\:::\   \:::\    \    /::::::\   \:::\    \    /:::/  |::|   | _____    /:::/    / \:::\    \    /::::::\   \:::\    \   /:::/____/   \:::\____\        |::|   |
 *     /\   \:::\   \:::\    \  /:::/\:::\   \:::\    \  /:::/   |::|   |/\    \  /:::/    /   \:::\ ___\  /:::/\:::\   \:::\ ___\ |:::|    |     |:::|    | ______|::|___|___ ____
 *    /::\   \:::\   \:::\____\/:::/  \:::\   \:::\____\/:: /    |::|   /::\____\/:::/____/     \:::|    |/:::/__\:::\   \:::|    ||:::|____|     |:::|    ||:::::::::::::::::|    |
 *    \:::\   \:::\   \::/    /\::/    \:::\  /:::/    /\::/    /|::|  /:::/    /\:::\    \     /:::|____|\:::\   \:::\  /:::|____| \:::\    \   /:::/    / |:::::::::::::::::|____|
 *     \:::\   \:::\   \/____/  \/____/ \:::\/:::/    /  \/____/ |::| /:::/    /  \:::\    \   /:::/    /  \:::\   \:::\/:::/    /   \:::\    \ /:::/    /   ~~~~~~|::|~~~|~~~
 *      \:::\   \:::\    \               \::::::/    /           |::|/:::/    /    \:::\    \ /:::/    /    \:::\   \::::::/    /     \:::\    /:::/    /          |::|   |
 *       \:::\   \:::\____\               \::::/    /            |::::::/    /      \:::\    /:::/    /      \:::\   \::::/    /       \:::\__/:::/    /           |::|   |
 *        \:::\  /:::/    /               /:::/    /             |:::::/    /        \:::\  /:::/    /        \:::\  /:::/    /         \::::::::/    /            |::|   |
 *         \:::\/:::/    /               /:::/    /              |::::/    /          \:::\/:::/    /          \:::\/:::/    /           \::::::/    /             |::|   |
 *          \::::::/    /               /:::/    /               /:::/    /            \::::::/    /            \::::::/    /             \::::/    /              |::|   |
 *           \::::/    /               /:::/    /               /:::/    /              \::::/    /              \::::/    /               \::/____/               |::|   |
 *            \::/    /                \::/    /                \::/    /                \::/____/                \::/____/                 ~~                     |::|___|
 *             \/____/                  \/____/                  \/____/                  ~~                       ~~                                               ~~
 *
 *
 *
 */

/**
 * sandbox 0-1
 * topic: special
 * teacher only, theme clear 1
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_theme_clear = (block) => {
  // const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  const THEME = block.getFieldValue('THEME');
  const ITEM = block.getFieldValue('ITEM');
  const paramsString = `{'TRN':'${THEME}', 'CL':'${ITEM}'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-2
 * topic: special
 * teacher only, theme clear 1
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_load_video_youtube =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const URL = block.getFieldValue('URL');
    const LOOP = block.getFieldValue('LOOP');
    const SCREEN = block.getFieldValue('SCREEN');
    const VOL = block.getFieldValue('VOL');
    const paramsString = `{'R':${ROBOT}, 'URL':'${URL}', 'FS':${SCREEN}, 'Loop':${LOOP}, 'Volume':${VOL}}`;
    return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 0-3
 * topic: special
 * teacher only, spawn 3d model 1
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_spawn_3d_model = (block) => {
  const ModelName = block.getFieldValue('THREE_D_MODEL_URL');
  const X = Blockly.JavaScript.valueToCode(
    block,
    'X',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const Y = Blockly.JavaScript.valueToCode(
    block,
    'Y',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const DURATION = Blockly.JavaScript.valueToCode(
    block,
    'DURATION',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const paramsString = `{'M':'${ModelName}', 'X':${X}, 'Y':${Y}, 'DURATION':${DURATION}}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-4
 * topic: special
 * teacher only, clear video
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_all_speech_bubbles = (block) => {
  const paramsString = JSON.stringify({
    SpeechBubble: 'ClearAll',
  });
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-4
 * topic: special
 * teacher only, clear video
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_video = (block) => {
  const paramsString = `{'URL':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-5
 * topic: special
 * teacher only, clear model & animation
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_all_model_animations = (block) => {
  const paramsString = `{'MA':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-6
 * topic: special
 * teacher only, clear tile
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_all_tiles = (block) => {
  const paramsString = `{'T':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-7
 * topic: special
 * teacher only, clear pt
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_all_trailes = (block) => {
  const paramsString = `{'PT':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-8
 * topic: special
 * teacher only, clear tr
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_all_transforms = (block) => {
  const paramsString = `{'TR':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-9
 * topic: special
 * teacher only, clear video
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_clear_everything = (block) => {
  const paramsString = `{'ALL':'Clear'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-10
 * topic: special
 * teacher only, mute sounds
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_mute_all_sounds = (block) => {
  const paramsString = `{'Mute':true}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-11
 * topic: special
 * teacher only, enable sounds
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_enable_all_sounds = (block) => {
  const paramsString = `{'Mute':false}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-12
 * topic: special
 * teacher only, theme clear 1
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_load_skybox_url = (block) => {
  const URL = block.getFieldValue('URL');
  const paramsString = `{'Skybox':'${URL}'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 0-13
 * topic: special
 * teacher only, change sun color
 */
Blockly.JavaScript.sandbox_viewer_teacher_only_change_sun_color = (block) => {
  const COLOUR = block.getFieldValue('COLOUR');
  const COLOR = COLOUR.replace('#', '');
  const paramsString = `{'SunColor':'${COLOR}'}`;
  return `await classRoom.sendMessageToSandboxViewer("special", ${paramsString}, { id: '${block.id}', priority });\n`;
};
/**
 * sandbox 1-1
 * topic: download
 * load 3d model 1
 */
Blockly.JavaScript.sandbox_viewer_download_by_file_name =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    let downloadURL = '';
    const downloadFileName = block.getFieldValue('DOWNLOAD_FILE_NAME');
    if (downloadFileName) {
      downloadURL = `https://kaisclan.ai/download/${downloadFileName}`;
    }
    if (!downloadURL) {
      try {
        downloadURL = JSON.parse(block.getFieldValue('THREE_D_MODEL_URL')).value;
      } catch (error) {
        //
        downloadURL = block.getFieldValue('THREE_D_MODEL_URL');
      }
    }
    if (!downloadURL) return '';
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { URL: downloadURL });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("download", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-1
 * topic: transform
 * rotate 1
 */
Blockly.JavaScript.sandbox_viewer_transform_rotate_object_by_XYZ_degrees =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const RO_X = Blockly.JavaScript.valueToCode(
      block,
      'RO_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const RO_Y = Blockly.JavaScript.valueToCode(
      block,
      'RO_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const RO_Z = Blockly.JavaScript.valueToCode(
      block,
      'RO_Z',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // const TR_S = [0, 0, 0];
    const TR_RO = [RO_X, RO_Y, RO_Z];
    // const TR_T = [0, 0, 0];
    // const paramsString = `{'S':[${TR_S}], 'RO':[${TR_RO}], 'T':[${TR_T}]}`;
    const paramsString = `{'R':${ROBOT}, 'RO':[${TR_RO}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-2
 * topic: transform
 * rotate 2
 */
Blockly.JavaScript.sandbox_viewer_transform_rotate_object_by_XYZ_degrees_with_angle_selector =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const RO_X = Number(block.getFieldValue('RO_X')) || 0;
    const RO_Y = Number(block.getFieldValue('RO_Y')) || 0;
    const RO_Z = Number(block.getFieldValue('RO_Z')) || 0;
    const TR_RO = [RO_X, RO_Y, RO_Z];
    const paramsString = `{'R':${ROBOT}, 'RO':[${TR_RO}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-2-1
 * topic: transform
 * rotate 2
 */
Blockly.JavaScript.sandbox_viewer_transform_rotate_object_by_left_right =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const RO_Z = Number(block.getFieldValue('DIRECTION')) || 0;
    const TR_RO = [0, 0, RO_Z];
    const paramsString = `{'R':${ROBOT}, 'RO':[${TR_RO}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-3
 * topic: transform
 * rotate 3
 */
Blockly.JavaScript.sandbox_viewer_transform_rotate_object_reset =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const TR_RO = [0, 0, 0];
    const paramsString = `{'R':${ROBOT}, 'RO':[${TR_RO}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-4
 * topic: transform
 * scale 1
 */
Blockly.JavaScript.sandbox_viewer_transform_scale_object_by_all_persentage =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const SCALE = Number(block.getFieldValue('SCALE')) || 0;
    const TR_S = [SCALE, SCALE, SCALE];
    const paramsString = `{'R':${ROBOT}, 'S':[${TR_S}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-5
 * topic: transform
 * scale 2
 */
Blockly.JavaScript.sandbox_viewer_transform_scale_object_by_XYZ_persentage =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const SCALE_X = Blockly.JavaScript.valueToCode(
      block,
      'SCALE_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const SCALE_Y = Blockly.JavaScript.valueToCode(
      block,
      'SCALE_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const SCALE_Z = Blockly.JavaScript.valueToCode(
      block,
      'SCALE_Z',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const TR_S = [SCALE_X, SCALE_Y, SCALE_Z];
    const paramsString = `{'R':${ROBOT}, 'S':[${TR_S}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-6
 * topic: transform
 * scale 3
 */
Blockly.JavaScript.sandbox_viewer_transform_scale_object_reset =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const TR_S = [100, 100, 100];
    const paramsString = `{'R':${ROBOT}, 'S':[${TR_S}]}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 2-7
 * topic: transform
 * fly with height 1
 */
Blockly.JavaScript.sandbox_viewer_transform_fly_object_by_height =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const height = Blockly.JavaScript.valueToCode(
      block,
      'HEIGHT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'R':${ROBOT}, 'H':${height}}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 3-1
 * topic: animation
 * play animation 1
 */
Blockly.JavaScript.sandbox_viewer_animations_play =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const animationName = block.getFieldValue('ANIMATION_NAME');
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const when = block.getFieldValue('WHEN');
    const payloadJson = {
      IE: 'NoAnim',
      SE: 'NoAnim',
      ME: 'NoAnim',
    };
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    switch (when) {
      case 'idle':
        Object.assign(payloadJson, { IE: animationName });
        break;
      case 'one_shot':
        Object.assign(payloadJson, { SE: animationName });
        break;
      case 'moves':
      default:
        Object.assign(payloadJson, { ME: animationName });
        break;
    }
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("animations", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 3-2
 * topic: animation
 * stop animation 1
 */
Blockly.JavaScript.sandbox_viewer_animations_stop =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const paramsString = `{'R':${ROBOT}, 'IE':'NoAnim', 'SE':'NoAnim', 'ME':'NoAnim'}`;
    return `await classRoom.sendMessageToSandboxViewer("animations", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 3-3
 * topic: animation
 * orbit 1
 */
Blockly.JavaScript.sandbox_viewer_animation_orbit =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    // const OBJECT = Number(block.getFieldValue('OBJECT')) || 0;
    const ANGLE = Number(block.getFieldValue('ANGLE')) || 0;
    const RADIUS = Blockly.JavaScript.valueToCode(
      block,
      'RADIUS',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const SPEED = Number(block.getFieldValue('SPEED')) || 0;
    const paramsString = `{'R':${ROBOT}, 'A':${ANGLE}, 'RD':${RADIUS}, 'SP':${SPEED}}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 4-1
 * topic: effect
 * effect 1
 */
Blockly.JavaScript.sandbox_viewer_effects_enable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const effectName = block.getFieldValue('EFFECT_NAME');
    const color = Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    let colorObject;
    try {
      colorObject = JSON.parse(color);
    } catch (error) {
      colorObject = {
        r: 0,
        g: 0,
        b: 0,
      };
    }
    if (!colorObject) {
      colorObject = {
        r: 0,
        g: 0,
        b: 0,
      };
    }
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const when = block.getFieldValue('WHEN');

    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    switch (when) {
      case 'idle':
        Object.assign(payloadJson, { IE: effectName });
        Object.assign(payloadJson, { IC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
        break;
      case 'one_shot':
        Object.assign(payloadJson, { SE: effectName });
        Object.assign(payloadJson, { SC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
        break;
      case 'moves':
      default:
        Object.assign(payloadJson, { ME: effectName });
        Object.assign(payloadJson, { MC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
        break;
    }
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("effects", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 4-2
 * topic: effect
 * effect 2
 */
Blockly.JavaScript.sandbox_viewer_effect_clear =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const paramsString = `{'R':${ROBOT}, 'ME':'Clear', 'IE':'Clear', 'SE':'Clear', 'MC':'#000000', 'IC':'#000000', 'SC':'#000000'}`;
    return `await classRoom.sendMessageToSandboxViewer("effects", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 4-3
 * topic: effect
 * effect 1
 */
Blockly.JavaScript.sandbox_viewer_effects_shoot =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const effectName = 'Shoot';
    const color = Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    let colorObject;
    try {
      colorObject = JSON.parse(color);
    } catch (error) {
      colorObject = {
        r: 0,
        g: 0,
        b: 0,
      };
    }
    if (!colorObject) {
      colorObject = {
        r: 0,
        g: 0,
        b: 0,
      };
    }
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { IE: effectName });
    Object.assign(payloadJson, { IC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
    Object.assign(payloadJson, { SE: effectName });
    Object.assign(payloadJson, { SC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
    Object.assign(payloadJson, { ME: effectName });
    Object.assign(payloadJson, { MC: `#${decToHex(colorObject.r, 2)}${decToHex(colorObject.g, 2)}${decToHex(colorObject.b, 2)}` });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("effects", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 4-4
 * topic: ghost
 * ghost 1
 */
Blockly.JavaScript.sandbox_viewer_ghost_robot =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const RATIO = block.getFieldValue('RATIO');
    const paramsString = `{'R':${ROBOT}, 'ME':'Ghost', 'IE':'Ghost', 'SE':'Ghost', 'PC':${RATIO}}`;
    return `await classRoom.sendMessageToSandboxViewer("transform", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 5-1
 * topic: trail
 * trail paint 1
 */
Blockly.JavaScript.sandbox_viewer_trail_enable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const duration = Blockly.JavaScript.valueToCode(
      block,
      'DURATION',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const width = Blockly.JavaScript.valueToCode(
      block,
      'WIDTH',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // start colour
    const startColourString = Blockly.JavaScript.valueToCode(
      block,
      'START_COLOUR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    let startColourObject;
    try {
      startColourObject = JSON.parse(startColourString);
    } catch (error) {
      startColourObject = null;
      console.reportError(error, 'Cannot parse start colour object:', startColourString);
    }
    startColourObject = startColourObject || {
      r: 0,
      g: 0,
      b: 0,
    };

    // end colour
    const endColourString = Blockly.JavaScript.valueToCode(
      block,
      'END_COLOUR',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    let endColourObject;
    try {
      endColourObject = JSON.parse(endColourString);
    } catch (error) {
      endColourObject = null;
      console.reportError(error, 'Cannot parse end colour object:', endColourString);
    }
    endColourObject = endColourObject || {
      r: 0,
      g: 0,
      b: 0,
    };

    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { SC: `#${decToHex(startColourObject.r, 2)}${decToHex(startColourObject.g, 2)}${decToHex(startColourObject.b, 2)}` });
    Object.assign(payloadJson, { EC: `#${decToHex(endColourObject.r, 2)}${decToHex(endColourObject.g, 2)}${decToHex(endColourObject.b, 2)}` });
    Object.assign(payloadJson, { D: Number(duration) || 0 });
    Object.assign(payloadJson, { W: Number(width) || 0 });
    // Object.assign(payloadJson, { A: true });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("trail", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 5-1-1
 * topic: trail
 * trail paint 2
 */
Blockly.JavaScript.sandbox_viewer_trail_enable_beginner =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const duration = 10;
    const width = 1;
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { SC: `#0000ff` });
    Object.assign(payloadJson, { EC: `#ffff00` });
    Object.assign(payloadJson, { D: Number(duration) || 0 });
    Object.assign(payloadJson, { W: Number(width) || 0 });
    // Object.assign(payloadJson, { A: true });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("trail", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 5-2
 * topic: trail
 * trail clear
 */
Blockly.JavaScript.sandbox_viewer_trail_disable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { A: false });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("trail", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 6-1
 * topic: tile
 * tile build 1
 */
Blockly.JavaScript.sandbox_viewer_tile_build =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const LAYER = block.getFieldValue('LAYER');
    const ACTIVATE = Blockly.JavaScript.valueToCode(
      block,
      'ACTIVATE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'R':${ROBOT}, 'LA':${LAYER}, 'P':true, 'S':false, 'Activate':${ACTIVATE}}`;
    return `await classRoom.sendMessageToSandboxViewer("tile", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 6-1-1
 * topic: tile
 * tile build 2
 */
Blockly.JavaScript.sandbox_viewer_tile_build_beginner =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const LAYER = -1;
    const ACTIVATE = Blockly.JavaScript.valueToCode(
      block,
      'ACTIVATE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'R':${ROBOT}, 'LA':${LAYER}, 'P':true, 'S':false, 'Activate':${ACTIVATE}}`;
    return `await classRoom.sendMessageToSandboxViewer("tile", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 6-2
 * topic: tile
 * tile destroy 1
 */
Blockly.JavaScript.sandbox_viewer_tile_destroy =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const LAYER = Blockly.JavaScript.valueToCode(
      block,
      'LAYER',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const ACTIVATE = Blockly.JavaScript.valueToCode(
      block,
      'ACTIVATE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'R':${ROBOT}, 'LA':${LAYER}, 'P':false, 'S':true, 'Activate':${ACTIVATE}}`;
    return `await classRoom.sendMessageToSandboxViewer("tile", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 6-2-1
 * topic: tile
 * tile destroy 1
 */
Blockly.JavaScript.sandbox_viewer_tile_destroy_beginner =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const LAYER = -1;
    const ACTIVATE = Blockly.JavaScript.valueToCode(
      block,
      'ACTIVATE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const paramsString = `{'R':${ROBOT}, 'LA':${LAYER}, 'P':false, 'S':true, 'Activate':${ACTIVATE}}`;
    return `await classRoom.sendMessageToSandboxViewer("tile", ${paramsString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 7-1
 * topic: sounds
 * sounds play 1
 */
Blockly.JavaScript.sandbox_viewer_sounds_enable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const soundName = block.getFieldValue('SOUND_NAME');
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const when = block.getFieldValue('WHEN');
    const payloadJson = {
      IE: 'Default',
      SE: 'Default',
      ME: 'Default',
    };
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    switch (when) {
      case 'idle':
        Object.assign(payloadJson, { IE: soundName });
        break;
      case 'one_shot':
        Object.assign(payloadJson, { SE: soundName });
        break;
      case 'moves':
      default:
        Object.assign(payloadJson, { ME: soundName });
        break;
    }
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("sounds", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 7-2
 * topic: sounds
 * sounds mute 1
 */
Blockly.JavaScript.sandbox_viewer_sounds_disable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const soundName = 'Default';
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const payloadJson = {
      IE: 'Default',
      SE: 'Default',
      ME: 'Default',
    };
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { IE: soundName });
    Object.assign(payloadJson, { SE: soundName });
    Object.assign(payloadJson, { ME: soundName });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("sounds", ${payloadString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 8-1
 * topic: speech
 * speech 1
 */
Blockly.JavaScript.sandbox_viewer_speech_show_info_panel_with_custom_colour =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const text = Blockly.JavaScript.valueToCode(
      block,
      'TEXT_INPUT',
      Blockly.JavaScript.ORDER_ATOMIC,
    );

    const textColour = parseColourObjectToHex(Blockly.JavaScript.valueToCode(
      block,
      'TEXT_COLOUR',
      Blockly.JavaScript.ORDER_ATOMIC,
    ));
    const bubbleColour = parseColourObjectToHex(Blockly.JavaScript.valueToCode(
      block,
      'BUBBLE_COLOUR',
      Blockly.JavaScript.ORDER_ATOMIC,
    ));
    const DURATION = Blockly.JavaScript.valueToCode(
      block,
      'DURATION',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    // const payloadJson = {};
    // Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    // Object.assign(payloadJson, { D: Number(DURATION) || 1 });
    // Object.assign(payloadJson, { M: text });
    // Object.assign(payloadJson, { TC: textColour });
    // Object.assign(payloadJson, { BC: bubbleColour });
    // const payloadString = JSON.stringify(payloadJson);
    const payloadJsonString = `{
      "R":${parseInt(ROBOT, 10)},
      "D":${Number(DURATION) || 1},
      "M":${text},
      "TC":"${textColour}",
      "BC":"${bubbleColour}",
    }`;
    return `await classRoom.sendMessageToSandboxViewer("speech", ${payloadJsonString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 8-2
 * topic: speech
 * speech 2
 */
Blockly.JavaScript.sandbox_viewer_speech_show_info_panel =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const text = block.getFieldValue('TEXT');
    // const payloadJson = {};
    // Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    // Object.assign(payloadJson, { D: 10 });
    // Object.assign(payloadJson, { M: text });
    // Object.assign(payloadJson, { TC: '#000000' });
    // Object.assign(payloadJson, { BC: '#FFFFFF' });
    // const payloadString = JSON.stringify(payloadJson);
    const payloadJsonString = `{
      "R":${parseInt(ROBOT, 10)},
      "D":10,
      "M":${text},
      "TC":"#000000",
      "BC":"#FFFFFF",
    }`;
    return `await classRoom.sendMessageToSandboxViewer("speech", ${payloadJsonString}, { id: '${block.id}', priority });\n`;
  });
/**
 * sandbox 8-3
 * topic: speech
 * speech 3
 */
Blockly.JavaScript.sandbox_viewer_speech_show_emoji =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const text = block.getFieldValue('EMOJI');
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { E: text });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("speech", ${payloadString}, { id: '${block.id}', priority });\n`;
  });

Blockly.JavaScript.sandbox_viewer_crunch_enable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const layer = block.getFieldValue('LAYER');
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { LA: Number(layer) || 0 });
    Object.assign(payloadJson, { W: 1 });
    Object.assign(payloadJson, { S: false });
    Object.assign(payloadJson, { P: true });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("crunch", ${payloadString}, { id: '${block.id}', priority });\n`;
  });

Blockly.JavaScript.sandbox_viewer_crunch_disable =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const layer = block.getFieldValue('LAYER');
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { LA: Number(layer) || 0 });
    Object.assign(payloadJson, { W: 1 });
    Object.assign(payloadJson, { S: true });
    Object.assign(payloadJson, { P: true });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("crunch", ${payloadString}, { id: '${block.id}', priority });\n`;
  });

Blockly.JavaScript.sandbox_viewer_terrain = (block) => {
  const environmentName = block.getFieldValue('ENVIRONMENT_NAME');
  const payloadJson = {};
  Object.assign(payloadJson, { TRN: environmentName });
  const payloadString = JSON.stringify(payloadJson);
  return `await classRoom.sendMessageToSandboxViewer("terrain", ${payloadString}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.sandbox_viewer_general =
  blockCheckerDecorator([
    // BLOCK_CHECKER__CHECK_ROBOT_OFFLINE, // don't need to check online
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const smoothingModifier = Blockly.JavaScript.valueToCode(
      block,
      'SMOOTHING_MODIFIER',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const timeOfFade = block.getFieldValue('TIME_OF_FADE') || 5;
    const payloadJson = {};
    Object.assign(payloadJson, { R: parseInt(ROBOT, 10) });
    Object.assign(payloadJson, { SM: Number(smoothingModifier) || 0 });
    Object.assign(payloadJson, { F: timeOfFade });
    const payloadString = JSON.stringify(payloadJson);
    return `await classRoom.sendMessageToSandboxViewer("general", ${payloadString}, { id: '${block.id}', priority });\n`;
  });

Blockly.JavaScript.sandbox_viewer_clear = (block) => {
  const payloadJson = {};
  const payloadString = JSON.stringify(payloadJson);
  return `await classRoom.sendMessageToSandboxViewer("clear", ${payloadString}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.sandbox_viewer_spawn_random_collectables = (block) => {
  const payloadJson = {
    spawn_random_collectables: true,
  };
  const payloadString = JSON.stringify(payloadJson);
  return `await classRoom.sendMessageToSandboxViewer("special", ${payloadString}, { id: '${block.id}', priority });\n`;
};

/*
 * old code not used anymore
 */
/*
Blockly.JavaScript.text__print = (block) => {
  const text = Blockly.JavaScript.valueToCode(
    block,
    'TEXT',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await F.log('%o', ${text});\n`;
};

Blockly.JavaScript.sensing__mapMarkXY = (block) => {
  const xy = block.getFieldValue('POSITION_NAME');
  // const pinned = classRoomStore.mapMark.get('pinned');
  return [
    `classRoom.getRuntimeVariable('mapMark-${xy}')`,
    Blockly.JavaScript.ORDER_FUNCTION_CALL,
  ];
  // [`${val || 0}`, Blockly.JavaScript.ORDER_NONE];
};

Blockly.JavaScript.motion_move__move_to_XY_with_self_adjustment =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    const xr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const yr = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToWithSelfAdjustment(${xr}, ${yr}, { id: '${block.id}', mode: ${mode}, priority });\n`;
  });

Blockly.JavaScript.motion_move__move_to_XY_stop_distance_with_self_adjustment =
  blockCheckerDecorator([
    BLOCK_CHECKER__CHECK_ROBOT_OFFLINE,
    BLOCK_CHECKER__CHECK_ROBOT_EXISTION,
  ])((block) => {
    const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
    cleanBlockWarning(block);
    checkRobotOffine(block, ROBOT);
    const xt = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_X',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const yt = Blockly.JavaScript.valueToCode(
      block,
      'POSITION_Y',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const dt = Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    );
    const mode = 1;
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveToStopDistanceWithSelfAdjustment(${xt}, ${yt}, ${dt},
    { id: '${block.id}', mode: ${mode}, priority });\n`;
  });

Blockly.JavaScript.motion_move__park = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const index = block.getFieldValue('INDEX');
  const { x, y } = mapSpecs[classRoomStore.mapId].parkingPosition(index);
  const mode = block.getFieldValue('MODE');
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .moveTo(${x}, ${y}, { id: '${block.id}', mode: ${mode}, priority });\n`;
};

Blockly.JavaScript.motion_mirror__follow = block =>
  jsCodeBy('motion_mirror__follow')(block, {
    ROBOT: getDropdownValue(block.getFieldValue('ROBOT')),
    TARGET: block.getFieldValue('TARGET'),
    DISTANCE: Blockly.JavaScript.valueToCode(
      block,
      'DISTANCE',
      Blockly.JavaScript.ORDER_ATOMIC,
    ),
  });

Blockly.JavaScript.motion_special__grab_object_by_halfway = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const xr = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_X',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const yr = Blockly.JavaScript.valueToCode(
    block,
    'POSITION_Y',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .grabObjectHalfWay(${xr}, ${yr}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.motion_special__oval = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const diameterX = Blockly.JavaScript.valueToCode(
    block,
    'DIAMETER_X',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const diameterY = Blockly.JavaScript.valueToCode(
    block,
    'DIAMETER_Y',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const leftRight = block.getFieldValue('LEFT_RIGHT');
  if (leftRight === 'LEFT') {
    return `await R({blocklyId: '${block.id}'}, ${ROBOT})
    .ovalLeft(${diameterX}, ${diameterY}, { id: '${block.id}', priority });\n`;
  }
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ovalRight(${diameterX}, ${diameterY},{ id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.beginner__headlights_set_colorObj = block =>
  jsCodeBy('leddiffuser_set_colorObj')(block, {
    ROBOT: getDropdownValue(block.getFieldValue('ROBOT')),
    COLOR: Blockly.JavaScript.valueToCode(
      block,
      'COLOR',
      Blockly.JavaScript.ORDER_ATOMIC,
    ),
    HEADLIGHT: 3,
  });

Blockly.JavaScript.sensing__led__read_whether_on = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  return [
    `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('S8', 'boolean')`,
    Blockly.JavaScript.ORDER_FUNCTION_CALL,
  ];
};
// abandoned
Blockly.JavaScript.sight_led__switch = block =>
  jsCodeBy('sight_led__switch')(block, {
    ROBOT: getDropdownValue(block.getFieldValue('ROBOT')),
    ON_OFF: Blockly.JavaScript.valueToCode(
      block,
      'ON_OFF',
      Blockly.JavaScript.ORDER_ATOMIC,
    ),
  });

Blockly.JavaScript.sensing__rgb_color = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  return [
    `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S4', 'int', ${block.getFieldValue('INDEX')})`,
    Blockly.JavaScript.ORDER_FUNCTION_CALL,
  ];
};

Blockly.JavaScript.sensing__tube_display = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  return [
    `R({blocklyId: '${block.id}'}, ${ROBOT})
    .readSensor('S57', 'int', ${block.getFieldValue('INDEX')})`,
    Blockly.JavaScript.ORDER_FUNCTION_CALL,
  ];
};

Blockly.JavaScript.sight_led__color = (block) => {
  const colour = block.getFieldValue('COLOUR');
  const code = COLORS.indexOf(colour) + 1 || 1;
  return [code, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript.sight_led_strip__light = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const index = Blockly.JavaScript.valueToCode(
    block,
    'INDEX',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const color = block.getFieldValue('COLOUR');
  const brightness = block.getFieldValue('BRIGHTNESS');
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripLight(${index}, '${color}', ${brightness}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.sight_led_strip__light_off = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const mode = block.getFieldValue('MODE');
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .ledStripLightOff(${mode}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.sight_tube_display__show = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const minutes = Blockly.JavaScript.valueToCode(
    block,
    'MINUTES',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  const seconds = Blockly.JavaScript.valueToCode(
    block,
    'SECONDS',
    Blockly.JavaScript.ORDER_ATOMIC,
  );
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .tubeDisplayShow(${minutes}, ${seconds}, { id: '${block.id}', priority });\n`;
};

Blockly.JavaScript.sendCommand = (block) => {
  const ROBOT = getDropdownValue(block.getFieldValue('ROBOT'));
  cleanBlockWarning(block);
  checkRobotOffine(block, ROBOT);
  const actuator = block.getFieldValue('ACTUATOR');
  const command = block.getFieldValue('COMMAND');
  const params = block.getFieldValue('PARAMS');
  return `await R({blocklyId: '${block.id}'}, ${ROBOT})
  .sendCommand('${actuator}', '${command}', [${params}], { id: '${block.id}', priority });\n`;
};
*/
// if the sensor.dataKeys is exist that means this sensor is a multdata sensor
/*
Object.values(sensorSpecs)
  .filter(
    sensor =>
      sensor.mode === "continuous" &&
      (!sensor.dataKeys || sensor.dataKeys.length === 1)
  )
  .forEach(sensor => {
    Blockly.JavaScript[`sensing__${sensor.varName}`] = block => {
      const ROBOT = block.getFieldValue("ROBOT");
      cleanBlockWarning(block);
      checkRobotOffine(block, ROBOT);
      checkSensorOnRobot(block, ROBOT, sensor.id);
      return [
        `R({blocklyId: '${block.id}'}, ${ROBOT}).readSensor('${sensor.id}', '${
          sensor.type
        }')`,
        Blockly.JavaScript.ORDER_FUNCTION_CALL
      ];
    };
  });
*/
/* multdata sensor are defined following */
