import swal from 'sweetalert2';
import monkeyify from 'monkeyify';

// monkeyify
swal.fire = monkeyify(swal.fire, {
  allowMonkeyInMonkey: 'returnOldMonkey',
  monkey: (caller, args, original) => {
    const arg0 = args[0];
    if ('object' === typeof arg0) {
      if ('type' in arg0) {
        arg0.icon = arg0.type;
        delete arg0.type;
      }
    }
    args[0] = arg0;
    return original.apply(caller, args);
  },
});

// eslint-disable-next-line
export const statusNotify = (arg1) => {
  const args = (typeof arg1 === 'string') ? { title: arg1 } : arg1;
  const {
    title,
    type,
    position,
    timer,
  } = args;
  return swal.fire({
    ...args,
    toast: true,
    position: position || 'bottom',
    type: type || 'warning',
    // backdrop: true,
    // background: '#000000',
    title,
    showConfirmButton: false,
    timer: timer || 3 * 1e3,
  });
};
swal.statusNotify = statusNotify;

let originalPopupErrorFunc = null;
swal.popupError = (...args) => {
  if (!originalPopupErrorFunc) {
    // eslint-disable-next-line
    const { popupError } = require('utils/error'); // a solution of a bug which caused by webpack
    originalPopupErrorFunc = popupError;
  }
  return originalPopupErrorFunc(...args);
};

export default swal;
