import Ajv from 'ajv';

const ajv = new Ajv();

export const schema = {
  type: 'object',
  properties: {
    topic: {
      type: 'string',
      pattern: 'to/classroom/(.+)/sensors_update',
    },
    payload: {
      type: 'object',
      properties: {
        R: {
          type: 'number',
        },
        S: {
          type: 'object',
        },
        STATUS: {
          type: 'object',
        },
      },
      required: ['R', 'S'],
    },
  },
  required: ['topic', 'payload'],
  additionalProperties: true,
};

const schemaCheck = ajv.compile(schema);

export const validator = schemaCheck;
